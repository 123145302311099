<template>
  <SendEmailForm
    :data="billing"
    :action-url="actionUrl"
    :action-name="actionName"
    :entity="entity"
    :subject="subject"
    :include-bcc-field="true"
    :extraData="printOptions"
    @save="emit('save')"
    @cancel="emit('cancel')"
    @template-obj-changed="selectedTemplateObj = $event"
  > 
    <template #extra-data>
      <BillingPrintOptions
        :billing="billing"
        :billingType="billingType"
        :data="printOptions"
        :selectedTemplateObj="selectedTemplateObj"
      />
    </template>
  </SendEmailForm>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import i18n from "@/i18n";
import {billingTypes} from "@/modules/accounts-receivable/pages/billings/billings";
import SendEmailForm from "@/components/form/SendEmailForm.vue";
import BillingPrintOptions from '@/modules/accounts-receivable/components/billings/BillingPrintOptions.vue';

const props = defineProps({
  billing: {
    type: Object,
    default: () => ({}),
  },
  billingType: {
    type: String,
    default: billingTypes.Service
  },
  entity: {
    type: String,
  }
})
const emit = defineEmits(['save', 'cancel'])

const selectedTemplateObj = ref({})

const printOptions = ref({
  flags: {
    print_retention: true,
    print_sales_tax: true,
    print_line_items: true,
  }
})

const subject = computed(() => {
  const titleMap = {
    [billingTypes.Service]: i18n.t('Service Invoice'),
    [billingTypes.Progress]: i18n.t('Progress Billing'),
    [billingTypes.UnitPrice]: i18n.t('Unit Price Billing'),
    [billingTypes.CostPlus]: i18n.t('Cost Plus Billing'),
    [billingTypes.LumpSum]: i18n.t('Lump Sum Billing'),
  }
  const title = titleMap[props.billingType]
  return `${title} #${props.billing.attributes.number}`
})

const actionUrl = computed(() => {
  return `/restify/billings/${props.billing.id}/actions`
})

const actionName = computed(() => {
  const actions = {
    [billingTypes.Service]: 'send-service-billing-mail',
    [billingTypes.Progress]: 'send-progress-billing-mail',
    [billingTypes.UnitPrice]: 'send-unit-price-billing-mail',
    [billingTypes.CostPlus]: 'send-cost-plus-billing-mail',
    [billingTypes.LumpSum]: 'send-lump-sum-billing-mail',
  }
  return actions[props.billingType]
})
</script>
