import i18n from '@/i18n'
import { addYears, eachQuarterOfInterval, format } from 'date-fns'
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import { globalResources } from '@/components/form/util'
import { calculateAmountAs } from '@/enum/payroll'
import { formatPercent } from '@/plugins/formatPercent'
import { formatPrice } from '@/plugins/formatPrice'
import get from 'lodash/get'

const multiplyByOptions = {
  'regular-hours': i18n.t('Regular Hours'),
  'overtime-hours': i18n.t('Overtime Hours'),
  'premium-hours': i18n.t('Premium Hours'),
  'total-hours': i18n.t('Total Hours'),
  'special-pay-units': i18n.t('Special Pay Units'),
  'regular-rate': i18n.t('Regular Rate (x 1.5, 2 on Ovt)'),

  'regular-hourly-pay': i18n.t('Regular Hourly Pay'),
  'total-hourly-pay': i18n.t('Total Hourly Pay'),
  'hourly-and-special-pay': i18n.t('Total Gross Pay'),
  'total-taxed-pay': i18n.t('Total Taxes Pay'),
}

const calculateByTexts = {
  rate: i18n.t('Rate on'),
  percentage: i18n.t('Percent of'),
}

const unionCodeApplication = i18n.t('Union Code for This Craft')

export function composeCodeApplication(source = {}) {
  const { calculate_by, multiply_by } = source
  if (!multiply_by) {
    return unionCodeApplication
  }
  return calculateByTexts[calculate_by] + ' ' + multiplyByOptions[multiply_by]
}

export const checkTypes = {
  NORMAL: 'normal',
  COMPUTE_PAYOFF_CHECK: 'compute-payoff-check',
  MANUAL_CHECK_ADJUST: 'manual-check-or-adjustment',
  NORMAL_CALC_TAX_ONLY: 'normal-calc-tax-only',
  NORMAL_TAX_ONLY_NO_DD: 'normal-tax-only-no-dd',
  NORMAL_NO_DD: 'normal-no-dd',
}

export const codeTypes = {
  PAY: 'pay-code',
  BEN: 'benefit-code',
  FLEX_BEN: 'flexible-benefit-plan',
  DEDUCTION: 'deduction-code',
  DIRECT_DEPOSIT_CODE: 'direct-deposit-code',
  GENERAL: 'general-code',
  TAX: 'tax-code',
  TIME_OFF: 'timeoff-policy',
  RETIREMENT_PLAN: 'retirement-plan',
  UNION: 'union',
  MATERIAL: 'material',
  EQUIPMENT: 'equipment',
  WTWO: 'w-two-code',
  VENDOR: 'vendor',
  LABOR: 'labor',
  CRAFT_CODE: 'craft-code',
}

export const codeLabels = {
  [codeTypes.PAY]: i18n.t('PAY'),
  [codeTypes.BEN]: i18n.t('BEN'),
  [codeTypes.DEDUCTION]: i18n.t('DED'),
  [codeTypes.TAX]: i18n.t('TAX'),
  [codeTypes.RETIREMENT_PLAN]: i18n.t('401K'),
  [codeTypes.FLEX_BEN]: i18n.t('125S'),
  [codeTypes.TIME_OFF]: i18n.t('TOFF'),
  [codeTypes.EQUIPMENT]: i18n.t('EQP'),
  [codeTypes.MATERIAL]: i18n.t('MAT'),
  [codeTypes.UNION]: i18n.t('UNION'),
}

export const codeLabelsByType = {
  [codeTypes.PAY]: i18n.t('Pay Code'),
  [codeTypes.BEN]: i18n.t('Benefit Code'),
  [codeTypes.DEDUCTION]: i18n.t('Deduction Code'),
  [codeTypes.DIRECT_DEPOSIT_CODE]: i18n.t('Direct Deposit Code'),
  [codeTypes.GENERAL]: i18n.t('General Code'),
  [codeTypes.TAX]: i18n.t('Tax code'),
  [codeTypes.EQUIPMENT]: i18n.t('Equipment'),
  [codeTypes.MATERIAL]: i18n.t('Material'),
  [codeTypes.MATERIAL]: i18n.t('Material'),
  [codeTypes.VENDOR]: i18n.t('Vendor'),
  [costCenterTypes.Material]: i18n.t('Material'),
  [costCenterTypes.Equipment]: i18n.t('Equipment'),
  [costCenterTypes.Labor]: i18n.t('Craft Code'),
  [codeTypes.TIME_OFF]: i18n.t('Time Off'),
  [codeTypes.RETIREMENT_PLAN]: i18n.t('Retirement Plan'),
  [codeTypes.FLEX_BEN]: i18n.t('Flexible Benefit'),
}

export const employeeRatesTypes = {
  PayRates: 'pay-rates',
  BenefitRates: 'benefit-rates',
  FlexibleBenefitRates: 'flexible-benefit-rates',
  DeductionRates: 'deduction-rates',
  RetirementRates: 'retirement-rates',
}

export const rateTypeFrequencies = {
  EveryPayPeriod: 'every',
  SelectedPayPeriod: 'selected',
  FirstPayPeriod: 'first',
  SecondPayPeriod: 'second',
  ThirdPeriod: 'third',
  FourthPayPeriod: 'fourth',
  LastPayPeriod: 'last',
  SelectedPeriod: 'selected',
  JobAndUnionOverride: 'job-and-union-override',
  JobOverride: 'job-override',
  UnionOverride: 'union-override',
}

export const rateTypeFrequencyOptions = {
  [employeeRatesTypes.PayRates]: [
    {
      label: i18n.t('Every Pay Period'),
      value: rateTypeFrequencies.EveryPayPeriod,
    },
    {
      label: i18n.t('Selected Pay Period'),
      value: rateTypeFrequencies.SelectedPayPeriod,
    },
    {
      label: i18n.t('1ST Employee Pay Period of Month'),
      value: rateTypeFrequencies.FirstPayPeriod,
    },
    {
      label: i18n.t('2ND Employee Pay Period of Month'),
      value: rateTypeFrequencies.SecondPayPeriod,
    },
    {
      label: i18n.t('3RD Employee Pay Period of Month'),
      value: rateTypeFrequencies.ThirdPeriod,
    },
    {
      label: i18n.t('4TH Employee Pay Period of Month'),
      value: rateTypeFrequencies.FourthPayPeriod,
    },
    {
      label: i18n.t('Last Employee Pay Period of Month'),
      value: rateTypeFrequencies.LastPayPeriod,
    },
  ],
  [employeeRatesTypes.DeductionRates]: [
    {
      label: i18n.t('Every Pay Period'),
      value: rateTypeFrequencies.EveryPayPeriod,
    },
    {
      label: i18n.t('Selected Pay Periods Only'),
      value: rateTypeFrequencies.SelectedPeriod,
    },
    {
      label: i18n.t('Job & Union Deduction Rate Override'),
      value: rateTypeFrequencies.JobAndUnionOverride,
    },
    {
      label: i18n.t('Job Deduction Rate Override'),
      value: rateTypeFrequencies.JobOverride,
    },
    {
      label: i18n.t('Union Deduction Rate Override'),
      value: rateTypeFrequencies.UnionOverride,
    },
  ],
  [employeeRatesTypes.RetirementRates]: [
    {
      label: i18n.t('Each Pay Period'),
      value: rateTypeFrequencies.EveryPayPeriod,
    },
    {
      label: i18n.t('Selected Pay Periods Only'),
      value: rateTypeFrequencies.SelectedPeriod,
    },
    {
      label: i18n.t('Job & Union Deduction Rate Override'),
      value: rateTypeFrequencies.JobAndUnionOverride,
    },
    {
      label: i18n.t('Job Deduction Rate Override'),
      value: rateTypeFrequencies.JobOverride,
    },
    {
      label: i18n.t('Union Deduction Rate Override'),
      value: rateTypeFrequencies.UnionOverride,
    },
  ],
  [employeeRatesTypes.BenefitRates]: [
    {
      label: i18n.t('Every Pay Period'),
      value: rateTypeFrequencies.EveryPayPeriod,
    },
    {
      label: i18n.t('Selected Pay Periods Only'),
      value: rateTypeFrequencies.SelectedPeriod,
    },
    {
      label: i18n.t('1ST Employee Pay Period of Month'),
      value: rateTypeFrequencies.FirstPayPeriod,
    },
    {
      label: i18n.t('2ND Employee Pay Period of Month'),
      value: rateTypeFrequencies.SecondPayPeriod,
    },
    {
      label: i18n.t('3RD Employee Pay Period of Month'),
      value: rateTypeFrequencies.ThirdPeriod,
    },
    {
      label: i18n.t('4TH Employee Pay Period of Month'),
      value: rateTypeFrequencies.FourthPayPeriod,
    },
    {
      label: i18n.t('Last Employee Pay Period of Month'),
      value: rateTypeFrequencies.LastPayPeriod,
    },
  ],
  [employeeRatesTypes.FlexibleBenefitRates]: [
    {
      label: i18n.t('Every Pay Period'),
      value: rateTypeFrequencies.EveryPayPeriod,
    },
    {
      label: i18n.t('Selected Pay Periods Only'),
      value: rateTypeFrequencies.SelectedPeriod,
    },
    {
      label: i18n.t('1ST Employee Pay Period of Month'),
      value: rateTypeFrequencies.FirstPayPeriod,
    },
    {
      label: i18n.t('2ND Employee Pay Period of Month'),
      value: rateTypeFrequencies.SecondPayPeriod,
    },
    {
      label: i18n.t('3RD Employee Pay Period of Month'),
      value: rateTypeFrequencies.ThirdPeriod,
    },
    {
      label: i18n.t('4TH Employee Pay Period of Month'),
      value: rateTypeFrequencies.FourthPayPeriod,
    },
    {
      label: i18n.t('Last Employee Pay Period of Month'),
      value: rateTypeFrequencies.LastPayPeriod,
    },
  ]
}

export const payrollStatuses = {
  PENDING: 'pending',
  POSTED: 'posted',
}

export const SPECIAL_PAY_UNITS_KEY = 'special-pay-units'
export const TOTAL_TAXED_PAY_KEY = 'total-taxed-pay'

export const multiplyBy = {
  [SPECIAL_PAY_UNITS_KEY]: 'special-pay-units',
  REGULAR_HOURS: 'regular-hours',
  OVERTIME_HOURS: 'overtime-hours',
  PREMIUM_HOURS: 'premium-hours',
  TOTAL_HOURS: 'total-hours',
  REGULAR_RATE: 'regular-rate',
  SPECIAL_PAY_UNITS: 'special-pay-units',
  REGULAR_HOURLY_PAY: 'regular-hourly-pay',
  TOTAL_HOURLY_PAY: 'total-hourly-pay',
  HOURLY_AND_SPECIAL_PAY: 'hourly-and-special-pay',
  TOTAL_TAXED_PAY: 'total-taxed-pay',
  TOTAL_NET_PAY: 'total-net-pay',
  INCLUDE_EXTRA: 'total-hourly-pay-at-regular-rate-plus-extra',
  TOTAL_EARNINGS: 'total-earnings',
}

const certifiedPayrollCategoryTypes = {
  VACATION: 'vacation',
  HEALTH_WELFARE: 'health-welfare',
  PENSION: 'pension',
  TRAINING: 'training',
  ADMIN_FUND: 'admin-fund',
  DUES: 'dues',
  TRAVEL_SUBS: 'travel-subs',
  OTHER: 'other',
  SAVINGS: 'savings',
  DISABILITY: 'disability',
  LIFE_INSURANCE: 'life-insurance',
  HOLIDAY: 'holiday',
}

export const CALCULATE_BY_RATE_KEY = 'rate'
export const CALCULATE_BY_PERCENTAGE_KEY = 'percentage'

export const calculateByOptions = [
  {
    label: i18n.t('Rate'),
    value: CALCULATE_BY_RATE_KEY,
  },
  {
    label: i18n.t('Percentage'),
    value: CALCULATE_BY_PERCENTAGE_KEY,
  },
]

export const multiplyOptions = {
  [CALCULATE_BY_RATE_KEY]: [
    {
      label: i18n.t('Regular Hours'),
      value: multiplyBy.REGULAR_HOURS,
    },
    {
      label: i18n.t('Overtime Hours'),
      value: multiplyBy.OVERTIME_HOURS,
    },
    {
      label: i18n.t('Premium Hours'),
      value: multiplyBy.PREMIUM_HOURS,
    },
    {
      label: i18n.t('Total Hours'),
      value: multiplyBy.TOTAL_HOURS,
    },
    {
      label: i18n.t('Special Pay Units'),
      value: SPECIAL_PAY_UNITS_KEY,
    },
    {
      label: i18n.t('Regular Rate (x 1.5, 2)'),
      value: multiplyBy.REGULAR_RATE,
    },
  ],
  [CALCULATE_BY_PERCENTAGE_KEY]: [
    {
      label: i18n.t('Regular Hourly Pay'),
      value: multiplyBy.REGULAR_HOURLY_PAY,
    },
    {
      label: i18n.t('Total Hourly Pay'),
      value: multiplyBy.TOTAL_HOURLY_PAY,
    },
    {
      label: i18n.t('Hourly + Spc Units Pay'),
      value: multiplyBy.HOURLY_AND_SPECIAL_PAY,
    },
    {
      label: i18n.t('Total Taxed Pay'),
      value: multiplyBy.TOTAL_TAXED_PAY,
    },
    {
      label: i18n.t('Total Net Pay'),
      value: multiplyBy.TOTAL_NET_PAY,
    },
  ],
}

export const categoryTypeOptions = [
  {
    label: i18n.t('Not Applicable'),
    value: null,
  },
  {
    label: i18n.t('Vacation'),
    value: certifiedPayrollCategoryTypes.VACATION,
  },
  {
    label: i18n.t('Health & Welfare'),
    value: certifiedPayrollCategoryTypes.HEALTH_WELFARE,
  },
  {
    label: i18n.t('Pension'),
    value: certifiedPayrollCategoryTypes.PENSION,
  },
  {
    label: i18n.t('Training'),
    value: certifiedPayrollCategoryTypes.TRAINING,
  },
  {
    label: i18n.t('Admin Fund'),
    value: certifiedPayrollCategoryTypes.ADMIN_FUND,
  },
  {
    label: i18n.t('Dues'),
    value: certifiedPayrollCategoryTypes.DUES,
  },
  {
    label: i18n.t('Travel Subs'),
    value: certifiedPayrollCategoryTypes.TRAVEL_SUBS,
  },
  {
    label: i18n.t('Other'),
    value: certifiedPayrollCategoryTypes.OTHER,
  },
  {
    label: i18n.t('Savings'),
    value: certifiedPayrollCategoryTypes.SAVINGS,
  },
  {
    label: i18n.t('Disability'),
    value: certifiedPayrollCategoryTypes.DISABILITY,
  },
  {
    label: i18n.t('Life Insurance'),
    value: certifiedPayrollCategoryTypes.LIFE_INSURANCE,
  },
  {
    label: i18n.t('Holiday'),
    value: certifiedPayrollCategoryTypes.HOLIDAY,
  },
]

export const piecePayOptions = [
  {
    label: i18n.t('? N/A'),
    value: null,
  },
  {
    label: i18n.t('Calculate rest and recovery'),
    value: 'calculate-rest-and-recovery',
  },
  {
    label: i18n.t('Post units to job'),
    value: 'post-units-to-job',
  },
  {
    label: i18n.t('Non productive time'),
    value: 'non-productive-time',
  },
]

export const rateTypes = {
  AMOUNT: 'amount',
  PERCENT: 'percent',
}

export const calculateBy = {
  Rate: CALCULATE_BY_RATE_KEY,
  Percentage: CALCULATE_BY_PERCENTAGE_KEY,
}

const composePickerShortcutsOptions = (interval) => {
  const shortcuts = interval.map(date => {
    return {
      text: format(date, 'MM/dd/yyyy'),
      onClick(picker) {
        picker.$emit('pick', format(date, 'yyyy-MM-dd'));
      }
    }
  })
  return { shortcuts }
}

export function composeSuggestionsForStartDate(startDate, years = 1) {
  const start = new Date(startDate)
  let end = addYears(start, years)
  let interval = eachQuarterOfInterval({
    start,
    end,
  })
  interval.shift()
  return composePickerShortcutsOptions(interval)
}

export const ratesWithStartDate = {
  Unions: 'union-rates',
  WorkerComp: 'workers-comp-rates',
  GenLiability: 'gen-liability-rates',
}

export const StateBasedOnEmployeeRateAndDeduction = 'WA'
export const EmployeeDeductionAmountField = 'employee_deduction_amount'

export const ratesSourceMap = {
  [codeTypes.PAY]: globalResources.PayCodes,
  [codeTypes.BEN]: globalResources.BenefitCodes,
  [codeTypes.DEDUCTION]: globalResources.DeductionCodes,
  [codeTypes.TAX]: globalResources.TaxCodes,
  [codeTypes.UNION]: globalResources.UnionCodes,
  default: '',
}

export const ratesSourceTypeOptions = [
  {
    label: i18n.t('PAY'),
    value: codeTypes.PAY,
  },
  {
    label: i18n.t('BEN'),
    value: codeTypes.BEN,
  },
  {
    label: i18n.t('DED'),
    value: codeTypes.DEDUCTION,
  },
  {
    label: i18n.t('TAX'),
    value: codeTypes.TAX,
  },
  {
    label: i18n.t('Union'),
    value: codeTypes.UNION,
  },
]

export function formatCalculateByAmounts(row, value) {
  const calculate_by = get(row, 'attributes.calculate_by', calculateAmountAs.Rate)
  if (calculate_by === calculateAmountAs.Percentage) {
    return formatPercent(value, {
      maximumFractionDigits: 3,
    })
  }
  return formatPrice(value, {
    maximumFractionDigits: 5,
  })
}

export const accumulationIntervalOptions = [
  {
    label: i18n.t('Accumulated'),
    value: 'cumulative',
  },
  {
    label: i18n.t('Annual'),
    value: 'annual',
  },
  {
    label: i18n.t('Monthly'),
    value: 'monthly',
  },
]
