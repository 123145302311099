<template>
  <div>
    <span class="action-item-text"
          @click="triggerPrint">
        <div class="p-2 bg-primary-50 rounded-md mr-2">
          <PrinterIcon class="w-4 h-4 text-primary-500"/>
        </div>
        <span>{{ $t('Print') }}</span>
    </span>
    <BaseFormDialog
        v-if="showDialog"
        :title="printDialogTitle"
        :open.sync="showDialog"
        :appendToBody="true"
        size="sm"
    >
      <BaseForm
          :show-cancel="true"
          :save-text="$t('Print')"
          :loading="loading"
          :focus-on-first-input="false"
          layout="modal"
          @cancel="showDialog = false"
          @submit="printReport"
      >
        <BaseSelect
            v-model="orientation"
            :label="$t('Select Print Orientation')"
            :options="reportPrintOrientationOptions"
            class="col-span-6"
        />
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>

<script>
  import axios from 'axios'
  import capitalize from 'lodash/capitalize'
  import { PrinterIcon } from 'vue-feather-icons'
  import { printAction } from '@/components/ag-grid/printUtils'
  import {
    getFiltersByEntity,
    getReportNumber,
    hasRelatedReport,
  } from '@/components/table/utils/printActionUtils'
  import { previewFileInNewTab } from '@/modules/common/util/downloadFileLocally'
  import { reportPrintOrientationOptions, reportPrintOrientations } from '@/utils/reports'

  export default {
    name: 'print-action',
    components: {
      PrinterIcon,
    },
    props: {
      manual: Boolean,
      entity: String,
      filters: Object,
    },
    data() {
      return {
        loading: false,
        showDialog: false,
        orientation: this.$store.getters['company/getPrintReportOrientation'] || reportPrintOrientations.Portrait,
        reportPrintOrientationOptions,
      }
    },
    computed: {
      hasRelatedReport() {
        return hasRelatedReport(this.entity)
      },
      printDialogTitle() {
        const formattedTitle = capitalize(this.entity)
        return this.$t(`Print - ${formattedTitle}`)
      },
      reportNumber() {
        return getReportNumber(this.entity)
      },
    },
    methods: {
      async triggerPrint() {
        if (this.hasRelatedReport) {
          return this.showDialog = true
        }

        printAction()
      },
      async printReport() {
        try {
          this.showDialog = false

          this.$success(this.$t('Your file is on its way! Just a bit longer. Thank you for your patience.'))

          let payload = getFiltersByEntity(this.entity, this.filters)

          payload['orientation'] = this.orientation

          const url = `/reports/print-report-${this.reportNumber}`

          const pdfData = await axios.post(url, payload, {
            responseType: 'blob',
          })

          previewFileInNewTab(pdfData)

        } catch (err) {
          if (err.handled) {
            return
          }

          console.warn(err)

          this.$error(this.$t('Could not print data. Please try again.'))
        }
      }
    },
  }
</script>
