<template>
  <div>
    <InvoiceDetailsPage
      :data="purchaseOrderAdjustment"
      :loading="poAdjustmentLoading"
      :historyEntity="$t('Purchase Order Adjustment')"
      :hide-attachments="true"
      entity="purchase-order-adjustments"
    >
      <template #title>
        <InvoiceStatusPopover
          :invoice="purchaseOrderAdjustment"
          resource-name="purchase-orders"
          placement="left"
        />
        <ReviewStatus
          v-if="showApproveStatus"
          :row="purchaseOrderAdjustment"
          class="ml-2"
        />
      </template>

      <template #title-right>
        <div class="flex items-center">
          <span class="text-base print:text-print leading-6 font-semibold text-gray-900 mr-2">
            {{ $t('Extended Amount:') }}
          </span>
          <div
            class="bg-gray-900 bg-opacity-5 font-medium rounded-md py-1 px-4 print:px-1 print:text-print mr-3 tracking-wide">
            {{ $formatPrice(poExtendedAmount) }}
          </div>
          <span class="text-base leading-6 font-semibold text-gray-900 mr-2">
            {{ $t('->') }}
          </span>
          <div class="bg-gray-900 bg-opacity-5 font-medium rounded-md py-1 px-4 tracking-wide">
            {{ $formatPrice(extendedAmount) }}
          </div>
        </div>
      </template>

      <template #header-left>
        <div class="flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0">
          <VendorContactInfo :title="$t('From')" :vendor="vendor"/>
          <CompanyContactInfo :title="$t('Billed To')" :company="$currentCompany"/>
        </div>
      </template>

      <template #content>
        <InvoiceDescription
          v-if="purchaseOrder.id"
          :invoice="purchaseOrder"
          :can-edit="false"
          entity="purchase-order-adjustments"
        />
        <PurchaseOrderAdjustItems
          v-show="purchaseOrderAdjustment.id"
          :data="purchaseOrderAdjustment"
          readOnly
          class="p-4"
        />
        <div
          v-if="!poAdjustmentLoading && purchaseOrder.invoiced_amount > 0"
          class="p-4">
          <Elcollapse>
            <BaseCollapseSection :title="$t('Purchase Order Invoices')">
              <InvoiceTable
                :invoice-status="resourceStatuses.All"
                :url-params="invoiceUrlParams"
                :hide-all-actions="true"
              />
            </BaseCollapseSection>
          </Elcollapse>
        </div>
      </template>

      <template #totals>
        <dl v-if="!poAdjustmentLoading" class="col-span-3 md:col-span-1 divide-y divide-gray-100">
          <div class="totals-row">
            <span>
              {{ $t('Sub-Total') }}
            </span>
            <span>
              {{ comparePriceFormatter(purchaseOrder.amount, purchaseOrderAdjustment?.attributes?.amount) }}
            </span>
          </div>
          <div class="totals-row">
            <span>
              {{ $t('Sales Tax') }}
            </span>
            <span>
              {{
                comparePriceFormatter(purchaseOrder.sales_tax_amount, purchaseOrderAdjustment?.attributes?.sales_tax_amount)
              }}
            </span>
          </div>
          <div class="totals-row">
            <span>
              {{ $t('Freight') }}
            </span>
            <span>
              {{
                comparePriceFormatter(purchaseOrder.freight_amount, purchaseOrderAdjustment?.attributes?.freight_amount)
              }}
            </span>
          </div>
          <div class="totals-row bg-gray-50">
            <span>
              {{ $t('Total') }}
            </span>
            <span>
              {{ comparePriceFormatter(poExtendedAmount, extendedAmount) }}
            </span>
          </div>
        </dl>
      </template>

      <template #attachments>
        <FileAttachments
          v-if="purchaseOrder.id"
          :entityId="purchaseOrder.id"
          :authorizedToAttachFiles="false"
          :authorizedToRemoveFiles="false"
          :title="$t('Purchase Order Attachments')"
          entity="purchase-orders"
        />
      </template>

    </InvoiceDetailsPage>
    <BaseBackLink
      v-if="showBack"
      to="/purchasing-inventory/purchase-orders-adjustments/pending"
    />
  </div>
</template>

<script>
import VendorContactInfo from '@/modules/accounts-payable/components/invoice/VendorContactInfo'
import CompanyContactInfo from '@/modules/accounts-payable/components/invoice/CompanyContactInfo'
import InvoiceDetailsPage from '@/components/invoice/InvoiceDetailPage'
import InvoiceTable from "@/modules/accounts-payable/pages/invoices/InvoiceTable.vue";
import { resourceStatuses } from "@/enum/enums";
import ReviewStatus from "@/components/links/ReviewStatus.vue";
import PurchaseOrderAdjustItems
  from "@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderAdjustItems.vue";
import { comparePriceFormatter } from "../../components/purchase-orders/util";
import InvoiceDescription from "@/modules/accounts-payable/components/invoice/InvoiceDescription.vue";

export default {
  components: {
    InvoiceDescription,
    PurchaseOrderAdjustItems,
    ReviewStatus,
    InvoiceTable,
    VendorContactInfo,
    InvoiceDetailsPage,
    CompanyContactInfo,
  },
  props: {
    id: String,
    showBack: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      loading: false,
      lineItems: [],
      resourceStatuses,
    }
  },
  computed: {
    purchaseOrderAdjustment() {
      return this.$store.state.inventoryManagement.currentPurchaseOrderAdjustment
    },
    purchaseOrder() {
      const isPosted = this.purchaseOrderAdjustment?.attributes?.status === resourceStatuses.Posted
      if (isPosted) {
        return this.purchaseOrderAdjustment?.attributes?.meta?.prior || {}
      }
      return this.purchaseOrderAdjustment?.relationships?.purchaseOrder?.attributes || {}
    },
    poExtendedAmount() {
      const { amount, freight_amount, sales_tax_amount, extended_amount } = this.purchaseOrder || {}
      if (!extended_amount) {
        return amount + freight_amount + sales_tax_amount
      }
      return extended_amount
    },
    extendedAmount() {
      const {
        amount,
        freight_amount,
        sales_tax_amount,
        extended_amount
      } = this.purchaseOrderAdjustment?.attributes || {}
      if (!extended_amount) {
        return amount + freight_amount + sales_tax_amount
      }
      return extended_amount
    },
    showApproveStatus() {
      const status = this.purchaseOrderAdjustment?.attributes?.status
      return [resourceStatuses.Pending].includes(status)
    },
    poAdjustmentLoading() {
      return this.$store.state.inventoryManagement.poAdjustmentLoading
    },
    invoiceUrlParams() {
      return {
        purchase_order_id: this.purchaseOrder?.id,
      }
    },
    vendorId() {
      return this.purchaseOrder?.vendor_id
    },
    vendor() {
      return this.$store.getters['globalLists/getResourceById'](this.$globalResources.Vendors, this.vendorId)
    },
  },
  methods: {
    comparePriceFormatter
  },
}
</script>
