<template>
  <div class="truncate"
       :title="fullTitle">
    <router-link v-if="codeId && isLink"
                 :to="`/service-billing/settings/codes?id=${codeId}`"
                 :target="target"
    >
      <span>
        {{ code }}
      </span>
      <span class="print:hidden" v-if="description && showDescription">
        ({{ description }})
      </span>
    </router-link>
    <template v-else>
      <span>
        {{ code }}
      </span>
      <span class="print:hidden" v-if="description && showDescription">
        ({{ description }})
      </span>
    </template>
  </div>
</template>
<script>
  export default {
    name: 'ServiceCodeLink',
    props: {
      id: [String, Number],
      data: {
        type: Object,
        default: () => ({}),
      },
      target: String,
      isLink: {
        type: Boolean,
        default: true,
      },
      showDescription :{
        type: Boolean,
        default: true,
      }
    },
    computed: {
      serviceCode() {
        const idField = this.id || this.get(this.data, 'attributes.code_id') || this.get(this.data, 'code_id')
        return this.$store.state.globalLists.serviceBillingCodes?.find(a => a.id === idField)
      },
      code() {
        return this.serviceCode?.code
      },
      description() {
        return this.serviceCode?.description
      },
      codeId() {
        return this.serviceCode?.id
      },
      fullTitle() {
        return `${this.code} (${this.description})`
      }
    },
  }
</script>
