<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :hide-presets="true"
    :consistentRowColor="true"
  >
    <template #filters>
      <BaseFilterRow
        :title="$t('Report Format')"
      >
        <BaseSelect
          v-model="format"
          :add-entity="false"
          :options="formatOptions"
          class="col-span-3"
        />
      </BaseFilterRow>
      <BaseFilterRow
        :title="$t('State')"
      >
        <StateSelect
          v-model="model.state"
          class="col-span-3"
          clearable
          @clear="model.state = null"
        />
      </BaseFilterRow>
      <BaseFilterRow
        :title="$t('Tax Year')"
      >
        <BaseInput
          v-model="model.tax_year"
          class="col-span-3"
          :name="$t('Tax Year')"
          :placeholder="$t('Tax Year (YYYY)')"
          type="number"
          rules="required|min_value:2014"
        />
      </BaseFilterRow>
      <BaseFilterRow
        :title="$t('Filter Untaxed Employees')"
      >
        <BaseSwitch
          v-model="model.filter_untaxed_employees"
          id="filter_untaxed_employees"
          class="col-span-3"
        />
      </BaseFilterRow>
    </template>

    <template #table="{ data, loading }">
      <div v-if="isTextFileList">
        <TextFileList
          :data="data?.fileData ? [data.fileData] : []"
          :loading="loading"
          :file-name="data.fileName"
        />
      </div>
      <EmployeeW2ProofListingReportTable
        v-else-if="format === formats.W2_ProofListing"
        :data="data"
        :filters="model"
        :data-loading="loading"
        :report-number="reportNumber"
        ref="reportTable"
      />
      <div v-else>
        Not implemented
      </div>
    </template>

  </BaseReportForm>
</template>
<script>
import TextFileList from "@/modules/payroll/components/reports/TextFileList.vue";
import EmployeeW2ProofListingReportTable from "@/modules/payroll/components/reports/employee-w2s/EmployeeW2ProofListingReportTable.vue";
import {
  EmployeeW2SOptions
} from '@/modules/payroll/components/reports/util'

export default {
  components: {
    TextFileList,
    EmployeeW2ProofListingReportTable,
  },
  data() {
    return {
      formats: EmployeeW2SOptions.Formats,
      format: EmployeeW2SOptions.Formats.W2_ProofListing,
      model: {
        single_page: false,
        state: null,
        tax_year: null,
        filter_untaxed_employees: false,
      },
    }
  },
  computed: {
    reportNumber() {
      const reportMap = {
        [EmployeeW2SOptions.Formats.MagMediaEFW2_MMREF]: '4884fe',
        default: '4884',
      }
      return reportMap[this.format] || reportMap.default
    },
    formatOptions() {
      const formatOptions = [
        {
          label: this.$t('W-2 Proof Listing'),
          value: EmployeeW2SOptions.Formats.W2_ProofListing,
        },
        // Will be implemented in the future
        // {
        //   label: this.$t('W-2 Paper Forms'),
        //   value: EmployeeW2SOptions.Formats.W2_PaperForms,
        // },
        // {
        //   label: this.$t('W-2 MMREF/EFW2 E-File'),
        //   value: EmployeeW2SOptions.Formats.W2_MMRef_EFW2_EFile,
        // },
        // {
        //   label: this.$t('W-3 Paper Form'),
        //   value: EmployeeW2SOptions.Formats.W3_PaperForm,
        // },
        // {
        //   label: this.$t('W-2 CSV Export'),
        //   value: EmployeeW2SOptions.Formats.W2_CSVExport,
        // },
      ]
      
      return formatOptions
    },
    currentUser() {
      return this.$store.state.auth.user
    },
    isTextFileList() {
      return [
        this.formats.W2_MMRef_EFW2_EFile,
      ].includes(this.format)
    },
    payrollCalendarYear() {
      return this.$settings(this.$modules.PR, 'calendar_year')
    }
  },
  watch: {
    payrollCalendarYear: {
      handler(value) {
        if (this.model.tax_year) {
          return
        }

        this.model.tax_year = value
      },
      immediate: true,
    },
  }
}
</script>
