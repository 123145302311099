<template>
  <div class="w-full flex justify-center">
    <div
        :class="{
          'max-w-5xl': columns.length < 5,
        }"
        class="w-full mb-4"
    >
      <ag-data-table
          v-bind="defaultAttributes"
          :columns="columns"
          :data="rows"
          :total-rows="rowsLength"
          :has-summary="false"
          :pagination="false"
          dom-layout="autoHeight"
          @force-pagination="forcePagination"
      >
        <template #additional-actions>
          <StimulsoftPrintButton
            :data="data"
            :entity="StimulsoftPrintEntities.FinancialReport"
          />
        </template>

        <template #description="{row}">
          <div v-if="row.description" v-html="row.description"/>
        </template>
        <template #thead-infos>
          <tr>
            <th colspan="100%" class="thead-infos report-header-row">
              <BaseReportHeader :header="data.header"/>
            </th>
          </tr>
        </template>
      </ag-data-table>
    </div>
  </div>
</template>
<script lang="ts">
  import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper.vue'
  import { defineComponent } from 'vue'
  import { Column } from "@/components/ag-grid/tableTypes";
  import {CellClassParams, ValueFormatterParams, ValueGetterParams} from '@ag-grid-community/core';
  import { formatPercent } from "@/plugins/formatPercent";
  import { CellStyleType, FinancialReportRow, FinancialReportStyle } from "@/modules/ledger/enum/financialReports";
  import ReportPrintAction from "@/components/table/ReportPrintAction.vue";
  import {StimulsoftPrintEntities} from "@/enum/stimulsoft";

  export default defineComponent({
    components: {
      ReportPrintAction
    },
    extends: ReportTableWrapper,
    props: {
      filters: {
        type: Object,
        default: () => ({}),
      }
    },
    data() {
      return {
        StimulsoftPrintEntities,
      }
    },
    computed: {
      columns(): Column<FinancialReportRow>[] {
        let headerColumns = this.data.header?.columns || []
        headerColumns = headerColumns.map((column: any, index: number) => {
          const prop = `signed_columns[${index}].amount` || `columns2[${index}].amount`
          const percentProp = `signed_columns[${index}].percent` || `columns2[${index}].percent`

          return {
            label: column,
            prop,
            minWidth: 100,
            maxWidth: 200,
            valueGetter: (params: ValueGetterParams) => {
              return this.get(params.data, prop)
            },
            valueFormatter: (params: ValueFormatterParams) => {
              let percent = this.get(params.data, percentProp)
              let amount = this.get(params.data, prop)
              let formattedPercent = ''
              if (params.data.print_percent) {
                formattedPercent = formatPercent(percent)
              }
              const formattedAmount = this.formatAmount(amount)
              return `${formattedAmount} ${formattedPercent}`
            },
            cellClass: (params: CellClassParams) => {
              const level = params.data.level as number
              const levelMapping: Record<number, string> = {
                0: '',
                1: 'font-semibold',
                2: 'font-bold',
              }
              const cellStyles = this.getCellClassStyles(params.data.style)
              return `${levelMapping[level] || ''} ${cellStyles} flex justify-end`
            }
          }
        })
        return [
          {
            label: this.$t('Description'),
            prop: 'description',
            wrapText: true,
            autoHeight: true,
            minWidth: 200,
            cellClass: (params: CellClassParams) => {
              let level = params.data.level as number
              const levelMapping: Record<number, string> = {
                0: '!pl-10',
                1: '!pl-4 font-semibold',
                2: 'font-bold',
              }
              if (params.data.is_title && level?.toString() === '0') {
                level = 1
              }
              return `${levelMapping[level] || ''}`
            },
          },
          ...headerColumns,
        ]
      },
    },
    methods: {
      getCellClassStyles(style: CellStyleType) {
        const mapping: Record<string, string> = {
          [FinancialReportStyle.Dashed]: 'cell-dashed',
          [FinancialReportStyle.DoubleBorder]: 'cell-double',
          [FinancialReportStyle.SingleBorder]: 'cell-single',
          [FinancialReportStyle.Solid]: 'bg-gray-100',
          default: ''
        }
        return mapping[style] || mapping.default
      },
      composeRows(data: any) {
        this.rows = data.map((row: any) => {
          return {
            _localId: crypto.randomUUID(),
            ...row,
          }
        })
      },
      formatAmount(amount: number) {
        const fractionDigits = this.filters.round_to_whole_dollars ? 0 : 2
        const options = {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        }
        return this.$formatPrice(amount, options)
      }
    },
  })
</script>
<style lang="scss">
  .no-borders .ag-theme-alpine .ag-cell:not(.ag-cell-focus) {
    border-right: none;
    &.cell-single {
      border-bottom: 1px solid theme('colors.gray.400');
    }

    &.cell-dashed {
      border-bottom: 1px dashed theme('colors.gray.400');
    }

    &.cell-double {
      border-bottom: 1px solid theme('colors.gray.400');
      &:after {
        content: '';
        bottom: 2px;
        @apply absolute inset-x-0 border-b border-gray-400;
      }
    }
  }
</style>
