<template>
  <ExportInitsButton
    :url="exportUrl"
    :columns="columns"
    :url-params="{
      journal_id: id,
    }"
    class="mr-4 flex"
    v-bind="$attrs"
  />
</template>
<script>
import ExportInitsButton from "@/modules/common/components/export/ExportInitsButton.vue";

export default {
  components: {
    ExportInitsButton
  },
  props: {
    id: {
      type: String,
    },
    isPosted: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    exportUrl() {
      if (this.isPosted) {
        return `/restify/transactions/actions?action=journal-entry-transaction-export`
      }
      return `/restify/journal-entries/actions?action=journal-entry-export-action`
    },
    pendingColumns() {
      return [
        'account',
        'subaccount',
        'description',
        'journal.name',
        'journal.type',
        'reference_no',
        'reference_date',
        'amount',
      ]
    },
    postedColumns() {
      return [
        'account.number',
        'subaccount.number',
        'description',
        'journal.name',
        'journal.type',
        'reference_no',
        'reference_date',
        'amount',
        'status',
      ]
    },
    columns() {
      if (this.isPosted) {
        return this.postedColumns
      }
      return this.pendingColumns
    }
  }
}
</script>
