<template>
  <div>
    <div class="col-span-3 w-full flex justify-end print:bg-transparent pb-4">
      <BaseButton
        @click="onCancelSelection"
        variant="danger-link"
        class="mr-2"
      >
        {{ $t('Reset selections') }}
      </BaseButton>

      <ProofListingButton
        :callback-action="onViewProofListing"
        :disabled="!validPayments.length"
        path=""
      />
    </div>
    <AgDataTable
      :data="tableData"
      :columns="columns"
      v-bind="editableTableProps"
      :get-empty-row="getEmptyRow"
      :show-cells-legend="true"
      :add-text="$t('Payment')"
      :masterDetail="true"
      :detailCellRenderer="detailCellRenderer"
      :default-filters="false"
      actions="add"
      :deleteTitle="$t('Delete Payment')"
      :deleteDescription="$t('Are you sure you want to delete this payment?')"
      @add="onRowAdded"
      @grid-ready="grid = $event"
    >
      <template #header-info>
        <SummaryPill
          label="Amount"
          :value="totalAmounts.amount"
        />
        <SummaryPill
          label="Transfer Deposit"
          :value="totalAmounts.deposit"
        />
        <SummaryPill
          label="Discount"
          :value="totalAmounts.discount"
        />
      </template>
    </AgDataTable>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { editableTableProps } from '@/components/ag-grid/tableUtils'
import { CustomerPayment, StorageKey, PaymentsChangedKey } from '@/modules/accounts-receivable/pages/payments/customerPaymentTypes'
import { cellEditors } from '@/components/ag-grid/cellEditors/cellEditors'
import { cellClasses, requiredValueSetter } from '@/components/ag-grid/columnUtils'
import CustomerPaymentDetailCellRenderer
from '@/modules/accounts-receivable/pages/payments/CustomerPaymentDetailCellRenderer.vue'
import {
  CellClassParams,
  GridReadyEvent,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
  RefreshCellsParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from '@ag-grid-community/core'
import { Column } from '@/components/ag-grid/tableTypes'
import { Customer } from '@/modules/common/types/models'
import { useStorage } from '@vueuse/core'
import { dateTypes, formatDate } from '@/plugins/dateFormatPlugin'
import { $modules, BankUsedInTypes } from '@/enum/enums'
import { getSetting } from '@/plugins/settingsAndEnumsPlugin'
import { getAvailableCashOnDeposit } from '@/modules/accounts-receivable/pages/payments/customerPaymentUtils'
import { globalResources } from '@/components/form/util'
import i18n from '@/i18n'
import sumBy from 'lodash/sumBy'
import cloneDeep from 'lodash/cloneDeep'
import Data = API.Data
import { getDeleteColumn } from '@/components/ag-grid/columns/deleteColumns'

export default defineComponent({
  components: {
    CustomerPaymentDetailCellRenderer,
  },
  setup() {
    let defaultData: CustomerPayment[] = []
    try {
      defaultData = []
    } catch (err) {
      console.log(err)
    }
    const data = useStorage<CustomerPayment[]>(StorageKey, defaultData, sessionStorage)
    const dataChangedTrigger = useStorage<number>(PaymentsChangedKey, 1, sessionStorage)
    return {
      data,
      dataChangedTrigger,
    }
  },
  data() {
    return {
      tableData: [] as CustomerPayment[],
      grid: null as GridReadyEvent | null,
      editableTableProps,
      detailCellRenderer: 'CustomerPaymentDetailCellRenderer',
      selectedCustomers: [] as string[],
    }
  },
  computed: {
    validPayments() {
      return this.data.filter(this.isPaymentValid)
    },
    totalAmounts() {
      let amounts = {
        amount: 0,
        deposit: 0,
        discount: 0,
      }
      const tableData = this.data
      tableData.forEach(payment => {
        amounts.amount += +payment.amount

        payment.billings.forEach(billing => {
          amounts.deposit += +billing.from_cash_on_deposit_amount
          amounts.discount += +billing.discount_amount
        })
      })
      return amounts
    },
    columns(): Column[] {
      return [
        {
          headerName: this.$t('Customer'),
          field: 'customer_id',
          component: 'CustomerLink',
          cellRendererParams: {
            showName: true,
          },
          editable: true,
          cellEditor: cellEditors.CustomerSelect,
          cellEditorParams: (params: ICellEditorParams) => {
            return {
              urlParams: {
                sort: '-current_ar_amount',
              },
              showOpenAmount: true,
              showCashOnDeposit: true,
              onValueChanged: (params: ICellEditorParams, value: any, fullValue: Data<Customer>) => {
                // Reset billings
                params.node.setDataValue('billings', [])
              },
            }
          },
          minWidth: 100,
          valueSetter: (params: ValueSetterParams) => {
            this.expandRow(params?.node?.rowIndex || 0)
            return requiredValueSetter(params)
          },
          cellClass: ({data}: CellClassParams) => data?.customer_id ? '' : cellClasses.Invalid,
        },
        {
          field: 'customer',
          hide: true,
        },
        {
          field: 'billings',
          hide: true,
        },
        {
          headerName: this.$t('Bank'),
          field: 'bank_id',
          component: 'BankLink',
          cellRendererParams: (params: ICellRendererParams) => {
            return {
              id: params.data.bank_id,
            }
          },
          editable: true,
          cellEditor: cellEditors.BankSelect,
          cellEditorParams: {
            usedFor: BankUsedInTypes.AccountsReceivable,
          },
          minWidth: 100,
          cellClass: ({data}: CellClassParams) => data?.bank_id ? '' : cellClasses.Invalid,
        },
        {
          headerName: this.$t('Payment Reference'),
          field: 'number',
          minWidth: 80,
          editable: true,
          valueSetter: (params) => {
            requiredValueSetter(params)
            this.saveSelectionToStorage()
            return true
          },
          cellClass: ({data}: CellClassParams) => data?.number ? '' : cellClasses.Invalid,
        },
        {
          headerName: this.$t('Date'),
          field: 'date',
          minWidth: 100,
          editable: true,
          component: 'FormattedDate',
          cellEditor: cellEditors.DatePicker,
          cellClass: ({data}: CellClassParams) => data?.date ? '' : cellClasses.Invalid,
        },
        {
          headerName: this.$t('Prepaid Deposit'),
          field: 'deposit',
          minWidth: 100,
          editable: true,
          component: 'Status',
          cellEditor: cellEditors.Boolean,
          valueSetter: params => {
            if (params.newValue) {
              params.data.amount = 0
              params.data.apply_to_billings_amount = 0
            }
            const value =  requiredValueSetter(params)
            this.saveSelectionToStorage()
            return value
          },
        },
        {
          headerName: this.$t('Adjustments'),
          field: 'adjustments',
          minWidth: 100,
          editable: params => {
            return !params.data.deposit
          },
          component: 'Status',
          cellEditor: cellEditors.Boolean,
          valueSetter: params => {
            if (params.newValue) {
              params.data.amount = 0
              params.data.apply_to_billings_amount = 0
            }
            return requiredValueSetter(params)
          },
        },
        {
          headerName: this.$t('Amount'),
          field: 'amount',
          minWidth: 100,
          editable: params => !params.data.adjustments,
          cellClass: ({data}: CellClassParams) => {
            return data.adjustments ? cellClasses.ReadOnly : ''
          },
          component: 'FormattedPrice',
          cellEditor: cellEditors.Numeric,
          valueSetter: (params: ValueSetterParams) => {
            params.newValue = +params.newValue
            if (params.newValue && !params.data.deposit) {
              params.data.apply_to_billings_amount = +params.newValue
            }
            const value = requiredValueSetter(params, 0)
            this.saveSelectionToStorage()
            return value
          },
          suppressKeyboardEvent: params => {
            let isTabKey = params.event.key === 'Tab'
            if (isTabKey) {
              params.api.stopEditing()
            }
            return false
          },
        },
        {
          headerName: this.$t('Prepaid Deposit Amount'),
          field: 'cash_on_deposit',
          minWidth: 100,
          cellClass: cellClasses.ReadOnly,
          valueGetter: (params: ValueGetterParams) => {
            return getAvailableCashOnDeposit(params, this.data)
          },
          valueFormatter: (params: ValueFormatterParams) => {
            return this.$formatPrice(params.value)
          },
        },
        {
          field: 'order',
          headerName: ' ',
          cellRenderer: 'agGroupCellRenderer',
          minWidth: 40,
          maxWidth: 40,
          valueGetter: () => '',
        },
        {
          ...getDeleteColumn({
            url: '',
            title: this.$t('Delete Payment'),
            description: this.$t('Are you sure you want to delete this payment?'),
            onConfirm: this.onRowDeleted,
          }) as Column,
        },
      ]
    },
    customers() {
      return this.$store.getters['globalLists/getResourceList'](globalResources.Customers) || []
    },
  },
  methods: {
    getNewRowIndex() {
      let maxIdx = 0
      this.grid?.api.forEachNodeAfterFilter(node => {
        maxIdx = Math.max(maxIdx, node.data.idx)
      })

      return maxIdx + 1
    },
    getEmptyRow(): CustomerPayment {

      return {
        _localId: crypto.randomUUID(),
        idx: this.getNewRowIndex(),
        adjustments: false,
        deposit: false,
        bank_id: getSetting($modules.AR, 'default_bank_id') as string,
        customer_id: null,
        date: formatDate(new Date(), dateTypes.IsoDate),
        amount: 0,
        apply_to_billings_amount: 0,
        number: '',
        reference_no: formatDate(new Date()),
        billings: [],
      }
    },
    getSelectedCustomers(rowIndex: number): any[] {
      return this.data
          .map((d, index) => {
            return {
              customer_id: d.customer_id,
              index,
            }
          })
          .filter(c => c.customer_id && c.index !== rowIndex)
          .map(c => c.customer_id)
    },
    expandRow(index: number) {
      this.grid?.api?.getDisplayedRowAtIndex(index)?.setExpanded(true)
    },
    async onCancelSelection() {
      const result = await this.$deleteConfirm({
        title: this.$t('Do you really want to reset the selections ?'),
        description: this.$t('This action will reset all payment selections to the default state. You will have to process payments again.'),
        buttonText: this.$t('Reset Selections'),
      })
      if (!result) {
        return
      }
      this.clearSelections()
    },
    clearSelections() {
      this.tableData = []
      this.data = []
    },
    isPaymentValid(payment: CustomerPayment) {
      let hasBillings = payment.billings.length > 0
      let hasDiscounts = payment.billings.some(b => +b.discount_amount !== 0)
      if (payment.deposit) {
        hasBillings = true
      }
      const billingsTotal = sumBy(payment.billings, 'amount')
      const paymentAmount = payment.amount
      const hasAmounts = payment.amount !== 0 || paymentAmount === billingsTotal || payment.apply_to_billings_amount > 0 || hasDiscounts
      const hasRequiredFields =  payment.customer_id && payment.bank_id && payment.number && payment.date && hasAmounts
      return hasRequiredFields && hasBillings
    },
    async onViewProofListing() {
      this.cleanupData()
      await this.checkForPaymentDifferences()
      this.$router.push('/accounts-receivable/payments/proof-listing')
    },
    async checkForPaymentDifferences() {
      let hasPaymentDifferences = false
      this.data.forEach(payment => {
        const billingsTotal = this.round(sumBy(payment.billings, 'amount'), 2)
        if (payment.amount > billingsTotal && !payment.deposit && !payment.adjustments) {
          hasPaymentDifferences = true
        }
      })
      if (!hasPaymentDifferences) {
        return
      }
      const confirmed = await this.$confirm({
        title: this.$t('Payment Differences'),
        description: this.$t('There are payments with differences greater than $0. The difference will be automatically added to the customer cash on deposit. Do you want to continue?'),
        buttonText: this.$t('Continue'),
      })
      if (!confirmed) {
        return false
      }
      this.data.forEach(payment => {
        const billingsTotal = sumBy(payment.billings, 'amount')
        if (payment.amount > billingsTotal && !payment.deposit && !payment.adjustments) {
          payment.apply_to_billings_amount = billingsTotal
        }
      })

      return confirmed
    },
    cleanupData() {
      this.data = cloneDeep(this.data)
      this.data = this.data.map(payment => {
        const billings = payment.billings.filter(b => b.amount !== 0 || +b.discount_amount !== 0 || b.from_cash_on_deposit_amount !== 0)
        return {
          ...payment,
          billings,
        }
      })
    },
    async onRowAdded(params: any, row: CustomerPayment) {
      await this.$nextTick()
      this.grid?.api.forEachNode(node => {
        node.setExpanded(false)
      })
      await this.$nextTick()
      const rowNode: IRowNode | undefined = this.grid?.api.getRowNode(row._localId as string)
      const index: number = rowNode?.rowIndex || 0
      this.grid?.api.setFocusedCell(index, 'customer_id')
    },
    async onRowDeleted() {
      await this.$nextTick()
      this.dataChangedTrigger++
    },
    async saveSelectionToStorage() {
      await this.$nextTick()
      const lines: any[] = []

      this.grid?.api.forEachNode(node => {
        if (!node.data.customer_id) {
          return
        }

        lines.push(node.data)
      })

      this.data = cloneDeep(lines)
    },
    refreshGrid(refreshParams: RefreshCellsParams = {}) {
      setTimeout(() => {
        this.grid?.api.refreshCells(refreshParams)
      }, 100)
    }
  },
  watch: {
    dataChangedTrigger() {
      this.saveSelectionToStorage()
      this.refreshGrid()
    },
    'customers.length'() {
      // Refresh cash_on_deposit for each customer
      this.refreshGrid({
        columns: [
          'cash_on_deposit',
        ]
      })
    },
    grid() {
      window.grid = this.grid
    }
  },
  mounted() {
    this.tableData = this.data
  }
})
</script>
