<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :appendToBody="true"
    :title="$t('Copy Line Items')"
    size="lg"
  >
    <BaseForm
      :show-cancel="true"
      :show-submit="false"
      :loading="loading"
      layout="modal"
      :cancelText="$t('Close')"
      @cancel="$emit('close')"
    >
      <template #extra-buttons="{ valid }">
        <BaseButton
          :loading="loading"
          type="button"
          :disabled="!valid"
          @click.stop="triggerCopy"
        >
          <div class="px-4">{{ copyText }}</div>
        </BaseButton>
      </template>
      <JobSelectNew
        v-model="model.from"
        :label="$t('From Job')"
        :name="$t('From Job')"
        :placeholder="$t('Select From Job')"
        :excludeIds="[model.to]"
        :addEntity="false"
        :defaultShowActive="false"
        class="col-span-2"
        rules="required"
        :alwaysIncludeJobs="masterJob ? [masterJob] : []"
        @change="model.only_line_item_ids = []"
      >
        <template #label>
          <div class="flex space-x-3 items-center">
            <label
              class="block text-sm font-medium leading-5 text-gray-700 truncate"
            >
              {{ $t('From Job') }}*
            </label>
            <BaseButton
              class="col-span-2"
              @click="model.from = masterJobId"
              variant="white"
              size="xs"
            >
              {{ $t('Select MASTER Job') }}
            </BaseButton>
          </div>
        </template>
      </JobSelectNew>
      <JobSelect
        :value="model.to"
        :label="$t('To Job')"
        :name="$t('To Job')"
        :placeholder="$t('Select To Job')"
        :disabled="true"
        :addEntity="false"
        class="col-span-2"
        rules="required"
      />
      <BaseCheckbox
        v-model="model.include_budgets"
        :label="$t('Also Copy Line Item Budgets')"
        id="include_budgets"
        class="col-span-2"
      />

      <ValidationWarning
        class="col-span-6"
      >
        {{ $t('Duplicate Line Items will be skipped - Line Items that match Phase Code, Cost Code & Change Order with existing Line Items on') }}
        <span class="ml-1 font-semibold"> {{ toJob.attributes.number }} {{ toJob.attributes.description }}</span>.
      </ValidationWarning>
      <BaseAlert
        v-if="lastResult.message"
        :closable="true"
        class="col-span-6"
      >
        <div>{{ lastResult.message }}</div>
        <div v-if="lastResult.skipped.length">
          <div class="flex items-center space-x-2">
            <div>
              {{
                $tc('skipped count duplicate line items', null, {
                  count: lastResult.skipped.length,
                })
              }}:
            </div>

            <div>
              {{ lastSkippedToDisplay }}.
            </div>
          </div>
        </div>
        <div>{{ $t(`You can continue copying Line Items by selecting a different Job or changing the Line Item selection.`) }}</div>
      </BaseAlert>
      <div class="col-span-6 h-full">
        <AgDataTable
          v-if="model.from"
          :url="`/restify/line-items`"
          :urlParams="lineItemsUrlParams"
          :title="$t('Select Line Items To Copy (Leave selection empty to copy ALL Line Items from Job)')"
          :columns="columns"
          actions="search,refresh"
          :selected-rows.sync="selectedLineItems"
          :enableFilterDrawer="false"
        >
        <template #additional-actions-before>
          <BaseSelect
            v-model="lineItemType"
            :label="$t('Line Item Type')"
            :placeholder="$t('Line Item Type')"
            :add-entity="false"
            :options="lineItemTypeOptions"
          />
        </template>
        </AgDataTable>
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script>
import axios from 'axios'
import { costTypes } from '@/enum/enums';
import JobSelectNew from "@/components/select/entities/JobSelectNew.vue";

export default {
  components: {
    JobSelectNew,
  },
  props: {
    toJob: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      selectedLineItems: [],
      lineItemType: null,
      lineItemTypeOptions: [
        {
          label: this.$t('All'),
          value: null,
        },
        {
          label: this.$t('Cost Line Items'),
          value: costTypes.Cost,
        },
        {
          label: this.$t('Income Line Items'),
          value: costTypes.Income,
        },
      ],
      model: {
        to: this.toJob.id,
        from: '',
        include_budgets: false,
        only_line_item_ids: [],
      },
      lastResult: {
        message: '',
        skipped: [],
      }
    }
  },
  computed: {
    masterJobId() {
      return this.$settings(this.$modules.JC, 'master_job_id')
    },
    masterJob() {
      return this.$store.state.jobCosting.masterJob
    },
    columns() {
      return [
        {
          headerName: this.$t('Phase Code'),
          field: 'attributes.phase_code',
          minWidth: 150,
          maxWidth: 150,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          sort: 'asc',
        },
        {
          headerName: this.$t('Cost Code'),
          field: 'attributes.cost_code',
          minWidth: 100,
          maxWidth: 100,
          align: 'center',
        },
        {
          headerName: this.$t('Chg Order'),
          field: 'attributes.change_order',
          minWidth: 100,
          maxWidth: 100,
          align: 'center',
        },
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
        },
        {
          headerName: this.$t('Type'),
          field: 'attributes.type',
          align: 'center',
          minWidth: 80,
          maxWidth: 100,
          component: 'Status',
        },
        {
          headerName: this.$t('Budget'),
          field: 'attributes.amount',
          align: 'right',
          minWidth: 120,
          maxWidth: 120,
          component: 'FormattedPrice',
          hide: !this.model.include_budgets,
        },
      ]
    },
    lineItemsUrlParams() {
      return {
        job_id: this.model.from,
        type: this.lineItemType,
        perPage: 100,
        related: 'budgets',
        sort: 'phase_code',
      }
    },
    copyText() {
      if (this.selectedLineItems.length) {
        return this.$t('Copy Selected')
      }
      return this.$t('Copy All')
    },
    lastSkippedToDisplay() {
      const displayMaxCount = 3

      let skippedToDisplay = this.lastResult.skipped

      if (this.lastResult.skipped.length > displayMaxCount) {
        skippedToDisplay = this.lastResult.skipped.slice(0, displayMaxCount)
      }

      let formatted = skippedToDisplay.map(lineItem => {
        const {
          phase_code,
          cost_code,
          change_order,
          description = ''
        } = lineItem

        return [
          phase_code,
          cost_code,
          change_order,
          description
        ].join(' / ')
      }).join(', ')

      if (this.lastResult.skipped.length > displayMaxCount) {
        const countMoreMessage = this.$tc('and count more', null, {
          count: this.lastResult.skipped.length - displayMaxCount,
        })

        formatted += ` (${countMoreMessage})`
      }

      return formatted
    }
  },
  methods: {
    async triggerCopy() {
      this.loading = true
      this.lastResult = {
        message: '',
        skipped: [],
      }

      try {
        const data = {
          ...this.model,
        }

        data.only_line_item_ids = this.selectedLineItems.map(x => x.id)

        const result = await axios.post(`/restify/jobs/actions?action=copy-line-items`, data)

        this.lastResult = result?.data || {}
        this.selectedLineItems = []

        this.$emit('copied')
      } catch (err) {
        if (err.handled) {
          return
        }

        this.$error(this.$t('Failed to copy line items'))
      } finally {
        this.loading = false
      }
    },
  },
  created() {
    this.$store.dispatch('jobCosting/loadMasterJob')
  }
}
</script>
