<template>
  <base-form
      :loading="loading"
      :save-text="$t('Save adjustment')"
      :update-text="$t('Update adjustment')"
      :can-create-another-entity="!model.id"
      :submit-disabled="gridContext.loading"
      submit-button-type="button"
      grid-classes="grid grid-cols-1"
      layout="vertical"
      class="mb-10"
      ref="baseForm"
      @cancel="onCancel"
      @submit="onSubmit"
  >
    <el-collapse v-model="expandedSections"
                 class="col-span-1">
      <base-collapse-section
          :title="$t('Adjustment')"
          key="transaction_details"
          name="transaction_details"
          class="-m-6">
        <template #title>
          <div class="flex w-full justify-between form-header-summary">
              <span class="mx-4">
                {{ $t('Transaction') }}
              </span>
            <portal-target name="tr-summary"/>
          </div>
        </template>
        <div class="grid grid-cols-12 gap-x-3 px-4 pt-3">
          <base-date-picker
              v-model="model.reference_date"
              @change="onReferenceDateChange"
              :label="$t('Date')"
              :disabled="!!$route.params.id"
              id="date-input"
              class="col-span-12 md:col-span-3"
          />
          <span class="col-span-12 md:col-span-2">
            <base-switch
                v-model="model.meta.offset"
                :label-info="$t('Non Job Account Offset')"
                :vertical="true"
                id="offset"
            />
          </span>
          <template v-if="model.meta.offset">
            <account-select
                v-model="model.meta.account"
                :name="$t('Account')"
                :label="$t('Account')"
                label-key="number"
                value-key="number"
                id="account"
                class="col-span-12 md:col-span-3"
                rules="required"
            />
            <sub-account-select
                v-model="model.meta.subaccount"
                :name="$t('Sub Account')"
                :label="$t('Sub Account')"
                label-key="number"
                value-key="number"
                id="subaccount"
                class="col-span-12 md:col-span-2"
            />
          </template>
          <FiscalYearSelect
            v-model="model.fiscal_year"
            :label="$t('Fiscal Year')"
            :default-previous-year="true"
            class="col-span-12 md:col-span-2"
            id="fiscal_year"
          />
          <PeriodSelect
            v-model="model.period"
            :placeholder="$t('Period')"
            :default-to-last-period="true"
            class="col-span-12 md:col-span-2"
            id="period"
          />
          <div class="col-span-12">
            <base-alert :type="$promptType.Light" closable>
              <h6 v-if="type === transactionTypes.Cost">
                {{ $t('Positive adjustment amounts post to G/L as Debits: Negative amounts as Credits.') }}
              </h6>
              <h6 v-else>
                {{ $t('Positive adjustment amounts post to G/L as Credits: Negative amounts as Debits.') }}
              </h6>
            </base-alert>
          </div>
        </div>
      </base-collapse-section>
    </el-collapse>
    <job-transaction-entries
        :type="type"
        :data="model"
        ref="entriesTable"
        class="col-span-4 mt-6"
        @on-collapse-form-header="collapseFormHeader"
    />
  </base-form>
</template>
<script>
import axios from 'axios'
import { resourceStatuses, transactionTypes } from '@/enum/enums'
import { getJournalTemporaryFields, journalRecurringTypes, journalTypes } from '@/enum/journal'
import JobTransactionEntries from '@/modules/job-costing/components/JobTransactionEntries'
import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
import { formatDate } from "@/plugins/dateFormatPlugin";
import { gridContext } from "@/components/ag-grid/gridContext";

export default {
  components: {
    JobTransactionEntries,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: transactionTypes.Cost,
    },
  },
  data() {
    return {
      transactionTypes,
      loading: false,
      expandedSections: ['transaction_details'],
      gridContext,
      model: {
        reference_date: this.$now,
        status: resourceStatuses.Pending,
        code: journalTypes.JobCosting,
        type: journalRecurringTypes.SinglePeriod,
        transaction_type: this.type || transactionTypes.Cost,
        fiscal_year: this.$currentYear,
        period: this.$currentPeriod,
        meta: {
          offset: false,
          account: null,
          subaccount: null,
          batch_total: 0,
        },
      },
    }
  },
  methods: {
    onCancel() {
      this.$router.push(`/job-costing/transactions/${this.type}/pending`)
    },
    onReferenceDateChange(refDate) {
      const referenceDate = new Date(refDate)
      const date = formatDate(referenceDate)
      this.model.period = referenceDate.getUTCMonth()
      this.model.name = `${this.model.code} - ${date}`
    },
    async onSubmit() {
      try {
        const isInvalidData = await validateAgDataTable()
        if (isInvalidData) {
          return
        }

        this.loading = true

        this.model.meta.batch_total = this.$refs.entriesTable.batchTotal

        if (!this.model.id) {

          this.model = getJournalTemporaryFields(this.model)

          const {data} = await axios.post(`/restify/journals`, this.model)

          await this.$refs.entriesTable.storeProgress(data.id)

          this.$success(this.$t('Transaction created'))

          if (this.$createAnotherEntity) {
            return this.$emit('create-another')
          }

          await this.$router.push(`/job-costing/transactions/${this.type}/pending`)
        } else {

          await axios.put(`/restify/journals/${this.model.id}`, this.model)
          await this.$refs.entriesTable.storeProgress(this.model.id)

          await this.$store.dispatch('generalLedger/getJournal', this.model.id)

          this.$success(this.$t('Transaction updated'))
        }
      } catch (err) {
        console.warn(err)
        if (err?.handled) {
          return
        }
        this.$error(this.$t('Could not save the job transaction'))
      } finally {
        this.formIsChangedOrIsNotInBalance = false
        this.loading = false
      }
    },
    async collapseFormHeader() {
      if (!this.expandedSections.length) {
        return
      }

      const isValidForm = await this.$refs?.baseForm?.validate()

      if (!isValidForm) {
        return
      }

      this.expandedSections = []
    },
  },
  watch: {
    'data.attributes': {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          return
        }

        const meta = {
          ...this.model.meta,
          ...value?.meta,
        }

        this.model = {
          ...this.model,
          ...value,
          meta,
        }
      },
    },
  },
}
</script>
