<template>
  <div class="grid grid-cols-1">
    <base-form :loading="loading"
               :can-create-another-entity="!model.id"
               :save-text="$t('Create material')"
               :update-text="$t('Update material')"
               :layout="layout"
               :show-cancel="showCancel"
               grid-classes="grid grid-cols-8 gap-x-4"
               @submit="onSubmit"
               @cancel="onCancel"
    >
      <code-input
        v-model="model.code"
        :model="model"
        :resource-name="globalResources.Materials"
        class="col-span-8 md:col-span-1"
      />
      <address-type-select v-model="model.address_type"
                           class="col-span-8 md:col-span-1"
                           @change="model.address_id = undefined"
      />
      <address-select v-model="model.address_id"
                      :initial-value="get(data, 'relationships.address')"
                      :address-type="model.address_type"
                      class="col-span-8 md:col-span-2"
                      rules="required"
      />
      <material-class-select v-model="model.class_id"
                             class="col-span-6 md:col-span-2"
                             id="class_id"
      />
      <base-input v-model="model.um"
                  :label="$t('Unit Measure')"
                  :placeholder="$t('Unit Measure')"
                  class="col-span-8 md:col-span-1"
                  id="um"
                  rules="max:4"
      />
      <base-select v-model="model.status"
                   :label="$t('Status')"
                   :placeholder="$t('Status')"
                   :options="statusOptions"
                   class="col-span-6 md:col-span-1"
                   id="status"
      />
      <base-textarea v-model="model.description"
                     :label="$t('Description')"
                     :placeholder="$t('Description')"
                     class="col-span-8 md:col-span-4"
                     id="description"
                     :rows="5"
                     rules="max:150"
      />
      <div class="col-span-8 md:col-span-4 grid grid-cols-6 gap-x-4">
        <VendorSelect v-model="model.primary_vendor_id"
                      :label="$t('Primary Vendor')"
                      class="col-span-6 md:col-span-3"
                      id="primary_vendor_id"
        />
        <VendorSelect v-model="model.alternate_vendor_id"
                      :label="$t('Alternate Vendor')"
                      class="col-span-6 md:col-span-3"
                      id="alternate_vendor_id"
        />
        <div class="col-span-6 md:col-span-2">
          <BaseSwitch
            v-model="model.available_in_timesheets"
            :label-info="$t(`Show in Timesheets`)"
          />
        </div>
      </div>
      <div class="col-span-8">
        <el-collapse v-model="activeGroups">
          <base-collapse-section key="last_order_information"
                                 :title="$t('Last Order Information')"
                                 name="last_order_information">
            <div class="grid grid-cols-8 gap-x-3 px-1">
              <base-date-picker v-model="model.last_order_date"
                                :label="$t('Date')"
                                class="col-span-8 md:col-span-2"
                                id="last_order_date"
              />
              <VendorSelect v-model="model.last_order_vendor_id"
                            class="col-span-8 md:col-span-2"
                            id="last_order_vendor_id"
              />
              <base-input v-model="model.last_order_quantity"
                          :name="$t('Quantity')"
                          :label="$t('Quantity')"
                          :min="0"
                          :max="999999.999"
                          :step="1"
                          type="number"
                          id="last_order_quantity"
                          class="col-span-8 md:col-span-1"
                          rules="max_value:999999.999|min_value:0"
              />
              <purchase-order-select
                v-model="model.purchase_order_id"
                :label="$t('P.O.#')"
                :placeholder="$t('P.O.#')"
                class="col-span-8 md:col-span-1"
                id="po"
              />
              <base-input v-model="model.unit_price"
                          :name="$t('Unit Price')"
                          :label="$t('Unit Price')"
                          :min="0"
                          :max="9999999999.999999"
                          :step="1"
                          class="col-span-6 md:col-span-2"
                          id="unit_price"
                          type="number"
                          format="price"
                          rules="max_value:9999999999.999999|min_value:0"
              />
            </div>
            <template v-if="model.last_invoiced_vendor_id">
              <p class="font-semibold text-primary-500">{{ $t('Last Invoice') }}</p>
              <div class="grid grid-cols-8 sm:text-sm sm:leading-5">
	                <span class="col-span-8 md:col-span-2">
	                  {{ $formatDate(model.last_invoice_date) }}
	                </span>
                <span class="col-span-8 md:col-span-2">
									<VendorLink
                    :data="get(data, 'relationships.lastInvoicedVendor.attributes')"
                    :show-preview="false"
                    linkClass="text-gray-600 hover:text-gray-800"
                  />
	              </span>
                <span class="col-span-8 md:col-span-1">
	                  {{ model.last_invoice_quantity }}
	                </span>
                <span class="col-span-8 md:col-span-1"/>
                <span class="col-span-6 md:col-span-2">
	                  {{ $formatPrice(model.last_invoice_unit_rate) }}
	                </span>
              </div>
            </template>
          </base-collapse-section>
        </el-collapse>
      </div>
      <div class="col-span-8 grid grid-cols-8 gap-x-4 mt-4">
        <BillingRatesList :entity="data"
                          :only-regular-type="true"
                          :source-type="billingRateSources.Material"
                          ref="billingRates"
                          class="col-span-6"
        />
        <div class="col-span-2">
          <base-input v-model="model.standard_unit_rate"
                      :name="$t('Standard Unit Cost')"
                      :label="$t('Standard Unit Cost')"
                      :min="0"
                      :max="9999999999.999999"
                      :step="1"
                      id="standard_unit_rate"
                      type="number"
                      format="price"
                      rules="max_value:9999999999.999999|min_value:0"
          />
          <base-input v-model="model.estimated_unit_rate"
                      :disabled="true"
                      :name="$t('Estimated Unit Price')"
                      :label="$t('Estimated Unit Price')"
                      :min="0"
                      :max="9999999999.999999"
                      :step="1"
                      id="estimated_unit_rate"
                      type="number"
                      format="price"
                      rules="max_value:9999999999.999999|min_value:0"
          />
        </div>
      </div>
    </base-form>
  </div>
</template>
<script>
import axios from 'axios'
import { billingRateSources, resourceStatuses } from '@/enum/enums'
import VendorSelect from '@/components/select/entities/VendorSelect'
import BillingRatesList from '@/modules/common/components/BillingRatesList'
import { globalResources } from "@/components/form/util";
import { materialStatusOptions } from "@/modules/purchasing-inventory/utils/materialUtils";

export default {
  components: {
    BillingRatesList,
    VendorSelect,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    redirectToListAfterStore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      globalResources,
      billingRateSources,
      showCancel: true,
      activeGroups: ['last_order_information'],
      loading: false,
      model: {
        code: '',
        address_type: this.$addressTypes.Warehouse,
        address_id: '',
        description: '',
        um: '',
        class_id: undefined,
        status: resourceStatuses.Active,
        primary_vendor_id: undefined,
        alternate_vendor_id: undefined,

        last_order_date: null,
        last_order_vendor_id: undefined,
        last_order_quantity: 0,
        purchase_order_id: undefined,
        last_po_unit_rate: 0, // ??

        last_invoice_date: null,
        last_invoiced_vendor_id: undefined,
        last_invoice_quantity: 0,
        last_invoice_unit_rate: 0,

        quantity_on_order: 0,
        quantity_not_invoiced: 0,
        current_value: 0,
        quantity_on_hand: 0,

        standard_unit_rate: 0,
        estimated_unit_rate: 0,
        available_in_timesheets: true,
      },
      statusOptions: materialStatusOptions,
    }
  },
  computed: {
    layout() {
      return this.redirectToListAfterStore ? 'vertical' : 'modal'
    },
  },
  methods: {
    async onCancel() {
      if (this.redirectToListAfterStore) {
        return this.$router.push('/purchasing-inventory/materials')
      }
      this.$emit('cancel')
    },
    async onSubmit() {
      try {
        this.loading = true
        if (this.model.id) {
          await axios.put(`/restify/materials/${ this.model.id }`, this.model)
          await this.runBillingRateOperations(this.model.id)
          this.$success(this.$t('Material updated'))
          this.$emit('refresh', this.model)
        } else {
          const { data } = await axios.post('/restify/materials', this.model)
          await this.runBillingRateOperations(data.id)
          this.$success(this.$t('Material created'))
          this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          if (this.redirectToListAfterStore) {
            await this.$router.push(`/purchasing-inventory/materials/${ data.id }/view`)
          }
        }
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        this.loading = false
      }
    },
    async runBillingRateOperations(source_id) {
      const { billingRates } = this.$refs
      if (!billingRates) {
        return
      }
      await billingRates.runEntitiesOperation(source_id, billingRateSources.Material)
    },
    tryAssignDefaultAddress() {
      this.model.address_id = this.$settings(this.$modules.IV, 'default_address_id') || ''
    },
  },
  watch: {
    'data.attributes': {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          return this.tryAssignDefaultAddress()
        }

        this.model = {
          ...this.model,
          ...value,
        }
      },
    },
  },
}
</script>
