export const agedByViewFormats = {
  EachInvoice: 'each-invoice',
  VendorTotal: 'vendor-total',
  JobTotal: 'job-total',
}

export const reportNumbers = {
  AgedByVendor: 3882,
  AgedByJob: 3883,
}

export const vendorHistoryFormats = {
  ByVendor: 'by-vendor',
  ByJob: 'by-job',
  Payments: 'payments',
  CostType: 'cost-type',
}

export const VendorPaymentRegisterOptions = {
  SortBy: {
    Payment: 'payment',
    FiscalPeriods: 'fiscal_periods',
    VendorPayment: 'vendor_payment',
    Journal: 'journal',
    VendorInvoice: 'vendor_invoice',
  },
  SelectBy: {
    PaymentDates: 'payment_dates',
    FiscalPeriods: 'fiscal_periods',
    AllPayments: 'all_payments',
    DirectDeposit: 'direct_deposit',
  }
}

export const Vendor1099sReportOptions = {
  Formats: {
    Vendor1099s_ProofListing: 'vendor_1099s_proof_listing',
    // Others TBD
  }
}
