<template>
  <div>
    <BaseDataTable
        :columns="columns"
        :edit-entity-url-query="editEntityUrlQuery"
        :add-text="$t('New local tax jurisdiction')"
        url="/restify/local-tax-jurisdictions"
        :url-params="{
           related: 'whVendor',
         }"
        view-entity-url-query="/payroll/settings/local-taxes"
        default-sort="code"
        actions="search, refresh"
        permission="employees"
        ref="table"
        @add="onRowAdd"
        @edit="onRowEdit"
    >
      <template v-slot:whVendor="{row}">
        <VendorLink
            :data="get(row, 'relationships.whVendor.attributes')"
            :show-description="false"
        />
      </template>
    </BaseDataTable>
    <BaseFormDialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit Local Tax Jurisdiction') : $t('Add Local Tax Jurisdiction')"
        :open.sync="showDialog"
        size="xl"
        @close="onEventTrigger(false)"
    >
      <LocalTaxJurisdictionForm
          :data="rowToEdit"
          @cancel="onEventTrigger(false)"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
  import LocalTaxJurisdictionForm from '@/modules/payroll/components/settings/LocalTaxJurisdictionForm'

  export default {
    components: {
      LocalTaxJurisdictionForm,
    },
    data() {
      return {
        renderKey: 1,
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: false,
        columns: [
          {
            label: this.$t('Code'),
            prop: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/settings/local-taxes?id={ID}',
          },
          {
            label: this.$t('Description'),
            prop: 'attributes.description',
          },
          {
            label: this.$t('Withholding'),
            prop: 'attributes.employer_number',
          },
          {
            label: this.$t('401K Exempt'),
            prop: 'attributes.is_401k_exempt',
            component: 'Status',
          },
          {
            label: this.$t('125S Exempt'),
            prop: 'attributes.is_125s_exempt',
            component: 'Status',
          },
          {
            label: this.$t('W2 Memo Code'),
            prop: 'attributes.w2_code',
            visible: false,
          },
        ],
      }
    },
    methods: {
      onRowEdit(row) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      onEventTrigger(refresh = true) {
        this.rowToEdit = null
        this.showDialog = false
        if (!refresh) {
          return
        }
        this.refreshTable()
      },
    },
  }
</script>
