<template>
  <base-form
      :layout="layout"
      :loading="loading"
      :show-back="showBack"
      :show-cancel="showCancel"
      :show-reset="!model.id && redirectToListAfterStore"
      :can-create-another-entity="!model.id && redirectToListAfterStore"
      :save-text="$t('Create cost line item')"
      :update-text="$t('Update cost line item')"
      :focus-on-first-input="false"
      grid-classes="grid grid-cols-8 gap-x-3"
      @cancel="onCancel"
      @submit="onSubmit"
  >
    <div class="col-span-8 md:col-span-4 lg:col-span-2">
      <JobSelectNew
        v-model="model.job_id"
        :initial-value="getJobInitialValue"
        :disabled="!!$route.params.id"
        id="job_id"
        rules="required"
        @change="onJobChange"
      />
    </div>
    <phase-cost-input
        :phase_code.sync="model.phase_code"
        :cost_code.sync="model.cost_code"
        :change_order.sync="model.change_order"
        :description.sync="model.description"
        :line-item-type="costTypes.Cost"
        :job-id="model.job_id"
        :id="model.id"
        class="col-span-8 md:col-span-4 lg:col-span-3"
    />

    <div>
      <label>{{ $t('Addition Description') }}</label>
      <base-switch v-model="model.has_additional_description"
                   class="mr-4 mt-3"
                   id="has_additional_description"
      />
    </div>
    <div class="col-span-6 md:col-span-2 xl:col-span-1">
      <BaseSwitch
        v-model="model.available_in_timesheets"
        :label-info="$t(`Show in Timesheets`)"
      />
    </div>

    <div class="col-span-8 lg:col-span-4">
      <base-textarea
          v-model="model.description"
          :label="$t('Description')"
          :placeholder="$t('Description')"
          id="description"
          rules="max:150"
          class="col-span-2"
      />
    </div>
    <div class="col-span-8 lg:col-span-4">
      <div class="grid grid-cols-2 gap-x-4">
        <div v-if="model.has_additional_description && model.additional_description"
             class="col-span-2">
          <base-input v-model="model.additional_description[0]"
                      :label="$t('Addition Description')"
                      :placeholder="$t('Addition Description')"
                      id="additional_description"
          />
        </div>
      </div>
    </div>
    <div class="col-span-8 -mx-2">
      <el-collapse v-model="activeGroups">
        <div class="grid grid-cols-1 lg:grid-cols-2">
          <base-collapse-section key="budgets"
                                 v-if="budgets.length"
                                 :title="$t('Budgets')"
                                 name="budgets"
                                 class="col-span-2"
          >
            <LineItemBudgets
                :type="model.type"
                :budgets="budgets"
                :budgetsLoading="budgetsLoading"
            />
          </base-collapse-section>
          <base-collapse-section class="col-span-2"
                                 key="additional_settings"
                                 :title="$t('Additional Settings')"
                                 name="additional_settings"
          >
            <div class="grid grid-cols-8 gap-x-3">
              <div class="col-span-8 md:col-span-4 lg:col-span-2">
                <account-select v-model="model.account"
                                :name="$t('Account')"
                                :label="$t('Account')"
                                value-key="number"
                                :clearable="true"
                                id="account"
                />
              </div>
              <div class="col-span-8 md:col-span-4 lg:col-span-1">
                <sub-account-select v-model="model.subaccount"
                                    :label="$t('Sub Account')"
                                    :name="$t('Sub Account')"
                                    value-key="number"
                                    :clearable="true"
                                    id="subaccount"
                />
              </div>
              <div class="col-span-8 md:col-span-4 lg:col-span-1">
                <VendorSelect v-model="model.vendor_id"
                              :label="$t('SubCon Vend')"
                              :initial-value="get(data, 'relationships.vendor')"
                              clearable
                              id="vendor_id"
                />
              </div>
              <div class="col-span-8 md:col-span-4 lg:col-span-2">
                <material-select v-model="model.material_id"
                                 :label="$t('Material Code')"
                                 :initial-value="get(data, 'relationships.material')"
                                 clearable
                                 id="material_id"
                />
              </div>
              <div class="col-span-8 md:col-span-4 lg:col-span-2">
                <equipment-select v-model="model.equipment_id"
                                  :label="$t('Equipment')"
                                  :initial-value="get(data, 'relationships.equipment')"
                                  clearable
                                  id="equipment_id"
                />
              </div>
            </div>
          </base-collapse-section>
          <base-collapse-section class="col-span-2"
                                 key="payroll"
                                 :title="$t('Payroll Settings')"
                                 name="payroll"
          >
            <div class="grid grid-cols-8 gap-x-3">
              <div class="col-span-8 md:col-span-4 lg:col-span-1">
                <base-select v-model="model.certified_payroll_exempt"
                             :label="$t('Certified P/R Exempt')"
                             :placeholder="$t('Certified P/R Exempt')"
                             :options="certifiedPayrollOptions"
                             id="certified_payroll_exempt"
                />
              </div>
              <worker-comp-rate-select
                  v-model="model.workers_comp_rate_code"
                  :add-entity="true"
                  :initial-value="get(data, 'relationships.workersCompRate')"
                  class="col-span-8 md:col-span-4 lg:col-span-2"
                  clearable
              />
              <gen-liability-rate-select
                  class="col-span-8 md:col-span-4 lg:col-span-2"
                  v-model="model.gen_liability_rate_code"
                  :initial-value="get(data, 'relationships.genLiabilityRate')"
                  :add-entity="true"
                  clearable
              />

              <base-select
                  class="col-span-8 md:col-span-4 lg:col-span-1"
                  v-model="model.pay_rate_adjustment"
                  :label="$t('Pay Rate Adjustment')"
                  :placeholder="$t('Pay Rate Adjustment')"
                  :options="rateAdjustmentOptions"
                  id="pay_rate_adjustment"
              />
              <base-input
                  class="col-span-8 md:col-span-4 lg:col-span-2"
                  v-if="model.pay_rate_adjustment === 'dollar'"
                  v-model="model.pay_rate_value"
                  :placeholder="$t('e.g 100.00')"
                  :label="$t('Amount')"
                  :min="0"
                  :max="9999999999.999999"
                  :step="0.000001"
                  type="number"
                  format="price"
                  id="dollar"
                  rules="max_value:9999999999.999999|min_value:0"
              />
              <base-input
                  v-if="model.pay_rate_adjustment === 'percent'"
                  v-model="model.pay_rate_value"
                  :placeholder="$t('e.g 100.00')"
                  :label="$t('Amount')"
                  :min="0"
                  :max="9999999999.999999"
                  :step="0.000001"
                  type="number"
                  format="percent"
                  id="percent"
                  rules="max_value:9999999999.999999|min_value:0"
              />

            </div>
          </base-collapse-section>
        </div>
      </el-collapse>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import { costTypes } from '@/enum/enums'
  import LineItemForm from '@/modules/job-costing/components/line-items/LineItemForm'
  import { certifiedPayrollTypes, rateAdjustmentTypes } from '@/modules/job-costing/enum/jobs'
  import LineItemBudgets from "@/modules/job-costing/components/line-items/LineItemBudgets";
  import { delay } from "@/utils/utils";
  import { RestifyResources } from "@/components/form/util";
  import JobSelectNew from "@/components/select/entities/JobSelectNew.vue";

  export default {
    extends: LineItemForm,
    components: {
      JobSelectNew,
      LineItemBudgets,
    },
    data() {
      return {
        costTypes,
        loading: false,
        showCancel: true,
        activeGroups: this.data.id ? ['budgets'] : ['budgets'],
        budgets: [],
        model: {
          type: costTypes.Cost,
          job_id: this.job.id || this.$route.query.jobId,
          phase_code: '',
          cost_code: '',
          change_order: 0,
          certified_payroll_exempt: certifiedPayrollTypes.No,
          description: '',
          has_additional_description: false,
          available_in_timesheets: true,
          additional_description: [],
          workers_comp_rate_code: '',
          gen_liability_rate_code: '',
          pay_rate_adjustment: rateAdjustmentTypes.NoAdjustment,
          pay_rate_value: undefined,
          exempt_from_sales_tax: false,
          piece_pay_rate: 0,
          account: '',
          subaccount: '',
          vendor_id: '',
          material_id: '',
          equipment_id: '',
        },
        certifiedPayrollOptions: [
          {
            label: this.$t('Yes with Union'),
            value: certifiedPayrollTypes.WithUnion,
          },
          {
            label: this.$t('Yes w/o Union'),
            value: certifiedPayrollTypes.WithoutUnion,
          },
          {
            label: this.$t('Yes w/o Union and Craft'),
            value: certifiedPayrollTypes.WithoutUnionCraft,
          },
          {
            label: this.$t('No'),
            value: certifiedPayrollTypes.No,
          },
        ],
        rateAdjustmentOptions: [
          {
            label: this.$t('No Adjustment'),
            value: rateAdjustmentTypes.NoAdjustment,
          },
          {
            label: this.$t('Dollar'),
            value: rateAdjustmentTypes.Dollar,
          },
          {
            label: this.$t('Percent'),
            value: rateAdjustmentTypes.Percentage,
          },
        ],
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true

          if (this.model.id) {
            await axios.put(`/restify/line-items/${this.model.id}`, this.model)
            this.$success(this.$t('Job cost line item updated'))
            await this.assignBudgets(this.model.id)
            await delay(500)
            await this.$store.dispatch('jobCosting/getLineItem', this.model.id)
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.LineItems,
              resourceId: this.model.id,
              isEdit: true,
            })
            this.$emit('save', this.model)
          } else {
            const { data } = await axios.post('/restify/line-items', this.model)
            this.$success(this.$t('Job cost line item created'))
            await this.assignBudgets(data.id)
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.LineItems,
              resourceId: data.id,
            })
            if (this.$createAnotherEntity && this.redirectToListAfterStore) {
              return this.$emit('create-another')
            }

            let path = `/job-costing/cost-line-items`
            const routeId = this.$route.params.id
            if (routeId) {
              path = `/job-costing/jobs/${routeId}/line-items`
            }
            await delay(500)
            if (this.redirectToListAfterStore) {
              await this.$router.push(path)
            }

            this.$emit('save', data)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
    },
  }
</script>
