<template>
  <div>
    <InvoiceDetailsPage
        :data="originalPurchaseOrder"
        :loading="purchaseOrderLoading"
        :historyEntity="$t('Purchase Order')"
        entity="purchase-orders"
    >
      <template #title>
        <InvoiceStatusPopover
            :invoice="originalPurchaseOrder"
            resource-name="purchase-orders"
            placement="left"
        />
        <ReviewStatus
          v-if="showApproveStatus"
          :row="originalPurchaseOrder"
          class="ml-2"
        />
      </template>

      <template #title-right>
        <div class="flex items-center">
          <span class="text-base print:text-print leading-6 font-semibold text-gray-900 mr-2">
            {{ $t('Remaining:') }}
          </span>
          <div class="bg-gray-900 bg-opacity-5 font-medium rounded-md py-1 px-4 print:px-1 print:text-print mr-3 tracking-wide">
            {{ $formatPrice(purchaseOrder.extended_amount - purchaseOrder.invoiced_amount) }}
          </div>
          <span class="tracking-wide font-medium text-sm print:text-print leading-normal text-gray-400 mr-2">
            {{ $t('out of') }}
          </span>
          <span class="text-base leading-6 font-semibold text-gray-900 mr-2">
            {{ $t('Total: ') }}
          </span>
          <div class="bg-gray-900 bg-opacity-5 font-medium rounded-md py-1 px-4 tracking-wide">
            {{ $formatPrice(purchaseOrder.extended_amount) }}
          </div>
        </div>
      </template>

      <template #header-left>
        <div class="flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0">
          <VendorContactInfo :title="$t('From')" :vendor="vendor"/>
          <CompanyContactInfo :title="$t('Billed To')" :company="$currentCompany"/>
        </div>
      </template>

      <template #content>
        <InvoiceDescription
          v-if="purchaseOrder.id"
          :invoice="purchaseOrder"
          entity="purchase-orders"
        />
        <PurchaseOrderItems
            v-show="purchaseOrder.id"
            :data="purchaseOrder"
            readOnly
            class="p-4"
        />
        <div v-if="!purchaseOrderLoading && purchaseOrder.invoiced_amount > 0" class="p-4">
          <h4 class="font-bold leading-8 text-gray-800 flex items-center text-base print:text-xs -mb-4 -mt-6">
            {{ $t('Invoices') }}
          </h4>
          <InvoiceTable
              :invoice-status="resourceStatuses.All"
              :url-params="invoiceUrlParams"
              :hide-all-actions="true"
          />
        </div>
        <ElCollapse
          v-if="!purchaseOrderLoading && isPosted && purchaseOrder.adjustments_count > 0"
          class="p-4 -mt-4">
          <BaseCollapseSection>
            <template #title>
              <span class="mr-2">{{$t('Adjustments')}}</span>
              <StatusBadge :status="purchaseOrder.adjustments_count"/>
            </template>
            <PurchaseOrderAdjustmentsList
              :status="resourceStatuses.All"
              :url-params="invoiceUrlParams"
              :hide-all-actions="true"
              :hide-vendor="true"
              class="-mt-6"
            />
          </BaseCollapseSection>
        </ElCollapse>
      </template>

      <template #totals>
        <dl v-if="!purchaseOrderLoading" class="col-span-3 md:col-span-1 divide-y divide-gray-100">
          <div class="totals-row">
            <span>
              {{ $t('Sub-Total') }}
            </span>
            <span>
              {{ $formatPrice(purchaseOrder.amount) }}
            </span>
          </div>
          <div class="totals-row">
              <span>
                {{ $t('Sales Tax') }}
              </span>
            <span>
                {{ $formatPrice(purchaseOrder.sales_tax_amount) }}
              </span>
          </div>
          <div class="totals-row">
              <span>
                {{ $t('Freight') }}
              </span>
            <span>
                {{ $formatPrice(purchaseOrder.freight_amount) }}
              </span>
          </div>
          <div class="totals-row bg-gray-50">
            <span>
              {{ $t('Total') }}
            </span>
            <span>
              {{ $formatPrice(purchaseOrder.extended_amount) }}
            </span>
          </div>
          <div class="totals-row">
            <span>
              {{ $t('Invoiced') }}
            </span>
            <span v-if="purchaseOrder.invoiced_amount === 0">
              {{ $formatPrice(purchaseOrder.invoiced_amount) }}
            </span>
            <router-link
                v-else
                :to="`/accounts-payable/invoices/all?purchase_order_id=${purchaseOrder.id}`"
                class="hover:underline"
            >
              {{ $formatPrice(purchaseOrder.invoiced_amount) }}
            </router-link>
          </div>
          <div class="totals-row">
            <span>
              {{ $t('Remaining') }}
            </span>
            <span>
              {{ $formatPrice(purchaseOrder.extended_amount - purchaseOrder.invoiced_amount) }}
            </span>
          </div>
        </dl>
      </template>

    </InvoiceDetailsPage>
    <BaseBackLink
      v-if="showBack"
      to="/purchasing-inventory/purchase-orders"
    />
  </div>
</template>

<script>
  import PurchaseOrderItems
    from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderItems'
  import VendorContactInfo from '@/modules/accounts-payable/components/invoice/VendorContactInfo'
  import CompanyContactInfo from '@/modules/accounts-payable/components/invoice/CompanyContactInfo'
  import InvoiceDetailsPage from '@/components/invoice/InvoiceDetailPage'
  import InvoiceTable from "@/modules/accounts-payable/pages/invoices/InvoiceTable.vue";
  import { resourceStatuses } from "@/enum/enums";
  import ReviewStatus from "@/components/links/ReviewStatus.vue";
  import PurchaseOrderAdjustmentsList
    from "@/modules/purchasing-inventory/pages/purchase-orders/purchase-order-adjustments-list.vue";
  import InvoiceDescription from "@/modules/accounts-payable/components/invoice/InvoiceDescription.vue";

  export default {
    props: {
      id: String,
      showBack: {
        type: Boolean,
        default: true,
      }
    },
    components: {
      InvoiceDescription,
      PurchaseOrderAdjustmentsList,
      ReviewStatus,
      InvoiceTable,
      VendorContactInfo,
      InvoiceDetailsPage,
      CompanyContactInfo,
      PurchaseOrderItems,
    },
    data() {
      return {
        loading: false,
        lineItems: [],
        resourceStatuses,
      }
    },
    computed: {
      originalPurchaseOrder() {
        return this.$store.state.inventoryManagement.currentPurchaseOrder || {
          attributes: {},
          relationships: {},
          meta: {},
        }
      },
      showApproveStatus() {
        const status = this.originalPurchaseOrder?.attributes?.status
        return [resourceStatuses.Pending, resourceStatuses.NoPost].includes(status)
      },
      isPosted() {
        const status = this.originalPurchaseOrder?.attributes?.status
        return ![resourceStatuses.Pending, resourceStatuses.NoPost].includes(status)
      },
      purchaseOrderLoading() {
        return this.$store.state.inventoryManagement.poLoading
      },
      purchaseOrder() {
        return {
          ...this.originalPurchaseOrder?.attributes || {},
          ...this.originalPurchaseOrder?.relationships || {},
        }
      },
      invoiceUrlParams() {
        return {
          purchase_order_id: this.originalPurchaseOrder?.id,
        }
      },
      vendor() {
        const vendorData = this.originalPurchaseOrder?.relationships?.vendor || { attributes: {}, relationships: {} }
        const addresses = this.originalPurchaseOrder?.relationships['vendor.addresses']?.addresses || []
        return {
          ...vendorData?.attributes,
          ...vendorData?.relationships,
          addresses,
        }
      },
    },
  }
</script>
