<template>
  <div>
    <AgDataTable
      ref="table"
      url="/restify/tasks"
      :url-params="urlParams"
      :columns="columns"
      :add-text="$t('New Task')"
      :transform-data="transformData"
      edit-entity-url-query="?id={ID}"
      domLayout="autoHeight"
      actions="refresh,search,add,edit,delete"
      @add="showAddTaskDialog = true"
    >
      <template #attributes.employee_ids="{row}">
        <div>
          <EmployeeLink
            v-for="employeeId in row.attributes.employee_ids"
            :key="employeeId"
            :id="employeeId"
          />
        </div>
      </template>
    </AgDataTable>
    <TaskDialog
      v-if="showAddTaskDialog"
      :open.sync="showAddTaskDialog"
      @save="onTaskSave"
    />
    <TaskDialog
      v-if="showEditTaskDialog"
      :open.sync="showEditTaskDialog"
      :data="selectedRow"
      @save="onTaskSave"
    />
  </div>
</template>
<script>
import TaskDialog from "@/modules/payroll/components/tasks/TaskDialog.vue";
import axios from "axios";

export default {
  components: {
    TaskDialog
  },
  data() {
    return {
      showAddTaskDialog: false,
      showEditTaskDialog: false,
      selectedRow: null,
      tasks: [],
    }
  },
  computed: {
    urlParams() {
      return {}
    },
    columns() {
      return [
        {
          headerName: this.$t('Cost Center'),
          field: 'attributes.cost_center',
          minWidth: 70,
          maxWidth: 100,
        },
        {
          headerName: this.$t('Source'),
          field: 'attributes.source_id',
          component: 'SourceLink',
          minWidth: 150,
          maxWidth: 250,
        },
        {
          headerName: this.$t('Line Item'),
          field: 'attributes.addl_source_id',
          component: 'AddlSourceLink',
          minWidth: 200,
          maxWidth: 250,
        },
        {
          headerName: this.$t('Start Date & Time'),
          field: 'attributes.start_at',
          component: 'FormattedDate',
          minWidth: 180,
          maxWidth: 200,
          cellRendererParams: {
            format: 'datetime'
          },
        },
        {
          headerName: this.$t('End Date & Time'),
          field: 'attributes.finish_at',
          component: 'FormattedDate',
          minWidth: 180,
          maxWidth: 200,
          cellRendererParams: {
            format: 'datetime'
          },
        },
        {
          headerName: this.$t('Assigned Employees'),
          field: 'attributes.employee_ids',
          wrapText: true,
          autoHeight: true,
          minWidth: 250,
          maxWidth: 400,
        }
      ]
    }
  },
  methods: {
    onTaskSave() {
      this.showAddTaskDialog = false
      this.refresh()
    },
    refresh() {
      this.$refs.table?.refresh()
    },
    transformData(data) {
      this.tasks = data
      return data
    },
    async getTaskById(id) {
      const task = this.tasks.find(task => task.id === id)
      if (task) {
        this.selectedRow = task
        return
      }

      const { data } = await axios.get(`/restify/tasks/${id}`, {
        params: {
          ignore404: true,
        }
      })

      this.selectedRow = data
    }
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      async handler(taskId) {
        if (!taskId) {
          return
        }
        await this.getTaskById(taskId)
        this.showEditTaskDialog = true
      }
    }
  }
}
</script>
