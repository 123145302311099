import i18n from "@/i18n";

export const reportPrintOrientations = {
  Portrait: 'portrait',
  Landscape: 'landscape',
}
export const reportPrintOrientationOptions = [
  {
    label: i18n.t('Portrait'),
    value: reportPrintOrientations.Portrait,
  },
  {
    label: i18n.t('Landscape'),
    value: reportPrintOrientations.Landscape,
  }
]
export const reportNumbersMap = {
  1871: {
    number: '1871',
    name: 'History Selections',
  },
  1872: {
    number: '1872',
    name: 'Job & Other Distributions',
  },
  1881: {
    number: '1881',
    name: 'General Ledger',
  },
  1882: {
    number: '1882',
    name: 'Account Listing',
  },
  1883: {
    number: '1883',
    name: 'Sub-Accounts / Budgets',
  },
  1884: {
    number: '1884',
    name: 'Budget To Actual Compare',
  },
  1885: {
    number: '1885',
    name: 'Account Balance Comparison',
  },
  '1885a': {
    number: '1885a',
    name: 'Account Balance Comparison and Sub-Accounts',
  },
  1886: {
    number: '1886',
    name: 'Trial Balance',
    printV2: true,
    printScaling: {
      [reportPrintOrientations.Portrait]: 0.8
    }
  },
  1877: {
    number: '1877',
    name: 'Check / Deposit Register',
  },
  2871: {
    number: '2871',
    name: 'Daily Job Cost Detail',
  },
  2877: {
    number: '2877',
    name: 'Billing / Cost Comparison',
    printV2: true,
    printOrientation: reportPrintOrientations.Landscape,
    printScaling: {
      [reportPrintOrientations.Landscape]: 0.8,
    }
  },
  2886: {
    number: '2886',
    name: 'Job Cost Variance Report',
  },
  3882: {
    number: '3882',
    name: 'AP - Aged by Vendor',
  },
  3883: {
    number: '3883',
    name: 'AP - Aged by Job',
  },
  '3884a': {
    number: '3884a',
    name: 'Vendor 1099s Proof Listing',
    printV2: true,
  },
  3885: {
    number: '3885',
    name: 'Vendor Payment Register',
    printV2: true,
  },
  3888: {
    number: '3888',
    name: 'Vendor History',
  },
  '3888a': {
    number: '3888a',
    name: 'Vendor History - By Job',
  },
  '3888b': {
    number: '3888b',
    name: 'Vendor History - Payment Summary',
  },
  '4060a': {
    number: '4060a',
    name: 'Direct Deposit Listing',
  },
  '4872b': {
    number: '4872b',
    name: '125S & 401K Plan Summmary',
  },
  '4872c': {
    number: '4872c',
    name: 'Payroll History - By Employee',
  },
  '4872f': {
    number: '4872f',
    name: 'Payroll Register Recap',
  },
  '4875': {
    number: '4875',
    name: 'Selected Payroll History',
  },
  4877: {
    number: '4877',
    name: 'General Liability Summary By Employee',
  },
  '4877a': {
    number: '4877a',
    name: 'General Liability Summary By Job',
  },
  4878: {
    number: '4878',
    name: 'Quarterly State Unemployment',
    printV2: true,
  },
  4879: {
    number: '4879',
    name: 'Payroll Payment Register',
    printV2: true,
  },
  4881: {
    number: '4881',
    name: 'Quarterly Earnings - Federal',
  },
  4882: {
    number: '4882',
    name: 'Quarterly Earnings - State / Local',
  },
  '4882a': {
    number: '4882a',
    name: 'Quarterly Earnings - State / Local',
  },
  4883: {
    number: '4883',
    name: 'To-Date Earnings and Taxes',
  },
  4884: {
    number: '4884',
    name: 'Employee W-2 Proof Listing',
    printV2: true,
    printOrientation: reportPrintOrientations.Landscape,
    printScaling: {
      [reportPrintOrientations.Landscape]: 0.9,
    }
  },
  4885: {
    number: '4885',
    name: 'Employee Listing',
    printOrientation: reportPrintOrientations.Landscape,
  },
  4886: {
    number: '4886',
    name: 'Certified Payroll',
  },
  4887: {
    number: '4887',
    name: 'Union Report - By Craft',
  },
  '4887a': {
    number: '4887a',
    name: 'Union Report - By Employee Summary',
  },
  '4887b': {
    number: '4887b',
    name: 'Union Report - By Employee Summary',
  },
  '4887c': {
    number: '4887c',
    name: 'Union Report - By Job Total Hours',
  },
  4888 : {
    number: '4888',
    name: 'Worker`s Comp Summary - By Employee',
  },
  '4888a': {
    number: '48.88a',
    name: 'Worker`s Comp Summary - By Job',
  },
  5873: {
    number: '5873',
    name: 'AR - Invoice Listing - By Invoice',
    printV2: true,
    printScaling: {
      [reportPrintOrientations.Landscape]: 0.9,
      [reportPrintOrientations.Portrait]: 0.8,
    },
  },
  5877: {
    number: '5877',
    name: 'AR - Sales Tax Report',
  },
  '5877d': {
    number: '5877d',
    name: 'AR - Customer Details Sales Tax Report',
  },
  5878: {
    number: '5878',
    name: 'AR - Applied Customer Payments',
    printV2: true,
    printScaling: {
      [reportPrintOrientations.Landscape]: 0.9,
      [reportPrintOrientations.Portrait]: 0.8,
    },
  },
  5882: {
    number: '5882',
    name: 'AR - Aged by Customer',
  },
  5883: {
    number: '5883',
    name: 'AR - Aged by Job',
  },
  5884: {
    number: '5884',
    name: 'AR - History by Customer',
  },
  '5884a': {
    number: '5884a',
    name: 'AR - History by Job',
  },
  '5884b': {
    number: '5884b',
    name: 'AR - History by Invoice',
  },
  '5884c': {
    number: '5884c',
    name: 'AR - History by Invoice',
  },
  5889: {
    number: '5889',
    name: 'Job Pre-Billing Worksheet',
  },
  5581: {
    number: '5581',
    name: 'SB - Work Order Detail',
  },
  5582: {
    number: '5582',
    name: 'SB - Work Order Listing',
  },
  5583: {
    number: '5583',
    name: 'SB - Work Order Profit Summary',
  },
  5584: {
    number: '5584',
    name: 'SB - Service Billing History',
  },
  7871: {
    number: '7871',
    name: 'IV - Purchase Order Listing',
  },
  default: false,
}
