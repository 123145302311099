<template>
  <base-form
      :loading="loading"
      :show-cancel="showCancel"
      :save-text="$t('Create flexible benefit plan')"
      :update-text="$t('Update flexible benefit plan')"
      :can-create-another-entity="!model.id"
      layout="modal"
      grid-classes="grid grid-cols-8 gap-x-4"
      @cancel="$emit('cancel')"
      @submit="onSubmit"
  >
    <NonTaxableWarning :data="model" class="col-span-8 mb-4"/>
    <code-input
      v-model="model.code"
      :model="model"
      :resource-name="globalResources.FlexibleBenefitPlans"
      class="col-span-8 md:col-span-3 xl:col-span-1"
    />
    <base-input
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        id="description"
        rules="max:150"
        class="col-span-8 md:col-span-5 xl:col-span-2"
    />
    <div class="col-span-8 md:col-span-2 xl:col-span-1">
      <W2MemoCodeSelect
        v-model="model.w2_code"
        clearable
      />
    </div>
    <base-select
        v-model="model.calculate_by"
        :label="$t('Calculate By')"
        :placeholder="$t('Calculate By')"
        :options="calculateByOptions"
        id="calculate_by"
        rules="required"
        class="col-span-8 md:col-span-2 xl:col-span-1"
        @change="onChangeCalculateBy"
    />

    <base-select
        v-model="model.multiply_by"
        :label="$t('Multiply Rate By')"
        :placeholder="$t('Multiply Rate By')"
        :options="getMultiplyByOptions"
        id="multiply_by"
        rules="required"
        class="col-span-8 md:col-span-2"
        @change="onChangeMultiplyBy"
    />
    <div class="col-span-6 md:col-span-1">
      <ActiveSwitch v-model="model.active"></ActiveSwitch>
    </div>
    <div class="col-span-8"/>
    <account-select
        v-model="model.account"
        :label="$t('Account')"
        :placeholder="$t('Account')"
        clearable
        value-key="number"
        label-key="number"
        id="liability_account"
        rules="required"
        class="col-span-4 md:col-span-2"
    />
    <sub-account-select
        v-model="model.subaccount"
        :label="$t('Subaccount')"
        :placeholder="$t('Subaccount')"
        clearable
        value-key="number"
        label-key="number"
        id="expense_subaccount"
        class="col-span-4 md:col-span-2 xl:col-span-1"
    />

    <div class="col-span-8 md:col-span-2 mb-4 md:mt-0">
      <base-checkbox
          v-model="model.subject_to_workers_comp"
          :label="$t(`Worker's Compensation`)"
          id="subject_to_workers_comp"
      />
      <base-checkbox
          v-model="model.subject_to_liability_insurance"
          :label="$t('General Liability Insurance')"
          id="subject_to_liability_insurance"
      />
    </div>
    <div class="col-span-8">
      <el-collapse v-model="activeGroups">
        <base-collapse-section
            key="subject_to"
            name="subject_to"
        >
          <template v-slot:title>
            <div class="flex w-full items-center justify-between">
              {{ $t('Subject To') }}
              <div class="flex items-center">
                {{ bulkTaxesState ? $t('Disable All') : $t('Enable All') }}
                <base-switch
                    v-model="bulkTaxesState"
                    class="ml-2 mr-4"
                    @click.native.stop
                    @change="triggerRrBulkAction"
                />
              </div>
            </div>
          </template>
          <div class="grid grid-cols-8 gap-x-4">
            <div class="col-span-8 md:col-span-2 mb-4">
              <base-checkbox
                  v-model="model.subject_to_fui"
                  :label="$t('Federal Unemployment')"
                  id="subject_to_fui"
              />
              <base-checkbox
                  v-model="model.subject_to_sui"
                  :label="$t('State Unemployment')"
                  id="subject_to_sui"
              />
              <base-checkbox
                  v-model="model.subject_to_sdi"
                  :label="$t('State Disability')"
                  id="subject_to_sdi"
              />
              <base-checkbox
                  v-model="model.subject_to_fica"
                  :label="$t('Fica/Medicare')"
                  id="subject_to_fica"
              />
            </div>
            <div class="col-span-8 md:col-span-2 mb-4">
              <base-checkbox
                  v-model="model.subject_to_fit"
                  :label="$t('Federal Withholding')"
                  id="subject_to_fit"
              />
              <base-checkbox
                  v-model="model.subject_to_sit"
                  :label="$t('State Withholding')"
                  id="subject_to_sit"
              />
              <base-checkbox
                  v-model="model.subject_to_lit"
                  :label="$t('Local Withholding')"
                  id="subject_to_lit"
              />
            </div>
          </div>
        </base-collapse-section>
        <base-collapse-section
            :title="$t('Additional Information')"
            key="additional_information"
            name="additional_information"
        >
          <div class="grid grid-cols-8 gap-x-4">
            <div class="col-span-8 md:col-span-2 mb-4">
              <base-checkbox
                  v-model="model.include_on_union_reports"
                  :label="$t('Include On Union Report')"
                  id="include_on_union_reports"
              />
              <base-checkbox
                  v-model="model.show_on_paystub"
                  :label="$t('Show on Paystub')"
                  id="show_on_paystub"
              />
            </div>
          </div>
        </base-collapse-section>
      </el-collapse>
    </div>
  </base-form>
</template>
<script lang="ts">
  import axios from 'axios'
  import { defineComponent } from 'vue'
  import BaseCollapseSection from '@/components/form/BaseCollapseSection.vue'
  import BenefitCodeForm from '@/modules/payroll/components/codes/BenefitCodeForm.vue'
  import NonTaxableWarning from '@/modules/payroll/components/codes/NonTaxableWarning.vue'
  import { CALCULATE_BY_RATE_KEY, multiplyBy } from '@/modules/payroll/components/rates/util.js'
  import { BenefitCode } from '@/modules/common/types/models'
  import {globalResources} from "@/components/form/util";

  export default defineComponent({
    extends: BenefitCodeForm,
    components: {
      BaseCollapseSection,
      NonTaxableWarning,
    },
    data() {
      return {
        globalResources,
        model: {
          code: '',
          description: '',
          account: '',
          subaccount: '',
          calculate_by: CALCULATE_BY_RATE_KEY,
          multiply_by: multiplyBy.REGULAR_HOURS,
          subject_to_workers_comp: true,
          subject_to_liability_insurance: true,
          subject_to_fit: false,
          subject_to_sit: false,
          subject_to_lit: false,
          subject_to_fica: true,
          subject_to_fui: true,
          subject_to_sdi: true,
          subject_to_sui: true,
          include_on_union_reports: true,
          show_on_paystub: true,
          active: true,
          reduce_disposible_earnings: false,
          show_in_ytds: false,
          w2_code: null,
        } as BenefitCode,
      }
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          delete this.model.job_type_id
          if (this.model.id) {
            await axios.put(`/restify/flexible-benefit-plans/${ this.model.id }`, this.model)
            this.$success(this.$t('Flexible Benefit plans updated.'))
            this.$emit('save', this.model)
          } else {
            const { data } = await axios.post('/restify/flexible-benefit-plans', this.model)
            this.$success(this.$t('Flexible Benefit plans created.'))
            // @ts-ignore
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
        } catch (err: any) {
          if (err.handled) {
            return
          }
          console.log('err', err)
          this.$error(this.$t('Could not update the flexible benefit plan.'))
        } finally {
          this.loading = false
        }
      },
      triggerRrBulkAction(state: boolean) {
        this.model = {
          ...this.model,
          subject_to_fit: state,
          subject_to_sit: state,
          subject_to_lit: state,
          subject_to_fica: state,
          subject_to_fui: state,
          subject_to_sdi: state,
          subject_to_sui: state,
          subject_to_workers_comp: state,
          subject_to_liability_insurance: state,
        }
      },
    },
  })
</script>
