<template>
  <div class="flex-1 h-[calc(100%-50px)]">
    <ag-data-table
      :url="url"
      :columns="getColumns"
      :url-params="finalUrlParams"
      :is-expandable="isExpandable"
      :add-entity-in-new-tab="true"
      :select-row-filter="row => !isRowSelectionDisabled(row)"
      :add-text="$t('New purchase order')"
      :hide-actions="hideActions"
      :add-entity-url-query="addEntityUrlQuery"
      :groupDefaultExpanded="1"
      :groupRowRendererParams="groupRowRendererParams"
      :masterDetail="true"
      :detailCellRenderer="detailCellRenderer"
      :detailRowAutoHeight="true"
      :enableFillHandle="true"
      :enableRangeSelection="true"
      :actions-column-width="200"
      ref="table"
      groupDisplayType="groupRows"
      default-sort="code"
      entity="purchase-orders"
      permission="purchase_orders"
      actions="search,refresh,view"
      base-entity-path="/purchasing-inventory/purchase-orders"
      class="h-full"
      domLayout="autoHeight"
      @all-selected="onAllSelected"
      @delete="refreshTable"
      @data-fetch="onDataFetch"
      @meta-fetch="$emit('meta-fetch', $event)"
    >
      <template #attributes.cost_center="{row}">
        <div class="flex items-center space-x-1">
          <span>{{ row.attributes.cost_center }}</span>
          <SourceLink
            v-if="row.attributes.source_id"
            :data="row.attributes"
            :data-as-source="true"
          />
        </div>
      </template>
      <template v-slot:additional-actions>
        <void-button v-if="authorizedToVoidBulk"
                     :disabled="voidBulkDisabled"
                     :loading="voidingProgress"
                     size="xs"
                     variant="gray-link"
                     @click="showVoidDialog = true"
        >
          {{ $t('Void') }}
        </void-button>
        <post-button v-if="authorizedToPostBulk"
                     :disabled="postBulkDisabled"
                     :loading="postingProgress"
                     size="xs"
                     variant="gray-link"
                     @click="showPostDialog = true"
        />
      </template>
      <template v-slot:extra-actions="{row, index}">
        <slot
          name="extra-actions"
          :row="row"
          :index="index"
        >
          <PostAction
            v-if="$isAuthorized('authorizedToPost', row)"
            :id="row.id"
            :is-accounting="false"
            entity-name="Purchase Order"
            resource-name="purchase-orders"
            variant="gray-link"
            size="xs"
            @on-action-callback="refreshTable"
          />
        </slot>
        <VoidAction
          v-if="$isAuthorized('authorizedToVoid', row)"
          :id="row.id"
          :is-accounting="false"
          entity-name="Purchase Order"
          resource-name="purchase-orders"
          variant="gray-link"
          size="xs"
          @on-action-callback="refreshTable"
        />
      </template>
      <template #attributes.status="{row}">
        <invoice-status-popover
          :invoice="row"
          :resource-name="resourceName"
        />
      </template>
      <template #select="{row}">
        <BaseTooltip
          :disabled="!isRowSelectionDisabled(row)"
          :content="$t('Purchase order cannot be voided because it was already invoiced')">
          <base-checkbox v-model="row.selected"
                         :disabled="isRowSelectionDisabled(row)"/>
        </BaseTooltip>
      </template>
      <template #placed_by="{row}">
        <EmployeeLink
          v-if="get(row, 'attributes.employee_id')"
          :id="get(row, 'attributes.employee_id')"
          :show-name="false"
          :show-preview="false"
        />
        <span v-else>{{ row.attributes?.placed_by_name }}</span>
      </template>
    </ag-data-table>
    <BaseFormDialog
      v-if="showPostDialog"
      :title="$t('Post selected purchase order(s)?')"
      :open.sync="showPostDialog">
      <BasePostDialog
        :is-accounting="false"
        prompt-message="This will post the purchase order(s) entries to the corresponding vendor. Note that this action is irreversible."
        @cancel="showPostDialog = false"
        @save="onPost"
      />
    </BaseFormDialog>
    <BaseFormDialog
      v-if="showVoidDialog"
      :title="$t('Void selected purchase order(s)?')"
      :open.sync="showVoidDialog">
      <BaseVoidDialog
        :is-accounting="false"
        prompt-message="Note that this action is irreversible. We will still keep the invoices in our history"
        @cancel="showVoidDialog = false"
        @save="onVoid"
      />
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from 'axios'
import { getPurchaseOrderStatuses, resourceStatuses } from '@/enum/enums'
import { groupByType } from '@/components/table/utils/groupByTypes'
import PurchaseOrderItems from '@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderItems'
import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
import i18n from "@/i18n";
import { getAddButtonPath } from "@/modules/common/util/costCenterUtils";
import VendorGroupRow from "@/modules/purchasing-inventory/components/purchase-orders/VendorGroupRow.vue";
import PurchaseOrderDetails from "@/modules/purchasing-inventory/components/purchase-orders/PurchaseOrderDetails.vue";
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { cellClasses } from "@/components/ag-grid/columnUtils";
import { valueSetterWithUpdate } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import SourceLink from "@/components/links/SourceLink.vue";

export default {
  components: {
    SourceLink,
    VendorGroupRow,
    PurchaseOrderItems,
    PurchaseOrderDetails,
  },
  props: {
    status: String,
    urlParams: {
      type: Object,
      default: () => ({})
    },
    filterByVendor: {
      type: Boolean,
    },
    hideActions: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      selectedGroupBy: groupByType.Vendor,
      resourceName: 'purchase-orders',
      isExpandable: true,
      url: '/restify/purchase-orders',
      showPostDialog: false,
      showVoidDialog: false,
      postingProgress: false,
      voidingProgress: false,
      detailCellRenderer: 'PurchaseOrderDetails',
      purchaseOrders: [],
      columns: [
        {
          headerName: ' ',
          cellRenderer: 'agGroupCellRenderer',
          maxWidth: 55,
          minWidth: 55,
          lockVisible: true,
        },
        {
          label: 'Vendor',
          prop: 'attributes.vendor_id',
          hiddenValue: true,
          minWidth: 80,
          maxWidth: 80,
          rowGroup: true,
          hide: true,
          lockVisible: true,
        },
        {
          label: this.$t('PO #'),
          prop: 'attributes.number',
          minWidth: 100,
          maxWidth: 150,
          component: 'EntityLink',
          redirectTo: '/purchasing-inventory/purchase-orders/{ID}/view',
        },
        {
          label: i18n.t('Reference'),
          prop: 'attributes.cost_center',
          minWidth: 140,
          maxWidth: 320,
          hide: true,
        },
        {
          label: this.$t('Order Date'),
          prop: 'attributes.date',
          component: 'FormattedDate',
          minWidth: 110,
          maxWidth: 140,
        },
        {
          label: this.$t('Delivery Date'),
          prop: 'attributes.delivery_date',
          component: 'FormattedDate',
          minWidth: 110,
          maxWidth: 140,
        },
        {
          label: this.$t('Amount'),
          prop: 'attributes.extended_amount',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 110,
          maxWidth: 150,
        },
        {
          label: this.$t('Invoiced Amount'),
          prop: 'attributes.invoiced_amount',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 100,
          maxWidth: 150,
        },
        {
          label: this.$t('Freight Amount'),
          prop: 'attributes.freight_amount',
          align: 'right',
          component: 'FormattedPrice',
          minWidth: 100,
          maxWidth: 150,
        },
        {
          label: this.$t('Status'),
          prop: 'attributes.status',
          align: 'center',
          minWidth: 100,
          maxWidth: 160,
          component: 'Status',
          editable: true,
          cellEditor: cellEditors.Status,
          cellEditorParams: params => {
            return {
              options: getPurchaseOrderStatuses(params.data)
            }
          },
          headerClass: cellClasses.HeaderEditable,
          valueSetter: (params) => {
            return valueSetterWithUpdate({
              storeAction: 'inventoryManagement/patchPurchaseOrder',
            })(params)
          },
        },
        {
          label: this.$t('Adjustments'),
          prop: 'attributes.adjustments_count',
          align: 'center',
          minWidth: 120,
          maxWidth: 160,
          component: 'Status',
        },
        {
          label: i18n.t('Review Status'),
          align: 'center',
          prop: 'attributes.review_status',
          minWidth: 100,
          maxWidth: 160,
          component: 'ReviewStatus',
          hide: ![resourceStatuses.Review, resourceStatuses.Pending].includes(this.status),
        },
        {
          label: this.$t('Contact'),
          prop: 'attributes.vendor_contact_name',
          minWidth: 80,
          maxWidth: 250,
        },
        {
          label: this.$t('Placed By'),
          prop: 'placed_by',
          minWidth: 120,
          maxWidth: 250,
        },
      ],
    }
  },
  computed: {
    groupRowRendererParams() {
      return {
        innerRenderer: 'VendorGroupRow',
        suppressCount: true,
        wrapText: true,
        autoHeight: true,
      }
    },
    finalUrlParams() {
      let status = this.status
      if (status === resourceStatuses.All) {
        status = undefined
      }
      if (status === resourceStatuses.Pending) {
        status = `${resourceStatuses.Pending},${resourceStatuses.NoPost}`
      }
      return {
        related: `${entityPreviewFields.Vendor},${entityPreviewFields.Employee}`,
        status,
        ...this.urlParams,
      }
    },
    getColumns() {
      if (![resourceStatuses.Open, resourceStatuses.Pending, resourceStatuses.NoPost].includes(this.status)) {
        return this.columns
      }

      return [
        {
          headerName: ' ',
          field: 'select',
          minWidth: 45,
          maxWidth: 45,
          cellClass: '!px-2'
        },
        ...this.columns,
      ]
    },
    selectedRows() {
      return this.purchaseOrders.filter(e => e.selected)
    },
    authorizedToVoidBulk() {
      return this.status === resourceStatuses.Open
    },
    authorizedToPostBulk() {
      return this.status === resourceStatuses.Pending
    },
    voidBulkDisabled() {
      return !this.selectedRows.some(row => row.meta[this.$actionPolicies.Void])
    },
    postBulkDisabled() {
      return !this.selectedRows.some(row => row.meta[this.$actionPolicies.Post])
    },
    addEntityUrlQuery() {
      const baseUrl = '/purchasing-inventory/purchase-orders/add'
      return getAddButtonPath(baseUrl, this.urlParams)
    },
  },
  methods: {
    onAllSelected(value) {
      this.allPagesSelected = value
    },
    async onPost(data) {
      try {
        this.postingProgress = true
        this.showPostDialog = false
        const repositories = this.selectedRows.map(row => row.id)
        const payload = {
          repositories,
          ...data,
        }
        await axios.post('/restify/purchase-orders/actions?action=post-purchase-orders', payload)
        await this.$store('globalLists/getIVConfiguration', true)
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t(`Could not post purchase order(s)`))
      } finally {
        this.postingProgress = false
        this.refreshTable()
      }
    },
    isRowSelectionDisabled(row) {
      const { meta } = row
      if (!meta) {
        return false
      }
      return !meta[this.$actionPolicies.Post] && !meta[this.$actionPolicies.Void]
    },
    refreshTable() {
      this.allPagesSelected = false
      this.$refs.table.refresh()
    },
    onDataFetch(data) {
      this.purchaseOrders = data
      this.$emit('data-fetch', data)
    },
    async onVoid(data) {
      try {
        this.voidingProgress = true
        this.showVoidDialog = false
        const repositories = this.selectedRows.map(row => row.id)
        const payload = {
          repositories,
          ...data,
        }
        await axios.post('/restify/purchase-orders/actions?action=void-purchase-orders', payload)

      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t(`Could not void purchase order(s)`))
      } finally {
        this.voidingProgress = false
        this.refreshTable()
      }
    },
  },
}
</script>
