<template>
  <BaseDataTable
    v-bind="defaultAttributes"
    :dataLoading="dataLoading"
    :columns="columns"
    :data="getData"
    :total-rows="rowsLength"
    :has-summary="false"
    :compact="true"
    class="no-borders vendor-1099s-proof-listing"
    @force-pagination="forcePagination"
  >
    <template #additional-actions-before>
      <component
        :disabled="!batch_id"
        :is="batch_id ? 'router-link': 'span'"
        :to="`/accounts-payable/vendor-1099s/${batch_id}/view`"
      >
        <BaseButton
          variant="primary-link"
          :disabled="!batch_id"
        >
          <Edit2Icon
            class="w-4 h-4 mr-2"
          />
          {{ $t('Edit 1099s Batch')}}
        </BaseButton>
      </component>
    </template>
    <template #report-header>
      <BaseReportHeader :header="data.header"/>
      <EmployeeW2ReportErrors
        v-if="data.errors"
        :errors="data.errors"
      />
    </template>

    <template #html-row="{ htmlData }">
      <tr
        v-if="htmlData.divider"
        class="content-row"
      >
        <td :colspan="columns.length">
          <div class="border-t border-gray-300 mb-2 -mt-1" />
        </td>
      </tr>
      <tr
        v-else
        class="content-row"
      >
        <td :colspan="columns.length">
          <div class="font-semibold">{{ $t('Please verify the following payer information') }}:</div>
          <dl class="space-y-4 mt-4">
            <dt class="flex space-x-2">
              <span class="font-semibold">{{ $t('Payer Name & Address') }}</span>
              <div>
                <div>
                  {{ htmlData.payer.name }}
                </div>
                <div>
                  {{ htmlData.payer.address?.address_1 }}
                </div>
                <div v-if="htmlData.payer.address?.address_2">
                  {{ htmlData.payer.address?.address_2}}
                </div>
                <div>
                  {{ htmlData.payer.address?.city }} {{ htmlData.payer.address?.state }} {{ htmlData.payer.address?.postal_code }}
                </div>
              </div>
            </dt>
            <dt class="flex space-x-2">
              <span class="font-semibold">{{ $t('Telephone #') }}</span>
              <BasePhoneLink
                :value="htmlData.payer.phone"
                text-class="text-gray-600"
              />
            </dt>
            <dt class="flex space-x-2">
              <span class="font-semibold">{{ $t('Payer Federal ID Number (EIN)') }}</span>
              <div>
                {{ htmlData.payer.federal_tax_id }}
              </div>
            </dt>
            <dt class="flex space-x-4">
              <span class="font-semibold">{{ $t('Payer State ID Number (EIN)') }}</span>
              <div>{{ getStateIdNumber(htmlData) }}</div>
            </dt>
          </dl>
          <div class="max-w-4xl whitespace-pre-wrap mt-2">
            <p class="font-semibold">{{ $t('Note') }}</p>
            <p>{{ $t(`If Vendor 1099 Name is blank, the Vendor DBA name will be printed on the 1099 as the Recipient name. For Sole Proprietors, the IRS requires and individual's name (not company name) with either a Social Security or Federal ID number.`) }}</p>
          </div>
        </td>
      </tr>
    </template>
    <template #vendor="{ row }">
      <div v-if="row?.vendor">
        <VendorLink
          :data="row.vendor"
          :show-preview="false"
          class="min-w-[400px]"
        />
        <div>
          {{ row.address?.address_1 }}
        </div>
        <div v-if="row.address?.address_2">
          {{ row.address?.address_2 }}
        </div>
        <div>
          {{ row.address?.city }} {{ row.address?.state }} {{ row.address?.postal_code }}
        </div>
      </div>
    </template>

    <template #amounts="{ row }">
      <dl class="w-full">
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Non-Empl Compensation (1)') }}</span>
          <span>{{ $formatPrice(row.non_compensation_amount) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Resale Checkbox 2 (2)') }}</span>
          <span>{{ row.is_box_2_resale ? $t('Yes') : $t('No') }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Rents (1)') }}</span>
          <span>{{ $formatPrice(row.rent_amount) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Royalties (2)') }}</span>
          <span>{{ $formatPrice(row.royalties_amount) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Other income (3)') }}</span>
          <span>{{ $formatPrice(row.other_income_amount) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('FIT Withheld (4)') }}</span>
          <span>{{ $formatPrice(row.fit_amount) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Medical & Health Pay (6)') }}</span>
          <span>{{ $formatPrice(row.health_care_amount) }}</span>
        </dt>

        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Resale Checkbox 7 (7)') }}</span>
          <span>{{ row.is_box_7_resale ? $t('Yes') : $t('No') }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('Attourney Proceeds (10)') }}</span>
          <span>{{ $formatPrice(row.attorney_fees_amount) }}</span>
        </dt>
        <dt class="flex justify-between">
          <span class="font-semibold">{{ $t('State Withheld (5/16)') }}</span>
          <span>{{ $formatPrice(row.sit_amount) }}</span>
        </dt>
      </dl>

      <dl class="w-full">
        <dt class="flex justify-between mt-1 border-t border-gray-500">
          <span class="font-semibold mt-1">{{ $t('Total') }}</span>
          <span>{{ $formatPrice(row.total_amount) }}</span>
        </dt>
      </dl>
    </template>

    <template #subtotal="{ subtotal }">
      <template v-if="subtotal.warning">
        <td :colspan="columns.length">
          <div class="text-red-500 font-normal">
            {{ subtotal.warning }}
          </div>
        </td>
      </template>
      <template v-else>
        <td colspan="2" />
        <td align="right" style="vertical-align: top;">
          {{ subtotal?.description }}
        </td>
        <td align="center" style="vertical-align: top;">
          {{ $formatPrice(subtotal.totals.payments_amount) }}
        </td>
        <td style="vertical-align: top;">
          <dl class="w-full">
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('Non-Empl Compensation (1)') }}</span>
              <span>{{ $formatPrice(subtotal.totals.non_compensation_amount) }}</span>
            </dt>
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('Rents (1)') }}</span>
              <span>{{ $formatPrice(subtotal.totals.rent_amount) }}</span>
            </dt>
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('Royalties (2)') }}</span>
              <span>{{ $formatPrice(subtotal.totals.royalties_amount) }}</span>
            </dt>
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('Other income (3)') }}</span>
              <span>{{ $formatPrice(subtotal.totals.other_income_amount) }}</span>
            </dt>
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('FIT Withheld (4)') }}</span>
              <span>{{ $formatPrice(subtotal.totals.fit_amount) }}</span>
            </dt>
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('Medical & Health Pay (6)') }}</span>
              <span>{{ $formatPrice(subtotal.totals.health_care_amount) }}</span>
            </dt>

            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('Attourney Proceeds (10)') }}</span>
              <span>{{ $formatPrice(subtotal.totals.attorney_fees_amount) }}</span>
            </dt>
            <dt class="flex justify-between">
              <span class="font-semibold">{{ $t('State Withheld (5/16)') }}</span>
              <span>{{ $formatPrice(subtotal.totals.sit_amount) }}</span>
            </dt>
          </dl>

          <dl class="w-full">
            <dt class="flex justify-between mt-1 border-t border-gray-500">
              <span class="font-semibold mt-1">{{ $t('Total') }}</span>
              <span>{{ $formatPrice(subtotal.totals.total_amount) }}</span>
            </dt>
          </dl>
        </td>
      </template>
    </template>
  </BaseDataTable>
</template>
<script>
import ReportTableWrapper from '@/modules/common/components/reports/ReportTableWrapper'
import { Edit2Icon } from 'vue-feather-icons'
export default {
  extends: ReportTableWrapper,
  components: {
    Edit2Icon
  },
  props: {
    dataLoading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object
    }
  },
  computed: {
    tax_year() {
      return this.$route.query.tax_year
    },
    batch_id() {
      return this.data.batch_id
    },
    columns() {
      return [
        {
          label: this.$t('Vendor'),
          prop: 'vendor',
          verticalAlign: 'top',
          minWidth: 160,
          maxWidth: 210,
        },
        {
          label: this.$t('Taxpayer ID'),
          prop: 'vendor.federal_tax_id',
          verticalAlign: 'top',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Vendor <br> 1099 Name'),
          prop: 'vendor.vendor_personal_name',
          verticalAlign: 'top',
          minWidth: 100,
          maxWidth: 120,
        },
        {
          label: this.$t('Total Payments') + `<br> (${this.tax_year || ''})`,
          prop: 'payments_amount',
          verticalAlign: 'top',
          align: 'center',
          minWidth: 100,
          maxWidth: 120,
          component: 'FormattedPrice',
        },
        {
          label: this.$t('1099 Amounts'),
          verticalAlign: 'top',
          prop: 'amounts',
          minWidth: 120,
          maxWidth: 130,
        },
      ]
    },
  },
  methods: {
    addPayerInfo() {
      const payer = this.data.company

      this.rows.push({
        htmlData: {
          payer,
        }
      })

      payer.warnings.forEach(warning => {
        this.rows.push({
          subtotal: {
            warning
          }
        })
      })
    },
    addGrandTotals() {
      const grandTotals = this.data.grand_totals
      this.rows.push({
        subtotal: {
          description: this.$t(`Grand Totals ${grandTotals.count} vendors`),
          totals: grandTotals
        },
      })

      if (grandTotals.warnings) {
        this.rows.push({
          subtotal: {
            warning: `*** WARNING... ` + this.$tc('X warnings proof list', grandTotals.warnings, {
              count: grandTotals.warnings
            })
          }
        })
      }
    },
    composeRows(data) {
      this.addPayerInfo()

      data.forEach(item => {
        this.rows.push({
          htmlData: {
            divider: true
          }
        })

        this.rows.push(item)

        item.warnings.forEach(warning => {
          this.rows.push({
            subtotal: {
              warning
            }
          })
        })

      })

      this.addGrandTotals()

      return this.rows
    },
    getStateIdNumber(data) {
      if (!data.payer?.state?.code) {
        return '-'
      }

      return `${data.payer.state.code}/${data.payer.state.employer_number}`
    }
  }
}
</script>
