<template>
  <div>
    <AgDataTable
        :columns="columns"
        :edit-entity-url-query="editEntityUrlQuery"
        :add-text="$t('New pay code')"
        url="/restify/pay-codes"
        view-entity-url-query="/payroll/codes/pay-codes"
        import-url="/payroll/codes/pay-codes/import"
        entity="pay-code"
        actions="search,refresh,print,import,export"
        permission="employees"
        hide-actions="view"
        default-sort="code"
        ref="table"
        @add="onRowAdd"
        @edit="onRowEdit"
    />
    <BaseFormDialog
        v-if="showDialog"
        :title="formTitle"
        :open.sync="showDialog"
        size="2xl"
        @cancel="onEventTrigger(false)"
    >
      <PayCodeForm
          :data="rowToEdit"
          :key="renderKey"
          @cancel="onEventTrigger(false)"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { PayCode } from '@/modules/common/types/models'
  import { Column } from '@/components/ag-grid/tableTypes'
  import PayCodeForm from '@/modules/payroll/components/codes/PayCodeForm.vue'

  export default defineComponent({
    components: {
      PayCodeForm,
    },
    data() {
      return {
        renderKey: <number>1,
        editEntityUrlQuery: <string>'?id={ID}',
        rowToEdit: null as API.Data<PayCode> | null,
        showDialog: <boolean>false,
      }
    },
    computed: {
      formTitle() {
        if (!this.rowToEdit) {
          return this.$t('Add Pay Code')
        }
        if (this.$isAuthorized(this.$actionPolicies.Update, this.rowToEdit)) {
          return this.$t('Edit Pay Code')
        }
        return this.$t('View Pay Code')
      },
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Code'),
            field: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/codes/pay-codes?id={ID}',
          },
          {
            headerName: this.$t('Description'),
            field: 'attributes.description',
          },
          {
            headerName: this.$t('Calculate By'),
            field: 'attributes.calculate_by',
            component: 'FormattedText',
          },
          {
            headerName: this.$t('Multiply By'),
            field: 'attributes.multiply_by',
            component: 'FormattedText',
          },
          {
            headerName: this.$t('Payroll Category Type'),
            field: 'attributes.certified_payroll_category_type',
            component: 'Status',
          },
          {
            headerName: this.$t('Employee Regular Rate'),
            field: 'attributes.use_employee_regular_rate',
            component: 'Status',
          },
          {
            label: this.$t('W2 Memo Code'),
            prop: 'attributes.w2_code',
            hide: true,
          },
          {
            label: this.$t('Active'),
            prop: 'attributes.active',
            component: 'Status',
            align: 'center',
          },
        ]
      }
    },
    methods: {
      onRowEdit(row: API.Data<PayCode>) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        // @ts-ignore
        this.$refs.table.refresh()
      },
      onEventTrigger(refresh = true) {
        this.rowToEdit = null
        this.showDialog = false
        if (!refresh) {
          return
        }
        this.refreshTable()
      },
    },
  })
</script>
