import axios from 'axios'
import Vue from 'vue'
import { $modules, billingTypeAbbreviations } from "@/enum/enums";
import { entityPreviewFields } from "@/modules/common/components/entity-preview/entities";
import { getSetting } from "@/plugins/settingsAndEnumsPlugin";

const app = new Vue({})

const types = {
  SET_JOB_TYPES: 'SET_JOB_TYPES',
  SET_CURRENT_JOB: 'SET_CURRENT_JOB',
  SET_CURRENT_LINE_ITEM: 'SET_CURRENT_LINE_ITEM',
  SET_CURRENT_JOB_LOADING: 'SET_CURRENT_JOB_LOADING',
  SET_CURRENT_LINE_ITEM_LOADING: 'SET_CURRENT_LINE_ITEM_LOADING',
  SET_MASTER_JOB: 'SET_MASTER_JOB',
}

const state = {
  types: [],
  currentJob: {
    attributes: {},
    relationships: {},
    meta: {},
  },
  jobLoading: false,
  currentLineItem: {
    attributes: {},
    relationships: {},
    meta: {},
  },
  lineItemLoading: false,
  masterJob: null,
}

const mutations = {
  [types.SET_JOB_TYPES]: (state, jobTypes) => {
    state.types = jobTypes
  },
  [types.SET_CURRENT_JOB]: (state, data) => {
    state.currentJob = {
      ...state.currentJob,
      ...data,
    }
  },
  [types.SET_CURRENT_JOB_LOADING]: (state, value) => {
    state.jobLoading = value
  },
  [types.SET_CURRENT_LINE_ITEM_LOADING]: (state, value) => {
    state.lineItemLoading = value
  },
  [types.SET_CURRENT_LINE_ITEM]: (state, data) => {
    state.currentLineItem = {
      ...state.currentLineItem,
      ...data,
    }
  },
  [types.SET_MASTER_JOB]: (state, data) => {
    state.masterJob = data
  }
}

const actions = {
  async getJobTypes({ commit }) {
    try {
      if (!app.$can('job_types_show')) {
        return
      }
      const { data } = await axios.get('restify/job-types?sort=index')
      const jobTypes = data.map(el => ({ ...el.attributes }))
      commit(types.SET_JOB_TYPES, jobTypes)
    } catch (err) {
      console.warn(err)
    }
  },
  async getJob({ commit }, id) {
    try {
      commit(types.SET_CURRENT_JOB_LOADING, true)
      const params = {
        related: `${entityPreviewFields.Customer},${entityPreviewFields.SalesPerson},category[id|code],addresses,contacts,district[id|code|state|description|sales_tax_percent],preNotice[id]`
      }

      const { data } = await axios.get(`/restify/jobs/${id}`, {
        params,
      })
      commit(types.SET_CURRENT_JOB, data)
    } finally {
      commit(types.SET_CURRENT_JOB_LOADING, false)
    }
  },
  async getLineItem({ commit }, id) {
    try {
      commit(types.SET_CURRENT_LINE_ITEM_LOADING, true)
      const params = {
        related: `budgets,${entityPreviewFields.Job},${entityPreviewFields.Material},${entityPreviewFields.Equipment},${entityPreviewFields.Vendor},${entityPreviewFields.WorkersCompRate},${entityPreviewFields.GenLiabilityRate}`
      }
      const { data } = await axios.get(`/restify/line-items/${id}`, {
        params,
      })
      commit(types.SET_CURRENT_LINE_ITEM, data)
    } finally {
      commit(types.SET_CURRENT_LINE_ITEM_LOADING, false)
    }
  },
  async patchJob({ state }, data = {}) {
    await axios.patch(`/restify/jobs/${data.id}`, data)
  },
  async patchLineItem({ state }, data = {}) {
    await axios.patch(`/restify/line-items/${data.id}`, data)
  },
  async loadMasterJob({ state, commit }) {
    try {
      if (state.masterJob?.id) {
        return
      }

      const masterJobId = getSetting($modules.JC, 'master_job_id')

      if (!masterJobId) {
        return
      }

      const { data } = await axios.get(`/restify/jobs/${masterJobId}`)
      commit(types.SET_MASTER_JOB, {
        id: data.id,
        ...data.attributes,
      })
    } catch (err) {
      console.warn(err)
    }
  },
}

const getters = {
  getTypeByAbbr: (state) => abbr => {
    return state.types.find(el => el.abbr === abbr) || {}
  },
  getJobTypeById: state => id => {
    return state.types.find(t => t.id === id) || {}
  },
  getJobTypeNameById: (state, getters) => id => {
    const type = getters.getJobTypeById(id)
    return type?.name || ''
  },
  getJobTypesByType: (state) => type => {
    return state.types.filter(el => el.type === type)
  },
  getBillingTypes: state => {
    return state.types.filter(type => billingTypeAbbreviations.includes(type.abbr))
  },
  getBillingTypeIds: state => {
    return state.types.filter(type => billingTypeAbbreviations.includes(type.abbr)).map(type => type.id)
  },
}

export default {
  namespaced: true,
  types,
  state,
  mutations,
  actions,
  getters,
}
