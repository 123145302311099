<template>
  <div>
    <base-go-to-edit-alert
        v-if="!!existingBillingId"
        :path="`/accounts-receivable/billings/progress/${existingBillingId}/edit`"
        message="Billing exists in open payable file. Please select another number or job, or edit."
    />
    <base-form
        :loading="loading"
        :show-back="showBack"
        :show-cancel="showCancel"
        :save-text="$t('Create cost plus billing')"
        :update-text="$t('Update cost plus billing')"
        :submit-disabled="!!existingBillingId || gridContext.loading"
        :can-create-another-entity="!model.id"
        :sticky-header="expandedSections.length === 0"
        layout="vertical"
        submit-button-type="button"
        grid-classes="grid grid-cols-8 gap-x-3"
        ref="baseForm"
        @submit="onSubmit"
        @cancel="$router.push('/accounts-receivable/billings/cost-plus')"
    >
      <template #header>
        <el-collapse v-model="expandedSections"
                     class="col-span-8">
          <base-collapse-section
            key="form-header"
            name="form-header"
            class="-m-6"
            has-background
          >
            <template slot="title">
              <div class="flex w-full items-center justify-between mx-2">
                <BillingFormHeader :data="model"/>
              </div>
            </template>
            <div class="grid grid-cols-8 gap-x-4 mx-2">
              <job-select
                  v-model="model.job_id"
                  :on-map-entry="onSelectJob"
                  :disabled="!!$route.params.id"
                  :add-entity="false"
                  :edit-entity="!model.id"
                  :initial-value="data.job"
                  class="col-span-8 md:col-span-2"
                  id="job_id"
                  rules="required"
              />
              <customer-select
                  v-model="model.customer_id"
                  :add-entity="false"
                  :initial-value="data.customer"
                  :disabled="!!$route.params.id || !!customerInputDisabled"
                  class="col-span-8 md:col-span-2"
                  rules="required"
              />

              <InvoiceNumberInput
                v-model="model.number"
                :model="model"
                class="col-span-8 md:col-span-1"
              />

              <div class="col-span-8 md:col-span-1">
                <base-switch :value="model.status === resourceStatuses.NoPost"
                             :label-info="$t('No Post')"
                             :disable="noPostToggleDisabled"
                             @input="triggerNoPost"
                />
              </div>
              <div class="col-span-6"></div>
              <base-textarea
                v-model="model.description"
                :label="$t('Description')"
                :placeholder="$t('Description')"
                :rows="2"
                id="description"
                rules="max:500"
                class="col-span-6 md:col-span-4"
              />
              <BillingDates :model="model"/>
              <BillingTax
                  :model="model"
                  :data="data"
                  :show-retention="true"
                  @sales-tax-change="shouldEntriesChange"
                  @retention-change="shouldEntriesChange"
              />
              <div class="col-span-8 flex items-center mb-2">
                <h5 class="form-section-title">
                  {{ $t('Markups') }}
                </h5>
              </div>
              <CostPlusBillingMarkupSettings
                :data="model.meta.cost_types"
                class="col-span-8 lg:col-span-6"
                @change="shouldEntriesChange"
              />
              <div class="col-span-8 h-8"/>
              <base-date-picker
                  v-model="model.from_work_date"
                  :label="$t('From Work Date')"
                  :placeholder="$t('From Work Date')"
                  :name="$t('From Work Date')"
                  class="col-span-8 md:col-span-2"
                  id="from_work_date"
              />
              <base-date-picker
                  v-model="model.to_work_date"
                  :label="$t('To Work Date')"
                  :placeholder="$t('To Work Date')"
                  :name="$t('From Work Date')"
                  class="col-span-8 md:col-span-2"
                  id="to_work_date"
              />
            </div>
          </base-collapse-section>
        </el-collapse>
      </template>
      <div class="col-span-8">
        <CostPlusBillingEntries
            :key="model.job_id"
            :billing="model"
            ref="gridTable"
            @on-collapse-form-header="collapseFormHeader"
        />
      </div>
      <FileAttachments
          ref="attachments"
          class="col-span-8 mt-4"
          entity="billings"
          :entity-id="entityId"
      />
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import { resourceStatuses } from '@/enum/enums'
  import BillingFormHeader from '@/modules/accounts-receivable/components/BillingFormHeader'
  import { billingTypeAbbr, getNextBillingNumber } from '@/modules/accounts-receivable/pages/billings/billings'
  import CostPlusBillingEntries
    from '@/modules/accounts-receivable/components/cost-plus-billings/CostPlusBillingEntries'
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
  import { RestifyResources } from "@/components/form/util";
  import BillingDates from "@/modules/accounts-receivable/components/billings/BillingDates.vue";
  import BillingTax from "@/modules/accounts-receivable/components/billings/BillingTax.vue";
  import { shouldEntriesChange } from "@/modules/accounts-receivable/utils/billingUtils";
  import CostPlusBillingMarkupSettings
    from "@/modules/accounts-receivable/components/cost-plus-billings/CostPlusBillingMarkupSettings.vue";
  import orderBy from "lodash/orderBy";
  import InvoiceNumberInput from "@/modules/accounts-payable/components/invoice/InvoiceNumberInput.vue";
  import { gridContext } from "@/components/ag-grid/gridContext";

  export default {
    components: {
      InvoiceNumberInput,
      CostPlusBillingMarkupSettings,
      BillingTax,
      BillingDates,
      BillingFormHeader,
      CostPlusBillingEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        resourceStatuses,
        expandedSections: [],
        loading: false,
        showBack: true,
        showCancel: true,
        existingBillingId: false,
        entityId: null,
        model: {
          job_id: undefined,
          customer_id: undefined,
          number: '',
          gross_amount: 0,
          discount_percent: 0,
          retention_percent: 0,
          status: this.$settings(this.$modules.AR, 'default_cpb_status') || resourceStatuses.Pending,
          sales_tax_percent: 0,
          date: this.$now,
          due_date: null,
          discount_date: null,
          description: '',
          type: billingTypeAbbr.CostPlus,
          from_work_date: '',
          to_work_date: '',
          meta: {
            sales_tax_percent: 0,
            cost_types: [],
            district_id: '',
            exempt_from_sales_tax: false,
          },
        },
        gridContext,
      }
    },
    computed: {
      customerInputDisabled() {
        return this.model.id || (this.model.job_id && this.model.customer_id)
      },
      hasMarkups() {
        return this.get(this.model, 'meta.cost_types.length', 0)
      },
      noPostToggleDisabled() {
        return ![resourceStatuses.Pending, resourceStatuses.NoPost].includes(this.model.status)
      },
    },
    methods: {
      async onSubmit() {
        try {
          const isInvalidData = await validateAgDataTable()
          if (isInvalidData) {
            return
          }

          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/billings/${this.model.id}`, this.model)
            await this.$refs.gridTable.storeProgress(this.model.id)
            this.$success(this.$t('Job Billing updated'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Billings,
              resourceId: this.model.id,
              isEdit: true
            })
            this.$emit('refresh')
          } else {
            const { data } = await axios.post('/restify/billings/', this.model)
            await this.$refs.gridTable.storeProgress(data.id)
            this.$success(this.$t('Job Billing added'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Billings,
              resourceId: data.id,
            })
            this.entityId = data.id
            await this.$refs.attachments.triggerUpload()
            if (this.$createAnotherEntity) {
              return this.$emit('create-another')
            }
            await this.$router.push('/accounts-receivable/billings/cost-plus/pending')
          }
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      async onSelectJob(job) {
        const { customer_id } = job?.attributes
        if (!job?.attributes) {
          return
        }

        this.model.customer_id = customer_id

        this.model.number = getNextBillingNumber(billingTypeAbbr.CostPlus, job.attributes)
        return this.setDefaultMarkups()
      },
      setDefaultMarkups() {
        const jobTypes = this.$store.state.globalLists[this.$globalResources.JobCostTypes]
        this.mapMarkups(jobTypes)
      },
      getMarkupByTypeId(id) {
        const jobTypes = this.$store.state.globalLists[this.$globalResources.JobCostTypes]
        return jobTypes.find(type => type.id === id)
      },
      getMarkupByAbbr(abbr) {
        const jobTypes = this.$store.state.globalLists[this.$globalResources.JobCostTypes]
        return jobTypes.find(type => type.abbr === abbr)
      },
      mapMarkups(jobTypes = []) {

        this.model.meta.cost_types = jobTypes.map(type => {
          return {
            id: type.id,
            type_id: type.id,
            type_type: 'job-type',
            type_index: type.index,
            type_abbr: type.abbr,
            type_name: type.name,
            markup_percent: type.markup_percent,
            profit_percent: type.profit_percent,
            subject_to_tax: type.subject_to_tax,
            subject_to_retention: type.subject_to_retention,
            markup_subject_to_tax: type.markup_subject_to_tax,
            profit_subject_to_tax: type.profit_subject_to_tax,
          }
        })
      },
      async getCategoryJobTypes(categoryId) {
        try {
          const { data } = await axios.get(`/restify/job-categories/${categoryId}?related=jobTypes`)
          let { jobTypes } = data.relationships

          jobTypes = jobTypes.filter(type => type.attributes.type === 'cost').map(el => el.attributes)

          if (!jobTypes?.length) {
            this.setDefaultMarkups()
          } else {
            this.mapMarkups(jobTypes)
          }
        } catch (e) {
          console.warn(e)
        }
      },
      triggerNoPost(value) {
        value ? this.model.status = resourceStatuses.NoPost : this.model.status = resourceStatuses.Pending
      },
      async collapseFormHeader() {
        if (!this.expandedSections.length) {
          return
        }

        const isValidForm = await this.$refs.baseForm?.validate()

        if (!isValidForm) {
          return
        }

        this.expandedSections = []
      },
      composeMarkupModels() {
        let markups = this.get(this.model, 'cost_types', [])
        markups = markups.map(markup => {
          const typeId = markup.type_id || markup.id
          const abbr = markup.type_abbr || markup.abbr
          const markupObj = this.getMarkupByTypeId(typeId) || this.getMarkupByAbbr(abbr)
          const id = markup.id || markupObj?.id
          const subject_to_tax = markup.subject_to_tax || markupObj?.subject_to_tax
          const subject_to_retention = markup.subject_to_retention || markupObj?.subject_to_retention
          const profit_subject_to_tax = markup.profit_subject_to_tax || markupObj?.profit_subject_to_tax
          const markup_subject_to_tax = markup.markup_subject_to_tax || markupObj?.markup_subject_to_tax
          const markup_percent = markup.markup_percent || markupObj?.markup_percent
          const profit_percent = markup.profit_percent || markupObj?.profit_percent
          markup.subject_to_tax = subject_to_tax
          markup.subject_to_retention = subject_to_retention
          markup.profit_subject_to_tax = profit_subject_to_tax
          markup.markup_subject_to_tax = markup_subject_to_tax
          markup.markup_percent = markup_percent
          markup.profit_percent = profit_percent
          markup.type_abbr = markup.abbr
          markup.type_name = markup.name || markupObj?.name
          markup.type_id = id
          markup.index = markupObj.index
          markup.id = id
          return markup
        })
        markups = orderBy(markups, ['index'], ['asc'])

        this.model.meta.cost_types = markups
      },
      async shouldEntriesChange() {
        await shouldEntriesChange(this.$refs.gridTable)
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return this.expandedSections = ['form-header']
          }

          this.model = {
            ...this.model,
            ...value,
            ...value.meta,
          }

          this.composeMarkupModels()

          this.collapseFormHeader()
        },
      },
    },
  }
</script>
