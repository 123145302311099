<template>
  <div>
    <portal
        v-if="expandedSections.length === 0 && model.number"
        to="page-title"
    >
      {{ $t('Billing #') }}{{ model.number }},&nbsp;@
      <router-link :to="`/accounts-receivable/customers/${selectedCustomer.id}/view`">
        {{ selectedCustomer?.attributes?.code }}
        <span v-if="selectedCustomer?.attributes?.name">({{ selectedCustomer.attributes.name }})</span>
      </router-link>
    </portal>
    <BaseAlert
      v-if="existingBillingId"
      type="danger"
    >
      {{ $t('Invoice # is already taken.')  }}
      <span
        class="underline cursor-pointer"
        @click="getInvoiceNumber"
      >
        {{ $t('Click here get the next available #') }}
      </span>
    </BaseAlert>
    <base-form
        :loading="loading"
        :show-back="true"
        :show-cancel="true"
        :save-text="$t('Create lump sum billing')"
        :update-text="$t('Update lump sum billing')"
        :submit-disabled="!!existingBillingId || gridContext.loading"
        :can-create-another-entity="!model.id"
        :sticky-header="expandedSections.length === 0"
        submit-button-type="button"
        layout="vertical"
        grid-classes="grid grid-cols-1"
        ref="baseForm"
        @cancel="onCancel"
        @submit="onSubmit"
    >
      <template #header>
        <el-collapse
            v-model="expandedSections"
            class="col-span-1"
        >
          <base-collapse-section
              name="form-header"
              class="-m-6 mb-6"
              has-background
          >
            <template #title>
              <div class="flex flex-1 items-center justify-between form-header-summary">
                {{ $t('Lump Sum Billing') }}
                <portal-target
                    name="lmp-summary"
                />
              </div>
            </template>
            <div class="grid grid-cols-6 gap-x-4 py-5 px-6">
              <div class="col-span-4 grid grid-cols-6 gap-x-4">
                <customer-select
                    v-model="model.customer_id"
                    :add-entity="true"
                    :initial-value="selectedCustomer"
                    :disabled="!!$route.params.id"
                    class="col-span-6 md:col-span-2"
                    rules="required"
                    @entity-change="onChangeCustomer"
                />

                <InvoiceNumberInput
                  v-model="model.number"
                  :model="model"
                  :key="loadingNumber"
                  :loading="loadingNumber"
                  :default-locked="true"
                  class="col-span-6 md:col-span-1"
                  @change="validateUniqueInvoiceNumber"
                />

                <base-select
                    v-model="model.status"
                    :label="$t('Status')"
                    :placeholder="$t('Invoice Status')"
                    :options="statusOptions"
                    id="status"
                    class="col-span-6 md:col-span-1"
                />
                <base-input
                  v-model="model.meta.architect"
                  :label="$t('Architect Project Number')"
                  :placeholder="$t('Architect Project Number')"
                  :name="$t('Architect Project Number')"
                  id="architect"
                  rules="max:12"
                  class="col-span-8 md:col-span-2"
                />
                <div class="col-span-6 md:col-span-1"></div>
                <base-textarea
                    v-model="model.description"
                    :label="$t('Description')"
                    :placeholder="$t('Description')"
                    :rows="2"
                    id="description"
                    rules="max:500"
                    class="col-span-6 md:col-span-4"
                />
                <base-input
                    v-model="model.gross_amount"
                    :label="$t('Gross Billing')"
                    :placeholder="$t('Gross Billing')"
                    :min="-9999999999.99"
                    :step="1"
                    :max="9999999999.99"
                    id="gross_amount"
                    type="number"
                    format="price"
                    rules="required|grossAmount"
                    class="col-span-6 md:col-span-2"
                    @change="onChangeGrossBilling"
                />

                <BillingDates
                  :model="model"
                  class="col-span-6"
                  @date-change="validateUniqueInvoiceNumber"
                />

                <BillingTax
                  :model="model"
                  :data="data"
                  input-class="col-span-8 md:col-span-2"
                />
              </div>
              <div class="col-span-2">
                <customer-preview
                    :key="customerPreviewRenderKey"
                    :entity="selectedCustomer.attributes"
                />
              </div>
            </div>
          </base-collapse-section>
        </el-collapse>
      </template>
      <lump-sum-billing-entries-v2
          ref="entriesTable"
          :billing="model"
          :customer="selectedCustomer.attributes"
          :computed-gross-billing="computedGrossBilling"
          :gross-amount.sync="model.gross_amount"
          @on-collapse-form-header="collapseFormHeader"
      />
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import { resourceStatuses } from '@/enum/enums'
  import { billingTypeAbbr } from '@/modules/accounts-receivable/pages/billings/billings'
  import CustomerPreview from '@/modules/common/components/entity-preview/CustomerPreview'
  import LumpSumBillingEntriesV2
    from '@/modules/accounts-receivable/components/lump-sum-billings/LumpSumBillingEntriesV2'
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
  import { RestifyResources } from "@/components/form/util";
  import BillingDates from "@/modules/accounts-receivable/components/billings/BillingDates.vue";
  import BillingTax from "@/modules/accounts-receivable/components/billings/BillingTax.vue";
  import InvoiceNumberInput from "@/modules/accounts-payable/components/invoice/InvoiceNumberInput.vue";
  import { useInvoiceSequencing } from '@/modules/accounts-receivable/composables/useInvoiceSequencing'
import BaseAlert from '@/components/common/BaseAlert.vue'
  import { gridContext } from "@/components/ag-grid/gridContext";

  export default {
    components: {
      InvoiceNumberInput,
      BillingTax,
      BillingDates,
      CustomerPreview,
      LumpSumBillingEntriesV2,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    setup() {
      const {
        getNextInvoiceNumber,
        billingTypesAbbr,
        loadingNumber,
      } = useInvoiceSequencing()

      return {
        getNextInvoiceNumber,
        billingTypesAbbr,
        loadingNumber,
      }
    },
    data() {
      return {
        computedGrossBilling: true,
        existingBillingId: false,
        expandedSections: [],
        loading: false,
        customerPreviewRenderKey: 1,
        selectedCustomer: {},
        model: {
          customer_id: '',
          number: '',
          description: '',
          date: this.$now,
          due_date: null,
          discount_date: null,
          gross_amount: 0,
          discount_percent: 0,
          type: billingTypeAbbr.LumpSum,
          status: this.$settings(this.$modules.AR, 'default_lmp_status') || resourceStatuses.Pending,
          bank_id: '',
          payment_number: '',
          payment_date: '',
          retention_percent: 0,
          sales_tax_amount: 0,
          open_amount: 0,
          meta: {
            architect: '',
            district_id: null,
            exempt_from_sales_tax: false,
          }
        },
        gridContext,
        createdId: null,
        statusOptions: [
          {
            label: this.$t('Pending'),
            value: resourceStatuses.Pending,
          },
          {
            label: this.$t('No Post'),
            value: resourceStatuses.NoPost,
          },
        ],
      }
    },
    methods: {
      onChangeGrossBilling() {
        this.computedGrossBilling = false
      },
      async validateUniqueInvoiceNumber(toggleLoading = false) {
        this.existingBillingId = false
        if (this.model.id || !this.model.number) {
          return
        }

        try {
          toggleLoading && (this.loading = true)
          const { data } = await axios.get('restify/billings', {
            params: {
              number: this.model.number,
              type: billingTypeAbbr.LumpSum,
            },
          })

          this.existingBillingId = this.get(data, '[0].id', '')
        }
        catch (err) {
          console.warn(err)
          this.$error(this.$t('Could not validate invoice number'))
        }
        finally {
          toggleLoading && (this.loading = false)
        }
      },
      async onChangeCustomer(customer) {
        if (!customer) {
          customer = {
            attributes: {},
            id: '',
          }
        }
        this.selectedCustomer = customer
        await this.$router.replace({
          path: this.$route.path,
          query: {
            customer_id: customer?.id,
          },
        })
        this.customerPreviewRenderKey++
      },
      async onSubmit() {
        try {
          const isInvalidData = await validateAgDataTable()
          if (isInvalidData) {
            return
          }

          await this.validateUniqueInvoiceNumber(true)

          if (this.existingBillingId) {
            return
          }

          this.loading = true

          const payload = {
            ...this.model,
          }

          if (this.model.id) {
            await axios.put(`/restify/billings/${this.model.id}`, payload)
            await this.$refs.entriesTable.storeProgress(this.model.id)

            this.$success(this.$t('Lump sum billing updated successfully'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Billings,
              resourceId: this.model.id,
              isEdit: true
            })
          } else {

            const { data } = await axios.post('/restify/billings', payload)
            this.createdId = data.id
            await this.$refs.entriesTable.storeProgress(data.id)

            this.$success(this.$t('Lump sum billing created successfully'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Billings,
              resourceId: data.id,
            })
            if (this.$createAnotherEntity) {
              return this.$emit('create-another')
            }
            this.$router.push('/accounts-receivable/billings/lump-sum/pending')
          }

        } catch (err) {
          console.warn(err)
          if (this.createdId) {
            await axios.delete(`/restify/billings/${this.createdId}`)
            this.createdId = null
          }
          if (err?.handled) {
            return
          }
          this.$error(this.$t('Could not store lump sum billing'))
        } finally {
          this.loading = false
        }
      },
      onCancel() {
        return this.$router.push('/accounts-receivable/billings/lump-sum')
      },
      async collapseFormHeader() {
        if (!this.expandedSections.length) {
          return
        }

        const isValidForm = await this.$refs.baseForm?.validate()

        if (!isValidForm) {
          return
        }

        this.expandedSections = []
      },
      expandFormHeader() {
        this.expandedSections = ['form-header']
      },
      async getInvoiceNumber() {
        if (this.model.id) {
          return
        }

        this.model.number = ''
        this.model.number = await this.getNextInvoiceNumber({
          type: this.billingTypesAbbr.LumpSum
        })

        this.validateUniqueInvoiceNumber()
      },
      async initCreateModel() {
        this.expandFormHeader()
        this.getInvoiceNumber()
      }
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            this.initCreateModel()
            return
          }

          this.computedGrossBilling = false
          this.collapseFormHeader()

          this.model = {
            ...this.model,
            ...value.attributes,
          }

          this.selectedCustomer = this.get(value, 'relationships.customer', {})
        },
      },
    },
  }
</script>
