<template>
  <div class="py-2 px-2 flex justify-between truncate">
    <div class="truncate">
      <div class="font-medium">
        <SourceLink
          v-if="entry?.attributes?.source_id"
          :data="entry"
          :data-as-source="true"
        />
        <span v-else>
          <template v-if="timeOff.length">
            {{ $t('Time Off') }}
          </template>
          <template v-else>
            {{ entry.attributes.cost_center }}
          </template>
        </span>
      </div>
      <div class="text-gray-700 leading-5">
        <AddlSourceLink
          v-if="entry?.attributes?.addl_source_id"
          :id="entry?.attributes?.addl_source_id"
          :data="entry"
          link-class="text-gray-700 font-normal no-underline"
        />
      </div>
      <div
        v-if="entry.attributes.notes"
        class="text-xs text-gray-500 mt-0.5 whitespace-pre truncate max-w-[60vw]"
        v-html="entry.attributes.notes"
      />
      <div v-if="materials.length" class="mt-2">
        <span class="font-medium text-sm">
          {{ $t('Materials') }}
        </span>
        <div v-for="material in materials" :key="material.id" class="flex space-x-1 text-gray-700 text-sm">
          <MaterialLink :id="material.id" link-class="text-sm !text-gray-700 font-normal"/>
          <span>{{ material.units }} {{ material.um }}</span>
        </div>
      </div>
      <div v-if="equipment.length" class="mt-2">
        <span class="font-medium text-gray-700 text-sm">
          {{ $t('Equipment') }}
        </span>
        <div class="flex space-x-2">
          <div v-for="equipment in equipment" :key="equipment.id" class="flex space-x-1 text-gray-700 text-sm">
            <EquipmentLink :id="equipment.id" link-class="text-sm !text-gray-700 font-normal"/>
            <span>({{ equipment.units }} {{ $t('hours') }})</span>
          </div>
        </div>
      </div>
      <div v-if="payCodes.length" class="mt-2">
        <span class="font-medium text-gray-700 text-sm">
          {{ $t('Pay Codes') }}
        </span>
        <div class="flex space-x-2">
          <div v-for="payCode in payCodes" :key="payCode.id" class="flex space-x-1 text-gray-700 text-sm">
            <PayCodeLink :id="payCode.id" link-class="text-sm !text-gray-700 font-normal"/>
            <span v-if="payCode.special_rate > 0">
              {{ $formatPrice(payCode.special_rate * (payCode.units || 1)) }}
            </span>
            <span v-else>{{ payCode.units }} </span>
          </div>
        </div>
      </div>
      <div v-if="timeOff.length" class="mt-2">
        <span class="font-medium text-gray-700 text-sm">
          {{ $t('Time Off') }}
        </span>
        <div class="flex space-x-2">
          <div
            v-for="source in timeOff"
            :key="source.id" class="flex space-x-1 text-gray-700 text-sm">
            <TimeOffPolicyLink :id="source.id" link-class="text-sm !text-gray-700 font-normal"/>
            <span class="font-semibold">{{ source.units }} {{ $t('hours') }}</span>
          </div>
        </div>
      </div>
      <div v-if="entry.attributes.craft_code_id" class="mt-2">
        <span class="font-medium text-gray-700 text-sm">
          {{ $t('Craft Code & Sub Trade') }}
        </span>
        <div class="flex flex-col">
          <CraftCodeLink :id="entry.attributes.craft_code_id" link-class="text-gray-700"/>
          <SubTradeLink
            v-if="entry.attributes.sub_trade_id"
            :id="entry.attributes.sub_trade_id"
          />
        </div>
      </div>
      <div v-if="entry.attributes.files?.length" class="flex space-x-1 my-2">
        <ZoomInImage
          v-for="file in entry.attributes.files"
          :key="file.id"
          :src="file.url"
          class="w-20 rounded shadow-sm overflow-hidden"
        />
      </div>
      <div
        class="text-xs text-gray-500 mt-0.5"
      >
        {{ $formatTime(entry.attributes.start_time) }}
      </div>
    </div>
    <div class="font-medium text-lg flex space-x-4 items-center mt-1">
      <span v-if="timeOff.length">
        {{ minutesToHours(totalTimeOff, true) }}
      </span>
      <span v-else>
        {{ minutesToHours(entry.attributes.duration, true) }}
      </span>
      <div v-if="$can('timesheets_approve') && editable">
        <BaseButton
          variant="white"
          size="sm"
          :disabled="!isEditable"
          @click="$emit('edit', entry)"
        >
          {{ $t('Edit') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>
<script>
import AddlSourceLink from "@/components/links/AddlSourceLink.vue";
import { minutesToHours } from "../../utils/timeCardUtils";
import ZoomInImage from "./ZoomInImage.vue";
import { codeTypes } from "@/modules/payroll/components/rates/util";
import SourceLink from "@/components/links/SourceLink.vue";
import CraftCodeLink from "@/components/links/CraftCodeLink.vue";
import SubTradeLink from "@/components/links/SubTradeLink.vue";
import { isTimesheetEditable } from "@/modules/payroll/components/timesheets/utils";
import sumBy from "lodash/sumBy";

export default {
  components: {
    SubTradeLink,
    CraftCodeLink,
    SourceLink,
    ZoomInImage,
    AddlSourceLink,
  },
  props: {
    entry: {
      type: Object,
      default: () => ({}),
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    specialSources() {
      return this.entry?.attributes?.special_sources || []
    },
    materials() {
      return this.specialSources.filter(s => s.type === codeTypes.MATERIAL)
    },
    equipment() {
      return this.specialSources.filter(s => s.type === codeTypes.EQUIPMENT)
    },
    payCodes() {
      return this.specialSources.filter(s => s.type === codeTypes.PAY)
    },
    timeOff() {
      return this.specialSources.filter(s => s.type === codeTypes.TIME_OFF)
    },
    totalTimeOff() {
      return sumBy(this.timeOff, 'units') * 60
    },
    timesheet() {
      return this.entry?.attributes?.timesheet
    },
    isEditable() {
      return isTimesheetEditable(this.timesheet)
    }
  },
  methods: {
    minutesToHours,
  },
}
</script>
