<template>
  <base-form
    :loading="loading"
    :can-create-another-entity="!model.id"
    :save-text="$t('Create equipment')"
    :update-text="$t('Update equipment')"
    :layout="layout"
    :show-cancel="showCancel"
    grid-classes="grid grid-cols-8 gap-x-4"
    @submit="onSubmit"
    @cancel="onCancel"
  >
    <taggable-input
      v-model="model.code"
      :label="$t('Code')"
      :placeholder="$t('Code')"
      :default-state="model.id"
      :show-value="true"
      :unlock-text="$t('Unlock to modify')"
      :lock-tooltip-disabled="true"
      class="col-span-8 md:col-span-1"
      id="code"
      :rules="{
        required: true,
        code: {
          id: model.id,
          resourceName: globalResources.Equipments
        }
      }"
    />
    <address-type-select
      v-model="model.address_type"
      class="col-span-8 md:col-span-1"
      @change="model.address_id = undefined"
    >
    </address-type-select>
    <address-select
      v-model="model.address_id"
      :initial-value="get(data, 'relationships.address')"
      :address-type="model.address_type"
      class="col-span-8 md:col-span-2"
      rules="required"
    />
    <equipment-class-select
      v-model="model.class_id"
      class="col-span-8 md:col-span-2"
    />
    <base-select
      v-model="model.status"
      :label="$t('Status')"
      :placeholder="$t('Status')"
      :options="statusOptions"
      class="col-span-6 md:col-span-1"
      id="status"
    />
    <base-textarea
      v-model="model.description"
      :label="$t('Description')"
      :placeholder="$t('Description')"
      class="col-span-8 md:col-span-3"
      id="description"
      rules="max:150"
    />
    <entity-select
      v-model="model.specialty_id"
      :label="$t('Equipment Specialty Code')"
      class="col-span-8 md:col-span-1"
      url="/restify/equipment-specialties"
      label-key="code"
      value-key="id"
    />
    <base-input
      v-model="model.year_and_model"
      :label="$t('Year & Model')"
      :placeholder="$t('Year & Model')"
      class="col-span-8 md:col-span-2"
      id="year_and_model"
      rules="max:50"
    />
    <div class="col-span-6 md:col-span-2">
      <BaseSwitch
        v-model="model.available_in_timesheets"
        :label-info="$t(`Show in Timesheets`)"
      />
    </div>
    <div class="col-span-8"/>
    <base-input
      v-model="model.serial_no"
      :label="$t('Serial Number')"
      :placeholder="$t('Serial Number')"
      class="col-span-8 md:col-span-2"
      id="serial_no"
      rules="max:18"
    />
    <base-input
      v-model="model.license_no"
      :label="$t('Licence Number')"
      :placeholder="$t('Licence Number')"
      class="col-span-8 md:col-span-2"
      id="licence_no"
      rules="max:18"
    />
    <equipment-asset-type-select
      v-model="model.asset_type"
      class="col-span-8 md:col-span-1"
      id="asset_type"
      @change="onChangeAssetType"
    />
    <base-input
      v-model="model.life"
      :disabled="!hasDeclarationInfo"
      :name="$t('Life (years)')"
      :label="$t('Life (years)')"
      :min="0"
      :max="99"
      :step="1"
      class="col-span-8 md:col-span-1"
      id="life"
      type="number"
      :rules="lifeRules"
    />
    <el-collapse v-model="expandedSections"
                 class="col-span-8">
      <base-collapse-section
        :title="$t('Financial Information')"
        key="financial-information"
        name="financial-information"
      >
        <div class="grid grid-cols-8 gap-x-4">
          <base-input
            v-model="model.actual_disposal_proceeds_amount"
            :disabled="!hasDeclarationInfo"
            :name="$t('Estimated Salvage Value')"
            :label="$t('Estimated Salvage Value')"
            :min="0"
            :max="9999999999.999999"
            :step="0.000001"
            class="col-span-8 md:col-span-2"
            id="actual_disposal_proceeds_amount"
            type="number"
            format="price"
            rules="max_value:9999999999.999999|min_value:0"
          />
          <base-input
            v-model="model.standard_job_cost_hourly_rate"
            :name="$t('Standard Job Cost Hourly Rate')"
            :label="$t('Standard Job Cost Hourly Rate')"
            :min="0"
            :max="9999999999.999999"
            :step="0.000001"
            class="col-span-8 md:col-span-2"
            id="standard_job_cost_hourly_rate"
            type="number"
            format="price"
            rules="max_value:9999999999.999999|min_value:0"
          />
          <base-input
            v-model="model.standard_estimating_hourly_rate"
            :name="$t('Standard Estimating Hourly Rate')"
            :label="$t('Standard Estimating Hourly Rate')"
            :min="0"
            :max="9999999999.999999"
            :step="0.000001"
            class="col-span-8 md:col-span-2"
            id="standard_estimating_hourly_rate"
            type="number"
            format="price"
            rules="max_value:9999999999.999999|min_value:0"
          />
          <base-input
            v-model="model.current_market_value"
            :name="$t('Current Market Value')"
            :label="$t('Current Market Value')"
            :min="0"
            :max="99999999.99"
            :step="0.01"
            class="col-span-8 md:col-span-2"
            id="current_market_value"
            type="number"
            format="price"
            rules="max_value:99999999.99|min_value:0"
          />
          <base-select
            v-model="model.disposition"
            :label="$t('Disposition')"
            :tip="$t(`Required if Asset type is ${assetTypes.disposed}`)"
            :placeholder="$t('?')"
            :options="dispositionOptions"
            :disabled="model.asset_type !== assetTypes.disposed"
            :rules="model.asset_type === assetTypes.disposed ? 'required' : ''"
            class="col-span-8 md:col-span-1"
            clearable
            id="disposition"
          />
          <base-date-picker
            v-model="model.disposal_date"
            :label="$t('Date Disposed')"
            :placeholder="$t('Date Disposed')"
            :disabled="model.asset_type !== assetTypes.disposed"
            class="col-span-8 md:col-span-2"
            id="disposal_date"
          />
          <base-select
            v-model="model.depreciation_method"
            :disabled="!hasDeclarationInfo"
            :tip="$t(`Depreciation details is available when Asset Type is not ${assetTypes.leased} or ${assetTypes.rented}`)"
            :label="$t('Depreciation Method')"
            :placeholder="$t('Depreciation Method')"
            :options="depreciationMethodOptions"
            class="col-span-8 md:col-span-2"
            id="depreciation_method"
            @change="onChangeDepreciationMethod"
          />
          <base-date-picker
            v-model="model.date_acquired"
            :label="$t('Date Acquired')"
            :placeholder="$t('Date Acquired')"
            class="col-span-8 md:col-span-2"
            id="date_acquired"
          />
          <base-switch
            v-model="model.on_balance_sheet"
            label-info="Balance Sheet"
            class="col-span-8 md:col-span-1"
          />
        </div>
      </base-collapse-section>
      <base-collapse-section
        :title="$t('Maintenance Information & Billing Rates')"
        key="maintenance-information"
        name="maintenance-information"
      >
        <div class="grid grid-cols-8 gap-x-12">
          <div class="flex flex-col col-span-2">
            <job-select
              v-model="model.last_job_id"
              :label="$t('Last Job')"
              :placeholder="$t('Last Job/Location')"
              class="col-span-8 md:col-span-2"
              id="last_job_id"
            />
            <base-date-picker
              v-model="model.last_job_date"
              :label="$t('Date')"
              :placeholder="$t('Date')"
              class="col-span-8 md:col-span-2"
              id="last_job_date"
            />
            <base-date-picker
              v-model="model.last_depreciation_date"
              :disabled="!hasDeclarationInfo"
              :label="$t('Last Depreciation Date')"
              :placeholder="$t('Last Depreciation Date')"
              :rules="depreciationDateRules"
              class="col-span-8 md:col-span-2"
              id="last_depreciation_date"
            />
          </div>
          <BillingRatesList
            :entity="data"
            :onlyRegularType="true"
            :source-type="billingRateSources.Equipment"
            ref="billingRates"
            class="col-span-6"
          />

        </div>
      </base-collapse-section>
    </el-collapse>
  </base-form>
</template>
<script>
import axios from 'axios'
import { billingRateSources, resourceStatuses } from '@/enum/enums'
import BillingRatesList from '@/modules/common/components/BillingRatesList'
import { assetTypes, depreciationMethod, dispositionOptions } from '@/enum/equipment'
import { globalResources, RestifyResources } from "@/components/form/util";
import { equipmentStatusOptions } from "@/modules/equipment/util/equipmentUtils";

export default {
  components: {
    BillingRatesList,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    redirectToListAfterStore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      globalResources,
      RestifyResources,
      billingRateSources,
      loading: false,
      showCancel: true,
      showBack: true,
      expandedSections: ['financial-information'],
      model: {
        code: '',
        address_type: this.$addressTypes.Warehouse,
        address_id: '',
        description: '',
        status: resourceStatuses.Active,
        class_id: undefined,
        last_job_id: undefined,
        specialty_id: undefined,
        year_and_model: '',
        serial_no: '',
        license_no: '',
        life: 0,
        actual_disposal_proceeds_amount: 0,
        standard_job_cost_hourly_rate: 0,
        standard_estimating_hourly_rate: 0,
        current_market_value: 0,
        asset_type: assetTypes.owned,
        disposition: '',
        disposal_date: '',
        depreciation_method: depreciationMethod.none,
        date_acquired: null,
        last_job_date: null,
        last_depreciation_date: null,
        gallons_to_date: 0,
        acquisition_cost: 0,
        depreciation_amount: 0,
        repairs_and_maintenance_to_date: 0,
        fuel_used_to_date: 0,
        utilization_hours_job_costed: 0,
        utilization_hours_customer_billed: 0,
        utilization_amount_job_costed: 0,
        utilization_amount_customer_billed: 0,
        mileage: 0,
        maintenance_hours_to_date: 0,
        is_depreciation_posted: false,
        is_equipment_cost_transferred: false,
        maintenance_interval: '',
        on_balance_sheet: false,
        available_in_timesheets: true,
      },
      statusOptions: equipmentStatusOptions,
      assetTypes,
      dispositionOptions,
      depreciationMethodOptions: [
        {
          label: this.$t('None'),
          value: depreciationMethod.none,
        },
        {
          label: this.$t('Straight Line'),
          value: depreciationMethod.straightLine,
        },
        {
          label: this.$t('Balance'),
          value: depreciationMethod.balance,
        },
      ],
    }
  },
  computed: {
    layout() {
      return this.redirectToListAfterStore ? 'vertical' : 'modal'
    },
    hasDeclarationInfo() {
      return ![assetTypes.leased, assetTypes.rented].includes(this.model.asset_type)
    },
    lifeRules() {
      return this.model.depreciation_method === depreciationMethod.none ? 'max_value:99|min_value:0' : 'required|max_value:99|min_value:0'
    },
    depreciationDateRules() {
      return this.model.depreciation_method === depreciationMethod.none ? '' : 'required'
    },
  },
  methods: {
    onChangeAssetType() {
      this.model = {
        ...this.model,
        disposition: null,
        disposal_date: null,
        depreciation_method: depreciationMethod.none,
        life: 0,
        last_depreciation_date: null,
        actual_disposal_proceeds_amount: 0,
      }
    },
    onChangeDepreciationMethod(state) {
      this.model.on_balance_sheet = state !== depreciationMethod.none
    },
    onCancel() {
      if (this.redirectToListAfterStore) {
        return this.$router.push('/equipment/list')
      }
      this.$emit('cancel')
    },
    async onSubmit() {
      try {
        this.loading = true
        if (this.model.id) {
          await axios.put(`/restify/equipment/${ this.model.id }`, this.model)
          await this.runBillingRateOperations(this.model.id)
          this.$success(this.$t('Equipment updated'))
          this.$emit('refresh', this.model)
        } else {
          const { data } = await axios.post('/restify/equipment', this.model)
          await this.runBillingRateOperations(data.id)
          this.$success(this.$t('Equipment created'))
          this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          if (this.redirectToListAfterStore) {
            await this.$router.push(`/equipment/list/${ data.id }/view`)
          }
        }
      } catch (err) {
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        this.loading = false
      }
    },
    async runBillingRateOperations(source_id) {
      const { billingRates } = this.$refs
      if (!billingRates) {
        return
      }
      await billingRates.runEntitiesOperation(source_id, billingRateSources.Equipment)
    },
    tryAssignDefaultAddress() {
      this.model.address_id = this.$settings(this.$modules.IV, 'default_address_id') || ''
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          return this.tryAssignDefaultAddress()
        }
        this.model = {
          ...this.model,
          ...value.attributes,
        }
      },
    },
  },
}
</script>
