<template>
  <BaseForm
      :show-cancel="true"
      :loading="loading"
      :save-text="isEdit ? $t('Update') : $t('Create')"
      :focus-on-first-input="false"
      layout="vertical"
      @cancel="onCancel"
      @submit="onSubmit"
  >
    <base-input
        v-model="model.code"
        :label="$t('Code')"
        :placeholder="$t('Code')"
        id="code"
        class="col-span-6 md:col-span-4 lg:col-span-1"
        rules="required"
    />
    <base-select
        v-model="model.type"
        :label="$t('Type')"
        :placeholder="$t('Type')"
        :options="FinancialReportTypeOptions"
        id="type"
        class="col-span-6 md:col-span-4 lg:col-span-1"
        rules="required"
    />

    <base-input
        v-model="model.description"
        :label="$t('Description')"
        :placeholder="$t('Description')"
        id="description"
        class="col-span-6 md:col-span-4 lg:col-span-2"
        rules="required"
    />

    <base-switch
        v-model="model.print_date_time"
        :label-info="$t('Print Date and Time')"
        id="print_date_time"
        class="col-span-6 md:col-span-4 lg:col-span-1"
    />

    <base-switch
        v-model="model.skip_rows_with_zero"
        :label-info="$t('Skip Rows with Zero')"
        id="skip_rows_with_zero"
        class="col-span-6 md:col-span-4 lg:col-span-1"
    />

    <base-switch
      v-model="model.show_percent_of_basis"
      :label-info="$t('Show Percent of Basis')"
      id="show_percent_of_basis"
      class="col-span-6 md:col-span-4 lg:col-span-1"
    />

    <template v-if="model.show_percent_of_basis">
      <AccountMultipleRangeTable
        ref="accountsTable"
        :value="model.accounts"
        :title="$t('Accounts to use as basis for percent calculation')"
        class="col-span-6"
      />
    </template>

    <div class="col-span-6">
      <AgDataTable
          v-bind="editableTableProps"
          :default-filters="false"
          :data="reportCols"
          :columns="columnColumns"
          :add-text="$t('Add Column')"
          :get-empty-row="getEmptyCol"
          actions="add"
          class="mb-4"
          @grid-ready="colsGrid = $event"
      >
        <template #header-info>
          <h4>{{ $t('Columns') }}</h4>
        </template>
      </AgDataTable>
    </div>

    <div class="col-span-6">
      <AgDataTable
          v-bind="columnsGridOptions"
          :data="reportRows"
          :columns="rowColumns"
          :add-text="$t('Add Row')"
          :get-empty-row="getEmptyRow"
          :allow-table-bulk-delete="true"
          :default-filters="false"
          actions="add"
          domLayout="autoHeight"
          class="mb-4"
          @grid-ready="rowsGrid = $event"
      >
        <template #header-info>
          <h4>{{ $t('Rows') }}</h4>
        </template>
        <template #description="{row}">
          <div v-if="row.description" v-html="row.description"/>
        </template>
        <template #accounts="{row}">
          <AccountRangeTags :account-range="row.accounts"/>
        </template>
      </AgDataTable>
    </div>
  </BaseForm>
</template>
<script lang="ts" setup>
  import axios from 'axios'
  import { FinancialReport } from "@/modules/common/types/models";
  import { computed, PropType, ref, watch } from "vue";
  import cloneDeep from 'lodash/cloneDeep'
  import {useRouter} from 'vue2-helpers/vue-router';
  import {
    FinancialReportCol,
    FinancialReportMethodOptions,
    FinancialReportRow,
    FinancialReportStyle,
    FinancialReportStyleOptions,
    FinancialReportType,
    FinancialReportTypeOptions,
    getFilterMethodLabel,
    LevelOptions
  } from "@/modules/ledger/enum/financialReports";
  import { Column } from "@/components/ag-grid/tableTypes";
  import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
  import i18n from "@/i18n";
  import { error, success } from '@/components/common/NotificationPlugin'
  import { editableTableProps, getTableData } from "@/components/ag-grid/tableUtils";
  import AccountRangeTags from "@/modules/ledger/components/financial-reports/AccountRangeTags.vue";
  import { getDeleteColumn } from "@/components/ag-grid/columns/deleteColumns";
  import { booleanValueSetter, cellClasses, requiredValueSetter } from "@/components/ag-grid/columnUtils";
  import { stopEditingOnTab, yesNoFormatter } from "@/components/ag-grid/columns/editableColumns";
  import { GridReadyEvent, ProcessCellForExportParams, ValueFormatterParams } from '@ag-grid-community/core'
  import store from '@/store'
  import Data = API.Data;
  import AccountMultipleRangeTable from "@/components/ag-grid/cellEditors/AccountMultipleRangeTable.vue";

  const props = defineProps({
    financialReport: {
      type: Object as PropType<Data<FinancialReport>>,
    },
  })

  const model = ref({
    id: null as any,
    code: '',
    type: FinancialReportType.BalanceSheet as FinancialReportType,
    description: '' as string | null,
    print_date_time: true as boolean,
    skip_rows_with_zero: true as boolean,
    show_percent_of_basis: false as boolean,
    accounts: [] as any[],
    cols: [] as FinancialReportCol[],
    rows: [] as FinancialReportRow[],
  })

  const rowsGrid = ref<GridReadyEvent>()
  const colsGrid = ref<GridReadyEvent>()

  const siblingData = computed(() => {
    return store.getters['sibling/getData']
  })
  watch(() => props.financialReport, (value) => {
    if (!value?.id) {
      return
    }
    const report: FinancialReport = {
      ...value.attributes,
      id: value.id as string,
    }
    initModel(report)
  }, { immediate: true })
  watch(() => siblingData.value, (value) => {
    initModel(value, true)
  }, { immediate: true })

  function initModel(value: FinancialReport, isCopy = false) {
    if (!value?.id) {
      return
    }
    let code = value.code
    if (isCopy) {
      code = `${value.code} COPY`
    }
    model.value = {
      id: value.id,
      code,
      type: value.type,
      description: value.description,
      print_date_time: value.print_date_time,
      skip_rows_with_zero: value.skip_rows_with_zero,
      show_percent_of_basis: value.accounts?.length > 0,
      accounts: value.accounts || [],
      cols: value.cols as FinancialReportCol[],
      rows: value.rows as FinancialReportRow[],
    }
  }

  const reportCols = computed<FinancialReportCol[]>(() => {
    let cols = cloneDeep(model.value.cols as FinancialReportCol[]) || []
    cols = cols.map(c => {
      c._localId = crypto.randomUUID()
      return c
    })
    return cols
  })

  const reportRows = computed(() => {
    let rows = cloneDeep(model.value.rows as FinancialReportRow[]) || []

    rows = rows.map(r => {
      r._localId = crypto.randomUUID()
      r.is_title = r.is_title !== undefined ? r.is_title : false
      r.description_html = r.description_html ?? r.description
      return r
    })
    return rows as FinancialReportRow[]
  })

  const columnsGridOptions = computed(() => {
    return {
      ...editableTableProps,
      allowAddEmptyRows: true,
      processCellForClipboard: (params: ProcessCellForExportParams) => {
        const isAccountsCol = params.column?.getColId() === 'accounts'
        if (isAccountsCol) {
          return JSON.stringify(params.value)
        }
        return params.value
      },
    }
  })
  const columnColumns = computed<Column<FinancialReportCol>[]>(() => {
    return [
      {
        headerName: i18n.t('Information to Print in Column'),
        field: 'method',
        minWidth: 160,
        maxWidth: 320,
        editable: true,
        cellEditor: cellEditors.BaseSelect,
        cellEditorParams: {
          options: FinancialReportMethodOptions,
        },
        cellClass: params => {
          return !params.value ? cellClasses.Invalid : ''
        },
        valueFormatter: (params) => {
          return getFilterMethodLabel(params.value)
        },
        valueSetter: (params) => {
          const isValid = requiredValueSetter(params)
          if (!isValid) {
            return
          }
          const methodLabel = getFilterMethodLabel(params.newValue)
          params.data.description = methodLabel
          params.data.name = methodLabel
          params.data.method = params.newValue

          params.node?.setData(params.data)

          return true
        }
      },
      {
        headerName: i18n.t('Column Heading'),
        field: 'name',
        minWidth: 160,
        editable: true,
      },
      {
        headerName: i18n.t('Reverse sign'),
        field: 'reverse_sign',
        minWidth: 80,
        maxWidth: 100,
        editable: true,
        cellEditor: cellEditors.Boolean,
        valueFormatter: yesNoFormatter,
      },
      {
        headerName: i18n.t('Hide zero'),
        field: 'zero_suppress',
        minWidth: 80,
        maxWidth: 100,
        editable: true,
        cellEditor: cellEditors.Boolean,
        valueFormatter: yesNoFormatter,
        suppressKeyboardEvent: stopEditingOnTab,
      },
      {
        ...getDeleteColumn({
          title: i18n.t(`Delete row`),
          description: i18n.t(`Are you sure you want to delete this row`),
        })
      }
    ]
  })

  function isPageAfterEnabled(params: any) {
    return !params.data?.is_title && !params.data?.accounts?.length
  }

  function rowYesNoFormatter(params: ValueFormatterParams) {
    if (params.data?.is_title) {
      return ''
    }
    return !!params.value ? i18n.t('Y') : i18n.t('N')
  }

  const rowColumns = computed<Column<FinancialReportRow>[]>(() => {
    return [
      {
        headerName: i18n.t('Level'),
        field: 'level',
        minWidth: 80,
        maxWidth: 100,
        editable: true,
        cellEditor: cellEditors.BaseSelect,
        cellEditorParams: {
          options: LevelOptions,
        }
      },
      {
        headerName: i18n.t('Title'),
        field: 'is_title',
        minWidth: 80,
        maxWidth: 100,
        editable: true,
        valueFormatter: yesNoFormatter,
        valueGetter: params => !!params.data?.is_title,
        valueSetter: booleanValueSetter,
        cellEditor: cellEditors.Boolean,
      },
      {
        headerName: i18n.t('Description'),
        field: 'description',
        minWidth: 240,
        maxWidth: 400,
        editable: true,
        cellEditor: cellEditors.Html,
        cellEditorPopup: true,
        wrapText: true,
        autoHeight: true,
        valueSetter: params => {
          params.data.description_html = params.newValue
          params.data.description = getTextDescription(params.newValue)
          return true
        }
      },
      {
        headerName: i18n.t('Row Style'),
        field: 'style',
        minWidth: 100,
        maxWidth: 160,
        editable: true,
        cellEditor: cellEditors.BaseSelect,
        cellEditorParams: {
          options: FinancialReportStyleOptions,
          stopNavigationOnChange: true,
        },
        valueFormatter: params => {
          if (params.value === FinancialReportStyle.None) {
            return ''
          }
          return FinancialReportStyleOptions.find(o => o.value === params.value)?.label || ''
        },
        suppressKeyboardEvent: params => {
          if (params.data?.is_title) {
            return stopEditingOnTab(params)
          }
        },
      },
      {
        headerName: i18n.t('Reverse Sign'),
        field: 'reverse_sign',
        minWidth: 80,
        maxWidth: 100,
        editable: params => !params.data?.is_title,
        valueGetter: params => !!params.data?.reverse_sign,
        valueFormatter: rowYesNoFormatter,
        valueSetter: booleanValueSetter,
        cellEditor: cellEditors.Boolean,
        cellClass: params => {
          return params.data?.is_title ? cellClasses.ReadOnlyLight : ''
        },
      },
      {
        headerName: i18n.t('Print %'),
        field: 'print_percent',
        minWidth: 80,
        maxWidth: 100,
        editable: params => !params.data?.is_title,
        valueGetter: params => !!params.data?.print_percent,
        valueFormatter: rowYesNoFormatter,
        valueSetter: booleanValueSetter,
        cellEditor: cellEditors.Boolean,
        cellClass: params => {
          return params.data?.is_title ? cellClasses.ReadOnlyLight : ''
        },
        hide: !model.value.show_percent_of_basis
      },
      {
        headerName: i18n.t('Page After'),
        field: 'page_after',
        minWidth: 80,
        maxWidth: 100,
        editable: isPageAfterEnabled,
        cellEditor: cellEditors.Boolean,
        valueFormatter: params => {
          if (!isPageAfterEnabled(params)) {
            return ''
          }
          return rowYesNoFormatter(params)
        },
        valueSetter: booleanValueSetter,
        cellClass: params => {
          return !isPageAfterEnabled(params) ? cellClasses.ReadOnlyLight : ''
        },
      },
      {
        headerName: i18n.t('Accounts'),
        field: 'accounts',
        wrapText: true,
        autoHeight: true,
        editable: params => {
          return !params.data?.is_title
        },
        cellEditor: cellEditors.AccountMultipleRangeSelect,
        cellClass: params => {
          return params.data?.is_title ? cellClasses.ReadOnlyLight : ''
        },
        suppressNavigable: params => {
          return params.data?.description
        },
        valueSetter: params => {
          const value = params.newValue
          if (!value) {
            params.data.accounts = []
            return true
          }
          const isSerialized = typeof value === 'string' && value.includes('[')
          if (isSerialized) {
            try {
              params.data.accounts = JSON.parse(value)
            } catch (err) {
              console.warn('Could not parse accounts value when using valueSetter')
              params.data.accounts = []
            }
            return true
          }
          params.data.accounts = params.newValue
          return true
        },
        suppressKeyboardEvent: stopEditingOnTab,
      },
      {
        ...getDeleteColumn({
          title: i18n.t(`Delete row`),
          description: i18n.t(`Are you sure you want to delete this row`),
        })
      }
    ]
  })

  function getEmptyCol(): FinancialReportCol {
    return {
      _localId: crypto.randomUUID(),
      name: '',
      description: '',
      method: null,
      reverse_sign: false,
      zero_suppress: true,
    }
  }

  function getTextDescription(htmlDescription: string) {
    const div = document.createElement('div')
    div.innerHTML = htmlDescription
    return div.textContent || ''
  }

  function getEmptyRow(): FinancialReportRow {
    const rows = getRows()
    return {
      _localId: crypto.randomUUID(),
      level: 0,
      order: rows.length,
      description: '',
      description_html: '',
      print_percent: false,
      page_after: false,
      reverse_sign: false,
      accounts: [],
      style: FinancialReportStyle.None,
    }
  }

  const router = useRouter()
  const accountsTable = ref()

  async function onCancel() {
    router.push('/ledger/settings/financial-reports');
  }

  const isEdit = computed(() => {
    return props.financialReport?.id
  })

  const loading = ref(false)

  function hasUnsavedData() {
    const rows = getTableData(rowsGrid.value?.api)
    const cols = getTableData(colsGrid.value?.api)
    return rows.some(row => row.dirty) || cols.some(col => col.dirty)
  }


  function getRows() {
    const data = cloneDeep(getTableData(rowsGrid.value?.api))
    return data.map(row => {
      row.description = getTextDescription(row.description)
      delete row._localId
      delete row.dirty
      return row
    })
  }

  function getCols() {
    const data = getTableData(colsGrid.value?.api)
    return data.map((col: any) => {
      delete col.dirty
      return col
    })
  }

  async function onSubmit() {
    try {
      const id = props.financialReport?.id
      loading.value = true

      const requestData = cloneDeep(model.value)

      requestData.cols = getCols()
      requestData.rows = getRows()
      if (accountsTable.value?.convertTableData) {
        requestData.accounts = accountsTable.value.convertTableData()
      }

      if (isEdit.value) {
        await axios.put(`/restify/financial-reports/${id}`, requestData)
        await store.dispatch('generalLedger/getFinancialReport', id)
      } else {
        const { data } = await axios.post(`/restify/financial-reports`, requestData)
        await store.dispatch('generalLedger/getFinancialReport', data.id)
        await router.push(`/ledger/settings/financial-reports/${data.id}/edit`)
      }

      success(i18n.t('Financial report saved successfully'))
    } catch (err: any) {
      if (err.handled) {
        return
      }
      console.log(err)
      error(i18n.t('Could not save the financial report'))
    } finally {
      loading.value = false
    }
  }

  defineExpose({
    hasUnsavedData
  })
</script>
