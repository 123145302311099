import { addressableMorph, costTypes, jobLineItemTypes, resourceStatuses } from "@/enum/enums";
import { globalResourceEntityMap, globalResources, moduleAccountTypes, storeMutations, customRefreshActionsMap } from "@/components/form/util";
import { payrollActions, payrollGetters, payrollState } from "@/modules/common/store/lists/payrollListsModule";
import {
  jobCostingActions,
  jobCostingGetters,
  jobCostingState
} from "@/modules/common/store/lists/jobCostingListsModule";
import { equipmentActions, equipmentGetters, equipmentState } from "@/modules/common/store/lists/equipmentListsModule";
import { isUUID } from "@/utils/utils";
import sortBy from "lodash/sortBy";
import Cache from '@/utils/Cache';
import store from '@/store'
import { cacheTTL } from "./cacheConfig";
import { toRestifyArray } from "@/utils/arrayUtils";
import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import snakeCase from "lodash/snakeCase";

const searchableFields = [
  'abbr',
  'name',
  'number',
  'code',
  'description',
]

function getResourceModel({ data, resource, state }) {
  let mappedData = {}
  const resourceData = state[resource] || []
  const sampleResource = resourceData[0]

  // We extract an example object from the resource data to determine the keys of the object
  if (!sampleResource) {
    mappedData = data
  } else {
    for (let key in sampleResource) {
      mappedData[key] = data[key]
    }
  }
  return mappedData
}

let getLineItemsController = new AbortController();

function tryUpdateLineItems(state, resource) {
  if (resource !== globalResources.LineItems) {
    return
  }

  let costLineItems = []
  let incomeLineItems = []

  state[globalResources.LineItems].forEach(lineItem => {
    if (lineItem.type === jobLineItemTypes.Cost) {
      costLineItems.push(lineItem)
    } else {
      incomeLineItems.push(lineItem)
    }
  })

  state[globalResources.CostLineItems] = costLineItems
  state[globalResources.IncomeLineItems] = incomeLineItems
}

const state = {
  [globalResources.Materials]: [],
  [globalResources.WorkOrders]: [],
  [globalResources.ServiceBillingCodes]: [],
  [globalResources.ServiceBillingCostTypes]: [],
  [globalResources.ServiceBillingIncomeTypes]: [],
  [globalResources.TransactionsForCostPlus]: [],
  [globalResources.PurchaseOrders]: [],
  [globalResources.Employees]: [],
  [globalResources.Vendors]: [],
  [globalResources.SalesTaxDistricts]: [],
  [globalResources.WaiverReleaseTemplates]: [],
  [globalResources.Customers]: [],
  [globalResources.Users]: [],
  [globalResources.CompanyAddresses]: [],
  [globalResources.PrintTemplates]: [],
  ...jobCostingState,
  ...payrollState,
  ...equipmentState,
  refreshResourceLoading: false,
};

const mutations = {
  [storeMutations.SetResource]: (state, { data, resource }) => {
    state[resource] = data
  },
  [storeMutations.AddResource]: (state, { data, resource }) => {
    let mappedData = getResourceModel({ data, resource, state })
    if (!state[resource]) {
      return
    }
    state[resource].push(mappedData)
    tryUpdateLineItems(state, resource)
  },
  [storeMutations.UpdateResource]: (state, { data, resource }) => {
    if (!resource || !state[resource]) {
      return
    }
    let mappedData = getResourceModel({ data, resource, state })
    const index = state[resource].findIndex(item => item.id === data.id)
    if (index === -1 || !state[resource]) {
      return
    }
    state[resource].splice(index, 1, mappedData)
    tryUpdateLineItems(state, resource)
  },
  [storeMutations.RemoveResource]: (state, { id, resource }) => {
    if (!resource || !state[resource]) {
      return
    }
    const index = state[resource].findIndex(item => item.id === id)
    if (index === -1 || !state[resource]) {
      return
    }
    state[resource].splice(index, 1)
    tryUpdateLineItems(state, resource)
  },
  [storeMutations.RemoveResourceEntries]: (state, { ids, resource }) => {
    if (!resource || !state[resource]) {
      return
    }

    ids.forEach(id => {
      const index = state[resource].findIndex(item => item.id === id)
      if (index === -1 || !state[resource]) {
        return
      }
      state[resource].splice(index, 1)
    })

    tryUpdateLineItems(state, resource)
  },
  [storeMutations.SetRefreshResourceLoading]: (state, value) => {
    state.refreshResourceLoading = value
  }
};

const actions = {
  async getMaterials({ commit }) {
    const data = await Cache.getRequest('/restify/materials/list', {
      ttl: cacheTTL,
    }) || []
    commit(storeMutations.SetResource, { data, resource: globalResources.Materials })
  },
  async getCompanyAddresses({ commit }) {
    const companyId = store.getters['company/getCurrentCompanyId']

    const params = {
      addressable_type: addressableMorph.Company,
      addressable_id: companyId,
    }

    const data = await Cache.getRequest('/restify/addresses/list', {
      ttl: cacheTTL,
      params,
    }) || []

    commit(storeMutations.SetResource, { data, resource: globalResources.CompanyAddresses })
  },
  async getVendors({ commit }) {
    const data = await Cache.getRequest('/restify/vendors/list', {
      ttl: cacheTTL,
      params: {
        status: resourceStatuses.Active,
      }
    }) || []
    commit(storeMutations.SetResource, { data, resource: globalResources.Vendors })
  },
  async getGeneralConfiguration({ commit, dispatch }, invalidateCache = false) {
    const { data } = await Cache.getRequest('/configuration', {
      ttl: cacheTTL,
      invalidateCache,
    })
    commit('dashboard/SET_ALL_DASHBOARDS', toRestifyArray(data.dashboards), { root: true })
    dispatch('company/setFiscalYears', toRestifyArray(data.fiscal_years), { root: true })
    dispatch('company/setCompanySettings', toRestifyArray(data.company_settings), { root: true })
    commit('company/SET_ACCOUNTS', data.accounts, { root: true })
    commit('company/SET_SUB_ACCOUNTS', data.subaccounts, { root: true })
    commit('company/SET_BANKS', data.banks, { root: true })
    commit('settings/SET_ORGANIZATION_SETTINGS', toRestifyArray(data.organization_settings), { root: true })
    commit('auth/SET_USER_ORGANIZATION', data.organization, { root: true })
    commit(storeMutations.SetResource, { data: data.users, resource: globalResources.Users })
  },
  setCommonConfiguration({ commit, dispatch }, data) {
    dispatch('setJobTypes', data.job_types)
    dispatch('setServiceBillingTypes', data.service_billing_types)
    commit(storeMutations.SetResource, { data: data.equipment, resource: globalResources.Equipments })
    commit(storeMutations.SetResource, { data: data.equipment_types, resource: globalResources.EquipmentTypes })
    commit(storeMutations.SetResource, { data: data.jobs, resource: globalResources.Jobs })
    commit(storeMutations.SetResource, { data: data.maintenance_codes, resource: globalResources.EquipmentMaintenances })
    commit(storeMutations.SetResource, { data: data.master_line_items, resource: globalResources.MasterLineItems })
    commit(storeMutations.SetResource, { data: data.materials, resource: globalResources.Materials })
    commit(storeMutations.SetResource, { data: data.print_templates, resource: globalResources.PrintTemplates })
    commit(storeMutations.SetResource, { data: data.service_billing_codes, resource: globalResources.ServiceBillingCodes })
    commit(storeMutations.SetResource, { data: data.work_orders, resource: globalResources.WorkOrders })
  },
  setServiceBillingTypes({ commit, dispatch }, data) {
    const costTypes = data.filter(type => type.type === moduleAccountTypes.Cost)
    const incomeTypes = data.filter(type => type.type === moduleAccountTypes.Income)
    commit(storeMutations.SetResource, { data: data, resource: globalResources.ServiceBillingTypes })
    commit(storeMutations.SetResource, { data: costTypes, resource: globalResources.ServiceBillingCostTypes })
    commit(storeMutations.SetResource, { data: incomeTypes, resource: globalResources.ServiceBillingIncomeTypes })
  },
  setCraftCodes({ commit, dispatch }, data) {
    commit(storeMutations.SetResource, { data: data, resource: globalResources.CraftCodes })
    // * Option for all crafts and levels
    let codes = cloneDeep(data)
    codes.splice(0, 0, {
      id: '*',
      code: '*',
      level: '*',
      description: 'All Crafts. All Levels',
    })

    commit(storeMutations.SetResource, { data: codes, resource: globalResources.AllCraftsAndLevels })
  },
  async getJCConfiguration({ commit, dispatch }, invalidateCache = false) {
    const { data } = await Cache.getRequest('/configuration/list', {
      ttl: cacheTTL,
      invalidateCache,
      params: {
        modules: 'jc,gen',
        entities: 'employees,phase_titles',
        job_status: resourceStatuses.Open
      },
    })
    dispatch('setJobTypes', data.job_types)
    dispatch('setCraftCodes', data.craft_codes)
    commit(storeMutations.SetResource, { data: data.customers, resource: globalResources.Customers })
    commit(storeMutations.SetResource, { data: data.equipment, resource: globalResources.Equipments })
    commit(storeMutations.SetResource, { data: data.jobs, resource: globalResources.Jobs })
    commit(storeMutations.SetResource, { data: data.master_line_items, resource: globalResources.MasterLineItems })
    commit(storeMutations.SetResource, { data: data.materials, resource: globalResources.Materials })
    commit(storeMutations.SetResource, { data: data.vendors, resource: globalResources.Vendors })
    commit(storeMutations.SetResource, { data: data.employees, resource: globalResources.Employees })
    commit(storeMutations.SetResource, { data: data.phase_titles, resource: globalResources.PhaseTitles })
    dispatch('setCommonConfiguration', data)
  },
  async getAPConfiguration({ commit, dispatch }, invalidateCache = false) {
    const { data } = await Cache.getRequest('/configuration/list?modules=ap,gen', {
      ttl: cacheTTL,
      invalidateCache,
      params: {
        entities: 'customers',
        job_status: resourceStatuses.Open,
      },
    })
    commit(storeMutations.SetResource, { data: data.vendors, resource: globalResources.Vendors })
    commit(storeMutations.SetResource, { data: data.purchase_orders, resource: globalResources.PurchaseOrders })
    commit(storeMutations.SetResource, { data: data.ap_waiver_release_templates, resource: globalResources.WaiverReleaseTemplates })
    commit(storeMutations.SetResource, { data: data.customers, resource: globalResources.Customers })
    dispatch('setCommonConfiguration', data)
  },
  async getGLConfiguration({ commit, dispatch }, invalidateCache = false) {
    const { data } = await Cache.getRequest('/configuration/list?modules=gl,gen', {
      ttl: cacheTTL,
      invalidateCache,
      params: {
        job_status: resourceStatuses.Open
      },
    })
    dispatch('setJobTypes', data.job_types)
    commit(storeMutations.SetResource, { data: data.jobs, resource: globalResources.Jobs })
    dispatch('setCommonConfiguration', data)
  },
  async getARConfiguration({ commit, dispatch }, invalidateCache = false) {
    const { data } = await Cache.getRequest('/configuration/list', {
      ttl: cacheTTL,
      invalidateCache,
      params: {
        modules: 'ar,gen',
        entities: 'vendors,phase_titles',
        job_status: resourceStatuses.Open
      },
    })
    dispatch('setCraftCodes', data.craft_codes)
    commit(storeMutations.SetResource, { data: data.billing_rate_types, resource: globalResources.BillingRateTypes })
    commit(storeMutations.SetResource, { data: data.customers, resource: globalResources.Customers })
    commit(storeMutations.SetResource, { data: data.districts, resource: globalResources.SalesTaxDistricts })
    commit(storeMutations.SetResource, { data: data.service_billing_codes, resource: globalResources.ServiceBillingCodes })
    commit(storeMutations.SetResource, { data: data.ar_waiver_release_templates, resource: globalResources.WaiverReleaseTemplates })
    commit(storeMutations.SetResource, { data: data.vendors, resource: globalResources.Vendors })
    commit(storeMutations.SetResource, { data: data.phase_titles, resource: globalResources.PhaseTitles })
    dispatch('setCommonConfiguration', data)
  },
  async getSettingsConfiguration({ dispatch }, invalidateCache = false) {
    const { data } = await Cache.getRequest('/configuration/list?modules=gen', {
      ttl: cacheTTL,
      invalidateCache,
    })
    dispatch('setCommonConfiguration', data)
  },
  async refreshResource({ commit, dispatch }, resourceName) {
    try {
      const actionName = customRefreshActionsMap[resourceName]

      if (actionName) {
        try {
          commit(storeMutations.SetRefreshResourceLoading, true)
          return await dispatch(actionName, {
            invalidateCache: true
          })
        }
        finally {
          commit(storeMutations.SetRefreshResourceLoading, false)
        }
      }

      let entity = snakeCase(resourceName)
      entity = globalResourceEntityMap[resourceName] || entity
      commit(storeMutations.SetRefreshResourceLoading, true)
      const { data } = await axios.get('/configuration/list', {
        params: {
          entities: entity,
        }
      })
      const resourceData = data[entity]
      if (!resourceData) {
        return
      }
      const actionMap = {
        job_types: () => dispatch('setJobTypes', data.job_types),
        service_billing_types: () => dispatch('setServiceBillingTypes', data.service_billing_types),
        craft_codes: () => dispatch('setCraftCodes', data.craft_codes),
        pay_codes: () => dispatch('setPayCodes', data.pay_codes),
        deduction_codes: () => dispatch('setDeductionCodes', data.deduction_codes),
        default: () => commit(storeMutations.SetResource, { data: resourceData, resource: resourceName })
      }
      const action = actionMap[entity] || actionMap.default
      action()
    } catch (err) {
      console.error('Error refreshing resource', err)
    } finally {
      commit(storeMutations.SetRefreshResourceLoading, false)
    }
  },
  async getLineItemsConfig({ dispatch, state }, invalidateCache = false) {
    if (getLineItemsController) {
      getLineItemsController.abort()
      getLineItemsController = new AbortController()
    }
    if (!invalidateCache && state[globalResources.LineItems].length) {
      return
    }
    const { data } = await axios.get('/configuration/list?entities=line_items', {
      signal: getLineItemsController.signal,
    })
    dispatch('setLineItems', data.line_items)
  },
  async getAllEmployees({ dispatch, commit }, invalidateCache = false) {
    const { data } = await Cache.getRequest('/configuration/list?entities=employees', {
      ttl: cacheTTL,
      invalidateCache,
    })
    commit(storeMutations.SetResource, { data: data.employees, resource: globalResources.Employees })
  },
  async getPRConfiguration({ commit, dispatch }, invalidateCache = false) {
    const { data } = await Cache.getRequest('/configuration/list?modules=pr,gen', {
      ttl: cacheTTL,
      invalidateCache,
      params: {
        job_status: resourceStatuses.Open
      },
    })
    dispatch('setDeductionCodes', data.deduction_codes)
    dispatch('setPayCodes', data.pay_codes)
    dispatch('setCraftCodes', data.craft_codes)
    dispatch('setTaxCodes', data.tax_codes)
    commit(storeMutations.SetResource, { data: data.benefit_codes, resource: globalResources.BenefitCodes })
    commit(storeMutations.SetResource, { data: data.sub_trades, resource: globalResources.SubTrades })
    commit(storeMutations.SetResource, { data: data.direct_deposit_codes, resource: globalResources.DirectDepositCodes })
    commit(storeMutations.SetResource, { data: data.departments, resource: globalResources.DepartmentCodes })
    commit(storeMutations.SetResource, { data: data.employees, resource: globalResources.Employees })
    commit(storeMutations.SetResource, { data: data.flexible_benefit_plans, resource: globalResources.FlexibleBenefitPlans })
    commit(storeMutations.SetResource, { data: data.local_tax_jurisdictions, resource: globalResources.LocalTaxes })
    commit(storeMutations.SetResource, { data: data.general_codes, resource: globalResources.GeneralCodes })
    commit(storeMutations.SetResource, { data: data.retirement_plans, resource: globalResources.RetirementPlans })
    commit(storeMutations.SetResource, { data: data.state_tax_jurisdictions, resource: globalResources.StateTaxes })
    commit(storeMutations.SetResource, { data: data.tax_codes, resource: globalResources.TaxCodes })
    commit(storeMutations.SetResource, { data: data.time_off_policies, resource: globalResources.TimeOffPolicies })
    commit(storeMutations.SetResource, { data: data.unions, resource: globalResources.UnionCodes })
    commit(storeMutations.SetResource, { data: data.vendors, resource: globalResources.Vendors })
    commit(storeMutations.SetResource, { data: data.w2_codes, resource: globalResources.WTwoCodes })
    dispatch('setCommonConfiguration', data)
  },
  async getSBConfiguration({ commit, dispatch }) {
    const { data } = await Cache.getRequest('/configuration/list', {
      ttl: cacheTTL,
      params: {
        modules: 'sb,gen',
        entities: 'districts',
      }
    })
    dispatch('setJobTypes', data.job_types)
    dispatch('setCraftCodes', data.craft_codes)
    dispatch('setServiceBillingTypes', data.service_billing_types)
    commit(storeMutations.SetResource, { data: data.employees, resource: globalResources.Employees })
    commit(storeMutations.SetResource, { data: data.equipment, resource: globalResources.Equipments })
    commit(storeMutations.SetResource, { data: data.materials, resource: globalResources.Materials })
    commit(storeMutations.SetResource, { data: data.purchase_orders, resource: globalResources.PurchaseOrders })
    commit(storeMutations.SetResource, { data: data.service_billing_codes, resource: globalResources.ServiceBillingCodes })
    commit(storeMutations.SetResource, { data: data.work_orders, resource: globalResources.WorkOrders })
    commit(storeMutations.SetResource, { data: data.districts, resource: globalResources.SalesTaxDistricts })
    dispatch('setCommonConfiguration', data)
  },
  async getIVConfiguration({ commit, dispatch }, invalidateCache = false) {
    const { data } = await Cache.getRequest('/configuration/list?modules=iv,gen', {
      ttl: cacheTTL,
      invalidateCache,
      params: {
        entities: 'equipment,equipment_types,employees',
        job_status: resourceStatuses.Open
      },
    })
    commit(storeMutations.SetResource, { data: data.addresses, resource: globalResources.CompanyAddresses })
    commit(storeMutations.SetResource, { data: data.districts, resource: globalResources.SalesTaxDistricts })
    commit(storeMutations.SetResource, { data: data.employees, resource: globalResources.Employees })
    commit(storeMutations.SetResource, { data: data.purchase_orders, resource: globalResources.PurchaseOrders })
    commit(storeMutations.SetResource, { data: data.vendors, resource: globalResources.Vendors })
    commit(storeMutations.SetResource, { data: data.employees, resource: globalResources.Employees })
    commit(storeMutations.SetResource, { data: data.equipment, resource: globalResources.Equipments })
    commit(storeMutations.SetResource, { data: data.equipment_types, resource: globalResources.EquipmentTypes })
    dispatch('setCommonConfiguration', data)
  },
  async getEQPConfiguration({ commit, dispatch }, invalidateCache = false) {
    const { data } = await Cache.getRequest('/configuration/list?modules=eq', {
      ttl: cacheTTL,
      invalidateCache
    })
    commit(storeMutations.SetResource, { data: data.equipment, resource: globalResources.Equipments })
    commit(storeMutations.SetResource, { data: data.equipment_types, resource: globalResources.EquipmentTypes })
    commit(storeMutations.SetResource, { data: data.maintenance_codes, resource: globalResources.EquipmentMaintenances })
    await dispatch('getAllEmployees')
    await dispatch('getLineItemsConfig')
    await dispatch('getJobs')
  },
  async getCustomers({ commit }) {
    const data = await Cache.getRequest('/restify/customers/list', {
      ttl: cacheTTL,
      params: {
        status: resourceStatuses.Active,
      }
    }) || []
    commit(storeMutations.SetResource, { data, resource: globalResources.Customers })
  },
  ...jobCostingActions,
  ...payrollActions,
  ...equipmentActions,
};

const getters = {
  getResourceOptions: state => (resourceName, searchQuery = '', perPage = 50) => {
    const list = state[resourceName] || []
    if (isUUID(searchQuery)) {
      if (!perPage) {
        return list
      }
      const index = list.findIndex(item => item.id === searchQuery)

      const start = Math.max(0, index - 50)
      const end = Math.min(index + 25, list.length)

      return list.slice(start, end)
    }

    const query = searchQuery?.toString()?.toLowerCase() || ''
    const options = list.filter(c => {
      if (!query) {
        return true
      }
      return searchableFields.some(field => {
        if (c[field]) {
          return c[field]?.toString()?.toLowerCase().includes(query)
        }
        return false
      })
    })
    if (!perPage) {
      return options
    }
    return options.slice(0, perPage)
  },
  getResourceById: state => (resourceName, id) => {
    if (resourceName === globalResources.LineItems) {
      const options = [...state[resourceName], ...state[globalResources.MasterLineItems]]
      return options.find(resource => resource.id === id) || {}
    }

    return state[resourceName].find(resource => resource.id === id) || {}
  },
  getResourceList: state => resourceName => {
    return state[resourceName]
  },
  getLastPurchaseOrderNumber: state => {
    let purchaseOrders = state[globalResources.PurchaseOrders] || []
    purchaseOrders = sortBy(purchaseOrders, 'created_at')
    return purchaseOrders.length ? purchaseOrders[purchaseOrders.length - 1].number : 0
  },
  ...jobCostingGetters,
  ...payrollGetters,
  ...equipmentGetters,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
