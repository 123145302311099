<template>
  <div>
    <AgDataTable
      :columns="columns"
      :url="url"
      :url-params="urlParams"
      :add-text="$t('New')"
      :per-page="$pagination.list"
      actions="search,refresh,view,add"
      entity="vendor"
      ref="table"
      @add="showCreateDialog = true"
    />

    <BaseFormDialog
      v-if="showCreateDialog"
      :title="$t('Vendor 1099 Batch')"
      :open.sync="showCreateDialog"
      @close="showCreateDialog = false"
      size="sm"
    >
      <BaseForm
        layout="modal"
        :loading="saving"
        :show-cancel="true"
        :save-text="$t('Continue')"
        :submitDisabled="!model.year"
        grid-classes="grid grid-cols-8 gap-x-4"
        @cancel="showCreateDialog = false"
        @submit="onSubmit"
      >
        <div class="mx-4 space-y-4 col-span-full">
          <BaseButton
            variant="white"
            block
            @click="model.year = currentYear"
          >
            {{ $t('Current Year') }} ({{ currentYear }})
          </BaseButton>
          <BaseButton
            variant="white"
            block
            @click="model.year = previousYear"
          >
            {{ $t('Previous Year') }} ({{ previousYear }})
          </BaseButton>
          <BaseInput
            v-model="model.year"
            :label="$t('Year')"
            :placeholder="$t('Year')"
            id="archive_year"
            type="number"
            rules="required|min_value:1900"
          />
        </div>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import axios from 'axios'
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      url: '/restify/vendor1099-batches',
      urlParams: {
      },
      showCreateDialog: false,
      saving: false,
      model: {
        year: null,
      },
    }
  },
  computed: {
    currentYear() {
      return this.$settings(this.$modules.AP, 'calendar_year')
    },
    previousYear() {
      return this.currentYear - 1
    },
    columns() {
      return [
        {
          headerName: this.$t('Year'),
          prop: 'attributes.year',
          component: 'EntityLink',
          redirectTo: '/accounts-payable/vendor-1099s/{ID}/view',
        },
        {
          headerName: this.$t('Status'),
          prop: 'attributes.status',
          component: 'Status',
        },

        {
          headerName: this.$t('Created By'),
          prop: 'attributes.created_by',
          component: 'UserLink',
        },
        {
          headerName: this.$t('Updated At'),
          prop: 'attributes.updated_at',
          component: 'FormattedDate',
        },
      ]
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.saving = true
        
        const { data } = await axios.get('/restify/vendor1099-batches', {
          params: {
            year: this.model.year
          }
        })

        let batch

        if (data?.length) {
          batch = data[0]
        }
        else {
          const { data: newBatch } = await axios.post('/restify/vendor1099-batches', {
            year: this.model.year
          })

          batch = newBatch
        }

        this.showCreateDialog = false

        this.$router.push(`/accounts-payable/vendor-1099s/${batch.id}/view`)

      } catch (err) {
        if (err.handled) {
          return
        }
        
        this.$error(this.$t('Something went wrong. Please try again.'))
      }
      finally {
        this.saving = false
        this.showCreateDialog = false
      }
    },
  }
})
</script>
