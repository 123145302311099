<template>
  <ProofListing
    entity="journal"
    url="/restify/journals/actions?action=post-jc-journals-proof-listing"
    post-url="/restify/journals/action?action=post-jc-journals"
    ids-field="journal_ids"
    @data-fetch="onDataFetched"
    #default="{ loading }"
    :printOptions="printOptions"
  >
    <AgDataTable
      :data="flatData"
      :columns="columns"
      :data-loading="loading"
      :pagination="false"
      :groupDefaultExpanded="-1"
      :groupIncludeFooter="true"
      :groupRowRendererParams="groupRowRendererParams"
      :compact="true"
      :no-borders="true"
      groupDisplayType="groupRows"
      dom-layout="autoHeight"
    />

    <div class="grid lg:grid-cols-2 gap-4 mt-8 print:break-inside-avoid">
      <div></div>
      <div>
        <h5 class="form-section-title">
          {{ $t('Account Summary') }}
        </h5>
        <ProofListingAccountSummary
          :data="accountSummaryData"
          :loading="loading"
        />
      </div>
    </div>
  </ProofListing>
</template>
<script>
import i18n from "@/i18n";
import PostDialog from "@/components/common/modal/PostDialog";
import ProofListing from "@/modules/common/components/proof-listing/ProofListing";
import ProofListingAccountSummary from "@/modules/common/components/ProofListingAccountSummary";
import ProofListingCostTypeSummary from "@/modules/common/components/ProofListingCostTypeSummary";
import JobTransactionsProofGroupRow from "@/modules/job-costing/components/JobTransactionsProofGroupRow.vue";
import { StimulsoftPrintEntities } from "@/enum/stimulsoft";

export default {
  components: {
    PostDialog,
    ProofListing,
    JobTransactionsProofGroupRow,
    ProofListingAccountSummary,
    ProofListingCostTypeSummary,
  },
  data() {
    return {
      loading: false,
      data: {
        journals: [],
        account_summary: [],
      },
      flatData: []
    }
  },
  computed: {
    accountSummaryData() {
      return this.data.account_summary.filter(account => {
        return account.debit_amount > 0 || account.credit_amount > 0
      })
    },
    printData() {
      return {
        journals: this.flatData,
        account_summary: this.accountSummaryData,
      }
    },
    printOptions() {
      return {
        stimulsoft: true,
        data: this.printData,
        entity: StimulsoftPrintEntities.JobTransactionsProofListing,
      }
    },
    groupRowRendererParams() {
      return {
        innerRenderer: 'JobTransactionsProofGroupRow',
      }
    },
    columns() {
      return [
        {
          label: i18n.t('Journal'),
          prop: 'journal.id',
          rowGroup: true,
          hide: true,
        },
        {
          label: i18n.t('Job'),
          prop: 'job.number',
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.$t('Type'),
          field: 'type_id',
          component: 'JobTypeLink',
          minWidth: 30,
          maxWidth: 80,
        },
        {
          headerName: this.$t('Line Item'),
          field: 'addl_source_id',
          minWidth: 100,
          maxWidth: 120,
          component: 'LineItemLink',
          cellRendererParams: {
            showDescription: false,
          },
        },
        {
          headerName: this.$t('Account'),
          field: 'account',
          minWidth: 100,
          maxWidth: 120,
          component: 'AccountLink',
          cellRendererParams: {
            showDescription: false,
          },
        },
        {
          headerName: this.$t('Description'),
          field: 'description',
          minWidth: 80,
        },
        {
          headerName: this.$t('Ref #'),
          field: 'reference_no',
          minWidth: 80,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Special Source'),
          field: 'special_source_id',
          component: 'SpecialSourceLink',
          minWidth: 80,
          maxWidth: 120,
        },
        {
          headerName: this.$t('Qty'),
          field: 'meta.quantity',
          valueGetter: params => {
            if (!params.data) {
              return ''
            }
            return +params.data?.meta?.quantity
          },
          component: 'FormattedQuantity',
          align: 'right',
          minWidth: 50,
          maxWidth: 100,
        },
        {
          headerName: this.$t('Unit Price'),
          field: 'meta.unit_price',
          valueGetter: params => {
            if (!params.data) {
              return ''
            }
            return +params.data?.meta?.unit_price
          },
          component: 'FormattedPrice',
          align: 'right',
          minWidth: 50,
          maxWidth: 100,
        },
        {
          headerName: this.$t('Extended $'),
          field: 'amount',
          minWidth: 90,
          maxWidth: 150,
          cellRendererParams: {
            hideZero: true,
          },
          component: 'FormattedPrice',
          align: 'right',
          aggFunc: 'sum',
        },
        {
          headerName: this.$t('Transaction Date'),
          field: 'reference_date',
          minWidth: 100,
          maxWidth: 150,
          component: 'FormattedDate',
          align: 'right',
        },
        {
          headerName: this.$t('Post to GL'),
          field: 'post_to_gl',
          minWidth: 80,
          maxWidth: 80,
          component: 'Status',
          align: 'right',
        },
      ]
    },
  },
  methods: {
    getPeriodName(period) {
      return this.$store.getters['company/getPeriodName'](period)
    },
    onDataFetched(data) {
      this.data = data

      const allEntries = []
      this.data.journals?.forEach(journal => {
        journal?.jobs?.forEach(job => {
          job?.entries?.forEach(entry => {
            const tableEntry = {
              ...entry,
              job,
              journal,
              debit_amount: +entry.debit_amount,
              credit_amount: +entry.credit_amount,
            }

            delete tableEntry.job.entries
            delete tableEntry.journal.jobs

            tableEntry.journal.reference_date_formatted = journal.reference_date
              ? this.$formatDate(journal.reference_date)
              : ''

            tableEntry.journal.period_name = this.getPeriodName(journal.period)

            tableEntry.amount = entry.credit_amount
              ? -entry.credit_amount
              : entry.debit_amount

            const {
              phase_code,
              cost_code,
              change_order,
            } = entry.addlSource || {}

            tableEntry.line_item_text = entry.addlSource
              ? `${phase_code || '-'} / ${cost_code || '-'} / ${change_order || '0'}`
              : ''

            allEntries.push(tableEntry)
          })
        })
      })

      this.flatData = allEntries
    },
  }
}
</script>
