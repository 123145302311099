<template>
  <div>
    <TimesheetsTable v-if="view === ViewModes.View">
      <template #additional-actions-before>
        <BaseButton
          variant="white" @click="switchTo(ViewModes.Edit)">
          <Edit2Icon class="w-4 h-4 mr-1"/>
          <span>{{ $t('Grid Mode') }}</span>
        </BaseButton>
      </template>
    </TimesheetsTable>
    <TimesheetsGrid v-if="view === ViewModes.Edit">
      <template #additional-actions-before>
        <BaseButton variant="white" @click="switchTo(ViewModes.View)">
          <ListIcon class="w-4 h-4 mr-1"/>
          <span>{{ $t('List Mode') }}</span>
        </BaseButton>
      </template>
    </TimesheetsGrid>
    <TimesheetEntryDialog
      v-if="showTimesheetEntryEditDialog"
      :open.sync="showTimesheetEntryEditDialog"
      :data="entryToEdit"
      :date="entryToEdit?.attributes?.date"
      :employee-id="entryToEdit?.attributes?.employee_id"
      :showEmployeeSelect="true"
      @close="showTimesheetEntryEditDialog = false"
      @submit="showTimesheetEntryEditDialog = false"
    />
  </div>
</template>
<script>
import axios from 'axios'
import TimesheetsTable from "@/modules/payroll/pages/timesheets/TimesheetsTable.vue";
import { Edit2Icon, ListIcon } from "vue-feather-icons";
import TimesheetsGrid from "@/modules/payroll/components/timesheets/TimesheetsGrid.vue";
import TimesheetEntryDialog from "@/modules/payroll/components/timesheets/TimesheetEntryDialog.vue";
import Cache from "@/utils/Cache";

const ViewModes = {
  View: 'view',
  Edit: 'edit',
}

export default {
  components: {
    ListIcon,
    Edit2Icon,
    TimesheetsGrid,
    TimesheetsTable,
    TimesheetEntryDialog,
  },
  data() {
    return {
      ViewModes,
      view: this.$route.query.view_type || ViewModes.View,
      entryToEdit: null,
      showTimesheetEntryEditDialog: false,
    }
  },
  methods: {
    switchTo(mode) {
      Cache.removeForEntity('timesheet-entries')
      Cache.removeForEntity('timesheets')
      this.view = mode
    },
    async getTimesheetEntryById(id) {
      const { data } = await axios.get(`/restify/timesheet-entries/${id}`, {
        params: {
          ignore404: true,
          related: 'timesheet'
        }
      })

      this.entryToEdit = {
        ...data,
        attributes: {
          ...data.attributes,
          timesheet: data?.relationships?.timesheet || {}
        }
      }
    }
  },
  watch: {
    '$route.query.timesheetEntryId': {
      immediate: true,
      async handler(timesheetEntryId) {
        if (!timesheetEntryId) {
          return
        }
        await this.getTimesheetEntryById(timesheetEntryId)
        this.showTimesheetEntryEditDialog = true
      }
    }
  }
}
</script>
