<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    size="lg"
    :title="$t('Close Work Orders')"
  >
    <BaseForm
      :save-text="$t('Close Work Orders')"
      :loading="loading"
      layout="vertical"
      @submit="onSubmit"
    >
      <div class="col-span-6 md:col-span-2">
        <BaseDatePicker
          v-model="model.older_than_date"
          :label="$t('Close work orders older than')"
          value-format="yyyy-MM-dd"
          rules="required"
          @change="getWorkOrderCount"
        />
      </div>
      <BaseAlert
        v-if="model.older_than_date"
        type="warning"
        class="col-span-6 mt-4"
        v-loading="loadingCount"
      >
        {{ $tc('Close work orders count', workOrderCount) }}
      </BaseAlert>
    </BaseForm>
  </BaseFormDialog>
</template>
<script setup lang="ts">

import {ref} from "vue";
import axios from "axios";

const loading = ref(false)
const model = ref({
  older_than_date: null,
})

const loadingCount = ref(false)
const workOrderCount = ref(0)

const emit = defineEmits(['save'])

async function getWorkOrderCount() {
  try {
    if (!model.value.older_than_date) {
      return
    }
    loadingCount.value = true
    const response = await axios.get(`/restify/work-orders/getters/closable-work-orders`, {
      params: {
        older_than_date: model.value.older_than_date,
      }
    })
    workOrderCount.value = response.data.count
  } catch (err) {
    console.error(err)
  } finally {
    loadingCount.value = false
  }
}

async function onSubmit() {
  try {
    loading.value = true
    await axios.post(`/restify/work-orders/actions?action=close-work-orders`, model.value)
    emit('save')
  } catch (err) {
    console.error(err)
  } finally {
    loading.value = false
  }
}
</script>
