<template>
  <div
    :title="lineItemName"
    class="truncate"
  >
    <router-link
        v-if="lineItemId && isLink"
        :to="`/job-costing/${lineItemTypePath}/${lineItemId}/view`"
        :target="target"
        :class="linkClass"
        class="text-gray-900"
    >
      <span>
        {{ lineItemName }}
      </span>
    </router-link>
    <template v-else>
      {{ lineItemName }}
    </template>
  </div>
</template>
<script>
  import { costTypes } from '@/enum/enums'
  import { formatLineItemCode } from '@/utils/utils';

  export default {
    name: 'LineItemLink',
    props: {
      id: {
        type: [String, Number],
      },
      data: {
        type: [Object, String],
        default: () => ({}),
      },
      target: String,
      isLink: {
        type: Boolean,
        default: true,
      },
      showDescription: {
        type: Boolean,
        default: true,
      },
      linkClass: {
        type: String,
      }
    },
    computed: {
      lineItemId() {
        return this.id || this.lineItem?.id
      },
      lineItem() {
        const id = this.id || this.data?.id
        if (!id) {
          return {}
        }

        const matchingItem =  this.$store.getters['globalLists/getResourceById'](this.$globalResources.LineItems, id)

        if (matchingItem?.id) {
          return matchingItem
        }

        if (this.data?.attributes) {
          return this.data?.attributes
        }

        if (this.data && typeof this.data === 'object' && this.data.id) {
          return this.data
        }
      },
      lineItemTypePath() {
        if (this.lineItem?.type === costTypes.Cost) {
          return 'cost-line-items'
        }
        return 'income-line-items'
      },
      lineItemName() {
        if (!this.lineItemId) {
          return ''
        }
        const code = formatLineItemCode(this.lineItem)
        const description = this.lineItem?.description || ''

        if (!this.showDescription) {
          return code
        }
        if (description) {
          return `${code} (${description})`
        }
        return code
      },
    },
  }
</script>
