<template>
  <base-form
    :loading="loading"
    :save-text="$t('Create Adjustment')"
    :update-text="$t('Update Adjustment')"
    :can-create-another-entity="!model.id"
    :submit-disabled="gridContext.loading"
    submit-button-type="button"
    grid-classes="grid grid-cols-1"
    layout="vertical"
    class="mb-10"
    ref="baseForm"
    @cancel="$router.push('/equipment/settings/initialize-equipment')"
    @submit="onSubmit"
  >
    <el-collapse v-model="expandedSections"
                 class="col-span-1">
      <base-collapse-section
        :title="$t('Adjustment')"
        key="details"
        name="details"
        class="-m-6">
        <template #title>
          <div class="flex w-full justify-between form-header-summary">
              <span class="mx-4">
                {{ $t('Adjustment') }}
              </span>
            <portal-target name="tr-summary"/>
          </div>
        </template>
        <div class="grid grid-cols-12 gap-x-3 px-4 pt-3">
          <base-date-picker
            v-model="model.transaction_date"
            @change="onChangeDate"
            :label="$t('Date')"
            :disabled="!!$route.params.id"
            id="date-input"
            class="col-span-12 md:col-span-3"
          />
          <span class="col-span-12 md:col-span-2">
            <base-switch
              v-model="model.has_offset_account"
              :label-info="$t('Non EQP G/L Account Offset')"
              :vertical="true"
              id="offset"
            />
          </span>
          <template v-if="model.has_offset_account">
            <account-select
              v-model="model.account"
              :name="$t('Account')"
              :label="$t('Account')"
              label-key="number"
              value-key="number"
              id="account"
              class="col-span-12 md:col-span-3"
              rules="required"
            />
            <sub-account-select
              v-model="model.subaccount"
              :name="$t('Sub Account')"
              :label="$t('Sub Account')"
              label-key="number"
              value-key="number"
              id="subaccount"
              class="col-span-12 md:col-span-2"
            />
          </template>
          <FiscalYearSelect
            v-model="model.fiscal_year"
            :label="$t('Fiscal Year')"
            :default-previous-year="true"
            class="col-span-12 md:col-span-2"
            id="fiscal_year"
          />
          <PeriodSelect
            v-model="model.period"
            :placeholder="$t('Period')"
            :default-to-last-period="true"
            class="col-span-12 md:col-span-2"
            id="period"
          />
          <div class="col-span-12">
            <base-alert :type="$promptType.Light" closable>
              <h6>
                {{ $t('Positive adjustment amounts post to G/L as Debits: Negative amounts as Credits.') }}
              </h6>
            </base-alert>
          </div>
        </div>
      </base-collapse-section>
    </el-collapse>
    <EquipmentAdjustmentEntries
      :data="model"
      :key="model.id"
      class="col-span-6"
      ref="gridTable"
    />
  </base-form>
</template>
<script>
import axios from 'axios'
import AccountsPayableInitEntries from '@/modules/accounts-payable/components/settings/AccountsPayableInitEntries'
import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
import JobTransactionEntries from "@/modules/job-costing/components/JobTransactionEntries.vue";
import { formatDate } from "@/plugins/dateFormatPlugin";
import EquipmentAdjustmentEntries from "@/modules/equipment/components/equipment-init/EquipmentAdjustmentEntries.vue";
import { gridContext } from "@/components/ag-grid/gridContext";

export default {
  components: {
    EquipmentAdjustmentEntries,
    JobTransactionEntries,
    AccountsPayableInitEntries,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      model: {
        account: '',
        subaccount: null,
        transaction_date: this.$now,
        status: this.$resourceStatuses.Pending,
        fiscal_year: this.$currentYear,
        period: this.$currentPeriod,
        has_offset_account: false,
      },
      gridContext,
      expandedSections: ['details'],
    }
  },
  methods: {
    onChangeDate(refDate) {
      const referenceDate = new Date(refDate)
      const date = formatDate(referenceDate)
      this.model.period = referenceDate.getUTCMonth()
      this.model.name = `${this.model.code} - ${date}`
    },
    async onSubmit() {
      try {
        const isInvalidData = await validateAgDataTable()
        if (isInvalidData) {
          return
        }

        this.loading = true
        if (this.model.id) {
          await axios.put(`/restify/equipment-adjustments/${this.model.id}`, this.model)
          await this.$refs.gridTable.storeProgress(this.model.id)

          this.$success(this.$t('Equipment Adjustment updated successfully'))
        } else {
          const { data } = await axios.post('/restify/equipment-adjustments', this.model)
          await this.$refs.gridTable.storeProgress(data.id)

          this.$success(this.$t('Equipment Adjustment created successfully'))
          await this.$router.push('/equipment/initialize-equipment')
        }
      } catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Error initializing Equipment Init'))
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value.id) {
          return
        }

        this.model = {
          ...this.model,
          ...value.attributes,
        }
      },
    },
  },
}
</script>
