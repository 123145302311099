<template>
  <BaseSelect
    v-bind="$attrs"
    v-on="$listeners"
    :id="$attrs.id || 'w2-code-select'"
    :name="$attrs.name || $t('W-2 Memo Code')"
    :placeholder="$attrs.placeholder || $t('Select W-2 Memo Code')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('W-2 Memo Code')"
    :options="options"
    :table-columns="tableColumns"
    :dataFilters="dataFilters"
    :resource-name="globalResources.WTwoCodes"
    :value-key="valueKey"
    :label-key="labelKey"
  >
    <template #label>
      <slot name="label" />
    </template>
  </BaseSelect>
</template>
<script>
import { globalResources } from "@/components/form/util";

export default {
  name: 'W2MemoCodeSelect',
  props: {
    valueKey: {
      type: String,
      default: 'code',
    },
    labelKey: {
      type: String,
      default: 'code',
    },
    filters: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      globalResources,
      dataFilters: {
        showActive: true,
      },
    }
  },
  computed: {
    w2Codes() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.WTwoCodes) || []
    },
    options() {
      let options = [...this.w2Codes]

      if (this.dataFilters.showActive) {
        options = options.filter(code => code.active)
      }

      if (Object.keys(this.filters || {})?.length) {
        options = options.filter(code => {
          return Object.keys(this.filters).every(key => {
            return code[key] == this.filters[key]
          })
        })
      }

      return options.map(code => ({
        ...code,
        isInactive: !code.active,
      }))
    },
    tableColumns() {
      return [
        {
          minWidth: 80,
          maxWidth: 80,
          name: this.$t('Code'),
          prop: 'code',
        },
        {
          minWidth: 80,
          maxWidth: 80,
          name: this.$t('Box #'),
          prop: 'box_number',
        },
        {
          minWidth: 100,
          maxWidth: 100,
          name: this.$t('Box Code'),
          prop: 'box_code',
        },
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Description'),
          prop: 'description',
        },
        {
          minWidth: 120,
          maxWidth: 120,
          name: this.$t('Active'),
          prop: 'active',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active codes'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
        },
      ]
    },
  },
}
</script>
