<template>
  <div
    class="truncate flex items-center"
    :class="{
      [textClass]: textClass
    }"
  >
    <a
      v-if="value"
      :href="`tel:${value}`"
      class="hover:text-primary-700 cursor-pointer truncate"
      :class="{
        [textClass]: textClass
      }"
    >
      {{ formattedValue }}
    </a>
    <span v-else>
      {{ noDataText }}
    </span>
  </div>
</template>
<script>
  import masker from 'vue-the-mask/src/masker'
  import tokens from 'vue-the-mask/src/tokens'
  import i18n from "@/i18n";

  export default {
    props: {
      value: {
        type: [String, Object],
      },
      phoneMask: {
        type: String,
        default: '###-###-####'
      },
      noDataText: {
        type: String,
        default: i18n.t('No phone provided')
      },
      textClass: {
        type: String,
        default: 'text-gray-900'
      }
    },
    computed: {
      formattedValue() {
        let val = this.value
        return masker(val, this.phoneMask, true, tokens)
      }
    }
  }
</script>
