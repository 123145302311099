<template>
  <div>
    <base-data-table
        :url="url"
        :columns="columns"
        :url-params="urlParams"
        :is-expandable="status === resourceStatuses.Pending"
        :add-entity-in-new-tab="true"
        :add-entity-url-query="addEntityUrlQuery"
        :base-entity-path="baseEntityPath"
        :add-text="$t(`New ${type} Adjustment`)"
        ref="table"
        actions="search,refresh,add,print"
        hide-actions="view"
        :selectRowFilter="selectRowFilter"
        @data-fetch="transactions = $event"
    >
      <template #extra-actions-before="{row}">
        <table-view-button
          :row="row"
          :link="getViewEntityPath(row)"
        />
      </template>
      <template #select="{row}">
        <base-checkbox v-model="row.selected"
                       :disabled="!$isAuthorized('authorizedToPost', row)"/>
      </template>
      <template v-slot:attributes.name="{row}">
        <div class="flex items-center">
          <postable-tooltip
              v-if="row.attributes.status !== 'posted'"
              :is-postable="$isAuthorized('authorizedToPost', row)"
          />
          <div class="truncate">
            <router-link :to="getViewEntityPath(row)">
              {{ row.attributes.name }}
            </router-link>
          </div>
        </div>
      </template>
      <template #extra-actions="{row}">
        <ProofListingButton
          v-if="$isAuthorized('authorizedToPost', row)"
          :selected-rows="[row]"
          path="/job-costing/transactions/proof-listing"
        />
      </template>
      <template #additional-actions>
        <ProofListingButton
          v-if="authorizedToPostBulk"
          :disabled="selectedRows.length === 0"
          :selected-rows="selectedRows"
          path="/job-costing/transactions/proof-listing"
        />
      </template>
      <template #expand-content="{row}">
        <JobTransactionEntries
            :type="row.originalRow?.attributes?.type"
            :data="row.originalRow?.attributes"
            :read-only="true"
            class="p-4"
        />
      </template>
    </base-data-table>
  </div>
</template>
<script>
  import axios from 'axios'
  import { journalTypes } from '@/enum/journal'
  import { resourceStatuses, transactionTypes } from '@/enum/enums'
  import JobTransactionEntries from '@/modules/job-costing/components/JobTransactionEntries.vue'
  import { dateTypes } from "@/plugins/dateFormatPlugin";
  import TableViewButton from "@/components/table/actions/TableViewButton.vue";

  export default {
    components: {
      JobTransactionEntries,
      TableViewButton,
    },
    props: {
      type: {
        type: String,
        default: transactionTypes.Cost,
      },
      status: {
        type: String,
        default: resourceStatuses.Pending,
      },
    },
    data() {
      return {
        resourceStatuses,
        transactions: [],
        postingProgress: false,
        url: '/restify/journals',
      }
    },
    computed: {
      columns() {
        const columns = [
          {
            label: this.$t('Name'),
            prop: 'attributes.name',
            maxWidth: 160,
          },
          {
            label: this.$t('Date'),
            prop: 'attributes.reference_date',
            maxWidth: 200,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Created By'),
            prop: 'attributes.created_by',
            minWidth: 100,
            maxWidth: 150,
            component: this.$tableColumns.UserLink,
          },
          {
            label: this.$t('Created at'),
            prop: 'attributes.created_at',
            component: 'FormattedDate',
            params: {
              format: dateTypes.DateTime,
            },
            minWidth: 100,
            maxWidth: 150,
          },
        ]

        if (!this.authorizedToPostBulk) {
          return columns
        }

        return [
          {
            label: '',
            prop: 'select',
            minWidth: 40,
            maxWidth: 40,
            smallCell: true,
          },
          ...columns,
        ]
      },
      urlParams() {
        return {
          code: journalTypes.JobCosting,
          transaction_type: this.type,
          status: this.status,
        }
      },
      addEntityUrlQuery() {
        return `/job-costing/transactions/${this.type}/add`
      },
      baseEntityPath() {
        return `/job-costing/transactions/${this.type}`
      },
      authorizedToPostBulk() {
        return this.status === resourceStatuses.Pending
      },
      selectedRows() {
        return this.transactions.filter(e => e.selected)
      },
    },
    methods: {
      getViewEntityPath(row) {
        const isPending = row.attributes.status === resourceStatuses.Pending
        if (isPending) {
          return `/job-costing/transactions/${this.type}/${row.id}/edit`
        }
        return `/job-costing/journals/${row.id}/view`
      },
      refreshTable() {
        this.$refs.table.refresh()
      },
      selectRowFilter(row) {
        return this.$isAuthorized('authorizedToPost', row)
      },
    },
  }
</script>
