<template>
  <div>
    <BaseAlert
      :type="$promptType.Info"
      :closable="true"
      class="mb-4"
    >
      <span class="font-bold">{{ $t('Note') }}</span>
      <p>
        {{ $t(`It is recommended to link all Line Items in the same direction, by linking either Cost to Income or Income to Cost.`) }}
      </p>
    </BaseAlert>

    <AgDataTable
      :url="url"
      :compact="true"
      :columns="columns"
      :url-params="urlParams"
      :default-sort="defaultSort"
      :actionsColumnWidth="195"
      permission="line_items"
      dom-layout="autoHeight"
      :disableCache="true"
      hide-actions="add,delete,view,edit"
      actions="search,refresh"
      entity="line-items"
      class="print:mt-4 -mt-6"
      ref="table"
    >
      <template #additional-actions-before>
        <slot name="actions" />
        <div class="min-w-[350px]">
          <BaseSelect
            v-model="lineItemType"
            :label="$t('Line Item Type')"
            :placeholder="$t('Line Item Type')"
            :add-entity="false"
            :options="lineItemTypeOptions"
          />
        </div>
      </template>

      <template #links="{ row }">
        <div class="flex items-start flex-col pl-4 py-1">
          <template v-if="row.is_linked">
            <div class="text-gray-700 text-sm flex space-x-1">
              <span>{{ $t('This Line Item is already linked to') }}</span>
              <LineItemLink
                :id="row.linker.id"
                link-class="font-normal !text-gray-700 text-sm truncate"
              />
            </div>
          </template>
          <template v-else>
            <LineItemLink
              v-for="link in row.links"
              :id="typeof link === 'string' ? link : link.id"
              :key="typeof link === 'string' ? link : link.id"
              :is-link="false"
            />
          </template>
        </div>
      </template>
    </AgDataTable>
  </div>
</template>
<script>
import { globalResources, moduleAccountTypes, storeMutations } from "@/components/form/util";
import axios from "axios";
import { cellClasses } from "@/components/ag-grid/columnUtils";

export default {
  data() {
    return {
      defaultSort: 'phase_code,cost_code,change_order',
      lineItemType: moduleAccountTypes.Cost,
      lineItemTypeOptions: [
        {
          label: this.$t('Cost Line Items'),
          value: moduleAccountTypes.Cost,
        },
        {
          label: this.$t('Income Line Items'),
          value: moduleAccountTypes.Income,
        },
      ]
    }
  },
  computed: {
    oppositeType() {
      if (this.lineItemType === moduleAccountTypes.Cost) {
        return moduleAccountTypes.Income
      }
      return moduleAccountTypes.Cost
    },
    oppositeTypeName() {
      if (this.oppositeType === moduleAccountTypes.Cost) {
        return this.$t('a cost')
      }
      return this.$t('an income')
    },
    jobId() {
      return this.$route.params.id
    },
    url() {
      return '/restify/line-items/linked-line-items'
    },
    urlParams() {
      return {
        job_id: this.jobId,
        type: this.lineItemType,
      }
    },
    columns() {
      const linkHeaderName = this.lineItemType === moduleAccountTypes.Cost
        ? this.$t('Linked Income Line Items')
        : this.$t('Linked Cost Line Items')

      return [
        {
          headerName: this.$t('Phase'),
          field: 'phase_code',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          headerName: this.$t('Cost Code'),
          field: 'cost_code',
          minWidth: 60,
          maxWidth: 80,
        },
        {
          headerName: this.$t('Change Order'),
          field: 'change_order',
          minWidth: 60,
          maxWidth: 70,
          align: 'center',
        },
        {
          headerName: this.$t('Description'),
          field: 'description',
          minWidth: 180,
          maxWidth: 320,
        },
        {
          headerName: linkHeaderName,
          field: 'links',
          minWidth: 300,
          flex: 1,
          wrapText: true,
          autoHeight: true,
          editable: params => {
            return !params.data?.is_linked
          },
          cellEditor: this.$cellEditors.GlobalResourceSelect,
          cellClass: params => {
            if (params.data?.is_linked) {
              return cellClasses.ReadOnly
            }
          },
          cellEditorParams: (params) => {
            return {
              resourceName: globalResources.LineItems,
              focusOnMount: true,
              multiple: true,
              filterMethod: (option) => {
                if (option.is_linked || option.links?.length > 0) {
                  return false
                }
                let type = option.type || this.oppositeType
                let isCorrectType = type === this.oppositeType

                if (!isCorrectType) {
                  return false
                }

                if (this.jobId) {
                  return option.job_id === this.jobId
                }

                return true
              },
              target: '_blank',
              class: 'min-w-[400px]'
            }
          },
          valueSetter: params => {
            const { data } = params
            let links = params.newValue || []
            if (!Array.isArray(links)) {
              links = [links]
            }
            data.links = links
            this.saveLineItemLinks(data, links)
            return true
          }
        },
      ]
    },
    allLineItems() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.LineItems) || []
    }
  },
  methods: {
    async updateJobRelatedLineItems() {
      const { data } = await axios.get(`/configuration/list`, {
        params: {
          entities: 'line_items',
          job_ids: this.jobId,
        }
      })

      const updatedLineItems = data.line_items
      const newLineItems = []

      this.allLineItems.forEach(lineItem => {
        const updatedLineItem = updatedLineItems.find(updatedLineItem => updatedLineItem.id === lineItem.id)
        if (updatedLineItem) {
          newLineItems.push(updatedLineItem)
        } else {
          newLineItems.push(lineItem)
        }
      })

      this.$store.commit(`globalLists/${storeMutations.SetResource}`, { data: newLineItems, resource: globalResources.LineItems })
    },
    async saveLineItemLinks(lineItem, links) {
      await axios.post(`/restify/line-items/actions?action=link-line-items`, {
        repositories: 'all',
        job_id: this.jobId,
        links: {
          [lineItem.id]: links,
        },
      })

      this.updateJobRelatedLineItems()
    }
  }
}
</script>
