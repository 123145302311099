<template>
  <div class="mb-4">
    <div class="mb-4">
      <span class="border-b border-double border-gray-300 pb-1">
        {{ $t('Summary By Type') }}
      </span>
    </div>
    <div v-if="get(grandTotals, billingTypesCostPath)" class="flex space-x-10 items-center">
      <span class="w-20">{{ $t('Cost:') }}</span>
      <div
        v-for="(value, key) in get(grandTotals, billingTypesCostPath)"
        :key="key"
        class="w-[120px] flex justify-between"
      >
        <span class="text-xs mr-2 text-gray-500">{{ getServiceBillingType(costOrIncomeTypes.Cost, key)?.abbr }}</span>
        <span>{{ $formatPrice(value?.service_billing_type?.total || value) }}</span>
      </div>
    </div>
    <div v-if="get(grandTotals, billingTypesIncomePath)" class="flex space-x-10 items-center">
      <span class="w-20">{{ $t('Income:') }}</span>
      <div
        v-for="(value, key) in get(grandTotals, billingTypesIncomePath)"
        :key="key"
        class="w-[120px] flex justify-between"
      >
        <span class="text-xs mr-2 text-gray-500">
          {{ getServiceBillingType(costOrIncomeTypes.Income, key)?.abbr }}</span>
        <span>{{ $formatPrice(value?.service_billing_type?.total || value) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { costOrIncomeTypes } from "@/components/grid-table/utils/cost-center";

export default {
  props: {
    grandTotals: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    billingTypesCostPath: {
      type: String,
      default: 'cost.service_billing_types'
    },
    billingTypesIncomePath: {
      type: String,
      default: 'income.service_billing_types'
    }

  },
  data() {
    return {
      costOrIncomeTypes,
    }
  },
  computed: {
    serviceBillingTypes() {
      return this.$store.getters['globalLists/getResourceList'](this.$globalResources.ServiceBillingTypes) || []
    },
  },
  methods: {
    getServiceBillingType(type, index) {
      return this.serviceBillingTypes?.find(item => item.type === type && item.index === +index) || {}
    },
  }
}
</script>
