<template>
  <BaseFormDialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Delete Line Item')"
    :append-to-body="true"
    size="sm"
  >
    <BaseForm
      :saveText="$t('Confirm')"
      :showCancel="true"
      :loading="loading"
      :submitVariant="$promptType.Danger"
      :submitDisabled="!canDelete"
      layout="modal"
      @submit="onDeleteConfirmed"
      @cancel="$emit('close')"
    >
      <div class="col-span-6 text-gray-800 text-sm">
        <p>{{ alertMessage }}</p>
        <p v-if="canDelete">{{ $t('Are you sure you want to delete this Line Item?') }}</p>
      </div>
      <div
        v-if="hasUsages"
        class="col-span-6 text-gray-800 text-sm"
      >
        <p>{{ $t('This Line Item is used in the following places and cannot be deleted.') }}</p>
        <p>{{ $t(`To allow deletion you must first remove it from all places where it's used.`)  }}</p>
        <BaseButton
          class="mt-2"
          variant="white"
          :loading="checkingCanDelete"
          @click="checkCanDelete"
          size="xs"
        >
          {{ $t('Check Again') }}
        </BaseButton>
        <div class="mt-2 space-y-1">
          <div
            v-for="usage in lineItemUsages"
            :key="usage.id"
            class="flex items-center space-x-2"
          >
            <span>{{ getUsageDescription(usage) }}</span>
            <a
              :href="getUsagePath(usage)"
              target="_blank"
              class="text-blue-500"
            >
              {{ $t('View') }}
            </a>
          </div>
        </div>
      </div>
    </BaseForm>
  </BaseFormDialog>
</template>
<script lang="ts">
import axios from 'axios'
import { billingAbbrToType } from "@/modules/accounts-receivable/pages/billings/billings";

enum LineItemUsedOnTypes {
  JobCostingInit = 'job-costing-init',
  Billing = 'billing',
  Invoice = 'invoice',
  Payroll = 'payroll',
  PurchaseOrder = 'purchase-order',
  RecurringInvoice = 'recurring-invoice',
  Task = 'task',
  Timecard = 'timecard',
  TimesheetEntry = 'timesheet-entry',
}

type LineItemUsage = {
  id: string
  type: LineItemUsedOnTypes
  description: string
  billing_type?: string
  batch_id?: string
}

export default {
  props: {
    lineItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkingCanDelete: false,
      lineItemUsages: [] as LineItemUsage[],
      loading: false
    }
  },
  computed: {
    alertMessage() {
      if (this.checkingCanDelete) {
        return this.$t('Checking if Line Item can be deleted...')
      }

      return ''
    },
    hasUsages() {
      return this.lineItemUsages.length
    },
    canDelete() {
      if (this.checkingCanDelete) {
        return false
      }

      return !this.hasUsages
    },
  },
  methods: {
    async checkCanDelete() {
      try {
        this.checkingCanDelete = true
        this.lineItemUsages = []
        const { data } = await axios.post(`/restify/line-items/${this.lineItem.id}/actions?action=delete-line-item-preflight`)

        this.lineItemUsages = data

      } finally {
        this.checkingCanDelete = false
      }
    },
    async onDeleteConfirmed() {
      try {
        this.loading = true
        await axios.delete(`/restify/line-items/${this.lineItem.id}`)
        this.$emit('deleted', this.lineItem)
      } finally {
        this.loading = false
      }
    },
    getUsagePath(usage: LineItemUsage) {
      switch (usage.type) {
        case LineItemUsedOnTypes.JobCostingInit:
          return `/job-costing/settings/job-cost-init/${usage.id}/edit`
        case LineItemUsedOnTypes.Billing:
          // @ts-ignore
          const type = billingAbbrToType[usage.billing_type]
          return `/accounts-receivable/billings/${type}/${usage.id}/view`
        case LineItemUsedOnTypes.Invoice:
          return `/accounts-payable/invoices/${usage.id}/view`
        case LineItemUsedOnTypes.RecurringInvoice:
          return `/accounts-payable/recurring-invoices/${usage.id}`
        case LineItemUsedOnTypes.Payroll:
          return `/payroll/batches/${usage.batch_id}/summary`
        case LineItemUsedOnTypes.Task:
          return `/payroll/timesheets/tasks?id=${usage.id}`
        case LineItemUsedOnTypes.Timecard:
          return `/payroll/timecard-batches/${usage.batch_id}/timecards/${usage.id}/edit`
        case LineItemUsedOnTypes.TimesheetEntry:
          return `/payroll/timesheets/list?timesheetEntryId=${usage.id}&view_type=edit`
        case LineItemUsedOnTypes.PurchaseOrder:
          return `/purchasing-inventory/purchase-orders/${usage.id}/view`
        default:
          return ''
      }
    },
    getUsageDescription(usage: LineItemUsage) {
      switch (usage.type) {
        case LineItemUsedOnTypes.JobCostingInit:
          return this.$t('Job Costs Init') + ` - ${usage.description}`
        case LineItemUsedOnTypes.Billing:
          return this.$t('Billing') + ` - #${usage.description}`
        case LineItemUsedOnTypes.Invoice:
          return this.$t('Invoice') + ` - #${usage.description}`
        case LineItemUsedOnTypes.Payroll:
          return this.$t('Payroll Register') + ` - ${usage.description}`
        case LineItemUsedOnTypes.PurchaseOrder:
          return this.$t('Purchase Order') + ` - #${usage.description}`
        case LineItemUsedOnTypes.RecurringInvoice:
          return this.$t('Recurring Payable') + ` - ${usage.description || ''}`
        case LineItemUsedOnTypes.Task:
          return this.$t('Task') + ` - ${usage.description}`
        case LineItemUsedOnTypes.Timecard:
          return this.$t('Timecard') + ` - #${usage.description}`
        case LineItemUsedOnTypes.TimesheetEntry:
          return this.$t('Timesheet Entry') + ` - ${usage.description}`
        default:
          return ''
      }
    }
  },
  mounted() {
    this.checkCanDelete()
  }
}
</script>
