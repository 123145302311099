<template>
  <div class="h-full flex flex-col">
    <div v-if="currentResourceLoading" class="flex flex-col space-y-2 w-full mt-2 mb-4">
      <span class="placeholder-loading-animation w-1/3 rounded-sm bg-gray-200 h-6"/>
      <span class="placeholder-loading-animation w-1/3 rounded-sm bg-gray-200 h-4"/>
    </div>
    <portal to="page-title">
      <div v-if="!currentResourceLoading" class="flex items-center justify-between md:justify-start">
        <span class="truncate">
          {{ $t('Timecard Batch') }}
          {{ $formatDateOnly(currentResource.period_end_date) }}
          <template v-if="currentResource.description">
            ({{ currentResource.description }})
          </template>
        </span>
        <div v-if="!currentResourceLoading"
             class="md:flex md:items-center flex-1 w-full md:justify-between md:space-x-5 print:hidden hide-breadcrumb">
          <div class="flex items-center space-x-2 ml-2">
            <span v-if="currentResource.status"
                  class="flex items-center">
                <status-badge :status="currentResource.status"/>
            </span>
            <TimecardBatchStats :batch="currentResource" class="font-medium text-sm"/>
            <div class="text-sm text-gray-700 font-medium">{{ $t('Last updated: ') }}
              {{ format(currentResource.updated_at, 'datetime') }}
            </div>
          </div>
          <div class="flex items-center space-x-3">
            <base-button
              v-if="currentResource.payroll_batch_id"
              :loading="loadingPayrollRegister"
              variant="primary-light"
              @click="goToPayroll">
              <CreditCardIcon class="w-4 h-4 mr-1"/>
              {{ $t('View Payroll Register') }}
            </base-button>
            <TimecardBatchExport/>
            <ComputePayroll
              v-if="$isAuthorized('authorizedToCompute', currentResource)"
              @on-compute="tryComputePayroll"
              @computed-remaining="goToPayroll"
            >
              <base-alert :type="$promptType.Primary"
                          class="col-span-1"
              >
                {{ getPromptMessage }}
              </base-alert>
            </ComputePayroll>
          </div>
        </div>
      </div>
    </portal>
    <base-dialog
      v-if="showComputeErrorDialog"
      :visible.sync="showComputeErrorDialog"
      :append-to-body="true"
    >
      <template #title>
        <div class="flex items-center justify-center space-x-2 mb-2">
          <AlertTriangleIcon class="w-6 h-6 text-red-500"/>
          <h3>{{ $t('Could not compute the payroll') }}</h3>
        </div>
      </template>
      <div>
        <div v-if="Object.values(timeCardSettingErrors).length">
          <div class="font-medium text-base mb-4 text-gray-700">{{ $t('We found the following settings errors') }}</div>
        </div>
        <div v-for="(errors, module) in timeCardSettingErrors"
             :key="module"
             class="border-b border-gray-200 pb-2"
        >
          <div class="flex justify-between">
            <span class="font-medium capitalize text-base">{{ startCase(module) }}</span>
            <BaseButton
              v-if="getModuleSettingsPath(module)"
              @click="$router.push(getModuleSettingsPath(module))"
              size="sm"
              variant="primary-light"
            >
              {{ $t('Go to settings') }}
            </BaseButton>
          </div>
          <div
            v-for="(error, index) in errors"
            :key="index"
            class="text-red-600 text-base"
          >
          <span class="font-medium">
            {{ error }}
          </span>
          </div>
        </div>
        <div class="mt-8" v-if="timeCardErrors.length">
          <div class="font-medium text-base mb-4 text-gray-700">{{ $t('We found the following timecard errors') }}</div>
        </div>
        <div
          v-for="(error, index) in timeCardErrors"
          :key="index"
          class="text-gray-700 text-base"
        >
          <div class="flex justify-between">
            <div>
              <template v-if="error.employee">
                <span class="font-semibold">{{ error.employee?.code }}</span>
                <span>({{ error.employee?.name }}) </span>
              </template>
              <span> #{{ error.timecard.number }}</span>
            </div>
            <div>
              <TableEditButton @click="goToTimecard(error.timecard)"></TableEditButton>
            </div>
          </div>
          <div class="mb-2">
            <div v-for="error in error.errors" :key="error">
              <div class="font-medium">
                {{ error }}
              </div>
            </div>
          </div>
          <div v-for="entry in error.entries" :key="entry.id">
            <div class="ml-4 font-medium">{{ getEntryDate(entry.entry) }}</div>
            <div v-for="message in entry.errors" :key="message" class="ml-8">
              {{ message }}
            </div>
          </div>
        </div>
      </div>
    </base-dialog>
    <layout-tabs :items="filteredItems" class="-mt-2"/>
    <div class="flex-1">
      <router-view/>
    </div>
  </div>
</template>
<script>
  import { CreditCardIcon } from 'vue-feather-icons'
  import layoutPermissions from '@/mixins/layoutPermissions'
  import TimecardBatchStats from "@/modules/payroll/components/timecard/TimecardBatchStats";
  import ComputePayroll from "@/modules/payroll/pages/timecard/ComputePayroll";
  import axios from "axios";
  import { resourceStatuses } from "@/enum/enums";
  import { getTimeCardBatchDays } from "@/modules/payroll/utils/timeCardUtils";
  import BaseDialog from "@/components/common/BaseDialog.vue";
  import { AlertTriangleIcon } from "vue-feather-icons";
  import parseISO from "date-fns/parseISO";
  import format from "date-fns/format";
  import startCase from "lodash/startCase";
  import TimecardBatchExport from "@/modules/payroll/components/timecard/TimecardBatchExport.vue";

  export default {
    components: {
      TimecardBatchExport,
      BaseDialog,
      AlertTriangleIcon,
      CreditCardIcon,
      ComputePayroll,
      TimecardBatchStats,
    },
    mixins: [layoutPermissions],
    data() {
      return {
        loadingPayrollRegister: false,
        timeCardErrors: [],
        timeCardSettingErrors: {},
        showComputeErrorDialog: false,
      }
    },
    computed: {
      items() {
        return [
          {
            name: this.$t('Employees'),
            tooltip: this.$t('Timecard batch grouped by employee'),
            path: `/payroll/timecard-batches/${this.$route.params.id}/view`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Sources'),
            tooltip: this.$t('Timecard batch grouped by source'),
            path: `/payroll/timecard-batches/${this.$route.params.id}/sources`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Proof Listing'),
            tooltip: this.$t('Timecard batch proof listing'),
            path: `/payroll/timecard-batches/${this.$route.params.id}/proof-listing`,
            authorizedTo: 'authorizedToShow',
          },
          {
            name: this.$t('Adjust Entries'),
            tooltip: this.$t('Timecard batch adjust entries'),
            path: `/payroll/timecard-batches/${this.$route.params.id}/adjust-entries`,
            authorizedTo: 'authorizedToShow',
            enabled: () => this.currentResource?.status === resourceStatuses.Posted,
          },
        ]
      },
      currentResourceLoading() {
        return this.$store.state.payroll.timecardBatchLoading
      },
      currentResource() {
        return this.$store.state.payroll.currentTimecardBatch
      },
      getPromptMessage() {
        return this.$t('By doing this, the selected Timecards will be computed and we’ll provide you with an overview of the payroll including taxes and previews of employee checks.')
      },
      currentTimecardBatchEntries() {
        return this.$store.state.payroll.currentTimecardBatchEntries || []
      },
      days() {
        return getTimeCardBatchDays(this.currentResource.period_end_date)
      }
    },
    methods: {
      startCase,
      getModuleSettingsPath(module) {
        const modulePaths = {
          'payroll': '/payroll/settings/payroll-settings',
          'service-billing': '/service-billing/settings/service-billing-settings',
          'accounts-receivable': '/accounts-receivable/settings/accounts-receivable-settings',
          'accounts-payable': '/accounts-payable/settings/ap-settings',
          'job-costing': '/job-costing/settings/job-cost-settings',
          'general-ledger': '/ledger/settings/initialize-system',
          'equipment': '/equipment/settings/equipment-management-settings',
          'inventory': '/purchasing-inventory/settings/purchasing-inventory-settings',
        }
        return modulePaths[module]
      },
      format(date) {
        const dateFormat = this.$store.getters['settings/getByKey']('datetime_format') || 'MM/dd/yyyy HH:mm:ss'
        const dateObject = parseISO(date)
        return format(dateObject, dateFormat)
      },
      async getCurrentResource(id) {
        await this.$store.dispatch('payroll/getTimecardBatch', id)
      },
      async tryComputePayroll(model, callback) {
        try {
          const batchId = this.$route.params.id
          const payload = {
            batch_id: batchId,
            ...model,
          }
          await this.checkErrorsBeforeCompute()
          await axios.post('/restify/timecards/actions?action=compute-timecard-batch', payload)
          await this.$store.dispatch('payroll/getTimecardBatch', batchId)
          setTimeout(() => {
            this.goToPayroll();
          }, 500)
        } catch (err) {
          if (err.handled) {
            return
          }
          console.warn(err)
          this.$error(this.$t('Could not compute Payroll.'))
        } finally {
          callback()
        }
      },
      async goToPayroll() {
        try {
          const payrollBatchId = this.currentResource.payroll_batch_id
          if (payrollBatchId) {
            await this.$router.push(`/payroll/batches/${payrollBatchId}`)
          } else {
            this.$error(this.$t('Could not find a matching payroll register for the timecard batch.'))
          }
        } finally {
          this.loadingPayrollRegister = false
        }
      },
      async checkErrorsBeforeCompute() {
        try {
          const batchId = this.$route.params.id
          await axios.post(`/restify/timecard-batches/${batchId}/actions?action=timecards-preflight`)
        } catch (err) {
          await this.parsePreFlightErrors(err)
          throw err
        }
      },
      async parsePreFlightErrors(error) {
        try {
          const response = error?.response?.data?.data
          this.timeCardErrors = response?.timecards || []
          this.timeCardSettingErrors = response?.settings || {}
          this.showComputeErrorDialog = true
        } catch (err) {
          console.error('Could not parse preflight errors', err)
        }
      },
      getEntryDate(entry) {
        return this.days.find(day => day.value.startsWith(entry.date))?.label
      },
      async goToTimecard(timecard) {
        const batchId = this.$route.params.id
        this.showComputeErrorDialog = false
        await this.$router.push(`/payroll/timecard-batches/${batchId}/timecards/${timecard.id}/edit`)
      },
    },
  }
</script>
