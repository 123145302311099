<template>
  <BaseCard class="flex flex-col !px-0 border border-gray-200">
    <base-tabs v-model="activeTab"
               :tabs="tabs"
               label-key="label"
               class="px-3 mb-2"
    >
    </base-tabs>
    <template v-if="activeTab === tabTypes.All">
      <div class="px-3">
        <TaskTypeSelect
          v-model="model.cost_center"
          class="col-span-3 md:col-span-2 -mb-2"
          @change="onChangeCostCenter"
        />
        <JobSelectNew
          v-if="model.cost_center === costCenterTypes.Job"
          v-model="model.job_id"
          class="-mt-4"
        />
      </div>
      <div class="-mt-4 px-3">
        <base-input
          v-model="model.search"
          :placeholder="placeholder"
        />
      </div>
      <div class="source-list overflow-scroll px-2 grid grid-cols-3 lg:flex lg:flex-col gap-1.5">
        <div v-if="!filteredItems.length" class="text-gray-500 text-center text-sm">
          {{ noDataText }}
        </div>
        <DraggableTask
          v-for="item in filteredItems"
          :key="item.id"
          :item="item"
          :job-id="model.job_id"
          :cost-center="model.cost_center"
        />
      </div>
    </template>
    <template v-if="activeTab === tabTypes.Recent">
      <RecentTasks/>
    </template>
  </BaseCard>
</template>
<script>
import orderBy from "lodash/orderBy";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import { getEventColor } from "@/modules/payroll/components/tasks/taskUtils";
import TaskTypeSelect from "@/modules/payroll/components/tasks/TaskTypeSelect.vue";
import { costTypes } from "@/enum/enums";
import LunchIcon from "@/modules/payroll/components/timesheets/LunchIcon.vue";
import BreakIcon from "@/modules/payroll/components/timesheets/BreakIcon.vue";
import i18n from "@/i18n";
import DraggableTask from "@/modules/payroll/components/tasks/DraggableTask.vue";
import RecentTasks from "@/modules/payroll/components/tasks/RecentTasks.vue";
import JobSelectNew from "@/components/select/entities/JobSelectNew.vue";
const tabTypes = {
  All: 'all',
  Recent: 'recent',
}
export default {
  components: {
    BreakIcon,
    LunchIcon,
    TaskTypeSelect,
    DraggableTask,
    JobSelectNew,
    RecentTasks
  },
  data() {
    return {
      costCenterTypes,
      tabTypes,
      activeTab: tabTypes.All,
      model: {
        cost_center: costCenterTypes.GeneralAndAdministrative,
        job_id: null,
        search: '',
      },
    }
  },
  computed: {
    tabs() {
      return [
        {
          name: tabTypes.All,
          label: this.$t('All'),
        },
        {
          name: tabTypes.Recent,
          label: this.$t('Recent'),
        },
      ]
    },
    entityName() {
      const map = {
        [costCenterTypes.Job]: 'Line Items',
        [costCenterTypes.Equipment]: 'Equipment',
        [costCenterTypes.WorkOrder]: 'Work Orders',
        [costCenterTypes.GeneralAndAdministrative]: 'G&A Items',
      }
      return map[this.model.cost_center] || 'Items'
    },
    placeholder() {
      return `Search ${this.entityName}...`
    },
    noDataText() {
      if (this.model.cost_center === costCenterTypes.Job && !this.model.job_id) {
        return `Select a job to view ${this.entityName}`
      }
      return `No ${this.entityName} found`
    },
    gaTasks() {
      return [
        {
          title: this.$t('General & Administrative'),
          cost_center: costCenterTypes.GeneralAndAdministrative
        },
        {
          title: this.$t('Lunch'),
          is_lunch: true,
          cost_center: costCenterTypes.GeneralAndAdministrative
        },
        {
          title: this.$t('Break'),
          is_break: true,
          cost_center: costCenterTypes.GeneralAndAdministrative
        },
      ]
    },
    allEquipment() {
      const equipment = this.$store.getters['globalLists/getResourceList'](this.$globalResources.Equipments) || []
      return orderBy(equipment, 'name')
    },
    allWorkOrders() {
      const equipment = this.$store.getters['globalLists/getResourceList'](this.$globalResources.WorkOrders) || []
      return orderBy(equipment, 'name')
    },
    lineItems() {
      const lineItems = this.$store.getters['globalLists/getResourceList'](this.$globalResources.LineItems) || []
      return lineItems.filter(item => item.type === costTypes.Cost)
    },
    items() {
      const cost_center = this.model.cost_center
      if (cost_center === costCenterTypes.Job && this.model.job_id) {
        return this.lineItems.filter(item => item.job_id === this.model.job_id)
      } else if (cost_center === costCenterTypes.Equipment) {
        return this.allEquipment
      } else if (cost_center === costCenterTypes.WorkOrder) {
        return orderBy(this.allWorkOrders, ['date'], ['desc'])
      } else if (cost_center === costCenterTypes.GeneralAndAdministrative) {
        return this.gaTasks
      }
      return []
    },
    color() {
      return getEventColor(this.model.cost_center)
    },
    filteredItems() {
      if (!this.model.search) {
        return this.items
      }
      return this.items.filter(equipment => {
        const searchableFields = [
          'name',
          'code',
          'number',
          'phase_code',
          'cost_code',
          'change_order',
          'serial_number',
          'model_number',
          'manufacturer',
          'description',
        ]
        return searchableFields.some(field => {
          const value = equipment[field]
          if (!value) {
            return false
          }
          return value?.toString().toLowerCase().includes(this.model.search.toLowerCase())
        })
      })
    },
  },
  methods: {
    onChangeCostCenter() {
      this.model.job_id = null
    }
  },
  watch: {
    items() {
      this.$emit('list-change')
    }
  }
}
</script>
<style lang="scss">
.source-list {
  height: 200px;
  @screen lg {
    height: max(60vh, 500px);
  }
}
</style>
