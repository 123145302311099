<template>
  <base-form :layout="layout"
             :loading="loading"
             :show-cancel="showCancel"
             :show-back="showBack"
             :can-create-another-entity="!model.id"
             :save-text="$t('Create vendor')"
             :update-text="$t('Update vendor')"
             grid-classes="grid grid-cols-8 gap-x-4"
             @cancel="onCancel"
             @submit="onSubmit"
  >
    <base-input v-model="model.name"
                :label="$t('Name')"
                :placeholder="$t('Name')"
                id="name"
                class="col-span-8 md:col-span-4 lg:col-span-2"
                rules="required|max:100"
                @blur="onNameBlur"
    />
    <code-input
      v-model="model.code"
      :model="model"
      :resource-name="globalResources.Vendors"
      class="col-span-8 md:col-span-4 xl:col-span-1"
    />
    <base-input v-model="model.email"
                :label="$t('Email')"
                type="email"
                id="email"
                :placeholder="$t('Email')"
                class="col-span-8 md:col-span-4 lg:col-span-2"
    />
    <vendor-status-select
      v-model="model.status"
      class="col-span-8 md:col-span-2 xl:col-span-1"
    />
    <div class="col-span-8"/>
    <base-phone-input v-model="model.phone"
                      id="phone"
                      class="col-span-8 md:col-span-4 lg:col-span-1"
    />
    <base-phone-input v-model="model.fax"
                      :label="$t('Fax')"
                      id="fax"
                      class="col-span-8 md:col-span-4 lg:col-span-1"
    />
    <base-input v-model="model.website_url"
                :name="$t('Website URL')"
                :label="$t('Website URL')"
                :placeholder="$t('Enter website url...')"
                id="website_url"
                class="col-span-8 md:col-span-4 lg:col-span-2"
                rules="url"
    >
      <template v-slot:suffix>
        <ExternalLinkIcon class="w-4 h-4"/>
      </template>
    </base-input>
    <base-input v-model="model.contact"
                :label="$t('Contact')"
                id="contact"
                rules="max:200"
                :placeholder="$t('Contact')"
                class="col-span-8 md:col-span-4 lg:col-span-2"
    />
    <div class="col-span-8"/>
    <account-select v-model="model.account"
                    :label="$t('Normal G/L Account')"
                    :placeholder="$t('Account')"
                    value-key="number"
                    class="col-span-8 md:col-span-2"
                    clearable
    />
    <sub-account-select v-model="model.subaccount"
                        :label="$t('Sub Account')"
                        :placeholder="$t('Sub Account')"
                        value-key="number"
                        class="col-span-8 md:col-span-2 lg:col-span-1"
                        clearable
    />
    <cost-center-defaults
        v-model="model"
        :can-be-applied-to-inventory="true"
        class="col-span-8"
    />

    <div class="col-span-8">
      <el-collapse v-model="activeGroups">
        <base-collapse-section :title="$t('Addresses & Contacts')"
                               key="addresses_and_contacts"
                               name="addresses_and_contacts"
        >
          <div class="grid grid-cols-2 gap-x-4">
            <AddressList
                :data="model.addresses"
                :entity-id="model.id"
                :resource="model"
                ref="addressList"
                entity="vendors"
                class="col-span-2 md:col-span-1"
                @refresh="$emit('refresh')"
            />
            <ContactList
                :contacts="model.contacts"
                :entity-id="model.id"
                ref="contactList"
                entity="vendors"
                class="col-span-2 md:col-span-1"
                @refresh="$emit('refresh')"
            />
          </div>
        </base-collapse-section>
        <base-collapse-section key="additional_information"
                               :title="$t('Additional Information')"
                               name="additional_information">
          <div class="grid grid-cols-8 gap-4">
            <base-select v-model="model.w9_on_file"
                         :label="$t('W-9 on File')"
                         :options="w9FileOptions"
                         id="w9_on_file"
                         :placeholder="$t('W-9 on File')"
                         class="col-span-8 md:col-span-4 xl:col-span-2"
            />
            <base-input v-model="model.check_memo"
                        :label="$t('Check Memo')"
                        id="check_memo"
                        rules="max:21"
                        :placeholder="$t('Check Memo')"
                        class="col-span-8 md:col-span-4 xl:col-span-2"
            />
          </div>
        </base-collapse-section>
        <base-collapse-section key="dir_fields"
                               :title="$t('DIR Fields')"
                               name="dir_fields">
          <div class="grid grid-cols-8 gap-4">
            <base-input v-model="model.dir"
                        :label="$t('DIR Number')"
                        id="dir"
                        rules="max:10"
                        :placeholder="$t('Dir Number')"
                        class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <base-date-picker v-model="model.dir_expiration_date"
                              :label="$t('Expiration Date')"
                              id="dir_expiration_date"
                              :placeholder="$t('Expiration Date')"
                              class="col-span-8 md:col-span-2"
            />
            <div class="col-span-8 md:col-span-2">
              <base-checkbox v-model="model.csp"
                             :label="$t('CSP')"
                             id="csp"
                             vertical
              />
            </div>
          </div>
        </base-collapse-section>
        <base-collapse-section key="print_1099"
                               :title="$t('Print 1099')"
                               name="print_1099">
          <template #title>
            <div class="flex justify-between w-full items-center">
              <div>{{ $t('Print 1099') }}</div>
              <base-switch class="mr-2 mb-0"
                           v-model="model.print_1099"
                           @click.native.stop
              />
            </div>
          </template>
          <template v-if="model.print_1099">
            <div class="grid grid-cols-8 gap-4">
              <BaseSelect
                v-model="model.form_1099_type"
                :options="vendor1099FormTypeOptions"
                :label="$t('Form 1099 Type')"
                id="form_1099_type"
                :placeholder="$t('Form 1099 Type')"
                class="col-span-8 md:col-span-2"
              />
              <base-input v-model="model.federal_tax_id"
                          :label="$t('Fed Tax ID')"
                          :placeholder="$t('Fed Tax ID')"
                          id="federal_tax_id"
                          rules="max:12"
                          class="col-span-8 md:col-span-2"
              />
              <base-input v-model="model.state_tax_id"
                          :label="$t('State Tax ID')"
                          id="state_tax_id"
                          rules="max:11"
                          :placeholder="$t('State Tax ID')"
                          class="col-span-8 md:col-span-2"
              />
              <base-input v-model="model.vendor_personal_name"
                          :label="$t('Personal Name')"
                          id="vendor_personal_name"
                          rules="max:35"
                          :placeholder="$t('Personal Name')"
                          class="col-span-8 md:col-span-2"
              />
            </div>
          </template>
        </base-collapse-section>

        <base-collapse-section :title="$t('Expiration Dates & Contractor License')"
                               key="dates_contractor_license"
                               name="dates_contractor_license">
          <div class="flex flex-col md:flex-row">
            <div class="flex flex-col">
              <div class="flex-1">
                <base-input v-model="model.contractor_license.number"
                            :label="$t('Number')"
                            id="contractor_license"
                            :placeholder="$t('Number')"
                            :rules="`max:14|${contractorLicenseRules}`"
                />
              </div>
              <div class="flex-1">
                <base-input v-model="model.contractor_license.class"
                            :label="$t('Class')"
                            id="contractor_license_class"
                            :placeholder="$t('Class')"
                            :rules="`max:3`"
                />
              </div>
              <div class="flex-1">
                <base-date-picker v-model="model.contractor_license.expiration_date"
                                  :label="$t('Expiration Date')"
                                  id="expiration_date"
                                  :placeholder="$t('Expiration Date')"
                                  :rules="contractorLicenseRules"
                />
              </div>
            </div>
            <ExpirationDateList v-if="!localLoading"
                                v-model="model.expiration_dates"
                                class="flex-1 md:ml-6 max-w-3xl"
                                ref="expirationDates"
                                @refresh-expiration-dates="refreshExpirationDates"
            />
          </div>
        </base-collapse-section>

        <base-collapse-section key="normal_terms"
                               :title="$t('Normal Terms')"
                               name="normal_terms">
          <div class="grid grid-cols-8 gap-4">
            <base-select v-model="model.due_date"
                         :label="$t('Due Date')"
                         :options="invoiceDateOptions"
                         id="due_date"
                         :placeholder="$t('Due Date')"
                         class="col-span-8 md:col-span-2"
            />
            <base-input v-model="model.due_date_days"
                        :label="$t('Day')"
                        :placeholder="$t('Day')"
                        :step="1"
                        :min="0"
                        type="number"
                        id="due_date_days"
                        class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <base-select v-model="model.discount_date"
                         :label="$t('Discount Date')"
                         :options="invoiceDateOptions"
                         :placeholder="$t('Discount Date')"
                         :step="1"
                         :min="0"
                         id="discount_date"
                         class="col-span-8 md:col-span-2"
            />
            <base-input v-model="model.discount_date_days"
                        :label="$t('Day')"
                        :placeholder="$t('Day')"
                        :step="1"
                        :min="0"
                        id="discount_date_days"
                        type="number"
                        rules="max_value:30|min_value:0"
                        class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <base-input v-model="model.discount_percent"
                        :name="$t('Discount')"
                        :label="$t('Discount')"
                        :min="0"
                        :step="0.01"
                        id="discount"
                        type="number"
                        format="percent"
                        rules="max_value:100|min_value:0"
                        class="col-span-8 md:col-span-2 xl:col-span-1"
            />
            <div class="col-span-8 md:col-span-4">
              <base-checkbox v-model="model.track_purchases"
                             :label="$t('Track Purchases Subject to Use Tax')"
                             id="track_purchases"
              />
            </div>
          </div>
        </base-collapse-section>
        <base-collapse-section key="payments"
                               :title="$t('Payments')"
                               name="payments">
          <div class="grid grid-cols-8 gap-4">
            <base-input v-model="model.payment_this_year"
                        :name="$t('This Year')"
                        :label="$t('This Year')"
                        id="payment_this_year"
                        :step="0.01"
                        type="number"
                        format="price"
                        rules="max_value:99999999.99|min_value:0"
                        class="col-span-8 md:col-span-2"
            />
            <base-input v-model="model.payment_last_year"
                        :name="$t('Last Year')"
                        :label="$t('Last Year')"
                        id="payment_last_year"
                        :step="0.01"
                        type="number"
                        rules="max_value:99999999.99|min_value:0"
                        format="price"
                        class="col-span-8 md:col-span-2"
            />
            <base-input v-model="model.payment_to_date"
                        :name="$t('To-Date')"
                        :label="$t('To-Date')"
                        id="payment_to_date"
                        :step="0.01"
                        type="number"
                        format="price"
                        rules="max_value:99999999.99"
                        class="col-span-8 md:col-span-2"
            />
          </div>
        </base-collapse-section>
      </el-collapse>
    </div>
  </base-form>
</template>
<script>
  import axios from 'axios'
  import cloneDeep from 'lodash/cloneDeep'
  import { ExternalLinkIcon } from 'vue-feather-icons'
  import ContactList from '@/modules/common/components/contacts/ContactList.vue'
  import AddressList from '@/modules/common/components/AddressList'
  import { generateCodeFromName } from '@/utils/generateCodeFromName'
  import CostCenterDefaults from '@/components/common/CostCenterDefaults'
  import {
    resourceStatuses,
    vendorDaysTerms,
    w9FileOptions,
    vendor1099FormTypeOptions,
    vendor1099FormTypes
  } from '@/enum/enums'
  import { costCenterTypes, setTypeSources } from '@/components/grid-table/utils/cost-center'
  import ExpirationDateList from '@/modules/accounts-payable/components/defined-fields/ExpirationDateList'
  import { globalResources, RestifyResources } from "@/components/form/util";
  import VendorStatusSelect from "@/modules/accounts-payable/components/VendorStatusSelect.vue";

  const COLLAPSE_ITEMS = ['payments']
  const defaultExpirationDates = {
    'general-liability': {
      date: null,
      label: 'General Liability',
      readonly: true,
    },
    'workers-compensation': {
      date: null,
      label: `Worker's Compensation`,
      readonly: true,
    },
  }

  export default {
    components: {
      ContactList,
      AddressList,
      ExternalLinkIcon,
      CostCenterDefaults,
      ExpirationDateList,
      VendorStatusSelect,
    },
    props: {
      redirectToListAfterStore: {
        type: Boolean,
        default: true,
      },
      data: {
        type: Object,
        default: () => ({}),
      },
      showBack: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        w9FileOptions,
        vendor1099FormTypeOptions,
        globalResources,
        localLoading: false,
        activeGroups: [],
        loading: false,
        showCancel: true,
        model: {
          code: '',
          name: '',
          contact: '',
          phone: '',
          fax: '',
          website_url: '',
          check_memo: '',
          federal_tax_id: '',
          state_tax_id: '',
          vendor_personal_name: '',
          email: '',
          dir: '',
          dir_expiration_date: '',
          contractor_license: {
            number: '',
            class: '',
            expiration_date: null,
          },
          class: '',
          w9_on_file: 'not-sent',
          due_date: 'days-from-invoice-date',
          due_date_days: 0,
          discount_date: 'days-from-invoice-date',
          discount_date_days: 0,
          status: resourceStatuses.Active,
          payment_this_year: 0.00,
          payment_last_year: 0.00,
          payment_to_date: 0.00,
          payments_on_hold_amount: 0.00,
          discount_percent: 0.00,
          expiration_date: '',
          workers_compensation: '',
          general_liability: '',
          print_1099: false,
          csp: false,
          new_vendor: true,
          track_purchases: false,
          expiration_dates: defaultExpirationDates,

          cost_center: costCenterTypes.GeneralAndAdministrative,
          account: undefined,
          subaccount: undefined,
          source_id: undefined,
          type_id: undefined,
          addl_source_id: undefined,
          form_1099_type: vendor1099FormTypes.NonEmployeeCompensation,
        },
        invoiceDateOptions: [
          {
            label: this.$t('Days from Invoice Date'),
            value: vendorDaysTerms.DaysFromInvoiceDate,
          },
          {
            label: this.$t('Day of Following Month'),
            value: vendorDaysTerms.DayOfFollowingMonth,
          },
          {
            label: this.$t('Day of Current Month'),
            value: vendorDaysTerms.DayOfCurrentMonth,
          },
          {
            label: this.$t('Day of 2nd Following Month'),
            value: vendorDaysTerms.DayOf2ndFollowingMonth,
          },
        ],
      }
    },
    computed: {
      layout() {
        return this.redirectToListAfterStore ? 'vertical': 'modal'
      },
      contractorLicenseRules() {
        let state = false
        Object.values(this.model.contractor_license).forEach(el => {
          if (el) {
            state = true
          }
        })
        return state ? 'required': ''
      },
    },
    methods: {
      onNameBlur() {
        if (this.data.id) {
          return
        }
        this.model.code = generateCodeFromName(this.model.name)
      },
      onCancel() {
        if (this.redirectToListAfterStore) {
          return this.$router.push('/accounts-payable/vendors')
        }
        this.$emit('cancel')
      },
      async onSubmit() {
        try {
          this.loading = true
          const model = this.cleanupModel(this.model)

          if (model.id) {
            const { data } = await axios.put(`/restify/vendors/${model.id}`, model)
            this.$emit('save', data)
            this.$success(this.$t('Vendor updated'))
            await this.$store.dispatch('accountsPayable/getVendor', data.id)
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Vendors,
              resourceId: data.id,
              isEdit: true,
            })
          } else {
            const { data } = await axios.post('/restify/vendors', model)
            await this.attachAddressesAndContacts(data.id)
            this.$success(this.$t('Vendor created'))

            if (this.$createAnotherEntity && this.redirectToListAfterStore) {
              return this.$emit('create-another')
            }
            if (this.redirectToListAfterStore) {
              await this.$router.push('/accounts-payable/vendors')
            }
            this.$emit('save', data)
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Vendors,
              resourceId: data.id,
            })
          }
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }
          this.$error(this.$t('Something went wrong. Please try again.'))
        } finally {
          this.loading = false
        }
      },
      cleanupModel(model) {
        let data = cloneDeep(model)

        for (let key in data) {
          if (data[key]===null) {
            delete data[key]
          }
        }

        return setTypeSources(data)
      },
      async attachAddressesAndContacts(vendorID) {
        await this.$refs.addressList.attachTemporaryAddresses(vendorID)
        await this.$refs.contactList.attachTemporaryContacts(vendorID)
      },
      refreshExpirationDates() {
        this.localLoading = true
        this.$nextTick(() => {
          this.localLoading = false
        })
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          this.model = {
            ...this.model,
            ...value,
          }
          if (Array.isArray(value.contractor_license) && value.contractor_license?.length === 0 || !value.contractor_license) {
            this.model.contractor_license = {
              number: '',
              class: '',
              expiration_date: null,
            }
          }
          if (Array.isArray(value.expiration_dates) && value.expiration_dates?.length === 0) {
            this.model.expiration_dates = {
              ...defaultExpirationDates,
            }
          }
          if (!value?.id) {
            this.activeGroups = COLLAPSE_ITEMS
            this.model.print_1099 = true
            return
          }
        },
      },
    },
  }
</script>
