<template>
  <div class="flex items-center">
    <void-button @click="showDialog = true"
                 :loading="loading"
                 v-bind="$attrs"
    >
      {{ $t('Void') }}
    </void-button>
    <BaseFormDialog v-if="showDialog"
                    :title="getPostActionTitle"
                    :open.sync="showDialog"
                    :appendToBody="true"
    >
      <VoidDialog
        v-if="showDialog"
        v-bind="$attrs"
        :open.sync="showDialog"
        :resource-name="resourceName"
        :resource-id="id"
        :loading="loading"
        @cancel="showDialog = false"
        @submit="onSubmit"
      >
        <template #custom-message>
          <slot name="custom-message"/>
        </template>
      </VoidDialog>
    </BaseFormDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import capitalize from 'lodash/capitalize'
  import VoidDialog from '@/components/common/modal/VoidDialog'
  import { refreshGlobalResourcesByModule } from "@/utils/modules";

  export default {
    components: {
      VoidDialog,
    },
    props: {
      entityName: {
        type: String,
        default: 'Invoice',
      },
      resourceName: {
        type: String,
        default: 'invoices',
      },
      actionUrl: {
        type: String,
      },
      id: {
        type: String || Number,
        default: '',
      },
    },
    data() {
      return {
        showDialog: false,
        loading: false,
      }
    },
    computed: {
      getPostActionTitle() {
        return this.$t(`Void ${this.entityName}`)
      },
      getActionName() {
        return `void-${this.resourceName}`
      },
    },
    methods: {
      async onSubmit(data = {}) {
        try {
          this.showDialog = false
          this.loading = true
          const payload = {
            repositories: [this.id],
            ...data,
          }

          let url = `/restify/${this.resourceName}/action?action=${this.getActionName}`
          if (this.actionUrl) {
            url = this.actionUrl
          }

          await axios.post(url, payload)
          await refreshGlobalResourcesByModule(this.$currentModule)
        } catch (e) {
          if (e.handled) {
            return
          }
          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
          this.$emit('on-action-callback', this.id)
        }
      },
    },
  }
</script>
