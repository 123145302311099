<template>
  <div class="flex justify-between items-center max-w-full group">
    <div class="flex flex-1 space-x-2 items-start">
      <div class="flex flex-1 flex-col space-y-1 items-start truncate">
        <div
          class="text-gray-800 truncate font-medium whitespace-break-spaces text-left"
        >
          {{ item.code }}
        </div>
        <span class="text-sm text-gray-500 truncate whitespace-break-spaces text-left">
            {{ item.description }}
            <span v-if="item.units" class="font-semibold">
              <template v-if="item.special_rate">
                ( {{ item.units ? $formatPrice(item.special_rate * item.units) : $formatPrice(item.special_rate) }}
              </template>
              <template v-else-if="!item.special_rate">
                ({{ item.units }}
              </template>
              <template v-if="[codeTypes.EQUIPMENT, codeTypes.TIME_OFF].includes(item.type)">
                {{ $t('hours') }}
              </template>
              <template v-else-if="item.um">
                {{ item.um }}
              </template>)
            </span>
            <template v-else-if="item.um && !item.units">
              ({{ item.um }})
            </template>
            <div v-if="codeTypes.TIME_OFF && timeOffData?.length" class="w-[200px] pl-1">
              <div class="flex justify-between">{{$t('Used: ')}} <span>{{timeOffInfo?.status?.current_year_used_hours}} {{$t('hours')}}</span></div>
              <div class="flex justify-between">{{$t('Available: ')}} <span>{{timeOffInfo?.status?.balance}} {{$t('hours')}}</span></div>
              <div class="flex justify-between">{{$t('Requested: ')}} <span>{{item.units}} {{$t('hours')}}</span></div>
              <div v-if="timeOffInfo?.status?.balance" class="flex justify-between">{{$t('Remaining: ')}} <span>{{timeOffInfo?.status?.balance - item.units}} {{$t('hours')}}</span></div>
            </div>
          </span>
      </div>
    </div>
    <div class="hidden group-hover:flex space-x-2">
      <TableEditButton @click="showEditDialog = true"/>
      <TableDeleteButton @click="onDelete"/>
    </div>
    <BaseFormDialog
      v-if="showEditDialog"
      :open.sync="showEditDialog"
      :title="title"
    >
      <BaseForm @submit="onUpdate">
        <BaseInput
          v-if="!usesDollarAmount"
          :label="[codeTypes.EQUIPMENT, codeTypes.TIME_OFF].includes(item.type) ? $t('Hours') : item.um || $t('Units')"
          v-model="model.units"
          type="number"
          class="col-span-6 md:col-span-3"
          step="0.01"
          :rules="validationRules"
        >
          <template #suffix>
            <template v-if="[codeTypes.EQUIPMENT, codeTypes.TIME_OFF].includes(item.type)">
              {{ $t('hours') }}
            </template>
            <template v-else>
              {{ item.um }}
            </template>
          </template>
        </BaseInput>
        <BaseInput
          v-else
          v-model="model.special_rate"
          :label="$t('Amount')"
          :placeholder="$t('Enter amount')"
          type="number"
          rules="min_value:0"
          class="col-span-6 md:col-span-3"
          step="0.01"
        >
          <template #suffix>
            $
          </template>
        </BaseInput>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import { codeTypes } from "@/modules/payroll/components/rates/util";
import { getSpecialSourceDurationValidation } from "@/modules/payroll/utils/timeOffUtils";

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    },
    duration: {
      type: Number,
      default: 0,
    },
    timeOffData: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      codeTypes,
      showEditDialog: false,
      model: {
        units: this.item.units,
        special_rate: this.item.special_rate,
      }
    }
  },
  computed: {
    usesDollarAmount() {
      return this.item?.uses_amount_in_timesheets
    },
    timeOffInfo() {
      return this.timeOffData?.find((item) => item?.policy?.id === this.item.id)
    },
    title() {
      const type = this.item.type
      const resourceMap = {
        [codeTypes.EQUIPMENT]: this.$t('Equipment'),
        [codeTypes.MATERIAL]: this.$t('Material'),
        [codeTypes.PAY]: this.$t('Pay Code'),
        [codeTypes.TIME_OFF]: this.$t('Time Off'),
      }
      return `${this.$t('Edit')} ${resourceMap[type]}`
    },
    validationRules() {
      return getSpecialSourceDurationValidation({
        type: this.item.type,
        duration: this.duration,
        timeOffData: this.timeOffInfo
      })
    }
  },
  methods: {
    async onDelete() {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Delete row'),
        description: this.$t('Are you sure you want to delete this row?'),
      })
      if (!confirmed) {
        return
      }
      this.$emit('delete')
    },
    onUpdate() {
      const newItem = {
        ...this.item,
        units: this.model.units,
        special_rate: this.model.special_rate,
      }
      this.showEditDialog = false
      this.$emit('update', newItem)
    },
  },
}
</script>
