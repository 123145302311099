<template>
  <ProofListing
    entity="journal"
    url="/restify/journals/actions?action=post-gl-journals-proof-listing"
    post-url="/restify/journals/action?action=post-selected-gl-journals"
    ids-field="journal_ids"
    @data-fetch="data = $event"
    #default="{ loading }"
    :printOptions="printOptions"
  >
    <AgDataTable
      :data="flatData"
      :columns="entryColumns"
      :data-loading="loading"
      :pagination="false"
      :groupDefaultExpanded="-1"
      :groupIncludeFooter="true"
      :suppressAggFuncInHeader="true"
      :groupRowRendererParams="groupRowRendererParams"
      :compact="true"
      :no-borders="true"
      :sortable="false"
      groupDisplayType="groupRows"
      dom-layout="autoHeight"
    >
      <template #period="{row}">
        {{ getPeriodName(row.period) }}
      </template>
      <template #expand-content="{row}">
        <div class="flex flex-col py-2">
          <BaseDataTable
              :no-borders="true"
              :data="row.originalRow.entries"
              :columns="entryColumns"
              :has-summary="true"
              :show-pagination="false"
              :showPagination="false"
              :limitMaxHeight="false"
          >
            <template #reverse_next_period="{row}">
              {{ row.reverse_next_period ? $t('Yes') : $t('No') }}
            </template>
            <template #debit_amount_summary>
              {{ $formatPrice(row.originalRow.totals.debit_amount) }}
            </template>
            <template #credit_amount_summary>
              {{ $formatPrice(row.originalRow.totals.credit_amount) }}
            </template>
          </BaseDataTable>
          <ProofListingErrors :errors="row.originalRow.errors"/>
        </div>
      </template>
    </AgDataTable>

    <div class="mt-8 print:break-inside-avoid">
      <h5 class="form-section-title">
        {{ $t('Account Summary') }}
      </h5>
      <ProofListingAccountSummary
        :data="accountSummaryData"
        :loading="loading"
      />
    </div>
  </ProofListing>
</template>
<script>
  import ProofListingAccountSummary from "@/modules/common/components/ProofListingAccountSummary";
  import ProofListing from "@/modules/common/components/proof-listing/ProofListing";
  import ProofListingErrors from "@/modules/common/components/proof-listing/ProofListingErrors";
  import JournalProofGroupRow from "@/modules/ledger/components/journals/JournalProofGroupRow.vue";
  import { gridContext } from "@/components/ag-grid/gridContext";
  import { StimulsoftPrintEntities } from "@/enum/stimulsoft";

  export default {
    components: {
      ProofListing,
      ProofListingErrors,
      JournalProofGroupRow,
      ProofListingAccountSummary,
    },
    data() {
      return {
        loading: false,
        data: {
          journals: [],
          account_summary: [],
        },
      }
    },
    computed: {
      flatData() {
        const entries = []
        this.data.journals.forEach(journal => {
          journal.entries.forEach(entry => {
            journal.reference_date_formatted = journal.reference_date
              ? this.$formatDate(journal.reference_date)
              : ''

            journal.period_name = this.getPeriodName(journal.period)
            entries.push({
              ...entry,
              journal,
            })
          })
        })
        return entries
      },
      groupRowRendererParams() {
        return {
          innerRenderer: 'JournalProofGroupRow',
        }
      },
      entryColumns() {
        return [
          {
            headerName: this.$t('Journal'),
            field: 'journal.id',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: this.$t('Account'),
            field: 'account',
            component: 'AccountLink',
            minWidth: 60,
            maxWidth: 80,
            cellRendererParams: {
              showDescription: false,
            }
          },
          {
            headerName: this.$t('Sub Account'),
            field: 'subaccount',
            minWidth: 60,
            maxWidth: 80,
          },
          {
            headerName: this.$t('Description'),
            field: 'description',
            minWidth: 120,
            maxWidth: gridContext.isPrintMode ? 250 : null,
          },
          {
            headerName: this.$t('Reverse Next Period'),
            field: 'reverse_next_period',
            minWidth: 100,
            maxWidth: 100,
            valueFormatter: params => {
              if (params.node.footer) {
                return this.$t('Totals')
              }
              return params.value ? this.$t('Yes') : this.$t('No')
            }
          },
          {
            headerName: this.$t('Debit Amount'),
            field: 'debit_amount',
            minWidth: 120,
            maxWidth: gridContext.isPrintMode ? 120 : 160,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
          {
            headerName: this.$t('Credit Amount'),
            field: 'credit_amount',
            minWidth: 120,
            maxWidth: gridContext.isPrintMode ? 120 : 160,
            align: 'right',
            component: 'FormattedPrice',
            aggFunc: 'sum',
          },
        ]
      },
      accountSummaryData() {
        return this.data.account_summary.filter(account => {
          return account.debit_amount > 0 || account.credit_amount > 0
        })
      },
      printData() {
        return {
          journals: this.flatData,
          account_summary: this.accountSummaryData,
        }
      },
      printOptions(){
        return {
          stimulsoft: true,
          data: this.printData,
          entity: StimulsoftPrintEntities.GlJournalProofListing,
        }
      }
    },
    methods: {
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      },
    }
  }
</script>
