<template>
  <BaseAlert
    v-if="errorsArray.length"
    :type="$promptType.Danger"
    class="font-normal"
  >
    <div class="font-semibold">{{ $t('Report Ran With Errors') }}</div>
    <div
      class="space-y-4 mt-4"
    >
      <div
        v-for="employeeGroup of errorsArray"
        :key="employeeGroup.employeeId"
      >
        <div class="font-semibold flex space-x-2 text-gray-900">
          <EmployeeLink
            :id="employeeGroup.employeeId"
          />
          <span>-</span>
          <router-link
            :to="`/payroll/adjustments/all/${employeeGroup.employeeId}/edit`"
            class="text-gray-900 underline"
          >
            {{ $t('Click here to adjust') }}
          </router-link>
        </div>
        <ul>
          <li
            v-for="error of employeeGroup.errors"
            :key="error.message"
          >
            {{ error.message }}
          </li>
        </ul>
      </div>
    </div>
  </BaseAlert>
</template>
<script>

export default {
  props: {
    errors: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    errorsArray() {
      if (Array.isArray(this.errors)) {
        return this.errors
      }

      const errors = []

      for (const employeeId in this.errors) {
        errors.push({
          employeeId,
          errors: this.errors[employeeId],
        })
      }

      return errors
    },
  },
}
</script>
