<template>
  <div>
    <portal
        v-if="expandedSections.length === 0 && model.number"
        to="page-title"
    >
      {{ $t('Service Invoice # ') }}{{ model.number }}&nbsp;@
      <router-link :to="`/accounts-receivable/customers/${selectedCustomer.id}/view`">
        {{ selectedCustomer?.attributes?.code }}
        <span v-if="selectedCustomer?.attributes?.name">({{ selectedCustomer.attributes.name }})</span>
      </router-link>
    </portal>
    <base-form
        :loading="loading"
        :save-text="$t('Create service invoice')"
        :update-text="$t('Update service invoice')"
        :can-create-another-entity="!model.id"
        :submit-disabled="gridContext.loading"
        :show-cancel="true"
        layout="vertical"
        grid-classes="grid grid-cols-1 gap-x-4"
        ref="baseForm"
        @submit="onSubmit"
        @cancel="$router.push('/service-billing/service-invoices/pending')"
    >
      <el-collapse v-model="expandedSections"
                   class="col-span-1">
        <base-collapse-section
            key="form-header"
            name="form-header"
            class="-m-6 mb-6"
            has-background
        >
          <template #title>
            <div class="flex flex-1 items-center justify-between form-header-summary">
              <div>
                <status-badge :status="model.status"/>
                <span class="mx-2">
                  {{ $t('Service Invoice') }}
              </span>
              </div>
              <div v-if="!expandedSections.includes('form-header')"
                   class="summary">
                {{ $t('Tax') }}
                <span>
                {{ $formatPercent(model.sales_tax_percent) }}
              </span>
              </div>
            </div>
          </template>
          <div class="grid grid-cols-6 gap-x-4 pt-5 px-6">
            <div class="col-span-4 grid grid-cols-6 gap-x-4">
              <WorkOrderSelect
                v-model="model.work_order_id"
                :add-entity="true"
                :url-params="woUrlParams"
                :disabled="!!$route.params.id"
                :showStatusColumn="false"
                :initial-value="get(data, 'relationships.workOrder') || selectedWorkOrder"
                id="wo-input"
                class="col-span-6 md:col-span-2"
                rules="required"
                @entity-change="onChangeWo"
              />
              <customer-select
                  v-model="model.customer_id"
                  :key="model.work_order_id"
                  :add-entity="false"
                  :initial-value="get(data, 'relationships.customer', selectedCustomer)"
                  class="col-span-6 md:col-span-2"
                  rules="required"
                  @entity-change="onChangeCustomer"
              />

              <InvoiceNumberInput
                v-model="model.number"
                :key="loadingNumber"
                :model="model"
                :loading="loadingNumber"
                class="col-span-6 md:col-span-2"
              />

              <base-select
                  v-model="model.status"
                  :options="statusOptions"
                  :label="$t('Status')"
                  class="col-span-6 md:col-span-1"
                  rules="required"
              />

              <BaseSwitch
                v-model="model.close_wo_after"
                :label-info="$t('Close W.O. After')"
                :disabled="!model.work_order_id"
                class="col-span-6 md:col-span-2"
                vertical
              />
              <template v-if="model.status === resourceStatuses.Prepaid">
                <div class="col-span-8">
                  <h4 class="form-section-title">
                    {{ $t('Check Information') }}
                  </h4>
                </div>
                <BankSelect
                  v-model="model.bank_id"
                  :name="$t('Bank')"
                  :label="$t('Bank')"
                  rules="required"
                  class="col-span-6 md:col-span-2"
                />
                <BaseInput
                  v-model="model.payment_number"
                  :name="$t('Check Number')"
                  :label="$t('Check Number')"
                  :placeholder="$t('Check Number')"
                  rules="required"
                  class="col-span-6 md:col-span-2"
                />
                <base-date-picker
                    v-model="model.payment_date"
                    :label="$t('Check Date')"
                    :placeholder="$t('Check Date')"
                    rules="required"
                    class="col-span-6 md:col-span-2"
                />
              </template>

              <billing-rate-type-select
                  v-model="model.billing_rate_type_id"
                  :label="$t('Billing Rate')"
                  :add-entity="false"
                  class="col-span-6 md:col-span-1"
              />
              <base-textarea
                  v-model="model.description"
                  :label="$t('Description')"
                  :placeholder="$t('Descriptions')"
                  rules="max:500"
                  class="col-span-6"
              />
              <BillingDates
                :model="model"
              />
              <base-date-picker
                  v-model="model.meta.work_date_from"
                  :label="$t('Service Start Date')"
                  :placeholder="$t('Start Date')"
                  class="col-span-6 md:col-span-2"
              />
              <base-date-picker
                  v-model="model.meta.work_date_to"
                  :label="$t('Service End Date')"
                  :placeholder="$t('End Date')"
                  class="col-span-6 md:col-span-2"
              />
              <BillingTax
                :model="model"
                :data="data"
                input-class="col-span-6 md:col-span-2"
                @sales-tax-change="shouldEntriesChange"
              />
            </div>
            <div class="col-span-2">
              <work-order-preview
                  :key="selectedWorkOrder.id"
                  :entity="selectedWorkOrder"
                  class="flex-1 mb-5"
              />
            </div>
          </div>
        </base-collapse-section>
      </el-collapse>
      <ServiceInvoiceEntries
          :invoice="model"
          :customer-billing-rate-type-id="billingRateTypeId"
          :work-order-service-billing-code-ids="workOrderServiceBillingCodeIds"
          class="mt-3"
          ref="gridTable"
          @on-collapse-form-header="collapseFormHeader"
      />
    </base-form>
  </div>
</template>
<script>
  import axios from 'axios'
  import { resourceStatuses } from '@/enum/enums'
  import WorkOrderPreview from '@/modules/common/components/entity-preview/WorkOrderPreview'
  import ServiceInvoiceEntries from '@/modules/service-billing/components/ServiceInvoiceEntries'
  import { billingTypeAbbr } from '@/modules/accounts-receivable/pages/billings/billings'
  import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
  import { entityPreviewFields } from '@/modules/common/components/entity-preview/entities'
  import { RestifyResources } from "@/components/form/util";
  import BillingDates from "@/modules/accounts-receivable/components/billings/BillingDates.vue";
  import BillingTax from "@/modules/accounts-receivable/components/billings/BillingTax.vue";
  import { shouldEntriesChange } from "@/modules/accounts-receivable/utils/billingUtils";
  import InvoiceNumberInput from "@/modules/accounts-payable/components/invoice/InvoiceNumberInput.vue";
  import { useInvoiceSequencing } from '@/modules/accounts-receivable/composables/useInvoiceSequencing'
  import { gridContext } from "@/components/ag-grid/gridContext";

  const meta = {
    district_id: '',
    work_date_from: '',
    work_date_to: '',
    exempt_from_sales_tax: false,
  }

  export default {
    components: {
      InvoiceNumberInput,
      BillingTax,
      BillingDates,
      WorkOrderPreview,
      ServiceInvoiceEntries,
    },
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
      listPath: {
        type: String,
        default: '/service-billing/service-invoices/pending',
      },
    },
    setup() {
      const {
        getNextInvoiceNumber,
        billingTypesAbbr,
        loadingNumber,
      } = useInvoiceSequencing()

      return {
        getNextInvoiceNumber,
        billingTypesAbbr,
        loadingNumber,
      }
    },
    data() {
      return {
        loading: false,
        expandedSections: [],
        resourceStatuses,
        statusOptions: [
          {
            label: this.$t('Pending'),
            value: resourceStatuses.Pending,
          },
          {
            label: this.$t('Prepaid'),
            value: resourceStatuses.Prepaid,
          },
          {
            label: this.$t('No Post'),
            value: resourceStatuses.NoPost,
          },
        ],
        model: {
          work_order_id: '',
          customer_id: '',
          number: '',
          date: this.$now,
          due_date: null,
          discount_date: null,
          billing_rate_type_id: '',
          status: this.$settings(this.$modules.SB, 'default_status') || resourceStatuses.Pending,
          bank_id: '',
          payment_number: '',
          payment_date: '',
          description: '',
          discount_percent: 0,
          gross_amount: 0,
          sales_tax_percent: 0,
          retention_percent: 0,
          type: billingTypeAbbr.Service,
          meta,
          close_wo_after: false,
        },
        gridContext,
        customerBillingRateTypeId: '',
        selectedCustomer: {},
        selectedWorkOrder: {},
        workOrderServiceBillingCodeIds: [],
      }
    },
    computed: {
      woUrlParams() {
        return {
          sort: '-date',
          status: 'open',
          related: `${entityPreviewFields.Customer},serviceBillingCodes[id]`,
        }
      },
      woRelatedUrlParams() {
        return {
          related: `${entityPreviewFields.Customer},serviceBillingCodes[id]`,
        }
      },
      billingRateTypeId() {
        return this.model.billing_rate_type_id || this.customerBillingRateTypeId
      },
    },
    methods: {
      onChangeWo(workOrder) {
        const { attributes } = workOrder
        const { customer, serviceBillingCodes: sbCodes = [] } = workOrder?.relationships || {}

        this.workOrderServiceBillingCodeIds = sbCodes.map(code => code.id)

        this.customerBillingRateTypeId = this.get(customer, 'attributes.billing_rate_type_id')
        this.selectedWorkOrder = workOrder?.attributes || {}
        if (this.model.work_order_id === workOrder.id && this.model.id) {
          return
        }

        this.model.work_order_id = workOrder.id
        this.selectedCustomer = customer

        this.model.work_order_id = attributes.id
        this.model.customer_id = attributes.customer_id
        this.model.billing_rate_type_id = attributes.billing_rate_type_id

        this.getInvoiceNumber()
      },
      async getInvoiceNumber() {
        if (this.model.id) {
          return
        }

        this.model.number = ''

        if (!this.model.work_order_id) {
          return
        }

        const model = {
          type: this.billingTypesAbbr.Service,
          work_order_id: this.model.work_order_id,
          work_order_number: this.selectedWorkOrder?.number,
        }

        this.model.number = await this.getNextInvoiceNumber(model)
      },
      async tryAssignWorkOrder(id) {
        try {
          this.loading = true
          const { data } = await axios.get(`/restify/work-orders/${id}`, { params: this.woRelatedUrlParams })
          this.onChangeWo(data)
        } catch (err) {
          console.warn(err)
        } finally {
          this.loading = false
        }
      },
      onChangeCustomer(customer) {
        this.selectedCustomer = customer
      },
      onChangeDistrict(district) {
        const { sales_tax_percent } = district?.attributes || {}
        this.model.meta.sales_tax_percent = sales_tax_percent ? sales_tax_percent: 0
      },
      async shouldEntriesChange() {
        await shouldEntriesChange(this.$refs.gridTable)
      },
      async getWorkOrder(woId) {
        const { data } = await axios.get(`/restify/work-orders/${woId}`, { params: this.woRelatedUrlParams })
        this.onChangeWo(data)
      },
      async onSubmit() {
        try {
          const isInvalidData = await validateAgDataTable()
          if (isInvalidData) {
            return
          }

          this.loading = true

          const data = {
            ...this.model,
          }
          delete data.gross_amount
          if (this.model.id) {
            await this.$refs.gridTable.storeProgress(this.model.id)
            await axios.patch(`/restify/billings/${this.model.id}`, data)

            this.$success(this.$t('Invoice updated.'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Billings,
              resourceId: this.model.id,
              isEdit: true
            })
          } else {

            const { data } = await axios.post('/restify/billings', this.model)
            await this.$refs.gridTable.storeProgress(data.id)

            this.$success(this.$t('Invoice created.'))
            await this.$addSystemGeneratedNote({
              resourceName: RestifyResources.Billings,
              resourceId: data.id,
            })
            if (this.$createAnotherEntity) {
              return this.$emit('create-another')
            }
            this.$router.push(this.listPath)
          }
        } catch (err) {
          if (err.handled) {
            return
          }
          this.$error(this.$t('Could not store service invoice'))
        } finally {
          this.loading = false
        }
      },
      async collapseFormHeader() {
        if (!this.expandedSections.length) {
          return
        }

        const isValidForm = await this.$refs.baseForm?.validate()

        if (!isValidForm) {
          return
        }

        this.expandedSections = []
      },
    },
    watch: {
      data: {
        immediate: true,
        handler(value) {
          if (!value?.id) {
            return this.expandedSections = ['form-header']
          }

          this.model = {
            ...this.model,
            ...meta,
            ...value.attributes,
          }

          this.getWorkOrder(this.model.work_order_id)
          this.collapseFormHeader()

          const { district, customer } = value.relationships
          this.selectedCustomer = customer
          if (!district) {
            return
          }

          this.onChangeDistrict(district)
        },
      },
      '$route.params': {
        immediate: true,
        handler(params) {
          const { workOrder } = params
          if (!workOrder) {
            return
          }

          this.tryAssignWorkOrder(workOrder)
        },
      },
    },
  }
</script>
