<template>
  <BaseCard class="border">
    <div class="text-sm flex flex-col space-y-4">
      <router-link
          v-if="worksite?.id && showWorksiteName"
          :to="`/service-billing/worksites/${worksite?.id}/view`"
          class="font-medium text-gray-700">
        {{ $t('Worksite') }} #{{ workSiteData.number }} {{ workSiteData.name }}
      </router-link>
      <div
          :title="$t('Contact Name')"
          class="detail-row">
        <UserIcon class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Contact Name') }}</span>
          <div class="text-gray-700 font-medium">
            <template v-if="workSiteData.contact_name">
              {{ workSiteData.contact_name }}
            </template>
            <template v-else>{{ $t('No contact name provided') }}</template>
          </div>
        </div>
      </div>
      <div :title="$t('Phone')"
           class="detail-row">
        <PhoneIcon class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Primary Phone') }}</span>
          <BasePhoneLink :value="workSiteData.worksite_phone"/>
        </div>
      </div>
      <div
          v-if="workSiteData.other_phone"
          :title="$t('Other phone')"
          class="detail-row">
        <PhoneIcon class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Secondary Phone') }}</span>
          <BasePhoneLink :value="workSiteData.other_phone"/>
        </div>
      </div>
      <div
          :title="$t('Fax')"
          class="detail-row">
        <PrinterIcon class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Fax') }}</span>
          <BasePhoneLink :value="workSiteData.fax"/>
        </div>
      </div>
      <div :title="$t('Address')"
           class="detail-row">
        <MapPinIcon class="detail-icon"/>
        <div class="truncate">
          <span class="text-xs text-gray-500">{{ $t('Address') }}</span>
          <BaseAddressLink :value="workSiteData" :is-work-site="true"/>
        </div>
      </div>
      <div :title="$t('Unit')"
           class="detail-row">
        <CornerUpRightIcon class="detail-icon"/>
        <div>
          <span class="text-xs text-gray-500">{{ $t('Unit') }}</span>
          <div class="capitalize text-gray-700 font-medium">
            {{ workSiteData.unit_type }} {{ workSiteData.unit_number }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex space-x-4 border-t border-gray-100 mt-4 -mb-4"
    >
      <PhoneButton :value="workSiteData.worksite_phone"/>
      <AddressButton :value="location"/>
      <div class="flex items-center">
        <NotesButton
            ref="notesButton"
            :params="{
            id: worksite?.id,
            resourceName: RestifyResources.WorkSites,
            title: workSiteData.name,
          }"
        />
        <span
            class="text-sm font-inter leading-normal tracking-wide font-medium text-gray-900 ml-1 cursor-pointer hover:underline"
            @click="$refs.notesButton?.onToggleNotePanel()"
        >
          {{ $t('Notes') }}
        </span>
      </div>
    </div>
  </BaseCard>
</template>
<script lang="ts" setup>
import {computed, PropType} from 'vue'
import {locationLink} from '@/utils/utils'
import {WorkSite} from '@/modules/common/types/models'
import {CornerUpRightIcon, MapPinIcon, PhoneIcon, PrinterIcon, UserIcon} from 'vue-feather-icons'
import PhoneButton from '@/modules/common/components/buttons/PhoneButton.vue'
import AddressButton from '@/modules/common/components/buttons/AddressButton.vue'
import NotesButton from "@/modules/dashboard/components/NotesButton.vue";
import {RestifyResources} from "@/components/form/util";
import Data = API.Data;

const props = defineProps({
  worksite: {
    type: Object as PropType<Data<WorkSite>>,
    default: () => ({}),
    required: true,
  },
  showWorksiteName: {
    type: Boolean,
    default: false,
  }
})

const workSiteData = computed<WorkSite>(() => {
  return props.worksite?.attributes || {}
})

const location = computed(() => {
  return locationLink(workSiteData.value)
})
</script>
<style lang="scss" scoped>
.detail-icon {
  @apply h-4 w-4 text-primary-500 min-w-[16px];
}

.detail-row {
  @apply flex space-x-3 items-center;
}
</style>
