<template>
  <div data-test="layout-tabs" class="flex justify-between layout-tabs-container">
    <nav class="flex items-center overflow-x-auto my-2 layout-tabs print:hidden nav-bar">
      <base-tooltip
          v-for="(route, index) in items"
          :content="route.tooltip || route.name"
          :disabled="!route.tooltip"
          :key="route.name"
      >
        <router-link
            :to="route.path"
            :class="{'ml-4': index !== 0}"
            :aria-current="$route.path === route.path"
            class="menu-item navigation-item nav-item"
        >
          <component
              v-if="route.icon"
              :is="route.icon"
              class="mr-1 w-4 h-4"
          />
          {{ route.name }}
        </router-link>
      </base-tooltip>
    </nav>
    <portal-target name="layout-actions"/>
  </div>
</template>
<script>
  export default {
    props: {
      items: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        selectedOption: this.$route.path,
      }
    },
    computed: {
      selectOptions() {
        return this.items.map(item => {
          return {
            ...item,
            value: item.path,
            label: item.name,
          }
        })
      },
    },
    watch: {
      '$route.path'(value) {
        if (this.selectedOption !== value) {
          this.selectedOption = value
        }
      },
      selectedOption(newVal) {
        if (newVal && newVal !== this.$route.path) {
          this.$router.push(newVal)
        }
      },
    },
  }
</script>
<style scoped>
  .layout-tabs {
    min-height: 36px;
  }
</style>
