<template>
  <div class="h-full">
    <AgDataTable
        ref="table"
        url="/restify/time-off-policies"
        :url-params="urlParams"
        :columns="columns"
        :header-height="40"
        :add-text="$t('New Time Off Policy')"
        actions="search,refresh,add,edit,delete,view"
        @add="onAdd"
    >
    </AgDataTable>
    <TimeOffGenerateDialog
        v-if="showAddDialog"
        :open.sync="showAddDialog"
        @save="onTimeOffPolicyGenerated"
    />
  </div>
</template>
<script>
import { AccrualMethodLabels } from "@/modules/payroll/utils/accrualMethods";
import TimeOffGenerateDialog from "@/modules/payroll/components/time-off/TimeOffGenerateDialog.vue";

export default {
  components: {
    TimeOffGenerateDialog
  },
  data() {
    return {
      showAddDialog: false,
      generateBasedOnState: false,
    }
  },
  computed: {
    urlParams() {
      return {
        related: 'timeOffType',
      }
    },
    columns() {
      return [
        {
          label: this.$t('Description'),
          prop: 'attributes.description',
          maxWidth: 300,
          minWidth: 160,
          component: 'EntityLink',
          redirectTo: '/payroll/codes/time-off/{ID}/view',
        },
        {
          label: this.$t('Type'),
          prop: 'relationships.timeOffType.attributes.name',
          maxWidth: 180,
          minWidth: 160,
        },
        {
          label: this.$t('Account'),
          prop: 'attributes.account',
          maxWidth: 250,
          minWidth: 160,
          component: 'AccountLink',
        },
        {
          label: this.$t('Method'),
          prop: 'attributes.accrual_method',
          maxWidth: 240,
          minWidth: 160,
          valueFormatter: (params) => {
            return AccrualMethodLabels[params.value] || ''
          }
        },
        {
          label: this.$t('W2 Memo Code'),
          prop: 'attributes.w2_code',
          hide: true,
        },
        {
          label: this.$t('Active'),
          prop: 'attributes.active',
          component: 'Status'
        },
      ]
    }
  },
  methods: {
    onAdd() {
      this.showAddDialog = true
    },
    onTimeOffPolicyGenerated() {
      this.showAddDialog = false
      this.$refs.table.refresh()
    }
  }
}
</script>
