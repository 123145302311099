<template>
  <BaseForm
    :loading="loading"
    :show-cancel="showCancel"
    :save-text="$t('Create w2 memo code')"
    :update-text="$t('Update w2 memo code')"
    :can-create-another-entity="!model.id"
    layout="modal"
    grid-classes="grid grid-cols-8 gap-x-4"
    @cancel="$emit('cancel')"
    @submit="onSubmit"
  >
    <BaseInput
      v-model="model.code"
      :label="$t('Code')"
      :placeholder="$t('W2 memo code')"
      rules="required|max:8"
      class="col-span-8 md:col-span-2"
    />
    <BaseSelect
      v-model="model.box_number"
      :options="boxNumberOptions"
      :label="$t('Box Number')"
      :placeholder="$t('Box Number')"
      class="col-span-8 md:col-span-2"
      rules="required"
    />
    <BaseInput
      v-model="model.box_code"
      :label="$t('Box Code')"
      :placeholder="$t('Box Code')"
      class="col-span-8 md:col-span-2"
      rules="max:20"
    />

    <BaseSwitch
      v-model="model.active"
      :label-info="$t('Active')"
      class="col-span-8 md:col-span-2"
    />

    <BaseInput
      v-model="model.start_year"
      :label="$t('Start Year')"
      :placeholder="$t('Start Year (YYYY)')"
      class="col-span-8 md:col-span-2"
      type="number"
      rules="required|min_value:1900|max_value:2100"
    />


    <BaseTextarea
      v-model="model.description"
      :label="$t('Description')"
      :placeholder="$t('Description')"
      rows="1"
      :rules="model.box_number === 14 ? 'max:14' : 'max:150'"
      class="col-span-8 md:col-span-5"
    />

    <BaseSwitch
      v-model="model.is_401k_deferral"
      :label-info="$t('Is 401k Deferral')"
      class="col-span-8 md:col-span-2"
    />

    <BaseSwitch
      v-model="model.is_hire_exempt"
      :label-info="$t('Is Hire Exempt')"
      class="col-span-8 md:col-span-2"
    />
  </BaseForm>
</template>
<script lang="ts">
import axios from 'axios'
import { defineComponent } from 'vue'
import { WTwoCode } from '@/modules/common/types/models'

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showCancel: true,
      loading: false,
      model: {
        code: '',
        description: '',
        box_code: '',
        box_number: null,
        active: true,
        is_401k_deferral: false,
        is_hire_exempt: false,
        start_year: new Date().getFullYear(),
      } as WTwoCode,
    }
  },
  computed: {
    boxNumberOptions() {
      return Array.from({ length: 20 }, (_, i) => ({
        label: i + 1,
        value: i + 1,
      }))
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true
        if (this.model.id) {
          await axios.put(`/restify/w-two-codes/${ this.model.id }`, this.model)
          this.$success(this.$t('W2 memo code updated.'))
          this.$emit('save', this.model)
        } else {
          const {data} = await axios.post('/restify/w-two-codes', this.model)
          this.$success(this.$t('W2 memo code created.'))
          // @ts-ignore
          this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
        }
      } catch (err: any) {
        if (err.handled) {
          return
        }
        console.log('err', err)
        this.$error(this.$t('Could not update the w2 memo code.'))
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    data: {
      immediate: true,
      handler(value) {
        if (!value?.id) {
          return
        }
        this.model = {
          ...this.model,
          ...value.attributes,
        }
      },
    },
  },
})
</script>
