<template>
  <div>
    <base-form
        :loading="loading"
        :show-cancel="showCancel"
        :can-create-another-entity="!model.id"
        :save-text="$t('Create retirement plan')"
        :update-text="$t('Update retirement plan')"
        layout="modal"
        grid-classes="grid grid-cols-8 gap-x-4"
        @cancel="$emit('cancel')"
        @submit="onSubmit"
    >
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <code-input
          v-model="model.code"
          :model="model"
          :resource-name="globalResources.RetirementPlans"
        />
      </div>
      <div class="col-span-8 md:col-span-3">
        <base-input
            v-model="model.description"
            :label="$t('Description')"
            :placeholder="$t('Description')"
            rules="max:150"
        >
        </base-input>
      </div>
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <W2MemoCodeSelect
          v-model="model.w2_code"
          clearable
        />
      </div>
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <base-select
            v-model="model.calculate_by"
            :label="$t('Calculate By')"
            :placeholder="$t('Calculate By')"
            :options="calculateByOptions"
            rules="required"
            @change="onChangeCalculateBy"
        />
      </div>
      <div class="col-span-6 md:col-span-2">
        <ActiveSwitch v-model="model.active"/>
      </div>
      <div class="col-span-8 md:col-span-2">
        <base-select
            v-model="model.multiply_by"
            :key="model.calculate_by"
            :label="$t('Multiply By')"
            :placeholder="$t('Multiply By')"
            :options="getMultiplyByOptions"
            rules="required"
        />
      </div>
      <div class="col-span-8 md:col-span-2">
        <account-select
            v-model="model.account"
            :label="$t('Liability Account')"
            :name="$t('Liability Account')"
            value-key="number"
            label-key="number"
            rules="required"
        />
      </div>
      <div class="col-span-8 md:col-span-2 xl:col-span-1">
        <sub-account-select
            v-model="model.subaccount"
            :label="$t('Liability Subaccount')"
            :name="$t('Liability Subaccount')"
            clearable
            value-key="number"
            label-key="number"
        />
      </div>
      <div class="col-span-8 md:col-span-2">
        <vendor-select
            v-model="model.vendor_id"
            :label="$t('Vendor Payee')"
        />
      </div>
      <div class="col-span-8">
        <h5 class="form-section-title">
          {{ $t('Options') }}
        </h5>
        <base-checkbox
            v-if="model.vendor_id"
            v-model="model.show_in_ytds"
            :label="$t('Show in YTDs')"
            id="show_in_ytds"
        />
        <base-checkbox
            v-model="model.separate_invoice_by_employee"
            :label="$t('Separate Invoice by Employee')"
            id="separate_invoice_by_employee"
        />
        <base-checkbox
            v-model="model.include_on_union_reports"
            :label="$t('Include on Union Report')"
            id="include_on_union_reports"
        />
      </div>
    </base-form>
  </div>
</template>
<script lang="ts">
  import axios from 'axios'
  import { defineComponent } from 'vue'
  import {
    CALCULATE_BY_RATE_KEY,
    calculateByOptions,
    multiplyBy,
    multiplyOptions,
  } from '@/modules/payroll/components/rates/util'
  import { DeductionCode } from '@/modules/common/types/models'
  import {globalResources} from "@/components/form/util";

  export default defineComponent({
    props: {
      data: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        globalResources,
        showCancel: true,
        loading: false,
        model: {
          code: '',
          description: '',
          w2_code: null,
          vendor_id: null,
          calculate_by: CALCULATE_BY_RATE_KEY,
          multiply_by: multiplyBy.REGULAR_HOURS,
          account: '',
          subaccount: '',
          active: true,
          show_in_ytds: false,
          include_on_union_reports: true,
          reduce_gross_pay_by_125s: false,
          separate_invoice_by_employee: false,
          is_loan: false,
          is_roth: false,
        }  as DeductionCode,
        calculateByOptions,
        multiplyOptions,
      }
    },
    computed: {
      getMultiplyByOptions() {
        // @ts-ignore
        return this.multiplyOptions[this.model.calculate_by]
      },
    },
    watch: {
      'data.attributes': {
        immediate: true,
        handler(value) {
          if (!value) {
            return
          }
          this.model = {
            ...this.model,
            ...value,
          }
        },
      },
    },
    methods: {
      async onSubmit() {
        try {
          this.loading = true
          if (this.model.id) {
            await axios.put(`/restify/retirement-plans/${ this.model.id }`, this.model)
            this.$success(this.$t('Retirement plan updated.'))
            this.$emit('save', this.model)
          } else {
            const {data} = await axios.post('/restify/retirement-plans', this.model)
            this.$success(this.$t('Retirement plan created.'))
            // @ts-ignore
            this.$createAnotherEntity ? this.$emit('create-another') : this.$emit('save', data)
          }
        } catch (err: any) {
          if (err.handled) {
            return
          }
          console.log('err', err)
          this.$error(this.$t('Could not update the retirement plan.'))
        } finally {
          this.loading = false
        }
      },
      onChangeCalculateBy(option: string) {
        if (option === CALCULATE_BY_RATE_KEY) {
          this.model.multiply_by = multiplyBy.REGULAR_HOURS
          return
        }
        this.model.multiply_by = multiplyBy.REGULAR_HOURLY_PAY
      },
    },
  })
</script>
