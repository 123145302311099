<template>
  <div class="h-full">
    <AgDataTable
      ref="table"
      :dataLoading="loading"
      url="/restify/time-off-statuses"
      :url-params="urlParams"
      :columns="columns"
      :selected-rows.sync="selectedEmployees"
      :add-text="$t('Add Employees To Policy')"
      :isRowSelectable="isRowSelectable"
      :actions="tableActions"
      :default-match-filters="defaultMatchFilters"
      :transform-data="transformData"
      class="h-full"
      @add="showDialog = true"
    >
      <template #extra-actions="{row}">
        <TableEditButton
          v-if="row?.meta?.authorizedToAdjust"
          :title="$t('Adjust')"
          @click="editTimeOffEmployee(row)"
        />
        <TableDeleteButton
          v-if="row?.meta?.authorizedToDeactivate"
          @click="removeEmployeeFromPolicy(row)"
        />
        <TableIconButton
          v-if="row?.meta?.authorizedToActivate"
          :title="$t('Reinstate Employee')"
          variant="gray-light"
          @click="reinstateEmployee(row)"
        >
          <RewindIcon
            class="w-4 h-4"
          />
        </TableIconButton>
      </template>
      <template #carryover="{ row }">
        {{ getCarryOverHours(row) }} {{ $t('hours') }}
      </template>
      <template #accrued="{ row }">
        {{ getAccruedHours(row) }} {{ $t('hours') }}
      </template>
      <template #used="{ row }">
        {{ getUsedHours( row ) }} {{ $t('hours') }}
      </template>
      <template #available="{ row }">
        {{ getAvailableHours(row) }} {{ $t('hours') }}
      </template>
      <template #dropdown-actions>
        <TableActionItem
          :title="$t('Move To Policy')"
          :disabled="!selectedEmployees?.length"
          :icon="MoveIcon"
          @click="showMoveDialog = true"
        />
      </template>
    </AgDataTable>
    <AddTimeOffEmployeesDialog
      v-if="showDialog"
      :open.sync="showDialog"
      :time-off-policy="timeOffPolicy"
      @save="onEmployeesAdded"
    />
    <AdjustEmployeeTimeOffStatusDialog
      v-if="showAdjustDialog"
      :open.sync="showAdjustDialog"
      :employeeTimeOffStatus="adjustTimeOffStatus"
      @save="onEmployeeTimeOffAdjusted"
    />
    <MoveEmployeesToPolicyDialog
      v-if="showMoveDialog"
      :open.sync="showMoveDialog"
      :timeOffStatuses="selectedEmployees"
      :fromPolicy="timeOffPolicy"
      @save="onEmployeesMoved"
    />
  </div>
</template>
<script>
import TableActionItem from '@/components/table/actions/TableActionItem.vue';
import AddTimeOffEmployeesDialog from "@/modules/payroll/components/time-off/AddTimeOffEmployeesDialog";
import AdjustEmployeeTimeOffStatusDialog from "@/modules/payroll/components/time-off/AdjustEmployeeTimeOffStatusDialog";
import MoveEmployeesToPolicyDialog from "@/modules/payroll/components/time-off/MoveEmployeesToPolicyDialog";
import { RewindIcon, MoveIcon } from 'vue-feather-icons'
import {
  sumBy,
  orderBy,
} from 'lodash'
import { getDefaultMatchFilter } from "@/components/ag-grid/filterUtils";

export default {
  components: {
    AddTimeOffEmployeesDialog,
    AdjustEmployeeTimeOffStatusDialog,
    MoveEmployeesToPolicyDialog,
    RewindIcon,
    TableActionItem,
  },
  props: {
    timeOffPolicy: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      showAdjustDialog: false,
      adjustTimeOffStatus: null,
      selectedEmployees: [],
      showMoveDialog: false,
      MoveIcon,
    }
  },
  computed: {
    tableActions() {
      // TODO - add permissions and remove delete,add action if user doesn't have permissions
      return 'refresh,search,add'
    },
    urlParams() {
      return {
        related: 'employee',
        time_off_policy_id: this.timeOffPolicy.id,
      }
    },
    columns() {
      return [
        {
          headerName: this.$t('Employee'),
          field: 'attributes.employee_id',
          component: 'EmployeeLink',
          minWidth: 250,
          headerCheckboxSelection: true,
          checkboxSelection: true,
        },
        {
          headerName: this.$t('Carry over (previous year)'),
          minWidth: 120,
          field: 'attributes.previous_year_carry_over_hours',
          valueFormatter: this.hoursFormatter,
        },
        {
          headerName: this.$t('Accrued (this year)'),
          minWidth: 120,
          field: 'attributes.current_year_accrued_hours',
          valueFormatter: this.hoursFormatter,
        },
        {
          headerName: this.$t('Used'),
          minWidth: 120,
          field: 'attributes.current_year_used_hours',
          valueFormatter: this.hoursFormatter,
        },
        {
          headerName: this.$t('Available'),
          field: 'attributes.balance',
          valueFormatter: this.hoursFormatter,
          minWidth: 120,
        },
      ]
    },
    defaultMatchFilters() {
      return {
        active: true,
        active_employees: true
      }
    },
  },
  methods: {
    transformData(data) {
      return orderBy(data, [
        'attributes.active',
        'relationships.employee.attributes.code'
      ], ['desc', 'asc'])
    },
    hoursFormatter(params) {
      const value = this.round(params.value)
      return `${value} ${this.$t('hours')}`
    },
    getTimeOffStatuses(employee) {
      let statuses = employee.relationships?.timeOffStatuses || []
      return statuses.filter(s => s.attributes.time_off_policy_id === this.timeOffPolicy.id)
    },
    getCarryOverHours(employee) {
      let statuses = this.getTimeOffStatuses(employee)
      return sumBy(statuses, 'attributes.previous_year_carry_over_hours')
    },
    getAccruedHours(employee) {
      let statuses = this.getTimeOffStatuses(employee)
      return sumBy(statuses, 'attributes.current_year_accrued_hours')
    },
    getUsedHours(employee) {
      let statuses = this.getTimeOffStatuses(employee)
      return sumBy(statuses, 'attributes.current_year_used_hours')
    },
    getAvailableHours(employee) {
      let statuses = this.getTimeOffStatuses(employee)
      return sumBy(statuses, 'attributes.balance')
    },
    refreshEmployees() {
      this.$refs.table.refresh()
    },
    onEmployeesAdded() {
      this.refreshEmployees()
      this.showDialog = false
    },
    async removeEmployeeFromPolicy(timeOffStatus) {
      const confirmed = await this.$deleteConfirm({
        title: this.$t('Remove Employee From Policy'),
        description: this.$t('The following action will remove the employee from the policy but will keep all values. Are you sure you want to continue?'),
      })

      if (!confirmed) {
        return
      }

      try {
        this.loading = true
        const employees = [
          {
            id: timeOffStatus.attributes.employee_id,
            pay_out_remaining_balance: false,
          }
        ]

        await this.$store.dispatch('payroll/removeEmployeesFromTimeOffPolicy', {
          timeOffPolicyId: this.timeOffPolicy?.id,
          employees
        })

        this.refreshEmployees()
      }
      finally {
        this.loading = false
      }
    },
    async reinstateEmployee(timeOffStatus) {
      try {
        this.loading = true
        await this.$store.dispatch('payroll/reinstateEmployeeTimeOffStatus', timeOffStatus.id)

        this.refreshEmployees()
      }
      finally {
        this.loading = false
      }
    },
    editTimeOffEmployee(timeOffStatus) {
      this.adjustTimeOffStatus = JSON.parse(JSON.stringify(timeOffStatus))
      this.showAdjustDialog = true
    },
    onEmployeeTimeOffAdjusted() {
      this.refreshEmployees()
      this.showAdjustDialog = false
    },
    onEmployeesMoved() {
      this.refreshEmployees()
      this.showMoveDialog = false
    },
    isRowSelectable(params) {
      return params.data?.meta?.authorizedToMove
    },
  }
}
</script>
