<template>
  <div class="flex-1 h-full">
    <layout-tabs
        v-if="!onViewResourcePage"
        :items="filteredItems"
    />
    <router-view/>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        items: [
          {
            name: this.$t('Pending'),
            tooltip: this.$t('Purchase orders that were not posted yet'),
            path: '/purchasing-inventory/purchase-orders/pending',
            permission: 'purchase_orders_show',
          },
          {
            name: this.$t('Open'),
            tooltip: this.$t('Posted purchase orders'),
            path: '/purchasing-inventory/purchase-orders/open',
            permission: 'purchase_orders_show',
          },
          {
            name: this.$t('Closed'),
            tooltip: this.$t('Closed purchase orders'),
            path: '/purchasing-inventory/purchase-orders/closed',
            permission: 'purchase_orders_show',
          },
          {
            name: this.$t('Voided'),
            tooltip: this.$t('Voided purchase orders'),
            path: '/purchasing-inventory/purchase-orders/voided',
            permission: 'purchase_orders_show',
          },
          {
            name: this.$t('All'),
            tooltip: this.$t('All purchase orders'),
            path: '/purchasing-inventory/purchase-orders/all',
            permission: 'purchase_orders_show',
          },
          {
            name: this.$t('Review'),
            tooltip: this.$t('Review purchase orders'),
            path: '/purchasing-inventory/purchase-orders/review',
            permission: 'purchase_orders_approve',
          },
          {
            name: this.$t('Review Adjustments'),
            tooltip: this.$t('Review purchase orders adjustments'),
            path: '/purchasing-inventory/purchase-orders/review-adjustments',
            permission: 'purchase_orders_approve',
          },
        ],
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter(item => this.$can(item.permission))
      },
    },
  }
</script>
