<template>
  <div class="shadow px-4 pb-4 rounded bg-white print:shadow-none print:p-0">
    <AgDataTable
      v-bind="editableTableProps"
      :url="url"
      :columns="allColumns"
      :url-params="urlParams"
      :default-sort="defaultSort"
      :data-loading="dataLoading"
      :getRowId="params => params.data._localId || params.data.id"
      :add-text="$t('New line item')"
      :disable-col-flex="true"
      :import-url="`/job-costing/line-items/import?jobId=${jobId}`"
      :resourceName="$globalResources.LineItems"
      :get-empty-row="getEmptyEntry"
      :map-duplicate-row="mapDuplicateRow"
      :exclude-filters="['last_updated_at']"
      permission="line_items"
      hide-actions="delete,view,edit"
      actions="search,add,refresh,import,export,bulk-delete"
      :isRowSelectable="isRowSelectable"
      :addNewRowAtTop="true"
      entity="line-items"
      class="print:mt-4"
      ref="table"
      :transform-data="transformData"
      @grid-ready="grid = $event"
      @add="tryCreateLineItem"
    >
      <template #additional-actions-before>
        <BaseSelect
          v-model="lineItemType"
          :label="$t('Line Item Type')"
          :placeholder="$t('Line Item Type')"
          :add-entity="false"
          :options="lineItemTypeOptions"
          @change="onItemTypeChanged"
        />
        <BaseSelect
          v-model="budgetTypeIds"
          :label="$t('Budget Types')"
          :placeholder="$t('All')"
          :add-entity="false"
          :collapse-tags="true"
          multiple
          :options="budgetTypeOptions"
        />
      </template>

      <template #extra-actions="{ row }">
        <TableDeleteButton
          v-if="$isAuthorized('authorizedToDelete', row)"
          @click="onDeleteLineItem(row)"
        />
      </template>

      <template #attributes.description="{row}">
        <div class="flex w-full space-x-2 items-center justify-between">
          <router-link :to="getLineItemLink(row)">
            {{ row.attributes.description || '' }}
          </router-link>
          <LoadingCircle
            v-if="row.creating"
            class="w-5 h-5"
          />
        </div>
      </template>

      <template #dropdown-actions>
        <TableActionItem>
          <BaseTooltip
            :content="$t('Copy Line Items From Another Job')"
            placement="top"
          >
            <div
              class="action-item-text text-gray-900"
              @click="showCopyLinesDialog = true"
            >
              <div class="p-2 bg-gray-100 mr-2 rounded-md">
                <CopyIcon class="w-4 h-4" />
              </div>
              <span>{{ $t('Copy Line Items') }}</span>
            </div>
          </BaseTooltip>
        </TableActionItem>
      </template>
    </AgDataTable>
    <CopyLineItemsFromAnotherJobDialog
      v-if="showCopyLinesDialog"
      :open.sync="showCopyLinesDialog"
      :toJob="currentJob"
      @copied="onLineItemsCopied"
      @close="showCopyLinesDialog = false"
    />
    <DeleteLineItemDialog
      v-if="showDeleteLineItemDialog"
      :open.sync="showDeleteLineItemDialog"
      :lineItem="lineItemToDelete"
      @deleted="onLineItemDeleted"
      @close="showDeleteLineItemDialog = false"
    />
  </div>
</template>
<script>
import axios from 'axios'
import { get, set, debounce } from 'lodash'
import { JobTypeFor } from "@/modules/job-costing/enum/jobs";
import { costTypes } from '@/enum/enums';
import { globalResources } from "@/components/form/util";
import { validateNumber, validateMaxDecimals } from '@/modules/common/util/validators';
import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import { cellClasses } from '@/components/ag-grid/columnUtils';
import { editableTableProps } from '@/components/ag-grid/tableUtils';
import LoadingCircle from '@/modules/wiki/components/LoadingCircle.vue';
import TableActionItem from '@/components/table/actions/TableActionItem.vue';
import CopyLineItemsFromAnotherJobDialog from '@/modules/job-costing/components/CopyLineItemsFromAnotherJobDialog.vue';
import DeleteLineItemDialog from '@/modules/job-costing/components/line-items/DeleteLineItemDialog.vue';

import { CopyIcon } from 'vue-feather-icons';

export default {
  name: 'ManageJobLineItems',
  components: {
    LoadingCircle,
    TableActionItem,
    CopyIcon,
    CopyLineItemsFromAnotherJobDialog,
    DeleteLineItemDialog,
  },
  props: {
    canUpdatePath: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editableTableProps,
      grid: null,
      defaultSort: 'phase_code,cost_code,change_order',
      selectedBudget: {},
      dataLoading: false,
      lineItemType: costTypes.Income,
      budgetTypeIds: [],
      showCopyLinesDialog: false,
      lineItemTypeOptions: [
        {
          label: this.$t('Cost Line Items'),
          value: costTypes.Cost,
        },
        {
          label: this.$t('Income Line Items'),
          value: costTypes.Income,
        },
      ],
      columns: [
        {
          headerName: this.$t('Phase Code'),
          field: 'attributes.phase_code',
          minWidth: 60,
          maxWidth: 80,
          pinned: 'left',
          editable: true,
          valueSetter: this.saveLineItemDetails
        },
        {
          headerName: this.$t('Cost Code'),
          field: 'attributes.cost_code',
          minWidth: 80,
          maxWidth: 80,
          pinned: 'left',
          editable: true,
          valueSetter: this.saveLineItemDetails
        },
        {
          headerName: this.$t('Chg Order'),
          field: 'attributes.change_order',
          minWidth: 80,
          maxWidth: 80,
          pinned: 'left',
          align: 'center',
          editable: true,
          cellEditor: cellEditors.Numeric,
          cellEditorParams: {
            step: '0.01'
          },
          valueSetter: (params) => {
            if (!validateNumber(params.newValue, { notifyErr: true })) {
              return false
            }
            this.saveLineItemDetails(params)
          }
        },
        {
          headerName: this.$t('Description'),
          field: 'attributes.description',
          minWidth: 300,
          maxWidth: 500,
          editable: true,
          valueSetter: this.saveLineItemDetails
        },
        {
          headerName: this.$t('Type'),
          field: 'attributes.type',
          align: 'center',
          minWidth: 40,
          maxWidth: 60,
          component: 'Status',
        },
      ],
      allColumns: [],
      refreshColumnsDebounced: null,
      showDeleteLineItemDialog: false,
      lineItemToDelete: null,
    }
  },
  computed: {
    jobId() {
      return this.$route.params.id
    },
    currentJob() {
      return this.$store.state.jobCosting.currentJob
    },
    url() {
      return '/restify/line-items'
    },
    urlParams() {
      return {
        job_id: this.jobId,
        related: 'budgets',
        type: this.lineItemType,
      }
    },
    jobTypes() {
      return this.$store.getters['globalLists/getResourceList'](globalResources.JobTypes) || []
    },
    incomeJobTypes() {
      return this.jobTypes.filter((jobType) => jobType.type === costTypes.Income)
    },
    costJobTypes() {
      return this.jobTypes.filter((jobType) => jobType.type === costTypes.Cost)
    },
    budgetTypeOptions() {
      if (this.lineItemType === costTypes.Income) {
        return this.incomeJobTypes.map(jobType => ({
          label: jobType.name,
          value: jobType.id,
        }))
      }
      return this.costJobTypes.map(jobType => ({
        label: jobType.name,
        value: jobType.id,
      }))
    },
  },
  methods: {
    transformData(data) {
      return data.map(this.lineItemMapper)
    },
    lineItemMapper(lineItem) {
      const budgets = get(lineItem, 'relationships.budgets', [])
      const item = {
        ...lineItem,
      }
      budgets.forEach((budget) => {
        set(item, `budgets.${budget.attributes.job_type_id}`, budget.attributes)
      })
      return item
    },
    getIncomeBudgetColumns() {
      let cols = []
      this.incomeJobTypes.forEach((jobType) => {
        if (this.isColumnHidden(jobType)) {
          return
        }
        if (jobType.for === JobTypeFor.Income.UnitPrice) {
          cols.push({
            headerName: `${jobType.for} Qty`,
            field: `budgets.${jobType.id}.quantity`,
            jobTypeId: jobType.id,
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            cellClass: (params) => {
              return params.data?.id ? '' : cellClasses.ReadOnly;
            },
            editable: (params) => {
              return params?.data?.id;
            },
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            valueSetter: (params) => {
              if (!validateNumber(params.newValue, { notifyErr: true })) {
                return false
              }
              if (!validateMaxDecimals(params.newValue, 3, { notifyErr: true })) {
                return false
              }
              this.saveOrUpdateBudget(params)
            },
            component: 'FormattedQuantity',
            params: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            },
          });
          cols.push({
            headerName: `${jobType.for} Price`,
            field: `budgets.${jobType.id}.unit_rate`,
            jobTypeId: jobType.id,
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            cellClass: (params) => {
              return params.data?.id ? '' : cellClasses.ReadOnly;
            },
            editable: (params) => {
              return params?.data?.id;
            },
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            valueSetter: (params) => {
              if (!validateNumber(params.newValue, { notifyErr: true })) {
                return false
              }
              if (!validateMaxDecimals(params.newValue, 2, { notifyErr: true })) {
                return false
              }
              this.saveOrUpdateBudget(params)
            },
            component: 'FormattedPrice',
            params: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          });
          cols.push({
            headerName: `${jobType.for} Measure`,
            field: `budgets.${jobType.id}.um`,
            jobTypeId: jobType.id,
            align: 'left',
            minWidth: 100,
            maxWidth: 150,
            valueSetter: this.saveOrUpdateBudget,
            cellClass: (params) => {
              return params.data?.id ? '' : cellClasses.ReadOnly
            },
            editable: (params) => {
              return params?.data?.id
            },
          });
        }
        const isEditable = jobType.for !== JobTypeFor.Income.UnitPrice
        cols.push({
          headerName: jobType.for === JobTypeFor.Income.UnitPrice
            ? this.$t('UPB Amount')
            : jobType.name,
          field: `budgets.${jobType.id}.amount`,
          jobTypeId: jobType.id,
          align: 'right',
          minWidth: 100,
          maxWidth: 150,
          editable: (params) => {
            return isEditable && params?.data?.id
          },
          cellClass: (params) => {
            return isEditable && params?.data?.id ? '' : cellClasses.ReadOnly
          },
          cellEditor: cellEditors.Numeric,
          cellEditorParams: {
            step: '0.01'
          },
          valueSetter: (params) => {
            if (!validateNumber(params.newValue, { notifyErr: true })) {
              return false
            }
            if (!validateMaxDecimals(params.newValue, 2, { notifyErr: true })) {
              return false
            }
            this.saveOrUpdateBudget(params)
          },
          component: 'FormattedPrice',
        })
      })
      return cols
    },
    getCostBudgetColumns() {
      const cols = [];
      this.costJobTypes.forEach(jobType => {
        if (this.isColumnHidden(jobType)) {
          return;
        }
        cols.push({
          headerName: jobType.name,
          field: `budgets.${jobType.id}.amount`,
          jobTypeId: jobType.id,
          align: 'right',
          minWidth: 100,
          maxWidth: 150,
          cellClass: (params) => {
            return params.data?.id ? '' : cellClasses.ReadOnly
          },
          editable: (params) => {
            return params?.data?.id
          },
          cellEditor: cellEditors.Numeric,
          cellEditorParams: {
            step: '0.01'
          },
          valueSetter: (params) => {
            if (!validateNumber(params.newValue, { notifyErr: true })) {
              return false
            }
            if (!validateMaxDecimals(params.newValue, 2, { notifyErr: true })) {
              return false
            }
            this.saveOrUpdateBudget(params)
          },
          component: 'FormattedPrice',
        });
        if (jobType.for === JobTypeFor.Cost.Equipment) {
          cols.push({
            headerName: `${jobType.for} Hours`,
            field: `budgets.${jobType.id}.quantity`,
            jobTypeId: jobType.id,
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            cellClass: (params) => {
              return params.data?.id ? '' : cellClasses.ReadOnly
            },
            editable: (params) => {
              return params?.data?.id
            },
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            valueSetter: (params) => {
              if (!validateNumber(params.newValue, { notifyErr: true })) {
                return false
              }
              if (!validateMaxDecimals(params.newValue, 3, { notifyErr: true })) {
                return false
              }
              this.saveOrUpdateBudget(params)
            },
            component: 'FormattedHours',
            params: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            },
          })
        }
        if (jobType.for === JobTypeFor.Cost.Labor) {
          cols.push({
            headerName: `${jobType.for} Hours`,
            field: `budgets.${jobType.id}.quantity`,
            jobTypeId: jobType.id,
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            cellClass: (params) => {
              return params.data?.id ? '' : cellClasses.ReadOnly
            },
            editable: (params) => {
              return params?.data?.id
            },
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            valueSetter: (params) => {
              if (!validateNumber(params.newValue, { notifyErr: true })) {
                return false
              }
              if (!validateMaxDecimals(params.newValue, 3, { notifyErr: true })) {
                return false
              }
              this.saveOrUpdateBudget(params)
            },
            component: 'FormattedHours',
            params: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            },
          })
        }
        if (jobType.for === JobTypeFor.Cost.Material) {
          cols.push({
            headerName: `${jobType.for} Units`,
            field: `budgets.${jobType.id}.quantity`,
            jobTypeId: jobType.id,
            align: 'right',
            minWidth: 100,
            maxWidth: 150,
            cellClass: (params) => {
              return params.data?.id ? '' : cellClasses.ReadOnly
            },
            editable: (params) => {
              return params?.data?.id
            },
            cellEditor: cellEditors.Numeric,
            cellEditorParams: {
              step: '0.01'
            },
            valueSetter: (params) => {
              if (!validateNumber(params.newValue, { notifyErr: true })) {
                return false
              }
              if (!validateMaxDecimals(params.newValue, 3, { notifyErr: true })) {
                return false
              }
              this.saveOrUpdateBudget(params)
            },
            component: 'FormattedQuantity',
            params: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 3,
            },
          })
          cols.push({
            headerName: `${jobType.for} Measure`,
            field: `budgets.${jobType.id}.um`,
            jobTypeId: jobType.id,
            align: 'left',
            minWidth: 100,
            maxWidth: 150,
            valueSetter: this.saveOrUpdateBudget,
            cellClass: (params) => {
              return params.data?.id ? '' : cellClasses.ReadOnly
            },
            editable: (params) => {
              return params?.data?.id
            },
          })
        }
      });
      return cols;
    },
    isColumnHidden(jobType) {
      if (!this.budgetTypeIds.length) {
        return false
      }
      return !this.budgetTypeIds.includes(jobType.id)
    },
    async refreshColumns() {
      await this.$nextTick()

      const typeColumns = this.lineItemType === costTypes.Income
        ? this.getIncomeBudgetColumns()
        : this.getCostBudgetColumns()

      this.allColumns = [
        ...this.columns,
        ...typeColumns,
      ]
    },
    refreshTable() {
      this.$refs.table.refresh({}, true)
    },
    async saveLineItemDetails(params) {
      const field = params.colDef.field
      const newValue = params.newValue
      const lineItem = params.data

      set(lineItem, field, newValue)

      if (!lineItem.id) {
        this.tryCreateLineItem(params)
        return;
      }
      const updateModel = {
        [field.replace('attributes.', '')]: newValue,
      }

      await axios.patch(`/restify/line-items/${lineItem.id}`, updateModel)
    },
    async tryCreateLineItem(params) {
      const lineItem = params.data
      const model = {
        ...lineItem.attributes,
      }

      if (!model.phase_code && !model.cost_code) {
        return
      }

      if (params.data.creating) {
        return
      }

      try {
        params.data.creating = true
        params.node.setData(params.data)

        let { data: savedLineItem } = await axios.post('/restify/line-items', model)
        savedLineItem = await this.createLineItemBudgets(params.data.budgets, savedLineItem)

        savedLineItem = this.lineItemMapper(savedLineItem)
        savedLineItem._localId = params.data._localId
        params.node.setData(savedLineItem)
      }
      catch (err) {
        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      }
      finally {
        params.data.creating = false
      }
    },
    async createLineItemBudgets(budgetsObj, lineItem) {
      const budgets = Object.values(budgetsObj)

      if (!budgets.length) {
        return lineItem
      }

      const budgetModels = budgets.map(budget => {
        return {
          ...budget,
          line_item_id: lineItem.id,
        }
      })

      await axios.post(`/restify/job-budgets/bulk`, budgetModels)

      const { data } = await axios.get(`/restify/line-items/${lineItem.id}`, {
        params: {
          related: 'budgets'
        }
      })

      return data
    },
    async saveOrUpdateBudget(params) {
      const field = params.colDef.field
      const newValue = params.newValue
      const lineItem = params.data
      const jobTypeId = params.colDef.jobTypeId

      set(lineItem, field, newValue)

      const budget = lineItem.budgets[jobTypeId]
      const jobType = this.jobTypes.find(jobType => jobType.id === jobTypeId)

      if (jobType.for === JobTypeFor.Income.UnitPrice) {
        const amount = (budget?.quantity || 0) * (budget?.unit_rate || 0)
        set(lineItem, `budgets.${jobTypeId}.amount`, amount)
      }

      if (budget?.id) {
        await this.updateBudgetEntry(params)
      }
      else {
        await this.createBudgetEntry(params)
      }
    },
    async createBudgetEntry(params) {
      const field = params.colDef.field
      const jobTypeId = params.colDef.jobTypeId
      const newValue = params.newValue

      if (params.data.creating) {
        params.data.updates = params.data.updates || []
        params.data.updates.push({
          jobTypeId,
          field,
          newValue,
        })

        return
      }
      try {
        params.data.creating = true
        const model = this.getUpdateModel(params.data, jobTypeId, field, newValue)
        const response = await axios.post(`/restify/job-budgets/bulk`, [model])
        const savedBudget = get(response, 'data[0]')

        set(params.data, `budgets.${model.job_type_id}`, savedBudget)

        if (params.data.updates?.length) {
          await this.applyPostCreateUpdates(params.data, params.data.updates)
        }
      }
      finally {
        params.data.creating = false
      }
    },
    async updateBudgetEntry(params) {
      const field = params.colDef.field
      const jobTypeId = params.colDef.jobTypeId
      const newValue = params.newValue

      const model = this.getUpdateModel(params.data, jobTypeId, field, newValue)
      await axios.post(`/restify/job-budgets/bulk/update`, [model])
    },
    async applyPostCreateUpdates(lineItem, updates) {
      let model = {}

      // Merge all updates into a single model
      updates.forEach(update => {
        const field = update.field
        const newValue = update.newValue
        const jobTypeId = update.jobTypeId

        set(lineItem, field, newValue)

        const updateModel = this.getUpdateModel(lineItem, jobTypeId, field, newValue)

        model = {
          ...model,
          ...updateModel,
        }
      })

      await axios.post(`/restify/job-budgets/bulk/update`, [model])
    },
    getUpdateModel(lineItem, jobTypeId, field, newValue) {
      const budgetProp = field.split('.').pop()
      const budget = lineItem.budgets[jobTypeId]

      const defaultValues = {
        amount: 0,
        quantity: 0,
        unit_rate: 0,
        um: '',
      }

      return {
        id: budget.id,
        job_type_id: jobTypeId,
        line_item_id: lineItem.id,
        ...defaultValues,
        ...budget,
        [budgetProp]: newValue,
      }
    },
    onItemTypeChanged() {
      this.budgetTypeIds = []
    },
    getLineItemLink(lineItem) {
      const { id, type } = lineItem.attributes
      return `/job-costing/${type}-line-items/${id}/view?fromJob=${this.jobId}`
    },
    mapDuplicateBudgets(lineItem) {
      const budgets = lineItem.budgets || {}
      const mappedBudgets = {}

      for (let jobTypeId in budgets) {
        const budget = budgets[jobTypeId]
        mappedBudgets[jobTypeId] = {
          job_type_id: budget.job_type_id,
          amount: budget.amount || 0,
          quantity: budget.quantity || 0,
          unit_rate: budget.unit_rate || 0,
          um: budget.um || '',
        }
      }

      return mappedBudgets
    },
    mapDuplicateRow(lineItem) {
      return {
        id: undefined,
        _localId: crypto.randomUUID(),
        attributes: {
          description: `${lineItem.attributes.description || ''} (Copy)`,
          type: lineItem.attributes.type,
          job_id: lineItem.attributes.job_id,
          exempt_from_sales_tax: lineItem.attributes.exempt_from_sales_tax,
          change_order: 0,
        },
        budgets: this.mapDuplicateBudgets(lineItem),
      }
    },
    getEmptyEntry() {
      const incomeLineItemDefaults = {
        type: costTypes.Income,
        exempt_from_sales_tax: false,
      }
      const costLineItemDefaults = {
        type: costTypes.Cost,
        exempt_from_sales_tax: false,
      }

      return {
        _localId: crypto.randomUUID(),
        attributes: {
          available_in_timesheets: true,
          ...this.lineItemType === costTypes.Income
            ? incomeLineItemDefaults
            : costLineItemDefaults,
          change_order: 0,
          job_id: this.jobId,
        },
        budgets: {}
      }
    },
    isRowSelectable(params) {
      return params.data?.meta?.authorizedToDelete
    },
    onDeleteLineItem(lineItem) {
      if (!lineItem.id) {
        this.grid.api.applyTransaction({
          remove: [lineItem],
        })

        return
      }

      this.lineItemToDelete = lineItem
      this.showDeleteLineItemDialog = true
    },
    onLineItemDeleted(lineItem) {
      this.showDeleteLineItemDialog = false
      this.grid.api.applyTransaction({
        remove: [lineItem],
      })
    },
    onLineItemsCopied() {
      this.refreshTable()
    },
  },
  watch: {
    lineItemType: {
      handler() {
        this.refreshColumns()
      },
      immediate: true,
    },
    budgetTypeIds() {
      this.refreshColumnsDebounced?.()
    },
    jobTypes() {
      this.refreshColumnsDebounced?.()
    }
  },
  created() {
    this.refreshColumnsDebounced = debounce(this.refreshColumns, 100)
  },
}
</script>
