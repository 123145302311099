<template>
  <div class="col-span-6 md:col-span-2 xl:col-span-1">
    <BaseDatePicker
      :value="value"
      :label="$t(`Starting Pay Period`)"
      :picker-options="{
          cellClassName
      }"
      @input="onDateChange"
    />
  </div>
</template>
<script>
import axios from "axios";
import parseISO from "date-fns/parseISO";
import { getPeriodEndDay } from "@/modules/payroll/components/timecard/util";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
    },
    entity: {
      type: String,
    },
    payloadKey: {
      type: String,
    }
  },
  computed: {
    payroll_period_start_day() {
      return this.$settings(this.$modules.PR, 'payroll_period_start_day')
    },
  },
  methods: {
    isCorrectPeriodEndDay(date) {
      if (typeof date === 'string') {
        date = parseISO(date)
      }
      const day = date.getDay()
      const startDay = getPeriodEndDay(this.payroll_period_start_day)
      return day === startDay
    },
    cellClassName(date) {
      if (this.isCorrectPeriodEndDay(date)) {
        return 'text-primary-500'
      }
    },
    async onDateChange(newValue) {
      if (!this.isCorrectPeriodEndDay(newValue)) {
        this.$error(this.$t('The selected pay period end date is different from the one provided in Payroll settings!'))
      }
      const formattedDate = this.$formatDate(newValue)
      const confirmed = await this.$confirm({
        title: this.$t('Change Starting Pay Period'),
        description: this.$t('Are you sure you want to change the starting pay period to {date}?', { date: formattedDate }),
        buttonText: this.$t('Change Starting Pay Period'),
      })
      if (!confirmed) {
        return
      }
      await axios.post(`/restify/${this.entity}/actions?action=change-starting-pay-period`, {
        [this.payloadKey]: [
          {
            id: this.id,
            starting_pay_period: newValue,
          }
        ]
      })
      this.$emit('change', newValue)
    }
  }
}
</script>
