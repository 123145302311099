<template>
  <div class="base-grid-select">
    <validation-provider v-bind="$attrs"
                         :name="$attrs.name || label"
                         class="w-full"
                         v-slot="{ errors }">
      <label v-if="label || $slots.label"
             :for="$attrs.id"
             class="block text-sm font-medium leading-5 text-gray-700 truncate">
        <slot name="label">
          <div class="flex items-center">
            <span v-html="label"></span>
            <span v-if="isRequired"
                  class="text-gray-500">
            *
          </span>
          </div>
        </slot>
      </label>
      <base-input-error :errors="errors"
                        :show-tooltip="inlineErrors">
        <el-select v-bind="$attrs"
                   v-on="listeners"
                   :value="computedValue"
                   :clearable="clearable"
                   :remote-method="hasRemoteSearch ? onSearch : undefined"
                   :remote="hasRemoteSearch"
                   :no-match-text="noMatchText"
                   :no-data-text="noDataText"
                   :multiple="multiple"
                   ref="baseGridSelect"
                   default-first-option
                   automatic-dropdown
                   filterable
                   class="entity-select w-full has-table"
                   popper-class="has-table"
                   @input.native="onQueryChange"
                   @clear="onClear"
                   @keydown.backspace.native.stop=""
        >
          <slot name="actions"/>
          <slot>
            <SelectColumns
              :columns="getTableColumns"
              :resource-name="resourceName"
              :on-sort="onSort"
              :sort-by="localSortBy"
              :sort-direction="sortDirection"
            />

            <el-option v-if="isAuthorizedToAdd"
                       :value="-1">
              <div class="flex items-center cursor-pointer justify-center"
                   @mousedown.prevent.stop="tryAddNewEntry">
                <IconAdd class="w-5 h-5 mr-2"/>
                <span class="mx-1 font-medium text-primary-900">
                  {{ getResourceName ? $t(getResourceName) : $t('Add New Entity') }}
                </span>
              </div>
            </el-option>

            <el-option v-if="isAuthorizedToAdd && !filteredOptions.length"
                       :disabled="true">
              <div class="flex items-center justify-center"
                   @mousedown.prevent.stop>
                <span class="mx-1 font-normal text-gray-500">
                  <template v-if="!filteredOptions.length && query">
                    {{ noMatchText }}
                  </template>
                  <template v-else>
                    {{ noDataText }}
                  </template>
                </span>
              </div>
            </el-option>

            <el-option
                v-if="$slots['custom-filter']"
                key="custom-filter"
                value="custom-filter"
                disabled
            >
              <div :style="minWidthStyles">
                <slot name="custom-filter"/>
              </div>
            </el-option>

            <el-option
              v-for="option in filteredOptions"
              :key="option[valueKey]"
              :value="option[valueKey]"
              :label="labelKey ? option[labelKey] : labelFormat(option)"
              :disabled="option.disabled"
            >
              <template v-slot:default>
                <slot :row="option">
                  <div class="flex">
                  <span v-for="(column, index) in getTableColumns"
                        :key="index"
                        :class="column.class ? column.class : ''"
                        :style="{minWidth: `${column.minWidth}px`, maxWidth: `${column.maxWidth}px`}"
                  >
                    <div @mousedown="onOptionClick(option)"
                         class="truncate pr-2">
                      <template v-if="column.component">
                        <component :is="column.component"
                                   :column="column"
                                   :row="option"
                        />
                      </template>
                      <template v-else>
                        <template v-if="column.toFormat">
                          {{ column.toFormat(get(option, column.prop), option) }}
                        </template>
                        <template v-else-if="column.formatPrice">
                          {{ $formatPrice(get(option, column.prop)) }}
                        </template>
                        <template v-else>
                          {{ getCellContent(option, column.prop) }}
                        </template>
                      </template>
                    </div>
                  </span>
                  </div>
                </slot>
              </template>
            </el-option>
            <div class="flex justify-center items-center pt-1"
                 v-if="allFilterOptions.length >= perPage"
            >
              <base-button variant="primary-link"
                           @mousedown.prevent.stop="viewMore">
                {{ $t('View More') }}
              </base-button>
            </div>
          </slot>
        </el-select>
      </base-input-error>
    </validation-provider>

    <AddNewResource
        :key="resourceToAddName"
        @save="onAddNewResource"
    />

  </div>
</template>
<script>
  import { Option, Select } from 'element-ui'
  import Status from '@/components/table/cells/Status'
  import AddNewResource from '@/components/form/AddNewResource'
  import { globalResources, tableColumns } from '@/components/form/util'
  import FormattedPercent from '@/components/table/cells/FormattedPercent'
  import { authorizedToAdd, getAddResourceConfig } from '@/enum/repositories'
  import { scrollToSelectedValue } from '@/components/form/selectSelectionUtils'
  import orderBy from 'lodash/orderBy'
  import { isReferenceId } from "@/utils/utils";
  import { RefreshCwIcon } from "vue-feather-icons";
  import RefreshResourceButton from "@/components/select/RefreshResourceButton.vue";
  import sumBy from "lodash/sumBy";
  import { useSelectSorting } from "@/modules/common/composables/useSelectSorting";
  import SelectColumns from "@/components/form/select/SelectColumns.vue";
  import { BenefitExceptions, PayCodeExceptions } from "@/modules/payroll/utils/benefitCodes";

  const DefaultPerPage = 75

  export default {
    inheritAttrs: false,
    components: {
      SelectColumns,
      RefreshResourceButton,
      Status,
      AddNewResource,
      FormattedPercent,
      RefreshCwIcon,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    props: {
      addLabel: String,
      resourceName: {
        type: String,
        default: globalResources.Jobs,
      },
      value: [String, Number, Array],
      valueKey: {
        type: String,
        default: 'id',
      },
      labelKey: String,
      label: String,
      clearable: {
        type: Boolean,
        default: false,
      },
      inlineErrors: {
        type: Boolean,
        default: true,
      },
      remoteSearch: {
        type: Boolean,
        default: true,
      },
      ownFilter: Function,
      filterMethod: Function,
      ownPolicy: Function,
      ownColumns: {
        type: Array,
        default: () => [],
      },
      unauthorizedToAdd: {
        type: Boolean,
        default: false,
      },
      sortBy: {
        type: String,
      },
      sortByFunction: {
        type: Function,
      },
      noMatchText: {
        type: String,
      },
      noDataText: {
        type: String,
      },
      multiple: {
        type: Boolean,
      },
      focusOnMount: {
        type: Boolean,
        default: false,
      },
    },
    inject: {
      initialSearchQuery: {
        default: null,
      },
    },
    setup() {
      const { onSort, sortData, sortDirection, sortBy: localSortBy } = useSelectSorting()
      return {
        onSort,
        sortData,
        sortDirection,
        localSortBy,
      }
    },
    data() {
      return {
        query: '',
        perPage: DefaultPerPage,
      }
    },
    computed: {
      computedValue() {
        return this.value
      },
      resourceToAddName() {
        return this.$store.getters['gridSelect/getResourceName']
      },
      isAuthorizedToAdd() {
        return this.authorizedToAdd && !this.unauthorizedToAdd
      },
      hasRemoteSearch() {
        return this.remoteSearch || !!this.ownFilter
      },
      getResourceName() {
        if (!this.authorizedToAdd) {
          return ''
        }

        return getAddResourceConfig(this.resourceName)?.actionTitle
      },
      authorizedToAdd() {
        if (this.ownPolicy) {
          return this.ownPolicy()
        }

        return authorizedToAdd(this.resourceName)
      },
      isRequired() {
        const rules = this.$attrs.rules
        if (!rules) {
          return false
        }

        return rules?.includes && rules?.includes('required') || rules?.required
      },
      getTableColumns() {
        if (this.ownColumns.length) {
          return this.ownColumns
        }
        return tableColumns[this.resourceName] || tableColumns.default
      },
      minColumnsWidth() {
        const offset = 20
        return sumBy(this.getTableColumns, 'minWidth') + offset
      },
      minWidthStyles() {
        return {
          minWidth: `${this.minColumnsWidth}px`,
        }
      },
      allFilterOptions() {
        let options = this.$store.getters['globalLists/getResourceOptions'](this.resourceName, this.query, null) || []
        options = options.filter(option => !PayCodeExceptions.includes(option.code) && !BenefitExceptions.includes(option.code))

        if (this.ownFilter) {
          const filteredData = this.ownFilter(this.query, null)
          return this.sortData(filteredData)
        }

        if (this.sortBy) {
          let order = this.sortBy.startsWith('-') ? 'desc' : 'asc'
          let sort = this.sortBy.replace('-', '')
          options = orderBy(options, sort, order)
        }
        if (this.sortByFunction) {
          options = this.sortByFunction(options)
        }
        return options
      },
      filteredOptions() {
        let options = this.allFilterOptions
        if (this.ownFilter) {
          const filteredData = this.ownFilter(this.query, this.perPage)
          return this.sortData(filteredData)
        }
        if (this.filterMethod) {
          const filteredData = options.filter(this.filterMethod).slice(0, this.perPage)
          return this.sortData(filteredData)
        }
        return this.sortData(options)
      },
      listeners() {
        return {
          ...this.$listeners,
          change: this.onChange,
        }
      },
    },
    methods: {
      async onAddNewResource(resource) {
        this.emitChange(resource[this.valueKey])
      },
      async tryAddNewEntry() {
        await this.$store.dispatch('gridSelect/setResourceName', this.resourceName)
        await this.$nextTick()
        await this.$store.dispatch('gridSelect/setDialogState', true)
        this.handleClose()
      },
      onSearch(query) {
        this.query = query
        // * Try focus on first match
        this.$nextTick(() => {
          this.tryFocusOnFirstMarch()
        })
      },
      tryFocusOnFirstMarch() {
        const selectRef = this.$refs.baseGridSelect

        if (this.isAuthorizedToAdd && !selectRef.hoverIndex) {
          selectRef.hoverIndex++
        }
      },
      onOptionClick(option) {
        if (this.multiple) {
          return
        }
        const value = option[this.valueKey]
        this.onChange(value)
      },
      getCellContent(row, prop) {
        const content = this.get(row, prop)
        const hasContent = content !== undefined && content !== null && content !== ''
        return hasContent ? content : ''
      },
      onQueryChange() {
        this.perPage = DefaultPerPage
      },
      viewMore() {
        this.perPage += DefaultPerPage
      },
      labelFormat(op) {
        const { code, number, abbr, description } = op
        let mainFiled = number || code || abbr
        if (!description) {
          return mainFiled
        }
        if (!mainFiled && description) {
          return description
        }
        return `${mainFiled} (${description})`
      },
      onChange(value) {
        if (value === -1) {
          if (this.authorizedToAdd) {
            this.tryAddNewEntry()
          }
          return
        }

        if (value === undefined) {
          value = null
        }

        this.emitChange(value)
      },
      onClear() {
        this.$emit('entity-change', { id: '' })
        this.query = ''
      },
      emitChange(value) {
        const entityValue = this.getFullValues(value)

        this.$emit('input', value)
        this.$emit('change', value, entityValue)
        this.$emit('entity-change', entityValue)
      },
      getFullValues(value) {
        if (Array.isArray(value)) {
          return value.map(item => this.findFullValue(item))
        }
        return this.findFullValue(value)
      },
      findFullValue(value) {
        return this.filteredOptions.find(entity => entity[this.valueKey]?.toString() === value?.toString())
      },
      initForceSelect() {
        if (this.initialSearchQuery) {
          setTimeout(() => {
            this.$refs.baseGridSelect.selectedLabel = this.initialSearchQuery
            this.$refs.baseGridSelect.onInputChange()
          }, 10)
        }

        const input = this.$el.querySelector('.entity-select .el-input')

        input.addEventListener('keydown', event => {
          const selectRef = this.$refs.baseGridSelect

          if (!selectRef || event.key !== 'Tab') {
            return
          }

          const option = selectRef.options[selectRef.hoverIndex]

          if (!option || option?.value === -1) {
            return
          }

          selectRef.handleOptionSelect(option);
        })
      },
      handleClose() {
        this.$refs.baseGridSelect.handleClose()
        this.$emit('close-editor')
      },
      trySearchOnMount() {
        if (!this.hasRemoteSearch) {
          return
        }

        this.query = this.value || ''
      },
      async focus() {
        this.$refs.baseGridSelect.handleQueryChange()
        await this.$nextTick()
        this.$refs.baseGridSelect.focus()
      }
    },
    mounted() {
      this.initForceSelect()
      this.trySearchOnMount()
      scrollToSelectedValue(this.value, this.$refs.baseGridSelect)

      if (this.focusOnMount) {
        this.focus()
      }
    },
    watch: {
      value(value) {
        scrollToSelectedValue(value, this.$refs.baseGridSelect)
      },
    },
  }
</script>
<style lang="scss">
  .el-select .el-input {
    input {
      @apply truncate;
    }
  }

  .has-table .el-select-dropdown__list {
    padding-top: 30px;
  }

  .has-table .el-select-dropdown__item {
    height: 30px;
    line-height: 30px;
  }
</style>
