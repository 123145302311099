<template>
  <div class="mt-4 flex flex-col space-y-2">
    <div>
      <div class="mb-2 max-w-[200px]">
        <BaseButton
          block
          variant="primary-light"
          @click="onAdd(codeTypes.EQUIPMENT)"
        >
          <EquipmentIcon class="w-4 h-4"/>
          <PlusIcon class="w-4 h-4 ml-2"/>
          <span>{{ $t('Equipment') }}</span>
        </BaseButton>
      </div>
      <SpecialSourceItem
        v-for="item in selectedEquipment"
        :key="item.id"
        :item="item"
        :duration="duration"
        @update="updateItem"
        @delete="deleteItem(item)"
      />
      <div
        v-if="selectedEquipment.length"
        class="py-2 border-b border-gray-200"
      />
    </div>
    <div>
      <div class="mb-2 max-w-[200px]">
        <BaseButton
          block
          variant="primary-light"
          @click="onAdd(codeTypes.MATERIAL)"
        >
          <MaterialIcon class="w-4 h-4"/>
          <PlusIcon class="w-4 h-4 ml-2"/>
          <span>{{ $t('Material') }}</span>
        </BaseButton>
      </div>
      <SpecialSourceItem
        v-for="item in selectedMaterials"
        :key="item.id"
        :item="item"
        :duration="duration"
        @update="updateItem"
        @delete="deleteItem(item)"
      />
      <div
        v-if="selectedMaterials.length"
        class="py-2 border-b border-gray-200"
      />
    </div>
    <div>
      <div class="mb-2 max-w-[200px]">
        <BaseButton
          block
          variant="primary-light"
          @click="onAdd(codeTypes.PAY)"
        >
          <PayCodeIcon class="w-4 h-4"/>
          <PlusIcon class="w-4 h-4 ml-2"/>
          <span>{{ $t('Pay Code') }}</span>
        </BaseButton>
      </div>
      <SpecialSourceItem
        v-for="item in selectedPayCodes"
        :key="item.id"
        :item="item"
        :duration="duration"
        @update="updateItem"
        @delete="deleteItem(item)"
      />
      <div
        v-if="selectedPayCodes.length"
        class="py-2 border-b border-gray-200"
      />
    </div>
    <div>
      <div class="mb-2 max-w-[200px]">
        <BaseButton
          block
          variant="primary-light"
          @click="onAdd(codeTypes.TIME_OFF)"
        >
          <TimeOffIcon class="w-4 h-4"/>
          <PlusIcon class="w-4 h-4 ml-2"/>
          <span>{{ $t('Time Off') }}</span>
        </BaseButton>
      </div>
      <SpecialSourceItem
        v-for="item in selectedTimeOff"
        :key="item.id"
        :item="item"
        :duration="duration"
        :time-off-data="timeOffData"
        @update="updateItem"
        @delete="deleteItem(item)"
      />
      <div
        v-if="selectedTimeOff.length"
        class="py-2 border-b border-gray-200"
      />
    </div>
    <BaseFormDialog
      v-if="showAddCodeDialog"
      :open.sync="showAddCodeDialog"
      :append-to-body="true"
      layout=""
    >
      <BaseForm
        layout="modal"
        @submit="addCode"
      >
        <EquipmentSelect
          v-if="selectedType === codeTypes.EQUIPMENT"
          v-model="model.id"
          :filter-available-in-timesheets="true"
          class="col-span-6 md:col-span-3"
        />
        <MaterialSelect
          v-if="selectedType === codeTypes.MATERIAL"
          v-model="model.id"
          :filter-available-in-timesheets="true"
          class="col-span-6 md:col-span-3"
        />
        <PayCodeSelect
          v-if="selectedType === codeTypes.PAY"
          v-model="model.id"
          :label="$t('Pay Code')"
          :filter-available-in-timesheets="true"
          class="col-span-6 md:col-span-3"
        />

        <EmployeeTimeOffSelect
          v-if="selectedType === codeTypes.TIME_OFF"
          v-model="model.id"
          :employee-id="employeeId"
          :label="$t('Time Off')"
          class="col-span-6 md:col-span-3"
          @entity-change="selectedTimeOffPolicy = $event"
        />

        <BaseInput
          v-if="!usesDollarAmount"
          v-model="model.units"
          :label="[codeTypes.EQUIPMENT, codeTypes.TIME_OFF].includes(selectedType) ? $t('Hours') : $t('Units')"
          :rules="validationRules"
          type="number"
          class="col-span-6 md:col-span-3"
          step="0.01"
        >
          <template #suffix>
            <template v-if="[codeTypes.EQUIPMENT, codeTypes.TIME_OFF].includes(selectedType)">
              {{ $t('hours') }}
            </template>
            <template v-else>
              {{ $t('units') }}
            </template>
          </template>
        </BaseInput>
        <BaseInput
          v-else
          v-model="model.special_rate"
          :label="$t('Amount')"
          :placeholder="$t('Enter amount')"
          type="number"
          rules="min_value:0"
          class="col-span-6 md:col-span-3"
          step="0.01"
        >
          <template #suffix>
            $
          </template>
        </BaseInput>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import { globalResources } from "@/components/form/util";
import { codeTypes } from "@/modules/payroll/components/rates/util";
import EquipmentIcon from "@/modules/payroll/components/timesheets/EquipmentIcon.vue";
import { PlusIcon } from "vue-feather-icons";
import SpecialSourceItem from "@/modules/payroll/components/timesheets/weekly-timesheets/SpecialSourceItem.vue";
import MaterialIcon from "@/modules/payroll/components/timesheets/MaterialIcon.vue";
import PayCodeIcon from "@/modules/payroll/components/timesheets/PayCodeIcon.vue";
import { getUnitsFromDuration } from "@/modules/payroll/utils/timeCardUtils";
import EmployeeTimeOffSelect from "@/components/select/entities/EmployeeTimeOffSelect.vue";
import TimeOffIcon from "@/modules/payroll/components/timesheets/TimeOffIcon.vue";
import axios from "axios";
import { getSpecialSourceDurationValidation } from "@/modules/payroll/utils/timeOffUtils";

export default {
  components: {
    TimeOffIcon,
    EmployeeTimeOffSelect,
    PayCodeIcon,
    MaterialIcon,
    SpecialSourceItem,
    EquipmentIcon,
    PlusIcon
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    duration: {
      type: Number,
      default: 0,
    },
    employeeId: {
      type: String,
    }
  },
  data() {
    return {
      selectedType: null,
      showAddCodeDialog: false,
      selectedTimeOffPolicy: null,
      timeOffData: [],
      model: {
        id: null,
        units: 0,
        special_rate: undefined,
      }
    }
  },
  computed: {
    codeTypes() {
      return codeTypes
    },
    selectedEquipment() {
      return this.getListFor(codeTypes.EQUIPMENT)
    },
    selectedMaterials() {
      return this.getListFor(codeTypes.MATERIAL)
    },
    selectedPayCodes() {
      return this.getListFor(codeTypes.PAY)
    },
    selectedTimeOff() {
      return this.getListFor(codeTypes.TIME_OFF)
    },
    validationRules() {
      return getSpecialSourceDurationValidation({
        type: this.selectedType,
        duration: this.duration,
        timeOffData: this.selectedTimeOffPolicy
      })
    },
    selectedCode() {
      return this.getSpecialSource(this.model.id, this.selectedType)
    },
    usesDollarAmount() {
      return this.selectedCode?.uses_amount_in_timesheets
    }
  },
  methods: {
    getSpecialSource(id, type) {
      const resourceMap = {
        [codeTypes.EQUIPMENT]: globalResources.Equipments,
        [codeTypes.MATERIAL]: globalResources.Materials,
        [codeTypes.PAY]: globalResources.PayCodes,
        [codeTypes.TIME_OFF]: globalResources.TimeOffPolicies,
      }
      const resourceName = resourceMap[type]
      return this.$store.getters['globalLists/getResourceById'](resourceName, id) || {}
    },
    getListFor(type) {
      return this.value?.filter((item) => item?.type === type).map((item) => {
        const specialSource = this.getSpecialSource(item.id, type)
        return {
          ...specialSource,
          units: item.units,
          special_rate: item.special_rate,
          type: item.type,
        }
      })
    },
    onAdd(type) {
      this.selectedType = type
      this.showAddCodeDialog = true
      this.model.units = getUnitsFromDuration(this.duration)
    },
    deleteItem(item) {
      const newValue = this.value.filter((i) => i.id !== item.id)
      this.$emit('input', newValue)
    },
    updateItem(item) {
      const newValue = [...this.value]
      const index = newValue.findIndex((i) => i.id === item.id)
      newValue.splice(index, 1, item)
      this.$emit('input', newValue)
    },
    addCode() {
      const newValue = [...this.value]
      if (this.usesDollarAmount) {
        this.model.units = 1
      }
      const newItem = {
        ...this.getSpecialSource(this.model.id, this.selectedType),
        ...this.model,
        type: this.selectedType,
      }
      newValue.push(newItem)
      this.$emit('input', newValue)
      this.showAddCodeDialog = false
    },
    onDurationChange(duration) {
      if (this.selectedType !== codeTypes.EQUIPMENT) {
        return 0
      }
      this.model.units = getUnitsFromDuration(duration)
    },
    async getTimeOffData() {
      if (!this.selectedTimeOff.length || !this.employeeId) {
        return
      }
      const res = await axios.get('/restify/employees/getters/time-off-statuses', {
        params: {
          repositories: [this.employeeId],
        }
      })
      this.timeOffData = res.data[this.employeeId] || []
    }
  },
  mounted() {
    this.getTimeOffData()
  },
  watch: {
    duration: {
      immediate: true,
      handler(value) {
        this.onDurationChange(value)
      },
    }
  }
}
</script>
