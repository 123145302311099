<template>
  <div>
    <AgDataTable
        :columns="columns"
        :edit-entity-url-query="editEntityUrlQuery"
        :add-text="$t('New flexible benefit plan')"
        entity="flexible-benefit-plan"
        import-url="/payroll/codes/flexible-benefit-plans/import"
        url="/restify/flexible-benefit-plans"
        actions="search,refresh,add,edit,import,export"
        permission="employees"
        hide-actions="view"
        ref="table"
        @add="onRowAdd"
        @edit="onRowEdit"
    />
    <BaseFormDialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit Flexible Benefit Plan') : $t('Add Flexible Benefit Plan')"
        :open.sync="showDialog"
        size="2xl"
        @close="onEventTrigger(false)"
    >
      <FlexibleBenefitPlanForm
          :data="rowToEdit"
          :key="renderKey"
          @cancel="onEventTrigger(false)"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import { BenefitCode } from '@/modules/common/types/models'
  import FlexibleBenefitPlanForm from '@/modules/payroll/components/codes/FlexibleBenefitPlanForm.vue'

  export default defineComponent({
    components: {
      FlexibleBenefitPlanForm,
    },
    data() {
      return {
        renderKey: <number>1,
        editEntityUrlQuery: <string>'?id={ID}',
        rowToEdit: null as API.Data<BenefitCode> | null,
        showDialog: <boolean>false,
      }
    },
    computed: {
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Code'),
            field: 'attributes.code',
            component: 'EntityLink',
            redirectTo: '/payroll/codes/flexible-benefit-plans?id={ID}',
          },
          {
            headerName: this.$t('Description'),
            field: 'attributes.description',
          },
          {
            headerName: this.$t('Account'),
            field: 'number',
            component: 'AccountLink',
          },
          {
            headerName: this.$t('Sub'),
            field: 'subaccount',
          },
          {
            headerName: this.$t('Calculate By'),
            field: 'attributes.calculate_by',
            component: 'FormattedText',
          },
          {
            headerName: this.$t('Multiply By'),
            field: 'attributes.multiply_by',
            component: 'FormattedText',
          },
          {
            label: this.$t('W2 Memo Code'),
            prop: 'attributes.w2_code',
            hide: true,
          },
          {
            label: this.$t('Active'),
            prop: 'attributes.active',
            component: 'Status',
            align: 'center',
          },
        ]
      },
    },
    methods: {
      onRowEdit(row: API.Data<BenefitCode>) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        // @ts-ignore
        this.$refs.table.refresh()
      },
      onEventTrigger(refresh: boolean = true) {
        this.rowToEdit = null
        this.showDialog = false

        if (!refresh) {
          return
        }

        this.refreshTable()
      },
    },
  })
</script>
