<template>
  <BaseReportForm
    :filters="model"
    :report-number="reportNumber"
    :hide-presets="true"
    :consistentRowColor="true"
    :no-cache="true"
    class="vendor-1099s-report"
  >
    <template #filters>
      <BaseFilterRow
        :title="$t('Report Format')"
      >
        <BaseSelect
          v-model="format"
          :add-entity="false"
          :options="formatOptions"
          class="col-span-3"
        />
      </BaseFilterRow>
      <BaseFilterRow
        :title="$t('Tax Year')"
      >
        <BaseInput
          v-model="model.tax_year"
          class="col-span-3"
          :name="$t('Tax Year')"
          :placeholder="$t('Tax Year (YYYY)')"
          type="number"
          rules="required|min_value:2014"
        />
      </BaseFilterRow>
    </template>

    <template #table="{ data, loading }">
      <Vendor1099sProofListingReportTable
        v-if="format === formats.Vendor1099s_ProofListing"
        :data="data"
        :filters="model"
        :data-loading="loading"
        :report-number="reportNumber"
        ref="reportTable"
      />
      <div v-else>
        Not implemented
      </div>
    </template>

  </BaseReportForm>
</template>
<script>
import TextFileList from "@/modules/payroll/components/reports/TextFileList.vue";
import Vendor1099sProofListingReportTable from "@/modules/accounts-payable/components/reports/vendor-1099s/Vendor1099sProofListingReportTable.vue";
import {
  Vendor1099sReportOptions
} from '@/modules/accounts-payable/components/reports/util'

export default {
  components: {
    TextFileList,
    Vendor1099sProofListingReportTable,
  },
  data() {
    return {
      formats: Vendor1099sReportOptions.Formats,
      format: Vendor1099sReportOptions.Formats.Vendor1099s_ProofListing,
      model: {
        tax_year: null,
      },
    }
  },
  computed: {
    reportNumber() {
      const reportMap = {
        [Vendor1099sReportOptions.Formats.Vendor1099s_ProofListing]: '3884a',
        default: '3884a',
      }
      return reportMap[this.format] || reportMap.default
    },
    formatOptions() {
      const formatOptions = [
        {
          label: this.$t('Vendor 1099s Proof Listing'),
          value: Vendor1099sReportOptions.Formats.Vendor1099s_ProofListing,
        },
        // Others TBD
      ]
      
      return formatOptions
    },
    currentUser() {
      return this.$store.state.auth.user
    },
    apCalendarYear() {
      return this.$settings(this.$modules.AP, 'calendar_year')
    }
  },
  watch: {
    apCalendarYear: {
      handler(value) {
        if (this.model.tax_year) {
          return
        }

        this.model.tax_year = value
      },
      immediate: true,
    },
  }
}
</script>
