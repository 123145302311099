import axios from 'axios'

const types = {
  SET_CURRENT_ACCOUNT: 'SET_CURRENT_ACCOUNT',
  SET_CURRENT_ACCOUNT_LOADING: 'SET_CURRENT_ACCOUNT_LOADING',
  SET_CURRENT_SUB_ACCOUNT: 'SET_CURRENT_SUB_ACCOUNT',
  SET_CURRENT_JOURNAL: 'SET_CURRENT_JOURNAL',
  SET_CURRENT_JOURNAL_ENTRIES: 'SET_CURRENT_JOURNAL_ENTRIES',
  SET_CURRENT_FINANCIAL_REPORT: 'SET_CURRENT_FINANCIAL_REPORT',
  SET_CURRENT_RECONCILIATION: 'SET_CURRENT_RECONCILIATION',
  RESET_CURRENT_RECONCILIATION: 'RESET_CURRENT_RECONCILIATION',
  SET_CURRENT_RECONCILIATION_ENTRIES: 'SET_CURRENT_RECONCILIATION_ENTRIES',
};
const state = {
  currentAccount: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentAccountLoading: false,
  currentSubAccount: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentJournal: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentJournalEntries: [],
  currentFinancialReport: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentReconciliation: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentReconciliationEntries: [],
  journalLoading: false,
  financialReportLoading: false,
  reconciliationLoading: false,
};

const mutations = {
  [types.SET_CURRENT_ACCOUNT]: (state, data) => {
    state.currentAccount = {
      ...state.currentAccount,
      ...data,
    }
  },
  [types.SET_CURRENT_ACCOUNT_LOADING]: (state, data) => {
    state.currentAccountLoading = data
  },
  [types.SET_CURRENT_SUB_ACCOUNT]: (state, data) => {
    state.currentSubAccount = {
      ...state.currentSubAccount,
      ...data,
    }
  },
  [types.SET_CURRENT_JOURNAL]: (state, data) => {
    state.journalLoading = false
    state.currentJournal = {
      ...state.currentJournal,
      ...data,
    }
  },
  [types.SET_CURRENT_JOURNAL_ENTRIES]: (state, data) => {
    state.currentJournalEntries = data
  },
  [types.SET_CURRENT_FINANCIAL_REPORT]: (state, data) => {
    state.financialReportLoading = false
    state.currentFinancialReport = {
      ...state.currentFinancialReport,
      ...data,
    }
  },
  [types.SET_CURRENT_RECONCILIATION]: (state, data) => {
    state.reconciliationLoading = false
    state.currentReconciliation = {
      ...state.currentReconciliation,
      ...data,
    }
  },
  [types.RESET_CURRENT_RECONCILIATION]: (state, data) => {
    state.currentReconciliation = {
      attributes: {},
      relationships: {},
      meta: {}
    }
  },
  [types.SET_CURRENT_RECONCILIATION_ENTRIES]: (state, data) => {
    state.currentReconciliationEntries = data
  },
};

const actions = {
  async getAccount({ commit }, { id, params }) {
    try {
      commit(types.SET_CURRENT_ACCOUNT_LOADING, true)
      const { data } = await axios.get(`/restify/accounts/${id}`, {
        params
      })
      commit(types.SET_CURRENT_ACCOUNT, data)
    } finally {
      commit(types.SET_CURRENT_ACCOUNT_LOADING, false)
    }
  },
  async getSubAccount({ commit }, id) {
    const { data } = await axios.get(`/restify/subaccounts/${id}`)
    commit(types.SET_CURRENT_SUB_ACCOUNT, data)
  },
  async getJournal({ commit }, id) {
    state.journalLoading = true
    const { data } = await axios.get(`/restify/journals/${id}`)
    commit(types.SET_CURRENT_JOURNAL, data)
  },
  async getFinancialReport({ commit }, id) {
    state.financialReportLoading = true
    const { data } = await axios.get(`/restify/financial-reports/${id}`)
    commit(types.SET_CURRENT_FINANCIAL_REPORT, data)
  },
  async getReconciliation({ commit }, id) {
    state.reconciliationLoading = true
    const { data } = await axios.get(`/restify/reconciliations/${id}`)
    commit(types.SET_CURRENT_RECONCILIATION, data)
  },
  async patchAccount({ state }, data = {}) {
    await axios.patch(`/restify/accounts/${data.id}`, data)
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
