import axios from 'axios'
import { isProduction } from '@/isProduction'
import { entityPreviewFields } from "@/modules/common/components/entity-preview/entities";
import { resourceStatuses } from "@/enum/enums";

const types = {
  SET_CHECKS_TEMPLATE: 'SET_CHECKS_TEMPLATE',
  SET_CURRENT_EMPLOYEE: 'SET_CURRENT_EMPLOYEE',
  SET_CURRENT_EMPLOYEE_LOADING: 'SET_CURRENT_EMPLOYEE_LOADING',
  SET_CURRENT_TIME_OFF_POLICY: 'SET_CURRENT_TIME_OFF_POLICY',
  SET_CURRENT_TIME_OFF_POLICY_LOADING: 'SET_CURRENT_TIME_OFF_POLICY_LOADING',
  SET_CURRENT_TIMECARD_BATCH: 'SET_CURRENT_TIMECARD_BATCH',
  SET_CURRENT_TIMECARD_BATCH_TO_PENDING: 'SET_CURRENT_TIMECARD_BATCH_TO_PENDING',
  SET_CURRENT_TIMECARD_BATCH_TIMECARDS: 'SET_CURRENT_TIMECARD_BATCH_TIMECARDS',
  SET_CURRENT_TIMECARD_BATCH_TIMECARDS_LOADING: 'SET_CURRENT_TIMECARD_BATCH_TIMECARDS_LOADING',
  SET_CURRENT_TIMECARD_BATCH_ENTRIES: 'SET_CURRENT_TIMECARD_BATCH_ENTRIES',
  UPDATE_TIMECARD_ENTRIES: 'UPDATE_TIMECARD_ENTRIES',
  DELETE_TIMECARD: 'DELETE_TIMECARD',
  SET_CURRENT_TIMECARD_BATCH_ENTRIES_LOADING: 'SET_CURRENT_TIMECARD_BATCH_ENTRIES_LOADING',
  SET_CURRENT_TIMECARD_BATCH_LOADING: 'SET_CURRENT_TIMECARD_BATCH_LOADING',
  SET_CURRENT_PAYROLL_BATCH: 'SET_CURRENT_PAYROLL_BATCH',
  SET_CURRENT_PAYROLL_BATCH_DETAILS: 'SET_CURRENT_PAYROLL_BATCH_DETAILS',
  SET_CURRENT_PAYROLL_BATCH_LOADING: 'SET_CURRENT_PAYROLL_BATCH_LOADING',
  SET_CURRENT_PAYROLL_BATCH_DETAILS_LOADING: 'SET_CURRENT_PAYROLL_BATCH_DETAILS_LOADING',
};
const state = {
  currentEmployee: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentPayrollBatch: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentPayrollBatchDetails: {},
  currentTimeOffPolicy: {
    attributes: {},
    relationships: {},
    meta: {}
  },
  currentEmployeeLoading: false,
  currentTimeOffPolicyLoading: false,
  defaultChecksTemplate: 'box',
  currentTimecardBatch: {},
  currentTimecardBatchTimecards: [],
  currentTimecardBatchTimecardsLoading: false,
  currentTimecardBatchEntries: [],
  currentTimecardBatchEntriesLoading: false,
  currentPayrollBatchLoading: false,
  currentPayrollBatchDetailsLoading: false,
  timecardBatchLoading: false,
  calendarYear: new Date().getFullYear(),
};

const mutations = {
  [types.SET_CURRENT_EMPLOYEE]: (state, customer) => {
    state.currentEmployee = customer
  },
  [types.SET_CURRENT_EMPLOYEE_LOADING]: (state, value) => {
    state.currentEmployeeLoading = value
  },
  [types.SET_CURRENT_PAYROLL_BATCH]: (state, batch) => {
    state.currentPayrollBatch = batch
  },
  [types.SET_CURRENT_PAYROLL_BATCH_LOADING]: (state, value) => {
    state.currentPayrollBatchLoading = value
  },
  [types.SET_CURRENT_PAYROLL_BATCH_DETAILS]: (state, batchDetails) => {
    state.currentPayrollBatchDetails = batchDetails
  },
  [types.SET_CURRENT_PAYROLL_BATCH_DETAILS_LOADING]: (state, value) => {
    state.currentPayrollBatchDetailsLoading = value
  },
  [types.SET_CHECKS_TEMPLATE]: (state, value) => {
    state.defaultChecksTemplate = value
  },
  [types.SET_CURRENT_TIMECARD_BATCH]: (state, batch) => {
    state.currentTimecardBatch = batch
  },
  [types.SET_CURRENT_TIMECARD_BATCH_TO_PENDING]: (state, batch) => {
    if (!state.currentTimecardBatch.status) {
      return
    }
    state.currentTimecardBatch.status = resourceStatuses.Pending
    state.currentTimecardBatch.payroll_batch_id = null
  },
  [types.SET_CURRENT_TIMECARD_BATCH_TIMECARDS]: (state, data) => {
    state.currentTimecardBatchTimecards = data
  },
  [types.SET_CURRENT_TIMECARD_BATCH_TIMECARDS_LOADING]: (state, value) => {
    state.currentTimecardBatchTimecardsLoading = value
  },
  [types.SET_CURRENT_TIMECARD_BATCH_ENTRIES]: (state, data) => {
    state.currentTimecardBatchEntries = data
  },
  [types.SET_CURRENT_TIMECARD_BATCH_LOADING]: (state, value) => {
    state.timecardBatchLoading = value
  },
  [types.SET_CURRENT_TIME_OFF_POLICY]: (state, customer) => {
    state.currentTimeOffPolicy = customer
  },
  [types.SET_CURRENT_TIME_OFF_POLICY_LOADING]: (state, value) => {
    state.currentTimeOffPolicyLoading = value
  },
  [types.UPDATE_TIMECARD_ENTRIES]: (state, data) => {
    const { timecard_id, entries } = data
    const index = state.currentTimecardBatchTimecards.findIndex((item) => item.id === timecard_id)
    if (index === -1) {
      return
    }
    const timecard = state.currentTimecardBatchTimecards[index]
    timecard.relationships.entries = entries
    state.currentTimecardBatchTimecards.splice(index, 1, timecard)
  },
  [types.DELETE_TIMECARD]: (state, index) => {
    state.currentTimecardBatchTimecards.splice(index, 1)
  }
};

const actions = {
  async getEmployee({ commit }, employeeId) {
    let employee = {}
    try {
      const baseRelationships = 'addresses,contacts,checks,user,craftCode'
      const related = isProduction() ? baseRelationships : baseRelationships + `,${entityPreviewFields.WithholdingState},${entityPreviewFields.WithholdingLocal},${entityPreviewFields.WithholdingState}`

      commit(types.SET_CURRENT_EMPLOYEE_LOADING, true)
      const { data } = await axios.get(`/restify/employees/${employeeId}?`, {
        params: {
          related,
        }
      })
      commit(types.SET_CURRENT_EMPLOYEE, data)
      employee = data
    } finally {
      commit(types.SET_CURRENT_EMPLOYEE_LOADING, false)
    }
    return employee
  },
  async getTimeOffPolicy({ commit }, id) {
    let policy = {}
    try {
      commit(types.SET_CURRENT_TIME_OFF_POLICY_LOADING, true)
      const { data } = await axios.get(`/restify/time-off-policies/${id}?`, {
        params: {
          related: 'timeOffType,tenures',
        }
      })
      commit(types.SET_CURRENT_TIME_OFF_POLICY, data)
      policy = data
    } finally {
      commit(types.SET_CURRENT_TIME_OFF_POLICY_LOADING, false)
    }
    return policy
  },
  async addEmployeesToTimeOffPolicy({}, {
    timeOffPolicyId,
    employees
  }) {

    try {
      const response = await axios.post(`/restify/time-off-policies/${timeOffPolicyId}/actions?action=add-employees-to-time-off-policy`, {
        employees
      })
      return response
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async removeEmployeesFromTimeOffPolicy({}, {
    timeOffPolicyId,
    employees
  }) {
    try {
      const response = await axios.post(`/restify/time-off-policies/${timeOffPolicyId}/actions?action=remove-employees-from-time-off-policy`, {
        employees
      })
      return response
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async reinstateEmployeeTimeOffStatus({}, timeOffStatusId) {
    try {
      const response = await axios.post(`/restify/time-off-statuses/${timeOffStatusId}/actions?action=reinstate-employee`)
      return response
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async adjustEmployeeTimeOffStatus({}, {
    timeOffStatusId,
    data
  }) {
    try {
      const response = await axios.post(`/restify/time-off-statuses/${timeOffStatusId}/actions?action=adjust-time-off-status`, data)
      return response
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async moveEmployeesBetweenTimeOffPolicies({}, { from, to, employees }) {
    try {
      const response = await axios.post(`/restify/time-off-policies/actions?action=move-employees-between-policies`, {
        from,
        to,
        employees
      })

      return response
    }
    catch (err) {
      if (err.handled) {
        return
      }
      throw err
    }
  },
  async getTimecardBatch({ commit }, batchId) {
    try {
      commit(types.SET_CURRENT_TIMECARD_BATCH_LOADING, true)
      const { data } = await axios.get(`/restify/timecard-batches/${batchId}`)
      commit(types.SET_CURRENT_TIMECARD_BATCH, {
        ...data.attributes,
        ...(data.relationships || {}),
        meta: data.meta,
      })
    } finally {
      commit(types.SET_CURRENT_TIMECARD_BATCH_LOADING, false)
    }
  },
  resetTimecardBatchToPending({ commit }) {
    commit(types.SET_CURRENT_TIMECARD_BATCH_TO_PENDING)
  },
  async getPayrollBatch({ commit }, batchId) {
    try {
      commit(types.SET_CURRENT_PAYROLL_BATCH_LOADING, true)
      const { data } = await axios.get(`/restify/payroll-batches/${batchId}`)
      commit(types.SET_CURRENT_PAYROLL_BATCH, data)
    } finally {
      commit(types.SET_CURRENT_PAYROLL_BATCH_LOADING, false)
    }
  },
  async getPayrollBatchDetails({ commit }, batchId) {
    try {
      commit(types.SET_CURRENT_PAYROLL_BATCH_DETAILS_LOADING, true)
      const { data } = await axios.get(`/restify/payroll-batches/${batchId}/details`)
      commit(types.SET_CURRENT_PAYROLL_BATCH_DETAILS, data)
    } finally {
      commit(types.SET_CURRENT_PAYROLL_BATCH_DETAILS_LOADING, false)
    }
  },
  updateCalendarYear({state}, year) {
    state.calendarYear = year
  },
  async patchEmployee({ state }, data = {}) {
    await axios.patch(`/restify/employees/${data.id}`, data)
  },
  async patchPayCode({}, data = {}) {
    await axios.patch(`/restify/pay-codes/${data.id}`, data)
  }
};

const getters = {
  getCalendarYear: (state) =>  {
    return state.calendarYear
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
