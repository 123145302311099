<template>
  <ElCollapse
    v-model="expandedSections"
    class="collapse-form"
  >
    <BaseCollapseSection
      :title="$t('Select Vendors By')"
      name="select-vendors-by"
      class="m-0 collapse-item"
      hasBackground
    >
      <BaseForm
        :saveText="$t('Apply')"
        layout="vertical"
        grid-classes="grid grid-cols-1"
        :loading="loading"
        :submitDisabled="!model.vendor_ids.length && !model.min_payment_amount"
        @submit="applySelection"
      >
        <template #extra-buttons-right>
          <BaseButton
            variant="white"
            class="mr-2 "
            @click="expandedSections = []"
          >
            {{ $t('Cancel') }}
          </BaseButton>
          <BaseButton
            variant="white"
            class="mr-2 "
            @click="resetModel"
          >
            {{ $t('Reset Filters') }}
          </BaseButton>
        </template>
        <BaseAlert
          :type="$promptType.Info"
          class="mb-4"
        >
          {{ $t('Select specific vendors or enter a minimum payment amount to apply to the current selection.') }}
        </BaseAlert>
        <BaseFilterRow :title="$t('Vendors')">
          <VendorSelect
            v-model="model.vendor_ids"
            :placeholder="$t('Select Specific Vendors...')"
            :availableIds="availableVendorIds"
            multiple
            :hide-label="true"
            :collapse-tags="true"
            class="col-span-3"
            clearable
          />
        </BaseFilterRow>
        <BaseFilterRow
          :title="$t('Min Payment Amount')"
        >
          <BaseInput
            v-model="model.min_payment_amount"
            :placeholder="$t('Min Payment Amount...')"
            :name="$t('Min Payment Amount')"
            id="min_payment_amount"
            class="col-span-3"
            type="number"
          />
        </BaseFilterRow>
        <BaseFilterRow
          class="col-span-full"
        >
          <BaseRadioGroup
            v-model="action"
            class="col-span-full"
            :options="actionOptions"
          />
        </BaseFilterRow>
      </BaseForm>
    </BaseCollapseSection>
  </ElCollapse>
</template>
<script>
export default {
  props: {
    defaultMinPaymentAmount: {
      type: Number,
      default: 600
    },
    availableVendorIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      action: 'add',
      model: {
        vendor_ids: [],
        min_payment_amount: this.defaultMinPaymentAmount
      },
      actionOptions: [
        {
          label: this.$t('Add To Selection'),
          value: 'add'
        },
        {
          label: this.$t('Remove From Selection'),
          value: 'remove',
          fill: '#f56565'
        }
      ],
      expandedSections: [],
    }
  },
  methods: {
    async applySelection() {
      this.$emit(`selection:${this.action}`, this.model)
      this.expandedSections = []
      this.resetModel()
    },
    resetModel() {
      this.action = 'add'
      this.model = {
        vendor_ids: [],
        min_payment_amount: this.defaultMinPaymentAmount
      }
    },
  },
}
</script>
