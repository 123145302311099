<template>
  <EntitySelect
    url="/restify/work-sites"
    v-bind="$attrs"
    v-on="$listeners"
    :url-params="allUrlParams"
    :dataFilters="dataFilters"
    :transformData="transformData"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :set-default-option="editEntity"
    :name="$attrs.name || $t('Worksite')"
    :placeholder="$attrs.placeholder || $t('Select Worksite')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Worksite')"
    :add-label="$t('Add new Worksite')"
    :id="$attrs.id || 'work-site-select'"
    :table-columns="tableColumns"
    :label-format="formatLabel"
    label-key="number"
  />
</template>
<script>
import { formattedAddress } from "@/utils/utils";

export default {
  name: 'WorkSiteSelect',
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'number',
      }),
    },
    addEntity: {
      type: Boolean,
      default: true,
    },
    editEntity: {
      type: Boolean,
      default: false,
    },
    showActiveColumn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataFilters: {
        showActive: true,
      },
    }
  },
  computed: {
    allUrlParams() {
      if (!this.showActiveColumn) {
        return this.urlParams
      }

      return {
        ...this.urlParams,
        active: this.dataFilters.showActive ? true : undefined,
      }
    },
    tableColumns() {
      const columns = [
        {
          minWidth: 10,
          maxWidth: 40,
          name: this.$t('#'),
          prop: 'attributes.number',
        },
        {
          minWidth: 150,
          maxWidth: 150,
          name: this.$t('Name'),
          prop: 'attributes.name',
        },
        {
          minWidth: 120,
          maxWidth: 150,
          name: this.$t('Address'),
          prop: 'attributes',
          toFormat: (row) => {
            return formattedAddress(row, true)
          },
        },
        {
          minWidth: 120,
          maxWidth: 120,
          name: this.$t('Active'),
          prop: 'attributes.active',
          component: 'Status',
          class: 'justify-center',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active worksites'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
          showIf: () => this.showActiveColumn,
        },
      ]

      return columns.filter(col => !col.showIf || col.showIf())
    },
  },
  methods: {
    transformData(data) {
      return data.map((worksite) => ({
        ...worksite,
        isInactive: !worksite.attributes?.active,
      }))
    },
    formatLabel(workSite) {
      const { number, name } = workSite?.attributes || {}
      if (!name) {
        return number
      }
      return `${number} (${name})`
    },
  },
}
</script>
