<template>
  <div v-loading="loading">
    <div v-if="!loading"
    >
      <div class="print:hidden text-sm font-medium text-gray-700 my-3">
        <div class="flex">
          <span class="w-32">{{ $t('Created By ') }}</span>
          <UserLink :id="journal.created_by"/>
        </div>
        <div class="flex py-2">
          <span class="w-32">{{ $t('Posted At ') }}</span>
          <span>{{ $formatDate(journal.posted_at) }}</span>
        </div>
        <div class="flex">
          <span class="w-32">{{ $t('Action ') }}</span>
          <status-badge :status="journal.action"/>
        </div>
        <hr class="mt-2">
      </div>

      <portal to="page-title-actions">
        <div class="flex space-x-2 items-center">
          <BaseTooltip
            v-if="$can('change_journals_posting')"
            :content="$t('You cannot change the journal posting for a closed fiscal year')"
            class="print:hidden"
            :disabled="!belongsToClosedYear"
          >
            <BaseButton
              variant="white"
              :disabled="belongsToClosedYear"
              @click="showChangeJournalPostingDialog = true"
            >
              {{ $t('Change Journal Posting') }}
            </BaseButton>
          </BaseTooltip>
          <TablePrintButton
            v-if="viewMode === viewModes.List"
          />
          <GroupBySelect
            v-if="recordTypes.length > 1"
            :value="viewMode"
            :options="options"
            :view-modes="viewModes"
            :viewMode.sync="viewMode"
          />
        </div>
      </portal>

      <base-alert v-if="!recordTypes.length">
        <span class="font-medium">
          {{ $t('No records for selected journal.') }}
        </span>
      </base-alert>

      <template v-else-if="recordTypes.length > 1">
        <template v-if="viewMode === viewModes.Group">

          <nav class="flex print:hidden nav-bar"
               aria-label="Tabs">
            <div v-for="(type, index) of recordTypes"
                 :class="{'active': index === selectedTypeIndex}"
                 role="button"
                 class="nav-item"
                 @click="onSelectType(index)"
            >
              {{ getLabel(type) }}
            </div>
          </nav>

          <RecordTable
              :journal="journal"
              :record-type-name="getLabel(selectedType)"
              :columns="getColumns()"
              :url-params="urlParams()"
              :url="url()"
              :type="selectedType"
              :per-page="50"
              :show-pagination="selectedType !== types.Transaction"
              actions="search,refresh"
              ref="table"
          />
        </template>
        <template v-else>
          <div v-for="(record, index) of recordTypes"
               class="flex flex-col w-full mb-4"
               :class="{'pt-5': index > 0}"
          >
            <RecordTable
                :journal="journal"
                :record-type-name="getLabel(record)"
                :columns="getColumns(record)"
                :url-params="urlParams(record)"
                :url="url(record)"
                :per-page="50"
                :show-pagination="record !== types.Transaction"
                :ref="record"
                :type="record"
            />
          </div>
        </template>
      </template>

      <RecordTable
          v-else
          :journal="journal"
          :record-type-name="getLabel(recordTypes[0])"
          :columns="getColumns(recordTypes[0])"
          :url-params="urlParams(recordTypes[0])"
          :url="url(recordTypes[0])"
          :ref="recordTypes[0]"
          :per-page="recordTypes[0] === types.Transaction ? 1000 : 50"
          :show-pagination="recordTypes[0] !== types.Transaction"
          :type="selectedType"
          actions="search,refresh"
      />
    </div>
    <ChangeJournalPostingDialog
      v-if="showChangeJournalPostingDialog"
      :open.sync="showChangeJournalPostingDialog"
      :journal="journal"
      :appendToBody="true"
      @close="showChangeJournalPostingDialog = false"
    />
  </div>
</template>

<script>
  import axios from 'axios'
  import uniq from 'lodash/uniq'
  import pluralize from 'pluralize'
  import { formattedText } from '@/utils/utils'
  import RecordTable from '@/modules/common/components/journal/RecordTable'
  import GroupBySelect from '@/modules/common/components/journal/GroupBySelect'
  import ChangeJournalPostingDialog from '@/modules/common/components/journal/ChangeJournalPostingDialog'
  import { getColumns, relationships, types } from '@/modules/common/components/journal/util'
  import { journalActions } from "@/enum/enums";

  const viewModes = {
    Group: 'group',
    List: 'list',
  }

  export default {
    components: {
      RecordTable,
      GroupBySelect,
      ChangeJournalPostingDialog,
    },
    props: {
      journal: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },
    data() {
      return {
        loading: false,
        showChangeJournalPostingDialog: false,
        viewModes,
        types,
        options: [
          {
            label: this.$t('View as Group'),
            value: viewModes.Group,
            component: 'GridIcon',
          },
          {
            label: this.$t('View as List'),
            value: viewModes.List,
            component: 'ListIcon',
          },
        ],
        viewMode: viewModes.List,
        selectedTypeIndex: 0,
        recordTypes: [],
      }
    },
    computed: {
      selectedType() {
        return this.recordTypes[this.selectedTypeIndex]
      },
      allFiscalYears() {
        return this.$store.state.company.fiscalYears || []
      },
      fiscalYear() {
        return this.allFiscalYears.find((fy) => fy.attributes.fiscal_year == this.journal?.fiscal_year) || {}
      },
      belongsToClosedYear() {
        return this.fiscalYear?.attributes?.closed_at
      },
    },
    methods: {
      isEquipmentHistory(type) {
        const rType = type || this.selectedType
        return rType === types.EquipmentHistory
      },
      url(type) {
        const rType = type || this.selectedType
        let repo = pluralize(rType)
        if (this.isEquipmentHistory(type)) {
          repo = 'equipment-utilization-histories'
        }

        return `/restify/${repo}`
      },
      urlParams(type) {
        const rType = type || this.selectedType
        let journalKey = 'journal_id'
        if (this.journal.action === journalActions.Void && !this.isEquipmentHistory(type)) {
          journalKey = 'void_journal_id'
        }
        return {
          [journalKey]: this.journal.id,
          related: relationships[rType],
        }
      },
      getColumns(type) {
        const rType = type || this.selectedType
        return getColumns(rType, this)
      },
      getLabel(type) {
        if (type === types.InvoicePayments) {
          return this.$t('Payments')
        }

        return formattedText(pluralize(type))
      },
      onSelectType(typeIndex) {
        this.selectedTypeIndex = typeIndex
      },
      async tryGetRecords() {
        try {
          this.loading = true

          let { data } = await axios.get(`/restify/journals/${this.journal.id}`, {
            params: {
              related: 'records',
            },
          })

          this.setAvailableRecords(data)
        } catch (err) {
          console.warn(err)
          if (err.handled) {
            return
          }

          this.$error(this.$t('Something went wrong please try again.'))
        } finally {
          this.loading = false
        }
      },
      setAvailableRecords(journal) {
        const records = this.get(journal, 'relationships.records', [])
        let recordSourceTypes = records.sort((a, b) => a.attributes.source_type > b.attributes.source_type ? -1 : 1).map(record => record.attributes.source_type)
        this.recordTypes = uniq(recordSourceTypes)
      },
    },
    async mounted() {
      await this.tryGetRecords()
    },
  }
</script>
