import { cellEditors } from "@/components/ag-grid/cellEditors/cellEditors";
import i18n from "@/i18n";
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";
import { globalResourceLabels, globalResources } from "@/components/form/util";
import { codeLabelsByType } from "@/modules/payroll/components/rates/util";
import { cellClasses, getCellClasses, requiredValueSetter } from "@/components/ag-grid/columnUtils";
import { getSpecialCodeTypeLabel } from "@/components/ag-grid/cellEditors/cellEditorUtils";
import store from "@/store";
import { accountRestrictions } from "@/modules/ledger/enum/account";
import { formatDate } from "@/plugins/dateFormatPlugin";
import { resourceStatuses } from "@/enum/enums";
import { getLaborHourAbbr, laborHourTypeOptions } from "@/modules/service-billing/util/service-billing";
import { getRestrictionLabel } from "@/enum/account";
import { error } from "@/components/common/NotificationPlugin";

export const allCostCenterOptions = [
  {
    label: i18n.t('G&A'),
    value: costCenterTypes.GeneralAndAdministrative,
  },
  {
    label: i18n.t('JOB'),
    value: costCenterTypes.Job,
  },
  {
    label: i18n.t('LAB'),
    value: costCenterTypes.Labor,
  },
  {
    label: i18n.t('S/B'),
    value: costCenterTypes.WorkOrder,
  },
  {
    label: i18n.t('EQP'),
    value: costCenterTypes.Equipment,
  },
  {
    label: i18n.t('INV'),
    value: costCenterTypes.Inventory,
  },
]

export const costCenterCol = {
  headerName: i18n.t('Cost Center'),
  field: 'cost_center',
  cellEditor: cellEditors.CostCenterSelect,
  editable: true,
  sortable: false,
  minWidth: 70,
  maxWidth: 100,
}

export function isSourceEditable(params) {
  const invalidCostCenters = [costCenterTypes.GeneralAndAdministrative, costCenterTypes.Inventory]
  return !invalidCostCenters.includes(params.data?.cost_center)
}

export const sourceCol = {
  field: 'source_id',
  headerName: ' ',
  component: 'SourceLink',
  minWidth: 180,
  maxWidth: 320,
  editable: isSourceEditable,
  sortable: false,
  cellRendererParams: {
    target: '_blank',
  },
  cellEditor: cellEditors.GlobalResourceSelect,
  cellEditorParams: params => {
    const { cost_center } = params.data
    const resourceMapping = {
      [costCenterTypes.Job]: globalResources.Jobs,
      [costCenterTypes.WorkOrder]: globalResources.WorkOrders,
      [costCenterTypes.Equipment]: globalResources.Equipments,
      [costCenterTypes.Inventory]: globalResources.Materials,
      [costCenterTypes.Material]: globalResources.Materials,
      [costCenterTypes.Labor]: globalResources.CraftCodes,
    }
    const resourceName = resourceMapping[cost_center]

    return {
      resourceName,
      unauthorizedToAdd: false,
      filterMethod: (option) => {
        if (cost_center === costCenterTypes.Labor && option?.code === '*') {
          return false
        }
        if (!option.status) {
          return true
        }
        if (params.colDef?.timesheets && option?.available_in_timesheets !== undefined) {
          return option.available_in_timesheets
        }
        return [resourceStatuses.Open, resourceStatuses.Active].includes(option.status)
      },
    }
  },
  cellClass: params => {
    if (params.node.footer) {
      return ''
    }
    const hasValue = params.data?.source_id
    if (!isSourceEditable(params)) {
      return 'bg-gray-100 cursor-not-allowed'
    }
    if (!hasValue) {
      return cellClasses.Invalid
    }
    return ''
  },
  suppressNavigable: params => {
    return !isSourceEditable(params)
  },
  valueSetter: params => {
    params.data.source_id = params.newValue
    params.data.addl_source_id = ''
    return true
  },
}

export const typeCol = {
  field: 'type_id',
  headerName: ' ',
  component: 'TypeLink',
  cellRendererParams: {
    target: '_blank',
  },
  minWidth: 50,
  maxWidth: 70,
  editable: params => {
    return params.data?.cost_center === costCenterTypes.Equipment
  },
  sortable: false,
  cellEditor: cellEditors.GlobalResourceSelect,
  cellEditorSelector: params => {
    if (isLabor(params)) {
      return {
        component: cellEditors.BaseSelect
      }
    }
    return {
      component: cellEditors.GlobalResourceSelect
    }
  },
  cellEditorParams: params => {
    const { cost_center } = params.data
    const resourceMapping = {
      [costCenterTypes.Job]: globalResources.JobCostTypes,
      [costCenterTypes.WorkOrder]: globalResources.ServiceBillingCostTypes,
      [costCenterTypes.Equipment]: globalResources.EquipmentTypes,
    }
    const resourceName = resourceMapping[cost_center]
    let options = []
    if (isLabor(params)) {
      options = laborHourTypeOptions
    }
    return {
      resourceName,
      options,
    }
  },
  cellClass: params => {
    if (params.node.footer) {
      return ''
    }
    const hasValue = params.data?.type_id
    if (params.data?.cost_center !== costCenterTypes.Equipment) {
      return 'bg-gray-100 cursor-not-allowed'
    }
    if (!hasValue) {
      return cellClasses.Invalid
    }
    return ''
  },
  valueGetter: params => {
    if (isLabor(params)) {
      return getLaborHourAbbr(params.data?.labor_type)
    }
    return params.data?.type_id
  },
  suppressNavigable: params => {
    return !isSourceEditable(params)
  },
  valueSetter: params => {
    params.data.type_id = params.newValue
    params.data.addl_source_id = ''
    if (isLabor(params)) {
      params.data.labor_type = params.newValue
    }
    return true
  },
}

function isLabor(params) {
  return params.data?.cost_center === costCenterTypes.Labor
}

export const additionalSourceCol = (props = {}) => {
  const { readOnly } = props
  return {
    field: 'addl_source_id',
    headerName: ' ',
    minWidth: 150,
    maxWidth: 200,
    component: 'AddlSourceLink',
    cellRendererParams: {
      target: '_blank',
    },
    sortable: false,
    editable: isSourceEditable,
    cellEditorSelector: params => {
      if (params.data?.cost_center === costCenterTypes.Job) {
        return {
          component: cellEditors.LineItemSelect
        }
      }
      return {
        component: cellEditors.GlobalResourceSelect
      }
    },
    cellEditorParams: params => {
      const { cost_center } = params.data
      const resourceMapping = {
        [costCenterTypes.Equipment]: globalResources.EquipmentMaintenances,
      }
      const resourceName = resourceMapping[cost_center]
      return {
        resourceName,
      }
    },
    cellClass: params => {
      if (params.node.footer || readOnly) {
        return ''
      }
      const validCostCenters = [costCenterTypes.Job, costCenterTypes.Equipment].includes(params.data?.cost_center)
      const hasValue = params.data?.addl_source_id
      if (!validCostCenters) {
        return 'bg-gray-100 cursor-not-allowed'
      }
      if (!hasValue && params.data?.cost_center === costCenterTypes.Job) {
        return cellClasses.Invalid
      }
      return ''
    },
    suppressNavigable: params => {
      const validCostCenters = [costCenterTypes.Job, costCenterTypes.Equipment].includes(params.data?.cost_center)
      return !validCostCenters
    },
    valueSetter: params => {
      params.data.addl_source_id = params.newValue
      return true
    }
  }
}

export const accountCol = (readOnly) => {
  return {
    field: 'account',
    minWidth: 90,
    maxWidth: 250,
    headerName: 'Account',
    component: 'AccountLink',
    cellRendererParams: {
      target: '_blank',
    },
    editable: true,
    cellEditor: cellEditors.AccountSelect,
    cellEditorParams: {
      valueKey: 'number',
    },
    valueSetter: params => {
      params.data.account = params.newValue
      return true
    },
    cellClass: params => {
      if(readOnly) {
        return ''
      }
      const { cost_center, account } = params?.data || {}

      if (!cost_center) {
        return
      }

      if (!account) {
        return cellClasses.Invalid
      }

      const isRestricted = isAccountRestrictedToUse(cost_center, account)
      if (isRestricted) {
        showAccountError(account)
        return cellClasses.Invalid
      }
      return ''
    },
    suppressNavigable: () => true
  }
}

export const subAccountCol = {
  field: 'subaccount',
  minWidth: 60,
  maxWidth: 80,
  headerName: 'Sub',
  component: 'SubAccountLink',
  cellRendererParams: {
    target: '_blank',
    showDescription: false,
  },
  editable: true,
  cellEditor: cellEditors.SubAccountSelect,
  cellEditorParams: {
    valueKey: 'number',
  },
  valueSetter: params => {
    params.data.subaccount = params.newValue
    return true
  },
  suppressNavigable: () => true
}

export const descriptionCol = {
  headerName: i18n.t('Description'),
  field: 'description',
  minWidth: 160,
  maxWidth: 300,
  editable: true,
  cellEditor: 'agLargeTextCellEditor',
  cellEditorParams: {
    maxLength: 65534, // max length of a text column in MySQL
    rows: 5,
    cols: 50
  }
}

export const dateCol = {
  headerName: i18n.t('Date'),
  field: 'date',
  cellEditor: cellEditors.Date,
  editable: true,
  minWidth: 120,
  valueFormatter: (params) => {
    return formatDate(params.value)
  },
  cellClass: params => {
    return params.node.footer ? '' : getCellClasses(params, 'date')
  },
}

export const quantityCol = {
  headerName: i18n.t('Qty'),
  field: 'quantity',
  minWidth: 70,
  maxWidth: 100,
  editable: true,
  cellEditor: cellEditors.Numeric,
  valueGetter: params => {
    if (params.node.footer || params.node.group) {
      return
    }
    return +params.data.quantity
  },
  valueSetter: params => {
    return requiredValueSetter(params, 0)
  },
  align: 'right',
}


export const specialSourceTypeCol = {
  field: 'special_source_type',
  headerName: 'Special Type',
  minWidth: 60,
  maxWidth: 150,
  editable: true,
  cellEditor: cellEditors.SpecialCodeType,
  valueFormatter: params => {
    return getSpecialCodeTypeLabel(params.data.special_source_type)
  },
  valueSetter: params => {
    params.data.special_source_id = ''
    if (params.data.units !== undefined) {
      params.data.units = 0
    }
    params.data.special_source_type = params.newValue
    params.node.setData(params.data)
    return true
  },
}

export const specialSourceCol = {
  field: 'special_source_id',
  headerName: i18n.t('Special Code'),
  minWidth: 120,
  maxWidth: 180,
  editable: params => {
    return !!params.data.special_source_type
  },
  suppressNavigable: params => {
    return !params.data.special_source_type
  },
  component: 'SpecialSourceLink',
  cellRendererParams: {
    target: '_blank',
  },
  cellEditor: cellEditors.SpecialCode,
  cellClass: params => {
    if (params.node.footer) {
      return ''
    }
    const hasValue = params.data?.special_source_id
    if (!hasValue && params.data?.special_source_type) {
      return cellClasses.Invalid
    }
    if (!params.data?.special_source_type) {
      return 'bg-gray-100 cursor-not-allowed'
    }
    return ''
  },
  cellEditorParams: params => {
    return {
      unauthorizedToAdd: false,
    }
  }
}

export function updateCostCenterHeaderNames(params, isInvoiceResource = false) {
  const { rowIndex } = params
  const rowNode = params.api.getDisplayedRowAtIndex(rowIndex)
  const row = rowNode?.data
  if (!row) {
    return
  }
  const columnApi = params.columnApi
  const costCenter = row.cost_center
  const specialSourceType = row.special_source_type
  const sourceCol = columnApi.getColumn('source_id')?.colDef
  const typeCol = columnApi.getColumn('type_id')?.colDef
  const addlSourceCol = columnApi.getColumn('addl_source_id')?.colDef
  const specialSourceCol = columnApi.getColumn('special_source_id')?.colDef

  if (costCenter === costCenterTypes.Job) {
    updateColumnHeader(sourceCol, i18n.t('Job'))
    updateColumnHeader(typeCol, i18n.t('Type'))
    updateColumnHeader(addlSourceCol, i18n.t('Line Item'))
  } else if (costCenter === costCenterTypes.WorkOrder) {
    updateColumnHeader(sourceCol, i18n.t('Work Order'))
    updateColumnHeader(typeCol, i18n.t('Type'))
    updateColumnHeader(addlSourceCol, i18n.t(''))
  } else if (costCenter === costCenterTypes.Equipment) {
    updateColumnHeader(sourceCol, i18n.t('Equipment'))
    updateColumnHeader(typeCol, i18n.t('Type'))
    updateColumnHeader(addlSourceCol, i18n.t('Maint Code'))
  } else if (isInvoiceResource && costCenter === costCenterTypes.Inventory) {
    updateColumnHeader(sourceCol, i18n.t('Material'))
  } else {
    updateColumnHeader(sourceCol, i18n.t('Source'))
    updateColumnHeader(typeCol, i18n.t('Type'))
    updateColumnHeader(addlSourceCol, i18n.t(''))
  }

  if (specialSourceType && specialSourceCol) {
    const column = codeLabelsByType[specialSourceType] || ' '
    updateColumnHeader(specialSourceCol, column)
  }

  params.api.refreshHeader()
}

export function updateColumnHeader(colDef, headerName) {
  if (!colDef) {
    return
  }
  colDef.headerName = headerName
}

function isAccountRestrictedToUse(costCenter, accountNumber) {
  const accountObject = store.getters['company/getCompanyAccountByNumber'](accountNumber)
  if (!accountObject?.restrictions || accountObject?.restrictions === accountRestrictions.NoRestrictions) {
    return false
  }

  if (costCenter === costCenterTypes.Job) {
    return accountObject.restrictions !== accountRestrictions.JobExpense
  }

  if (costCenter === costCenterTypes.GeneralAndAdministrative) {
    return accountObject.restrictions !== accountRestrictions.NonJob
  }

  if (costCenter === costCenterTypes.Inventory) {
    return ![accountRestrictions.Inventory, accountRestrictions.NonJob].includes(accountObject.restrictions)
  }

  if (costCenter === costCenterTypes.WorkOrder) {
    return ![accountRestrictions.Service, accountRestrictions.NonJob].includes(accountObject.restrictions)
  }

  if (costCenter === costCenterTypes.Equipment) {
    return ![accountRestrictions.Equipment, accountRestrictions.NonJob].includes(accountObject.restrictions)
  }

  return false
}

function showAccountError(accountNumber) {
  const account = store.getters['company/getCompanyAccountByNumber'](accountNumber)
  if (!account || !account?.restrictions) {
    return
  }
  const restriction = getRestrictionLabel(account?.restrictions)
  error(i18n.t(`Account ${account.number} is set to ${restriction}`))
}
