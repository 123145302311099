<template>
  <div class="h-full">
    <AgDataTable
        :columns="columns"
        :add-text="$t('New Deduction Code')"
        :edit-entity-url-query="editEntityUrlQuery"
        :url-params="urlParams"
        entity="deduction-code"
        import-url="/payroll/codes/deduction-codes/import"
        ref="table"
        url="/restify/deduction-codes"
        permission="employees"
        actions="search,refresh,add,edit,import,export"
        hide-actions="view"
        @add="onRowAdd"
        @edit="onRowEdit"
        @view="onRowEdit"
    >
    </AgDataTable>
    <BaseFormDialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit Deduction Code') : $t('Add Deduction Code')"
        :open.sync="showDialog"
        size="xl"
        @close="onEventTrigger(false)"
    >
      <DeductionCodeForm
          :data="rowToEdit"
          :key="renderKey"
          @cancel="onEventTrigger(false)"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import DeductionCodeForm from '@/modules/payroll/components/codes/DeductionCodeForm.vue'

  export default defineComponent({
    components: {
      DeductionCodeForm,
    },
    data() {
      return {
        editEntityUrlQuery: '?id={ID}',
        rowToEdit: null,
        showDialog: <boolean>false,
        renderKey: 1,
        urlParams: {
          related: 'account,vendor',
        },
      }
    },
    computed: {
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Code'),
            field: 'attributes.code',
            maxWidth: 300,
            minWidth: 160,
            component: 'EntityLink',
            redirectTo: '/payroll/codes/deduction-codes?id={ID}',
          },
          {
            headerName: this.$t('Description'),
            field: 'attributes.description',
            maxWidth: 180,
            minWidth: 160,
          },
          {
            headerName: this.$t('Account'),
            field: 'attributes.account',
            component: 'AccountLink',
          },
          {
            headerName: this.$t('Vendor'),
            field: 'attributes.vendor_id',
            component: 'VendorLink',
          },
          {
            headerName: this.$t('Calculate By'),
            field: 'attributes.calculate_by',
            component: 'FormattedText',
          },
          {
            label: this.$t('Multiply By'),
            prop: 'attributes.multiply_by',
            component: 'FormattedText',
          },
          {
            label: this.$t('W2 Memo Code'),
            prop: 'attributes.w2_code',
            hide: true,
          },
          {
            label: this.$t('Active'),
            prop: 'attributes.active',
            component: 'Status',
            align: 'center',
          },
        ]
      },
    },
    methods: {
      onRowEdit(row: any) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        // @ts-ignore
        this.$refs.table.refresh()
      },
      onEventTrigger(refresh = true) {
        this.rowToEdit = null
        this.showDialog = false

        if (!refresh) {
          return
        }

        this.refreshTable()
      },
    },
  })
</script>
