import { $modules, resourceStatuses } from '@/enum/enums'
import PaymentLayout from "@/modules/accounts-payable/layout/PaymentLayout";
import InvoicesLayout from "@/modules/accounts-payable/layout/InvoicesLayout";
import EmptyLayout from "@/components/common/EmptyLayout";
import AccountsPayableDashboard from "@/modules/accounts-payable/pages/dashboard/accounts-payable-dashboard";
import VendorList from "@/modules/accounts-payable/pages/vendors/vendor-list";
import VendorImport from "@/modules/accounts-payable/pages/vendors/vendor-import";
import VendorAdd from "@/modules/accounts-payable/pages/vendors/vendor-add";
import VendorLayout from "@/modules/accounts-payable/layout/VendorLayout";
import VendorEdit from "@/modules/accounts-payable/pages/vendors/vendor-edit";
import VendorDetails from "@/modules/accounts-payable/pages/vendors/vendor-details";
import VendorInvoices from "@/modules/accounts-payable/pages/vendors/vendor-invoices";
import VendorDocuments from "@/modules/accounts-payable/pages/vendors/vendor-documents";
import Vendor1099S from "@/modules/accounts-payable/pages/vendors/vendor-1099s";
import Vendor1099SBatchView from "@/modules/accounts-payable/pages/vendors/vendor-1099s-batch-view";
import VendorDirectDeposit from "@/modules/accounts-payable/pages/vendors/vendor-direct-deposit";
import InvoicesList from "@/modules/accounts-payable/pages/invoices/invoices-list";
import InvoicesProofListing from "@/modules/accounts-payable/pages/invoices/invoices-proof-listing";
import InvoiceAdd from "@/modules/accounts-payable/pages/invoices/invoice-add";
import InvoiceLayout from "@/modules/accounts-payable/layout/InvoiceLayout";
import InvoiceEdit from "@/modules/accounts-payable/pages/invoices/invoice-edit";
import InvoiceDetails from "@/modules/accounts-payable/pages/invoices/invoice-details";
import CreatePayments from "@/modules/accounts-payable/pages/create-payments";
import PaymentsProofListing from "@/modules/accounts-payable/pages/payments-proof-listing";
import PaymentBatches from "@/modules/accounts-payable/pages/payments/payment-batches";
import PaymentBatchDetails from "@/modules/accounts-payable/pages/payments/payment-batch-details";
import AllPayments from "@/modules/accounts-payable/pages/payments/all-payments";
import VendorReviewHistory from "@/modules/accounts-payable/pages/vendors/vendor-review-history";
import ReleaseRetention from "@/modules/accounts-payable/pages/release-retention/release-retention";
import ReleaseRetentionProofListing from "@/modules/accounts-payable/pages/release-retention/release-retention-proof-listing";
import Reports from "@/modules/accounts-payable/layout/Reports";
import WaiverAndReleaseReport from "@/modules/accounts-payable/pages/reports/waiver-and-release-report";
import AgedByVendorReport from "@/modules/accounts-payable/pages/reports/aged-by-vendor-report";
import AgedByJobReport from "@/modules/accounts-payable/pages/reports/aged-by-job-report";
import VendorPaymentRegisterReport from "@/modules/accounts-payable/pages/reports/vendor-payment-register-report";
import Vendor1099sReport from "@/modules/accounts-payable/pages/reports/vendor-1099s-report.vue";
import VendorHistoryReport from "@/modules/accounts-payable/pages/reports/vendor-history-report";
import APJournals from "@/modules/common/components/journal/journal";
import JournalDetails from "@/modules/common/components/journal/journal-details";
import AccountsPayableSettings from "@/modules/accounts-payable/pages/accounts-payable-settings";
import SettingsLayout from "@/modules/accounts-payable/layout/SettingsLayout";
import AccountsPayableInitLayout from "@/modules/accounts-payable/layout/AccountsPayableInitLayout";
import AccountsPayableInitList
  from "@/modules/accounts-payable/pages/settings/accounts-payable-init/accounts-payable-init-list";
import AccountsPayableInitAdd
  from "@/modules/accounts-payable/pages/settings/accounts-payable-init/accounts-payable-init-add";
import AccountsPayableInitEdit
  from "@/modules/accounts-payable/pages/settings/accounts-payable-init/accounts-payable-init-edit";
import AccountsPayableInitImport
  from "@/modules/accounts-payable/pages/settings/accounts-payable-init/accounts-payable-init-import";
import AccountsPayableInitProofListing
  from "@/modules/accounts-payable/pages/settings/accounts-payable-init/accounts-payable-init-proof-listing";
import SettingsActivityLog from "@/modules/common/components/SettingsActivityLog.vue";
import InvoiceImport from "@/modules/accounts-payable/pages/invoices/invoice-import.vue";
import ProgressInvoices from "@/modules/accounts-payable/pages/subcontract-invoices/progress-invoices.vue";
import SubcontractInvoicesLayout from "@/modules/accounts-payable/layout/SubcontractInvoicesLayout.vue";
import VendorPurchaseOrders from "@/modules/accounts-payable/pages/vendors/vendor-purchase-orders.vue";
import { RestifyResources } from "@/components/form/util";
import DocumentsTable from "@/modules/common/components/documents/DocumentsTable.vue";
import ReviewInvoices from "@/modules/accounts-payable/pages/invoices/review-invoices.vue";
import RecurringInvoicesList from "@/modules/accounts-payable/pages/recurring-invoices/recurring-invoices-list.vue";
import RecurringInvoicesLayout from "@/modules/accounts-payable/layout/RecurringInvoicesLayout.vue";
import RecurringInvoiceAdd from "@/modules/accounts-payable/pages/recurring-invoices/recurring-invoice-add.vue";
import RecurringInvoiceDetails from "@/modules/accounts-payable/pages/recurring-invoices/recurring-invoice-details.vue";
import RecurringInvoiceEdit from "@/modules/accounts-payable/pages/recurring-invoices/recurring-invoice-edit.vue";
import RecurringInvoiceLayout from "@/modules/accounts-payable/layout/RecurringInvoiceLayout.vue";
import ReviewRecurringInvoices from "@/modules/accounts-payable/pages/recurring-invoices/review-recurring-invoices.vue";
import Receipts from "@/modules/accounts-payable/pages/invoices/receipts.vue";
import ReceiptsImport from "@/modules/accounts-payable/pages/invoices/receipts-import.vue";

export const reportRoutes = [
  {
    path: 'aged-by-vendor',
    name: 'Aged A/P By Vendor',
    title: 'Aged A/P By Vendor',
    meta: {
      permissions: ['accounts_payable_reports'],
      appScreen: 'Aged A/P By Vendor: 38.82',
    },
    component: AgedByVendorReport,
  },
  {
    path: 'aged-by-job',
    name: 'Aged A/P By Job',
    title: 'Aged A/P By Job',
    meta: {
      permissions: ['accounts_payable_reports'],
      appScreen: 'Aged A/P By Job: 38.83',
    },
    component: AgedByJobReport,
  },
  {
    path: 'vendor-payment-register',
    name: 'Vendor Payment Register',
    title: 'Vendor Payment Register',
    meta: {
      permissions: ['accounts_payable_reports'],
      appScreen: 'Vendor Payment Register: 38.85',
    },
    component: VendorPaymentRegisterReport
  },
  {
    path: 'vendor-1099s',
    name: 'Vendor 1099s Report',
    title: 'Vendor 1099s Report',
    meta: {
      permissions: ['accounts_payable_reports'],
      appScreen: 'Vendor 1099s: 38.84',
    },
    component: Vendor1099sReport,
  },
  {
    path: 'vendor-history',
    name: 'Vendor History',
    title: 'Vendor History',
    meta: {
      permissions: ['accounts_payable_reports'],
      appScreen: 'Vendor History: 38.88',
    },
    component: VendorHistoryReport
  },
  {
    path: 'waiver-and-release',
    name: 'AP Waiver & Release',
    title: 'Waiver & Release',
    meta: {
      permissions: ['accounts_payable_reports'],
      appScreen: 'Aged A/P By Vendor: 38.71',
    },
    component: WaiverAndReleaseReport,
  },
]
export default [
  {
    path: '/accounts-payable',
    name: 'Accounts Payable',
    component: EmptyLayout,
    redirect: '/accounts-payable/vendors',
    meta: {
      isModule: true,
      permissions: ['vendors_show'],
    },
    children: [
      {
        path: 'dashboard',
        name: 'AP Dashboard',
        title: 'AP Dashboard',
        description: 'A visual summary of accounts payable',
        meta: {
          permissions: ['invoices_show'],
        },
        component: AccountsPayableDashboard,
      },
      {
        path: 'vendors',
        name: 'Vendors',
        title: 'Vendors List',
        description: 'The basics of Construction Partner Accounts Payable begins with the proper setup of Vendors',
        meta: {
          permissions: ['vendors_show'],
          appScreen: 'Setup Vendors: 30.10',
        },
        component: VendorList,
      },
      {
        path: 'vendors/import',
        name: 'Import AP Vendors',
        title: 'Import AP Vendors',
        description: 'Import AP Vendors from a CSV or Excel file',
        meta: {
          permissions: ['vendors_update'],
          appScreen: 'Setup Vendors: 30.10',
        },
        component: VendorImport,
      },
      {
        path: 'vendors/add',
        name: 'Add Vendor',
        title: 'Add Vendor',
        description: 'Add Vendor',
        meta: {
          permissions: ['vendors_store'],
          appScreen: 'Setup Vendors: 30.10',
          resourceName: 'vendors',
        },
        component: VendorAdd,
      },
      {
        path: 'vendors/:id',
        name: 'Vendor Details ',
        redirect: '/accounts-payable/vendors',
        meta: {
          permissions: ['vendors_show'],
        },
        component: VendorLayout,
        children: [
          {
            path: 'edit',
            name: 'Vendor Edit',
            meta: {
              permissions: ['vendors_update'],
              appScreen: 'Setup Vendors: 30.10',
              resourceName: 'vendors',
            },
            component: VendorEdit,
          },
          {
            path: 'view',
            name: 'Vendor Details',
            meta: {
              permissions: ['vendors_show'],
              appScreen: 'Setup Vendors: 30.10',
              resourceName: 'vendors',
            },
            component: VendorDetails,
          },
          {
            path: 'invoices',
            name: 'Vendor Invoices',
            meta: {
              permissions: ['vendors_show'],
              appScreen: 'Enter Invoices: 30.40',
              resourceName: 'vendors',
            },
            component: VendorInvoices,
          },
          {
            path: 'documents',
            name: 'Vendor Documents',
            meta: {
              permissions: ['vendor_documents_show'],
              resourceName: RestifyResources.Vendors,
            },
            component: DocumentsTable,
          },
          {
            path: 'purchase-orders',
            name: 'Vendor Purchase Orders',
            meta: {
              permissions: ['purchase_orders_show'],
              resourceName: 'vendors',
            },
            component: VendorPurchaseOrders,
          },
          // {
          //   path: 'documents',
          //   name: 'Vendor Documents',
          //   meta: {
          //     permissions: ['vendors_show'],
          //     appScreen: 'Enter/Review Vendor Documents: 30.20',
          //     resourceName: 'vendors',
          //   },
          //   component: VendorDocuments,
          // },
          {
            path: 'direct-deposit',
            name: 'Vendor Direct Deposit',
            meta: {
              permissions: ['vendors_show'],
              appScreen: 'Vendor Direct Deposit: 30.11',
              resourceName: 'vendors',
            },
            component: VendorDirectDeposit,
          },
        ]
      },
      {
        path: '/accounts-payable/invoices/import',
        name: 'Import AP Invoices',
        title: 'Import AP Invoices',
        description: 'Import AP Invoices from a CSV or Excel file',
        meta: {
          permissions: ['invoices_store'],
          appScreen: 'Setup Invoices: 30.40',
        },
        component: InvoiceImport,
      },
      {
        path: '/accounts-payable/invoices',
        name: 'Invoices',
        redirect: '/accounts-payable/invoices/open',
        meta: {
          permissions: ['invoices_show'],
        },
        component: InvoicesLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Invoices',
            title: 'Pending Invoices',
            description: 'Quickly access and manage your Accounts Payable pending invoices',
            meta: {
              permissions: ['invoices_show'],
              appScreen: 'Enter Invoices: 30.40',
              status: resourceStatuses.Pending
            },
            component: InvoicesList,
          },
          {
            path: 'hold',
            name: 'Hold Invoices',
            title: 'Hold Invoices',
            description: 'Manage Hold A/P Invoices',
            meta: {
              permissions: ['invoices_show'],
              appScreen: 'Enter Invoices: 30.40',
              status: resourceStatuses.Hold
            },
            component: InvoicesList,
          },
          {
            path: 'paid',
            name: 'Paid Invoices',
            title: 'Paid Invoices',
            description: 'Manage Paid A/P Invoices',
            meta: {
              permissions: ['invoices_show'],
              appScreen: 'Enter Invoices: 30.40',
              status: resourceStatuses.Paid
            },
            component: InvoicesList,
          },
          {
            path: 'partial-paid',
            name: 'Partially Paid Invoices',
            title: 'Partially Paid Invoices',
            description: 'Manage Partial Paid A/P Invoices',
            meta: {
              permissions: ['invoices_show'],
              appScreen: 'Enter Invoices: 30.40',
              status: resourceStatuses.PartialPaid
            },
            component: InvoicesList,
          },
          {
            path: 'voided',
            name: 'Voided Invoices',
            title: 'Voided Invoices',
            description: 'Manage Voided A/P Invoices',
            meta: {
              permissions: ['invoices_show'],
              appScreen: 'Enter Invoices: 30.40',
              status: resourceStatuses.Voided
            },
            component: InvoicesList,
          },
          {
            path: 'open',
            name: 'Open Invoices',
            title: 'Open Invoices',
            description: 'Manage Open A/P Invoices',
            meta: {
              permissions: ['invoices_show'],
              appScreen: 'Enter Invoices: 30.40',
              status: resourceStatuses.Posted
            },
            component: InvoicesList,
          },
          {
            path: 'all',
            name: 'All Invoices',
            title: 'All Invoices',
            description: 'Manage All A/P Invoices',
            meta: {
              permissions: ['invoices_show'],
              appScreen: 'Enter Invoices: 30.40',
              status: resourceStatuses.All
            },
            component: InvoicesList,
          },
          {
            path: 'review',
            name: 'Review Invoices',
            title: 'Review Invoices',
            description: 'Review pending AP Invoices',
            meta: {
              permissions: ['invoices_approve'],
            },
            component: ReviewInvoices,
          },
          {
            path: 'receipts',
            name: 'AP Receipts',
            title: 'AP Receipts',
            description: 'Enter AP Receipts',
            meta: {
              permissions: ['invoices_store'],
            },
            component: Receipts,
          },
          {
            path: 'receipts/import',
            name: 'Import AP Receipts',
            title: 'Import AP Receipts',
            description: 'Import AP Receipts',
            meta: {
              permissions: ['invoices_store'],
            },
            component: ReceiptsImport,
          },
          {
            path: 'proof-listing',
            name: 'Invoices Proof Listing',
            title: 'Invoices Proof Listing',
            description: 'Proof listing AP Invoices before posting them',
            meta: {
              permissions: ['invoices_show'],
            },
            component: InvoicesProofListing,
          },
          {
            path: 'add',
            name: 'Add Invoice',
            title: 'Add Invoice',
            description: 'Add Invoice',
            meta: {
              permissions: ['invoices_store'],
              appScreen: 'Enter Invoices: 30.40',
            },
            component: InvoiceAdd,
          },
          {
            path: ':id',
            redirect: '/accounts-payable/invoices/:id/view',
            meta: {
              permissions: ['invoices_show'],
            },
            component: InvoiceLayout,
            children: [
              {
                path: 'edit',
                name: 'Edit Invoice',
                meta: {
                  permissions: ['invoices_update'],
                  appScreen: 'Enter Invoices: 30.40',
                  resourceName: 'invoices',
                },
                component: InvoiceEdit,
              },
              {
                path: 'view',
                name: 'View Invoice',
                meta: {
                  permissions: ['invoices_update'],
                  appScreen: 'Enter Invoices: 30.40',
                  resourceName: 'invoices',
                },
                component: InvoiceDetails,
              },
            ],
          },
        ]
      },
      {
        path: '/accounts-payable/recurring-invoices',
        name: 'Recurring Payables',
        redirect: '/accounts-payable/recurring-invoices/active',
        meta: {
          permissions: ['recurring_invoices_show'],
        },
        component: RecurringInvoicesLayout,
        children: [
          {
            path: 'pending',
            name: 'Pending Recurring Payables',
            title: 'Pending Recurring Payables',
            meta: {
              permissions: ['recurring_invoices_show'],
              status: resourceStatuses.Pending,
            },
            component: RecurringInvoicesList,
          },
          {
            path: 'active',
            name: 'Active Recurring Payables',
            title: 'Active Recurring Payables',
            meta: {
              permissions: ['recurring_invoices_show'],
              status: resourceStatuses.Active,
            },
            component: RecurringInvoicesList,
          },
          {
            path: 'inactive',
            name: 'Inactive Recurring Payables',
            title: 'Inactive Recurring Payables',
            meta: {
              permissions: ['recurring_invoices_show'],
              status: resourceStatuses.Inactive,
            },
            component: RecurringInvoicesList,
          },
          {
            path: 'all',
            name: 'All Recurring Payables',
            title: 'All Recurring Payables',
            meta: {
              permissions: ['recurring_invoices_show'],
            },
            component: RecurringInvoicesList,
          },
          {
            path: 'review',
            name: 'Review Recurring Payables',
            title: 'Review Recurring Payables',
            meta: {
              permissions: ['recurring_invoices_update'],
            },
            component: ReviewRecurringInvoices,
          },
          {
            path: 'add',
            name: 'Add Recurring Payables',
            title: 'Add Recurring Payables',
            description: 'Create a Recurring Payable Template based on which invoices are automatically created',
            meta: {
              permissions: ['recurring_invoices_store'],
            },
            component: RecurringInvoiceAdd,
          },
          {
            path: ':id',
            redirect: '/accounts-payable/recurring-invoices/:id/view',
            meta: {
              permissions: ['recurring_invoices_show'],
            },
            component: RecurringInvoiceLayout,
            children: [
              {
                path: 'edit',
                name: 'Edit Recurring Payables',
                meta: {
                  permissions: ['recurring_invoices_update'],
                },
                component: RecurringInvoiceEdit,
              },
              {
                path: 'view',
                name: 'View Recurring Payables',
                meta: {
                  permissions: ['recurring_invoices_show'],
                },
                component: RecurringInvoiceDetails,
              },
            ],
          },
        ]
      },
      {
        path: '/accounts-payable/payments',
        name: 'Payments',
        redirect: '/accounts-payable/payments/create',
        meta: {
          permissions: ['invoice_payments_show'],
        },
        component: PaymentLayout,
        children: [
          {
            path: 'create',
            name: 'Create Payments',
            title: 'Create Payments',
            description: 'Create Payments based on posted invoices',
            meta: {
              permissions: ['invoice_payments_store'],
              appScreen: 'Print Checks: 30.50',
            },
            component: CreatePayments,
          },
          {
            path: 'proof-listing',
            name: 'AP Payments Proof Listing',
            title: 'Payments Proof Listing',
            meta: {
              permissions: ['invoice_payments_store'],
            },
            component: PaymentsProofListing,
          },
          {
            path: 'batches',
            name: 'Payment Batches',
            title: 'Payment Batches',
            description: 'Manage A/P Payment Batches',
            meta: {
              permissions: ['invoice_payments_show'],
              appScreen: 'Print Checks: 30.50',
            },
            component: PaymentBatches,
          },
          {
            path: 'batches/:journal/view',
            name: 'AP Payment Batches Journal',
            meta: {
              permissions: ['invoice_payments_show'],
            },
            component: PaymentBatchDetails,
          },
          {
            path: 'all',
            name: 'All Payments',
            title: 'All Payments',
            description: 'Review and manage Accounts Payable payment history',
            meta: {
              permissions: ['invoice_payments_show'],
              appScreen: 'Print Checks: 30.50',
            },
            component: AllPayments,
          },
        ]
      },
      // TODO: in progress
      // {
      //   path: 'subcontract-invoices',
      //   name: 'Subcontract Invoices',
      //   redirect: '/accounts-payable/subcontract-invoices/progress-invoices',
      //   meta: {
      //     permissions: ['accounts_receivable_sales_tax_district_show'],
      //   },
      //   component: () => import( './layout/SubcontractInvoicesLayout'),
      //   children: [
      //     {
      //       path: 'progress-invoices',
      //       name: 'Progress Invoices',
      //       title: 'Progress Invoices',
      //       description: 'Subcontract Progress',
      //       meta: {
      //         appScreen: 'Subcontract Invoices: 30.35',
      //       },
      //       component: () => import( './pages/subcontract-invoices/progress-invoices'),
      //     },
      //     {
      //       path: 'release-retention',
      //       name: 'Release Retention Subcontract Invoices',
      //       title: 'Release Retention',
      //       description: 'Release Retention',
      //       meta: {
      //         appScreen: 'Subcontract Invoices: 30.30',
      //       },
      //       component: () => import( './pages/subcontract-invoices/release-retention'),
      //     },
      //     {
      //       path: 'progress-worksheet',
      //       name: 'Progress Worksheet',
      //       title: 'Progress Worksheet',
      //       description: 'Sub Prog Pay Worksheet',
      //       meta: {
      //         appScreen: 'Subcontract Invoices: 30.38',
      //       },
      //       component: () => import( './pages/subcontract-invoices/progress-worksheet'),
      //     },
      //     {
      //       path: 'retention-worksheet',
      //       name: 'Retention Worksheet',
      //       title: 'Retention Worksheet',
      //       description: 'Retention Payable Worksheet',
      //       meta: {
      //         appScreen: 'Subcontract Invoices: 30.39',
      //       },
      //       component: () => import( './pages/subcontract-invoices/retention-worksheet'),
      //     },
      //   ],
      // },
      {
        path: 'vendor-1099s',
        name: `Vendor 1099s`,
        title: `Vendor 1099s`,
        description: 'Vendor 1099s',
        meta: {
          permissions: ['vendors_show'],
        },
        component: Vendor1099S,
      },
      {
        path: 'vendor-1099s/:id/view',
        name: 'View Vendor 1099 Batch',
        title: 'View Vendor 1099 Batch',
        description: 'View Vendor 1099 Batch',
        meta: {
          permissions: ['vendors_store'],
          appScreen: 'Vendor 1099s: 38.84',
        },
        component: Vendor1099SBatchView,
      },
      {
        path: 'vendor-review-history',
        name: 'Review Vendor History',
        title: 'Review Vendor History',
        description: 'Review all invoices and payments for each vendor',
        meta: {
          permissions: ['vendors_show'],
          appScreen: ' Review Vendor History: 30.70',
        },
        component: VendorReviewHistory,
      },
      {
        path: 'subcontract-invoices',
        name: 'Subcontract Invoices Layout',
        component: SubcontractInvoicesLayout,
        redirect: '/accounts-payable/subcontract-invoices/progress',
        children: [
          {
            path: 'progress',
            name: 'Progress Invoices',
            title: 'Progress Invoices',
            description: 'Prepare subcontract progress invoices from purchase orders contract values and earned progress to-date.',
            meta: {
              permissions: ['invoices_show'],
              appScreen: 'Progress Invoices: 30.35',
            },
            component: ProgressInvoices,
          },
          {
            path: 'release-retention',
            name: 'Release Retention',
            title: 'Release Retention',
            description: 'Vendor Release Retention',
            meta: {
              permissions: ['invoices_show'],
              appScreen: 'Release Retention: 30.30',
            },
            component: ReleaseRetention,
          },
        ]
      },
      {
        path: 'release-retention/proof-listing',
        name: 'Release Retention Proof Listing',
        title: 'Release Retention Proof Listing',
        description: 'Vendor Release Retention Release Retention Proof Listing',
        meta: {
          permissions: ['accounts_payable_reports'],
          appScreen: 'Release Retention: 30.30',
        },
        component: ReleaseRetentionProofListing,
      },
      {
        path: 'reports',
        name: 'AP Reports',
        redirect: '/accounts-payable/reports/aged-by-vendor',
        meta: {
          permissions: ['accounts_payable_reports'],
        },
        component: Reports,
        children: reportRoutes,
      },
      {
        path: 'journals',
        name: 'AP Journals',
        redirect: 'journals',
        meta: {
          permissions: ['accounts_payable_reports'],
          appScreen: 'General Journal Entries: 10.40',
          module: 'accounts-payable',
        },
        component: EmptyLayout,
        children: [
          {
            path: '',
            name: 'AP Journals ',
            title: 'AP Journals',
            meta: {
              hideBreadCrumb: true,
              permissions: ['accounts_payable_reports'],
              module: 'accounts-payable',
            },
            component: APJournals,
          },
          {
            path: ':id/view',
            name: 'AP Journal Details',
            meta: {
              permissions: ['accounts_payable_reports'],
              appScreen: 'General Journal Entries: 10.40',
              module: 'accounts-payable',
            },
            component: JournalDetails,
          },
        ]
      },
      {
        path: 'settings',
        name: 'AP Settings',
        redirect: 'settings/ap-settings',
        meta: {
          permissions: ['accounts_payable_settings'],
          appScreen: 'Accounts Payable Special Tasks: 39.00 ',
          module: 'accounts-payable',
        },
        component: SettingsLayout,
        children: [
          {
            path: 'ap-settings',
            name: 'Accounts Payable Settings',
            title: 'Accounts Payable Settings',
            meta: {
              hideBreadCrumb: true,
              permissions: ['accounts_payable_settings'],
              appScreen: 'Accounts Payable Special Tasks: 39.91 ',
              module: 'accounts-payable',
            },
            component: AccountsPayableSettings,
          },
          {
            path: 'activity-log',
            name: 'AP Settings Update Activity',
            meta: {
              permissions: ['accounts_payable_settings'],
              module: $modules.AP,
            },
            component: SettingsActivityLog,
          },
          {
            path: 'ap-initialize',
            name: 'Initialize Open Payable',
            title: 'Initialize Open Payable',
            meta: {
              hideBreadCrumb: true,
              permissions: ['accounts_payable_settings'],
              appScreen: 'Initialize Open Payable: 39.93 ',
              module: 'accounts-payable',
            },
            component: AccountsPayableInitList,
          },
          {
            path: 'ap-initialize/add',
            name: 'Add Open Payable',
            title: 'Add Open Payable',
            meta: {
              permissions: ['accounts_payable_settings'],
            },
            component: AccountsPayableInitAdd,
          },
          {
            path: 'ap-initialize/import',
            name: 'Import Open Payable',
            title: 'Import Open Payable',
            meta: {
              permissions: ['accounts_payable_settings'],
            },
            component: AccountsPayableInitImport,
          },
          {
            path: 'ap-initialize/:id',
            meta: {
              permissions: ['accounts_payable_settings'],
            },
            component: AccountsPayableInitLayout,
            children: [
              {
                path: 'edit',
                title: 'Edit Open Payable',
                meta: {
                  permissions: ['accounts_payable_settings'],
                },
                component: AccountsPayableInitEdit,
              },
              {
                path: 'proof-listing',
                title: 'Proof Listing',
                meta: {
                  permissions: ['accounts_payable_settings'],
                },
                component: AccountsPayableInitProofListing,
              },
            ],
          },
        ]
      }
    ],
  },
]
