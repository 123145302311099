import i18n from '@/i18n'
import { fetchTranslations } from '@/enum/translations'
import { isPrintPage } from "@/components/ag-grid/printUtils";
import { startCase, get } from 'lodash';

export const formattedAddress = (addressData, isWorkSite) => {
  if (typeof addressData === 'string' || !addressData) {
    return addressData
  }

  const address = addressData.attributes || addressData

  let address_1 = address.address_1 || ''
  let address_2 = address.address_2 || ''
  if (address_1 === '-') {
    address_1 = ''
  }
  if (address_2 === '-') {
    address_2 = ''
  }

  let number = address.number || ''
  if (isWorkSite) {
    number = address.street_number || address.unit_number || ''
    address_1 = address.street_name || ''
  }
  const city = address.city || ''
  const state = address.state || ''
  const country = address.country || ''
  const postal_code = address.postal_code || ''

  const firstAddressPart = `${address_1} ${address_2} ${number}`.trim();

  let result = []

  if (firstAddressPart) {
    result.push(firstAddressPart)
  }

  if (city && city !== '-') {
    result.push(city)
  }

  if (state && state !== '-') {
    result.push(state)
  }

  if (postal_code && postal_code !== '-') {
    result.push(postal_code)
  }

  if (country && country !== '-') {
    result.push(country)
  }
  return result.join(',').trim()
}

export const locationLink = (address, isWorkSite) => {
  return `https://www.google.com/maps/search/?api=1&query=${formattedAddress(address, isWorkSite)}`
}

export function composeLineItemName(lineItem, displayDescription = false) {
  if (!lineItem.change_order) {
    lineItem.change_order = '0'
  }

  const codes = [lineItem.phase_code, lineItem.cost_code, lineItem.change_order]

  let result = codes.map(c => c || '-').join(' / ')
  if (isPrintPage()) {
    result = result.replaceAll(' ', '')
  }

  if (!displayDescription || !lineItem.description) {
    return result
  }

  return result + ` (${lineItem.description})`
}

export const setOrganizationTranslations = async () => {
  const currentLanguage = i18n.locale
  let translations = await import(`../locales/${currentLanguage}.json`)
  translations = translations.default
  const overwrites = fetchTranslations()

  let messages = {}
  overwrites.forEach((el) => {
    messages[el.key] = el.value
  })
  let allMessages = { ...translations }

  const rules = new RegExp(Object.keys(messages).join('|'), 'gi')
  Object.keys(allMessages).forEach((key) => {
    allMessages[key] = allMessages[key].replace(rules, matched => messages[matched])
  })

  i18n.setLocaleMessage(i18n.locale, { ...allMessages })
}

export function delay(time = 100) {
  return new Promise(resolve => {
    setTimeout(resolve, time)
  })
}

export function isWindows() {
  return navigator.appVersion.indexOf("Win") !== -1
}

export function isUUID(value) {
  if (!value) {
    return false
  }
  return typeof value === 'string' && value.length === 26
}

export function formattedText(value) {
  if (!value) {
    return ''
  }
  //Remove special characters like (/,-,_) to make the text more readable
  const string = value.replace(/[^a-zA-Z0-9 ]/g, ' ')
  return startCase(string)
}

export function formatLineItemCode(lineItem, displayDescription = false) {
  let { phase_code, cost_code, change_order } = lineItem || {}

  const codes = [phase_code, cost_code, change_order || '0']

  if (!displayDescription || !lineItem?.description) {
    return codes.map(c => c || '-').join(' / ')
  }

  return codes.map(c => c || '-').join(' / ') + ` (${lineItem?.description})`
}

export function getNumberValue(value) {
  let number = +value
  if (isNaN(number)) {
    number = 0
  }
  return number
}

//Because new Date() can't convert dd/MM/yyyy - format
export function toValidDate(date) {
  let dateParts = date.split('/')

  let isoDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
  return isoDate.toString()
}

export function searchByPriority(options, searchQuery, searchableFields) {
  const exactMatches = options.filter(op => {
    return op.number.toLowerCase().startsWith(searchQuery.toLowerCase())
  })

  const partialMatches = options.filter(op => {
    return searchableFields.some(field =>
      !op.number.toLowerCase().startsWith(searchQuery.toLowerCase())
      && op[field]?.toString()?.toLowerCase().includes(searchQuery.toLowerCase()),
    )
  })

  return [
    ...exactMatches,
    ...partialMatches,
  ]
}

// * Date string to dd.MM.yyyy format
export function convertDateToIso(dateString) {
  if (!dateString) {
    return ''
  }

  let date = new Date(dateString)
  // * British English uses day-month-year order
  date = date.toLocaleDateString("en-GB").toString()

  return date.replace(/\//g, '.')
}

export function removeFalsyValues(obj) {
  const result = {}

  for (let key in obj) {
    const value = obj[key]

    if (value && typeof value === 'object') {
      const nestedObj = removeFalsyValues(value)
      if (Object.keys(nestedObj).length !== 0) {
        result[key] = nestedObj
      }
    } else if (value || value === 0 || value === false) {
      result[key] = value
    }
  }

  return result
}

// * Vendor payments and Customer sales
export function getActivitiesThisAndLastYear(resource = {}) {
  const activities = get(resource, 'relationships.activities', [])
    .map(activity => activity.attributes)
    .sort((a, b) => b.year - a.year)

  return {
    this_year: activities[0]?.amount ?? 0,
    last_year: activities[1]?.amount ?? 0,
  }
}

export function hexColorToRgba(hexCode = '', alpha = 1) {
  let hex = hexCode.replace('#', '')

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function isReferenceId(id) {
  if (!id || typeof id !== 'string') {
    return false
  }
  return id.trim().length === 26
}

export function hasValue(field) {
  return field !== null && field !== undefined && field !== ''
}
