<template>
  <div class="p-4">
    <BaseFormDialog v-if="showDialog"
                    :title="$t('Select accounts')"
                    :open.sync="showDialog"
                    :append-to-body="true"
    >
      <BaseForm
        layout="modal"
        :save-text="$t('Save')"
        @submit="onSubmit"
      >
        <div class="col-span-6">
          <AccountMultipleRangeTable
            :value="selectedValue"
            @grid-ready="grid = $event"
          />
        </div>
      </BaseForm>
    </BaseFormDialog>
  </div>
</template>
<script>
import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";
import AccountRangeTags from "@/modules/ledger/components/financial-reports/AccountRangeTags.vue";
import { getTableData } from "@/components/ag-grid/tableUtils";
import AccountMultipleRangeTable from "@/components/ag-grid/cellEditors/AccountMultipleRangeTable.vue";

export default {
  name: 'AccountMultipleRangeSelect',
  components: {
    AccountRangeTags,
    AccountMultipleRangeTable
  },
  mixins: [selectEditorMixin],
  data() {
    return {
      showDialog: true,
      grid: null,
    }
  },
  methods: {
    convertTableData() {
      const data = getTableData(this.grid.api)
      return data.map(row => {
        return row.isRange ? [row.from, row.to] : row.from
      })
    },
    onSubmit() {
      this.selectedValue = this.convertTableData()
      this.showDialog = false
      this.params.api.stopEditing()
      this.params.api.tabToNextCell()
    }
  },
  watch: {
    showDialog(value) {
      if (!value) {
        this.params.api.stopEditing()
        this.params.api.tabToNextCell()
      }
    }
  }
}
</script>
