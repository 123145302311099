<template>
  <div class="flex justify-between w-full">
    <div class="flex space-x-6">
      <span class="font-medium">
        {{ $t('Journal:') }} #{{ journal.code }} {{ journal.name }} {{ journal.description }}
      </span>
      <span>
        {{ $t('Reference Date:') }} {{ $formatDate(journal.reference_date) }}
      </span>
      <span>
        {{ $t('Period:') }} {{ getPeriodName(journal.period) }}
      </span>
      <span>
        {{ $t('Fiscal Year') }} {{ journal.fiscal_year }}
      </span>
    </div>
    <template v-if="params.node.level === 1">
      <div class="absolute right-5 text-red-500"
           v-for="error in errors">
        <span>{{ error.message }}</span>
        <span v-if="error.open_amount">
        {{ $formatPrice(error.open_amount) }}
      </span>
      </div>
    </template>
  </div>
</template>
<script>
  export default {
    name: 'JournalProofGroupRow',
    computed: {
      data() {
        return this.params.node?.allLeafChildren[0]?.data || {}
      },
      errors() {
        return this.data.journal?.errors || []
      },
      journal() {
        return this.data?.journal || {}
      }
    },
    methods: {
      getPeriodName(period) {
        return this.$store.getters['company/getPeriodName'](period)
      }
    }
  }
</script>
