<template>
  <div class="truncate">
    <template v-if="isLink">
      <component
        v-if="linkComponent && addlSourceProps?.id"
        :is="linkComponent"
        :id="addlSourceProps.id"
        :data="addlSourceProps"
        :show-description="showDescription"
      />
      <router-link
        v-else-if="addlSourceProps?.id && !linkComponent"
        :to="sourceLink"
        :title="fullSourceName"
        :target="target"
        :class="linkClass"
        class="truncate"
      >
      <span class="truncate">
        {{ sourceName }}
      </span>
      </router-link>
      <template v-else>
        <div class="truncate">
          <router-link v-if="id"
                       :to="sourceLink"
                       :title="fullSourceName"
                       :target="target"
                       :class="linkClass"
                       class="truncate"
          >
          <span class="truncate">
            {{ sourceName }}
          </span>
          </router-link>
          <span v-else class="truncate">
           {{ sourceName }}
          </span>
        </div>
      </template>
    </template>
    <span v-else
          class="truncate"
    >
      {{ sourceName }}
    </span>
  </div>
</template>
<script>
import { getAddlSourceLink, getAddlSourceName } from '@/components/grid-table/utils/cost-center-cell';
import { gridContext } from '@/components/ag-grid/gridContext';
import { costCenterTypes } from "@/components/grid-table/utils/cost-center";

const linkComponents = {
  [costCenterTypes.Job]: 'LineItemLink',
  [costCenterTypes.Equipment]: 'EquipmentLink',
  default: '',
}


export default {
  props: {
    id: String,
    data: {
      type: Object,
      default: () => ({}),
    },
    target: String,
    showDescription: {
      type: Boolean,
      default: true,
    },
    linkClass: String,
    isLink: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    linkComponent() {
      const cost_center = this.data?.cost_center || this.data?.attributes?.cost_center
      return linkComponents[cost_center]
    },
    addlSourceProps() {
      return this.get(this.data, 'relationships.addlSource') || this.data.addlSource || this.data.addl_source || this.data.line_item
    },
    sourceLink() {
      return getAddlSourceLink(this.data, this.addlSourceProps)
    },
    sourceName() {
      let showDescription = this.showDescription
      if (gridContext.isPrintMode) {
        showDescription = false
      }
      return getAddlSourceName(this.data, this.addlSourceProps, showDescription)
    },
    fullSourceName() {
      return getAddlSourceName(this.data, this.addlSourceProps, true)
    },
  },
}
</script>
