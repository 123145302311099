<template>
  <div>
    <AgDataTable
        :columns="columns"
        :add-text="$t('New W2 Memo Code')"
        :edit-entity-url-query="editEntityUrlQuery"
        :url-params="urlParams"
        ref="table"
        url="/restify/w-two-codes"
        permission="employees"
        actions="search,refresh,add,edit"
        hide-actions="view"
        @add="onRowAdd"
        @edit="onRowEdit"
        @view="onRowEdit"
    >
    </AgDataTable>
    <BaseFormDialog
        v-if="showDialog"
        :title="rowToEdit ? $t('Edit W2 Memo Code') : $t('Add W2 Memo Code')"
        :open.sync="showDialog"
        @close="onEventTrigger"
    >
      <W2MemoCodeForm
          :data="rowToEdit"
          :key="renderKey"
          @cancel="onEventTrigger"
          @save="onEventTrigger"
          @create-another="renderKey++"
      />
    </BaseFormDialog>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { Column } from '@/components/ag-grid/tableTypes'
  import W2MemoCodeForm from '@/modules/payroll/components/codes/W2MemoCodeForm.vue'
  import { WTwoCode } from '@/modules/common/types/models'

  export default defineComponent({
    components: {
      W2MemoCodeForm,
    },
    data() {
      return {
        editEntityUrlQuery: '?id={ID}' as string,
        rowToEdit: null as WTwoCode | null,
        showDialog: false as boolean,
        renderKey: 1 as number,
        urlParams: {
          related: 'account,vendor',
        },
      }
    },
    computed: {
      columns(): Column[] {
        return [
          {
            headerName: this.$t('Code'),
            field: 'attributes.code',
            minWidth: 160,
            maxWidth: 300,
            component: 'EntityLink',
            redirectTo: '/payroll/codes/w2-memo-codes?id={ID}',
          },
          {
            label: this.$t('Box Number'),
            field: 'attributes.box_number',
            minWidth: 100,
            maxWidth: 140,
          },
          {
            headerName: this.$t('Box Code'),
            field: 'attributes.box_code',
            minWidth: 100,
            maxWidth: 140,
          },
          {
            headerName: this.$t('Start Year'),
            field: 'attributes.start_year',
            minWidth: 100,
            maxWidth: 100,
          },
          {
            headerName: this.$t('Description'),
            field: 'attributes.description',
            minWidth: 160,
            maxWidth: 300,
          },
          {
            headerName: this.$t('Active'),
            field: 'attributes.active',
            component: 'Status',
            minWidth: 100,
            maxWidth: 100,
          },
        ]
      },
    },
    methods: {
      onRowEdit(row: WTwoCode) {
        this.showDialog = true
        this.rowToEdit = row
      },
      onRowAdd() {
        this.showDialog = true
      },
      refreshTable() {
        // @ts-ignore
        this.$refs.table.refresh()
      },
      onEventTrigger() {
        this.rowToEdit = null
        this.showDialog = false
        this.refreshTable()
      },
    },
  })
</script>
