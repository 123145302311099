<template>
  <div class="job-transaction-proof-row">
    <div class="flex justify-between w-full leading-4">
      <div v-if="level === 0" class="flex space-x-6">
        <div class="flex space-x-6">
      <span class="font-medium">
        {{ $t('Journal:') }} #{{ journal.code }} {{ journal.description }}
      </span>
          <span>
        {{ $t('Reference Date:') }} {{ $formatDate(journal.reference_date) }}
      </span>
          <span>
        {{ $t('Period:') }} {{ getPeriodName(journal.period) }}
      </span>
          <span>
        {{ $t('Fiscal Year:') }} {{ journal.fiscal_year }}
      </span>
        </div>
      </div>
      <div v-if="level === 1" class="flex space-x-6">
        <JobLink :id="job.id" class="job-link"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    data() {
      return this.params.node?.allLeafChildren[0]?.data || {}
    },
    errors() {
      return this.data.invoice?.errors || []
    },
    job() {
      return this.data?.job || {}
    },
    journal() {
      return this.data?.journal || {}
    },
    level() {
      return this.params.node.level
    }
  },
  methods: {
    getPeriodName(period) {
      return this.$store.getters['company/getPeriodName'](period)
    }
  }
}
</script>
<style lang="scss">
.job-transaction-proof-row {
  .job-link a span {
    @apply font-medium text-sm;
  }
}
</style>
