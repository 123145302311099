<template>
  <InvoiceDetails :invoice="params.data" class="p-4"/>
</template>
<script>
import InvoiceDetails from "@/modules/accounts-payable/pages/invoices/InvoiceDetails.vue";

export default {
  components: {
    InvoiceDetails
  }
}
</script>
