<template>
  <div>
    <FinancialReportForm
      ref="form"
      :financial-report="currentResource"
    />
  </div>
</template>
<script setup lang="ts">
  import FinancialReportForm from "@/modules/ledger/pages/settings/financial-reports/FinancialReportForm.vue"
  import {computed, ref} from 'vue'

  import store from '@/store'
  import {onBeforeRouteLeave} from "vue2-helpers/vue-router";
  import {$confirm} from "@/components/common/modal/modalPlugin";
  import i18n from "@/i18n";

  const currentResource = computed(() => {
    return store.state.generalLedger.currentFinancialReport || {}
  })

  const form = ref()

  onBeforeRouteLeave(async (to, from, next) => {
    const unsavedData = form.value?.hasUnsavedData?.()
    if (!unsavedData) {
      next()
    } else {
      const confirm = await $confirm({
        title: i18n.t('Are you sure you want to leave this page?'),
        description: i18n.t('You have unsaved data. Are you sure you want to leave this page?'),
        buttonText: i18n.t('Lave Page without saving'),
      })
      if (confirm) {
        next()
      }
    }
  })
</script>
