import { AccrualMethodLabels, AccrualMethods } from "@/modules/payroll/utils/accrualMethods";
import pluralize from "pluralize";
import { codeTypes } from "@/modules/payroll/components/rates/util";

export function getRuleText(rule, accrualMethod) {
  const { rate_unit, rate } = rule
  let accrualMethodLabel = AccrualMethodLabels[accrualMethod] || ''
  accrualMethodLabel = accrualMethodLabel.replace('Per ', '')
  let hoursLabel = pluralize('hour', +rate)
  return `${rate} ${hoursLabel} earned per ${rate_unit} ${accrualMethodLabel}`.toLowerCase()
}

export function getSpecialSourceDurationValidation({ duration, type, timeOffData }) {
  const isTimeOff = type === codeTypes.TIME_OFF
  const isPayCode = type === codeTypes.PAY
  if (isPayCode) {
    return `min_value:0`
  }
  if (!isTimeOff) {
    const maxUnits = duration / 60
    return `max_value:${maxUnits}`
  }
  const balance = timeOffData?.status?.balance || 0
  const allow_time_borrowing = timeOffData?.status?.allow_time_borrowing
  const isUnlimited = timeOffData?.status?.accrual_method === AccrualMethods.Unlimited
  if (allow_time_borrowing || isUnlimited) {
    return `min_value:0`
  }
  return `min_value:0|max_value:${balance}`
}
