<template>
  <div class="flex items-center">
    <post-button @click="showDialog = true"
                 :loading="loading"
                 v-bind="$attrs"
    >
      <slot name="button-text">
        <span>
          {{$t('Post')}}
        </span>
      </slot>
    </post-button>
    <BaseFormDialog v-if="showDialog"
                    :title="getPostActionTitle"
                    :open.sync="showDialog"
                    :appendToBody="true"
    >
      <PostDialog v-bind="$attrs"
                  :show-cancel="false"
                  :resource-name="resourceName"
                  @cancel="showDialog = false"
                  @save="onSubmit"
      >
        <template #custom-message>
          <slot name="custom-message"/>
        </template>
      </PostDialog>
    </BaseFormDialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import PostDialog from '@/components/common/modal/PostDialog'
  import { refreshGlobalResourcesByModule } from "@/utils/modules";

  export default {
    components: {
      PostDialog,
    },
    props: {
      entityName: {
        type: String,
        default: 'Invoice',
      },
      resourceName: {
        type: String,
        default: 'invoices',
      },
      actionUrl: {
        type: String,
      },
      id: {
        type: String || Number,
        default: '',
      },
      actionName: {
        type: String
      },
      submitFunction: Function,
    },
    data() {
      return {
        showDialog: false,
        loading: false,
      }
    },
    computed: {
      getPostActionTitle() {
        return this.$t(`Post ${this.entityName}`)
      },
      getActionName() {
        if (this.actionName) {
          return this.actionName
        }
        return `post-${this.resourceName}`
      },
    },
    methods: {
      async onSubmit(data = {}) {
        try {
          this.loading = true
          this.showDialog = false

          if (this.submitFunction) {
            await this.submitFunction(data)
          } else {
            const payload = {
              repositories: [this.id],
              ...data,
            }

            let url = `/restify/${this.resourceName}/action?action=${this.getActionName}`
            if (this.actionUrl) {
              url = this.actionUrl
            }

            await axios.post(url, payload)
            await refreshGlobalResourcesByModule(this.$currentModule)
          }
        } catch (e) {
          if (e.handled) {
            return
          }
          console.warn(e)
          this.$error(this.$t(`Could not post ${this.resourceName}.`))
        } finally {
          this.loading = false
          this.$emit('on-action-callback', this.id)
        }
      },
    },
  }
</script>
