<template>
  <EntitySelect
    url="/restify/vendors"
    :table-columns="tableColumns"
    :transformData="transformData"
    :dataFilters="dataFilters"
    v-bind="$attrs"
    v-on="$listeners"
    :url-params="allUrlParams"
    :filter-options="filterOptions"
    :name="$attrs.name || $t('Vendor')"
    :placeholder="$attrs.placeholder || $t('Select Vendor')"
    :label="$attrs.label !== undefined ? $attrs.label : $t('Vendor')"
    :add-label="$t('Add new Vendor')"
    :add-entity="addEntity"
    :edit-entity="editEntity"
    :set-default-option="editEntity"
    label-key="code"
    class="w-full"
    :id="$attrs.id || 'vendor-select'"
    :label-format="formatVendorLabel"
  />
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    urlParams: {
      type: Object,
      default: () => ({
        sort: 'code'
      })
    },
    excludeIds: {
      type: Array,
      default: () => []
    },
    availableIds: {
      type: Array,
      default: () => []
    },
    addEntity: {
      type: Boolean,
      default: true
    },
    editEntity: {
      type: Boolean,
      default: false,
    },
    showOpenAmount: {
      type: Boolean,
      default: false,
    },
    showStatusColumn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataFilters: {
        showActive: true
      }
    }
  },
  computed: {
    tableColumns() {
      const columns = [
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Code'),
          prop: 'attributes.code'
        },
        {
          minWidth: 200,
          maxWidth: 200,
          name: this.$t('Name'),
          prop: 'attributes.name'
        },
        {
          minWidth: 100,
          maxWidth: 150,
          name: this.$t('Open Amount'),
          prop: 'attributes.current_ap_amount',
          toFormat: this.$formatPrice,
          showIf: () => this.showOpenAmount
        },
        {
          minWidth: 120,
          maxWidth: 140,
          name: this.$t('Status'),
          prop: 'attributes.status',
          component: 'Status',
          filter: {
            type: 'switch',
            prop: 'showActive',
            tooltip: this.$t('Only show active vendors'),
            onChange: (value) => {
              this.dataFilters.showActive = value
            },
          },
          showIf: () => this.showStatusColumn,
        },
      ]

      return columns.filter(c => !c.showIf || c.showIf())
    },
    allUrlParams() {
      if (!this.showStatusColumn) {
        return this.urlParams
      }

      return {
        ...this.urlParams,
        status: this.dataFilters.showActive ? 'active' : null
      }
    },
    showInactiveSetting() {
      return this.$store.getters['settings/getFilterSetting']('show_inactive_vendors')
    },
  },
  watch: {
    showInactiveSetting: {
      handler() {
        this.dataFilters.showActive = !this.showInactiveSetting
      },
      immediate: true
    }
  },
  methods: {
    transformData(data) {
      return data.map((vendor) => ({
        ...vendor,
        isInactive: vendor.attributes.status !== 'active',
      }))
    },
    filterOptions(options) {
      let filteredOptions = [...options]

      if (this.availableIds?.length) {
        filteredOptions = filteredOptions.filter(o => {
          return this.availableIds.includes(o.id)
        })
      }

      if (this.excludeIds.length) {
        filteredOptions = filteredOptions.filter(o => {
          return !this.excludeIds.includes(o.id)
        })
      }

      return filteredOptions
    },
    formatVendorLabel(account) {
      const { code, name } = account?.attributes || {}
      let label = code
      if (name) {
        label += ` (${name})`
      }
      return label
    }
  },
}
</script>
