<template>
  <EmployeeTimeOffSelect
    ref="select"
    v-focus
    v-model="selectedValue"
    :show-full-label="false"
    :employee-id="params.employeeId"
    inline-errors
    class="w-full"
    @change="onChange"
    @keydown.enter.native="goToNextCell"
  />
</template>
<script>

import { selectEditorMixin } from "@/components/ag-grid/cellEditors/selectEditorMixin";
import EmployeeTimeOffSelect from "@/components/select/entities/EmployeeTimeOffSelect.vue";

export default {
  name: 'EmployeeTimeOffCell',
  components: { EmployeeTimeOffSelect },
  mixins: [selectEditorMixin],
}
</script>
