<template>
  <div>
    <portal
      v-if="expandedSections.length === 0 && model.number"
      to="page-title"
    >
      {{ $t('Invoice') }} #{{ model.number }},&nbsp;@
      <RouterLink :to="`/accounts-payable/vendors/${selectedVendor.id}/invoices`">
        {{ selectedVendor.code }}
        <span v-if="selectedVendor.name">({{ selectedVendor.name }})</span>
      </RouterLink>
    </portal>
    <BaseForm
      :loading="loading"
      :show-back="showBack"
      :show-cancel="showBack"
      :can-create-another-entity="!model.id"
      :save-text="$t('Create invoice')"
      :update-text="$t('Update invoice')"
      :sticky-header="expandedSections.length === 0"
      :submit-disabled="gridContext.loading"
      entity="Invoice"
      submit-button-type="button"
      grid-classes="grid grid-cols-8 gap-x-3"
      layout="vertical"
      ref="baseForm"
      @cancel="onCancel"
      @submit="onSubmit"
    >
      <template #header>
        <ElCollapse v-model="expandedSections"
                     class="col-span-8">
          <BaseCollapseSection key="form-header"
                                 name="form-header"
                                 class="-m-6 mb-6"
                                 has-background
          >
            <template #title>
              <div class="flex w-full items-center justify-between ml-2">
                <InvoiceFormHeader :invoice="model"/>
                <div class="mx-2">
                  {{ $t('Amount') }}:&nbsp;
                  {{ $formatPrice(model.gross_amount) }}
                </div>
              </div>
            </template>
            <InvoiceProcessingInput
              v-if="$route.query.beta"
              class="print:hidden"
              :vendor-id="model.vendor_id"
              @parsed="onInvoiceProcessed"
            />
            <div class="grid grid-cols-8 gap-x-4 pt-4 px-4">
              <VendorSelect v-model="model.vendor_id"
                            :disabled="!!model.id"
                            :edit-entity="!model.id"
                            :initial-value="get(invoice, 'relationships.vendor') || selectedVendor"
                            rules="required"
                            id="vendor"
                            class="col-span-8 md:col-span-2"
                            @change="onVendorChanged"
              />

              <BaseInput v-model="model.description"
                          :label="$t('Description')"
                          :placeholder="$t('Description')"
                          id="description"
                          rules="max:150"
                          class="col-span-8 md:col-span-3"
              />
              <BaseInput v-model="model.gross_amount"
                          :label="$t('Amount')"
                          :placeholder="$t('Amount')"
                          :min="-9999999999.99"
                          :step="1"
                          :max="9999999999.99"
                          id="gross_amount"
                          type="number"
                          format="price"
                          rules="required|max_value:9999999999.99|min_value:-9999999999.99"
                          class="col-span-8 md:col-span-2"
              />
              <div class="col-span-8">
                <h5 class="form-section-title">
                  {{ $t('Invoice Configuration') }}
                </h5>
              </div>
              <BaseInput v-model="model.discount_percent"
                          :label="$t('Discount')"
                          :placeholder="$t('Discount')"
                          id="discount"
                          :min="0"
                          :max="100"
                          :step="0.00001"
                          type="number"
                          format="percent"
                          rules="max_value:100|min_value:0"
                          class="col-span-8 md:col-span-1"
              />
              <BaseInput v-model="model.retention_percent"
                          :label="$t('Retention')"
                          :placeholder="$t('Retention')"
                          id="retention"
                          :min="0"
                          :max="100"
                          :step="0.01"
                          type="number"
                          format="percent"
                          rules="max_value:100|min_value:0"
                          class="col-span-8 md:col-span-1"
              />
              <base-select v-model="model.type"
                           :label="$t('Type')"
                           :placeholder="$t('Type')"
                           :options="invoiceTypeOptions"
                           id="type"
                           class="col-span-8 md:col-span-1"
              />
              <BankSelect
                v-if="model.type === InvoiceTypes.Manual"
                v-model="model.bank_id"
                :name="$t('Bank')"
                :label="$t('Bank')"
                :used-for="BankUsedInTypes.AccountsPayable"
                id="bank_id"
                rules="required"
                class="col-span-8 md:col-span-2"
              />
              <BaseSwitch v-model="model.should_post"
                           :label-info="$t('Post Automatically')"
                           :placeholder="$t('Post Automatically')"
                           id="should_post"
                           class="col-span-8 md:col-span-1"
              />
              <div class="col-span-8">
                <h5 class="form-section-title">
                  {{ $t('Schedule') }}
                </h5>
                <RecurringScheduleInputs
                  v-model="model.recurring_rule"
                  :start-date.sync="model.start_date"
                />

                <div class="flex items-center mt-4">
                  <span class="mr-2 text-sm">
                    {{ $t('Schedule Rule: ') }}
                  </span>
                  <div class="flex items-center">
                    <RRuleText
                      v-if="model?.recurring_rule"
                      :value="model?.recurring_rule"
                      :invoice="invoice"
                      class="font-medium text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </BaseCollapseSection>
        </ElCollapse>
      </template>
      <InvoiceEntries
        ref="entriesTable"
        endpoint="/restify/recurring-invoice-entries"
        parentEntityField="recurring_invoice_id"
        :data="model"
        :vendor="selectedVendor"
        class="col-span-8"
        @on-collapse-form-header="collapseFormHeader"
      />
      <FileAttachments
        v-if="false"
        ref="attachments"
        :entity-id="entityId"
        entity="recurring-invoices"
        class="col-span-8 mt-8"
      />
    </BaseForm>
  </div>
</template>
<script>
import axios from 'axios'
import { BankUsedInTypes, resourceStatuses } from '@/enum/enums'
import { invoiceTypeOptions, InvoiceTypes } from '@/modules/accounts-payable/enum/invoice'
import InvoiceWarnings from '@/modules/accounts-payable/components/invoice/InvoiceWarnings'
import InvoiceFormHeader from '@/modules/accounts-payable/components/invoice/InvoiceFormHeader'
import InvoiceEntries from '@/modules/accounts-payable/components/invoice/InvoiceEntries.vue';
import { validateAgDataTable } from '@/components/ag-grid/tableUtils'
import { globalResources, } from "@/components/form/util";
import InvoiceNumberInput from "@/modules/accounts-payable/components/invoice/InvoiceNumberInput.vue";
import InvoiceProcessingInput from "@/modules/accounts-payable/components/invoice/InvoiceProcessingInput.vue";
import { getTimezone } from "@/modules/payroll/utils/timeCardUtils";
import RRuleText from "@/modules/accounts-payable/components/recurring-invoice/RRuleText.vue";
import RecurringScheduleInputs
  from "@/modules/accounts-payable/components/recurring-invoice/RecurringScheduleInputs.vue";
import RecurringScheduleTable from "@/modules/accounts-payable/components/recurring-invoice/RecurringScheduleTable.vue";
import { ApiDateFormat } from "@/plugins/dateFormatPlugin";
import { gridContext } from "@/components/ag-grid/gridContext";

export default {
  components: {
    RecurringScheduleTable,
    RRuleText,
    RecurringScheduleInputs,
    InvoiceProcessingInput,
    InvoiceNumberInput,
    InvoiceEntries,
    InvoiceWarnings,
    InvoiceFormHeader,
  },
  props: {
    invoice: {
      type: Object,
      default: () => ({}),
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    collapseHeader: {
      type: Boolean,
    },
  },
  data() {
    return {
      resourceStatuses,
      InvoiceTypes,
      invoiceTypeOptions,
      expandedSections: [],
      loading: false,
      parsing: false,
      loadVendor: false,
      selectedVendor: {},
      entityId: null,
      showScheduleTable: false,
      BankUsedInTypes,
      model: {
        type: InvoiceTypes.Normal,
        start_date: new Date(),
        bank_id: null,
        recurring_rule: '',
        vendor_id: undefined,
        description: '',
        gross_amount: 0,
        discount_percent: 0,
        retention_percent: 0,
        issued_days_before: 0,
        due_date_days_after: 0,
        discount_date_days_after: 0,
        should_post: false,
        tags: [],
        timezone: getTimezone(),
      },
      gridContext,
    }
  },
  computed: {
    selectedVendor() {
      return this.$store.getters['globalLists/getResourceById'](globalResources.Vendors, this.model.vendor_id)
    }
  },
  methods: {
    async onVendorChanged() {
      if (!this.selectedVendor?.discount_percent) {
        return
      }
      this.model.discount_percent = parseFloat(this.selectedVendor?.discount_percent).toFixed(2) || 0
    },
    onCancel() {
      return this.$router.push('/accounts-payable/recurring-invoices/pending')
    },
    refreshInvoiceFromStore() {
      this.$store.dispatch('accountsPayable/getRecurringInvoice', this.model.id)
    },
    async onSubmit() {
      try {
        const isInvalidData = await validateAgDataTable()
        if (isInvalidData) {
          return
        }

        this.loading = true
        const requestData = {
          ...this.model,
          start_date: this.$formatDateOnly(this.model.start_date, ApiDateFormat),
        }
        if (this.model.id) {
          await axios.put(`/restify/recurring-invoices/${this.model.id}`, requestData)
          await this.$refs.entriesTable.storeProgress(this.model.id)
          this.$success(this.$t('Recurring Invoice updated'))

          this.refreshInvoiceFromStore()
          // await this.$addSystemGeneratedNote({
          //   resourceName: RestifyResources.RecurringInvoices,
          //   resourceId: this.model.id,
          //   isEdit: true,
          // })
        } else {
          const { data } = await axios.post('/restify/recurring-invoices', requestData)

          await this.$refs.entriesTable.storeProgress(data.id)
          this.$success(this.$t('Recurring Invoice created'))
          // await this.$addSystemGeneratedNote({
          //   resourceName: RestifyResources.RecurringInvoices,
          //   resourceId: data.id,
          // })
          this.entityId = data.id
          this.model.id = data.id
          // await this.$refs.attachments.triggerUpload()
          this.$createAnotherEntity ? this.$emit('create-another') : await this.$router.push('/accounts-payable/recurring-invoices/pending')
        }
      } catch (err) {
        this.loading = false
        if (!this.invoice?.id && err?.config?.url !== '/restify/recurring-invoices') {
          await this.$router.push(`/accounts-payable/recurring-invoices/${this.model.id}/edit`)
        }

        console.warn(err)
        if (err.handled) {
          return
        }
        this.$error(this.$t('Something went wrong. Please try again.'))
      } finally {
        this.loading = false
      }
    },
    tryPrefillVendor() {
      const { vendor_id } = this.$route.query
      if (this.data?.id || this.$route.params.id) {
        return
      }
      this.model.vendor_id = vendor_id
      this.onVendorChanged()
    },
    async collapseFormHeader() {
      if (!this.expandedSections.length) {
        return
      }

      const isValidForm = await this.$refs.baseForm?.validate()

      if (!isValidForm) {
        return
      }

      this.expandedSections = []
    },
    async onInvoiceProcessed(model, entries) {
      if (!entries.length || !model) {
        return
      }
      this.model = {
        ...this.model,
        ...model
      }
      await this.$refs.entriesTable?.pushParsedLineItems(entries)
    }
  },
  mounted() {
    this.tryPrefillVendor()
    if (this.collapseHeader) {
      this.collapseFormHeader()
    }
  },
  watch: {
    invoice: {
      immediate: true,
      handler(value) {
        if (!value?.id && !this.collapseHeader) {
          return this.expandedSections = ['form-header']
        }
        this.model = {
          ...this.model,
          ...value.attributes,
        }
        this.collapseFormHeader()
      },
    },
  },
}
</script>
